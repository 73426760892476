import { Component, OnInit } from '@angular/core';
import { DatePipe } from '@angular/common';
import { ManufacturersNameService } from '../../../services/qa-reports/manufacturers-name.service';
import { HttpParams } from '@angular/common/http';
import { AgenciesAndInspectorsDataService } from '../../../services/qa-management/agencies-inspectors.service';
import { IAgencyData } from '../../../interfaces/qa-management/agencyData';
import { IInspectorViewData } from '../../../interfaces/qa-management/inspectorViewData';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup } from '@angular/forms';
 
@Component({
  selector: 'app-agencyratings',
  templateUrl: './agencyratings.component.html',
  styleUrls: ['./agencyratings.component.css'],
  providers: [DatePipe]
})
export class AgencyratingsComponent implements OnInit {
  startDate: string;
  endDate: string;
  selectedAgencyId: number | null = null;
  selectedAgencyName: string = '';
  selectedInspectorId: number | null = null;
  selectedInspectorName: string = '';
  public agenciesData: IAgencyData[] = [];
  public inspectorsData: IInspectorViewData[] = [];
  validationMessages: String[] = [];
  isLoading = false;
  agencyForm: FormGroup;
 
  constructor(
    private datePipe: DatePipe,
    private agenciesAndInspectorsDataService: AgenciesAndInspectorsDataService,
    private manufacturersNameService: ManufacturersNameService,
    private router: Router,
    private fb: FormBuilder
  ) { }
 
  ngOnInit(): void {
    const currentYear = new Date().getFullYear();
    this.startDate = this.datePipe.transform(new Date(currentYear, 0, 1), 'yyyy-MM-dd') || '';
    this.endDate = this.datePipe.transform(new Date(), 'yyyy-MM-dd') || '';
    this.loadAgencies();
    this.loadInspectors();
    this.agencyForm = this.fb.group({
      agency: ['agencyDefault'],
      inspector: ['inspectorDefault'],
      startDate: [this.startDate],
      endDate: [this.endDate]
    });
 
  }
 
  resetStartDate() {
    this.startDate = '';
  }
 
  resetEndDate() {
    this.endDate = '';
  }
 
  cancel() {
    this.router.navigate(['/reports-forms'], { queryParams: { tab: 'qaReports' } });
  }
 
  loadAgencies() {
    this.agenciesAndInspectorsDataService.getAllAgencies().subscribe((data) => {
      this.agenciesData = data;
    });
  }
 
  loadInspectors() {
    this.agenciesAndInspectorsDataService.getAllInspectors().subscribe((data) => {
      this.inspectorsData = data;
    });
  }
 
  getInspectorName(expeditorId: string): string {
    let inspectorName: string = "";
    this.agenciesAndInspectorsDataService
      .getInspectorNameById(expeditorId)
      .subscribe((inspName) => {
        inspectorName = inspName;
      });
    return inspectorName;
  }
 
  onAgencyChange(event: any) {
    const selectedAgencyName = event.target.value;
    this.selectedAgencyName = selectedAgencyName;
    const selectedAgency = this.agenciesData.find(agency => agency.name === selectedAgencyName);
    this.selectedAgencyId = selectedAgency?.agencyId ?? null;
  }
 
  onInspectorChange(event: any) {
    const selectedInspectorName = event.target.value;
    this.selectedInspectorName = selectedInspectorName;
    const selectedInspector = this.inspectorsData.find(inspector => inspector.fullName === selectedInspectorName);
    this.selectedInspectorId = selectedInspector ? selectedInspector.inspectorId : null;
  }
 
  generateReport() {
    this.validationMessages = [];
    this.isLoading = true;
 
    if (!this.startDate) {
      this.validationMessages.push('Start date is required.');
      this.isLoading=false;
    }
    if (!this.endDate) {
      this.validationMessages.push('End date is required.');
      this.isLoading=false;
    }
    if (new Date(this.startDate) > new Date(this.endDate)) {
      this.validationMessages.push('Start date cannot be after end date.');
      this.isLoading=false;
    }
    if (!this.selectedAgencyId && !this.selectedInspectorId) {
      this.validationMessages.push('You must select an inspector or agency.');
      this.isLoading=false;
    }
    if (this.validationMessages.length > 0) {
      return;
    }
 
    const formattedStartDate = this.datePipe.transform(this.startDate, 'yyyy-MM-dd') || '';
    const formattedEndDate = this.datePipe.transform(this.endDate, 'yyyy-MM-dd') || '';
 
    const params = new HttpParams()
      .set('StartDate', formattedStartDate)
      .set('EndDate', formattedEndDate)
      .set('AgencyID', this.selectedAgencyId || 0)
       .set('InspectorID', this.selectedInspectorId || 0);
     
 
    this.manufacturersNameService.agencyOrInspector(params).subscribe(response => {
      const blob = new Blob([response], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = `AQUA3_AgencyOrInspectorReport_${new Date().toISOString().slice(0, 19).replace(/:/g, '-')}.xlsx`;
      a.click();
      window.URL.revokeObjectURL(url);
      this.isLoading = false;
    }, error => {
      console.error('Error downloading the report', error);
      this.isLoading = false;
    });
  }
}
 