import { HttpClient } from "@angular/common/http";
import { Component, OnInit, ViewChild } from "@angular/core";
import { FormGroup, FormBuilder } from "@angular/forms";
import { MatPaginator } from "@angular/material/paginator";
import { MatTableDataSource } from "@angular/material/table";
import { IViewPerformanceTrackingData } from "../../interfaces/vendor-information/viewPerformanceTrackingData";
import { PerformanceTrackingService } from "../../services/vendor-information/performance-tracking.service";
import { Router } from "@angular/router";
import { BaseComponent } from "../../shared/base/base.component";
import { UserPermissionsService } from "../../services/common/user-permissions.service";

@Component({
  selector: "app-performance-tracking",
  templateUrl: "./performance-tracking.component.html",
  styles: [
    `
      .button-container {
        display: flex !important;
        justify-content: space-between !important;
      }

      .unchecked {
        color: red;
      }

      .mt-1 {
        margin-top: 1rem !important;
      }
    `,
  ],
})
export class PerformanceTrackingComponent
  extends BaseComponent
  implements OnInit
{
  title = "Performance Tracking";
  public dataSource: MatTableDataSource<IViewPerformanceTrackingData>;
  public filtersForm: FormGroup;

  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;

  public displayedColumns: string[] = [
    "actions",
    "projectLongName",
    "commodityCodeTrimmed",
    "commodityDescriptionPath",
    "poNumber",
    "eng",
    "pro",
    "mfg",
    "con",
  ];

  dataSourceLoading: boolean = true;

  constructor(
    private fb: FormBuilder,
    private http: HttpClient,
    private router: Router,
    private service: PerformanceTrackingService,
    userPermissionsService: UserPermissionsService
  ) {
    super(userPermissionsService);
    this.filtersForm = this.fb.group({
      commodityDescription: [""],
    });
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.updateListings();
  }

  updateListings() {
    this.dataSourceLoading = true;
    this.service.getPerformanceTrackingData().subscribe({
      next: (data) => {
        this.dataSourceLoading = true;

        this.dataSource = new MatTableDataSource<IViewPerformanceTrackingData>(
          data
        );

        this.applyFilters();

        this.dataSourceLoading = false;
        // Delay the initialization of the paginator until the data is assigned
        setTimeout(() => {
          this.dataSource.paginator = this.paginator;
          this.paginator.pageSize = 10;
        });
      },
      error: (err) => {
        console.error(err);
      },
    });
  }

  applyFilters() {
    const filters = this.filtersForm.value;

    this.dataSource.data = this.dataSource.data.filter((item) => {
      return (
        !filters.commodityDescription ||
        item.commodityDescriptionPath
          .toLowerCase()
          .includes(filters.commodityDescription.toLowerCase())
      );
    });

    this.dataSource.paginator = this.paginator;
  }

  clearFilters() {
    this.filtersForm.reset({
      commodityDescription: "",
    });
    this.applyFilters();
  }

  select(data: IViewPerformanceTrackingData) {
    this.router.navigate([
      "vendor-information/performance-tracking/preview-spt/",
      data.performanceTrackingId,
    ]);
  }
}
