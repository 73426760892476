import { Component, Input, OnInit } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { PerformanceTrackingService } from "../../../services/vendor-information/performance-tracking.service";
import { ViewPerformanceRatingData } from "../../../interfaces/vendor-information/viewPerformanceRatingData";
import { BaseComponent } from "../../../shared/base/base.component";
import { UserPermissionsService } from "../../../services/common/user-permissions.service";
import { IPerformanceRatingData } from "../../../interfaces/vendor-information/performanceRatingData";

@Component({
  selector: "procurement-tab-component",
  templateUrl: "./procurement-tab.component.html",
})
export class ProcurementTabComponent extends BaseComponent implements OnInit {
  public procurementForm: FormGroup;
  public procurementRating: ViewPerformanceRatingData[] = [];
  public average: string;
  public performanceTrackingId: number;
  public dataSourceLoading: boolean = false;

  public validationMessages: string[] = [];

  @Input() showSaving: boolean = true;

  constructor(
    private fb: FormBuilder,
    private performanceService: PerformanceTrackingService,
    userPermissionsService: UserPermissionsService
  ) {
    super(userPermissionsService);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.dataSourceLoading = true;
    // Initialize the form with empty values
    this.procurementForm = this.fb.group({
      adherenceCommercial: [""],
      adherenceCommercialComments: [""],
      subSupplierManagement: [""],
      subSupplierManagementComments: [""],
      progressReporting: [""],
      progressReportingComments: [""],
      deliveryPerformance: [""],
      deliveryPerformanceComments: [""],
      logisticsPerformance: [""],
      logisticsPerformanceComments: [""],
    });

    this.performanceService.performanceTrackingId$.subscribe((data) => {
      if (data) {
        this.performanceTrackingId = data;

        this.performanceService.loadProcurement$.subscribe(
          (procurementData) => {
            if (procurementData) {
              this.procurementRating = procurementData;

              this.updateFormValues();
              this.calculateAverage();
              this.dataSourceLoading = false;
            }
          }
        );
      }
    });
  }

  updateFormValues(): void {
    this.procurementForm.patchValue({
      adherenceCommercial: this.procurementRating.find(
        (x) => x.keyPerformanceIndicatorId === 6
      )?.rating,
      adherenceCommercialComments: this.procurementRating.find(
        (x) => x.keyPerformanceIndicatorId === 6
      )?.comments,
      subSupplierManagement: this.procurementRating.find(
        (x) => x.keyPerformanceIndicatorId === 7
      )?.rating,
      subSupplierManagementComments: this.procurementRating.find(
        (x) => x.keyPerformanceIndicatorId === 7
      )?.comments,
      progressReporting: this.procurementRating.find(
        (x) => x.keyPerformanceIndicatorId === 8
      )?.rating,
      progressReportingComments: this.procurementRating.find(
        (x) => x.keyPerformanceIndicatorId === 8
      )?.comments,
      deliveryPerformance: this.procurementRating.find(
        (x) => x.keyPerformanceIndicatorId === 9
      )?.rating,
      deliveryPerformanceComments: this.procurementRating.find(
        (x) => x.keyPerformanceIndicatorId === 9
      )?.comments,
      logisticsPerformance: this.procurementRating.find(
        (x) => x.keyPerformanceIndicatorId === 10
      )?.rating,
      logisticsPerformanceComments: this.procurementRating.find(
        (x) => x.keyPerformanceIndicatorId === 10
      )?.comments,
    });
  }

  calculateAverage(): void {
    const ratings = [
      this.procurementForm.get("adherenceCommercial")?.value,
      this.procurementForm.get("subSupplierManagement")?.value,
      this.procurementForm.get("progressReporting")?.value,
      this.procurementForm.get("deliveryPerformance")?.value,
      this.procurementForm.get("logisticsPerformance")?.value,
    ].map(Number);

    const validRatings = ratings.filter((rating) => !isNaN(rating));
    const sum = validRatings.reduce((a, b) => a + b, 0);
    this.average = (sum / validRatings.length).toFixed(1);
  }

  onSubmit(): void {
    const formValues = this.procurementForm.value;
    this.dataSourceLoading = true;

    const kpiIds = [6, 7, 8, 9, 10]; // Define the KPI IDs you want to handle

    const validationMessages = this.performanceService.validateKpiForm(
      formValues,
      kpiIds,
      this.getFormControlName.bind(this)
    );

    if (validationMessages.length > 0) {
      this.validationMessages = validationMessages;
      this.dataSourceLoading = false;
      return;
    }

    this.validationMessages = [];
    this.performanceService
      .submitKpiForm(
        formValues,
        kpiIds,
        this.procurementRating,
        this.performanceTrackingId,
        this.getFormControlName.bind(this)
      )
      .subscribe({
        next: (result) => {
          this.dataSourceLoading = false;
        },
        error: (errorMessages) => {
          this.validationMessages = errorMessages;
          this.dataSourceLoading = false;
        },
      });
  }

  private getFormControlName(
    kpiId: number,
    isComment: boolean = false
  ): string {
    switch (kpiId) {
      case 6:
        return isComment
          ? "adherenceCommercialComments"
          : "adherenceCommercial";
      case 7:
        return isComment
          ? "subSupplierManagementComments"
          : "subSupplierManagement";
      case 8:
        return isComment ? "progressReportingComments" : "progressReporting";
      case 9:
        return isComment
          ? "deliveryPerformanceComments"
          : "deliveryPerformance";
      case 10:
        return isComment
          ? "logisticsPerformanceComments"
          : "logisticsPerformance";
      default:
        return "";
    }
  }
}
