import { Component, Input, OnInit } from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { ILookupData } from "projects/aqua3/src/app/interfaces/administration/lookupData";
import { LookupDataService } from "../../../services/administration/lookup-data-service";
import { Router } from "@angular/router";
import { environment } from "projects/aqua3/src/environments/environment";
import { BusOpcosDataService } from "../../../services/administration/bus-opcos-data-service";
import { BaseComponent } from "../../../shared/base/base.component";
import { UserPermissionsService } from "../../../services/common/user-permissions.service";
const apiUrl = environment.apiUrl;

@Component({
  selector: "add-bu-data-form.component",
  templateUrl: "./add-bu-data-form.component.html",
})
export class AddBUDataFormComponent extends BaseComponent implements OnInit {
  data: ILookupData;
  editForm: FormGroup;
  opcoParent: ILookupData;
  validationMessages = [];

  constructor(
    private busOpcosDataService: BusOpcosDataService,
    private fb: FormBuilder,
    private lookupDataService: LookupDataService,
    private router: Router,
    userPermissionsService: UserPermissionsService
  ) {
    super(userPermissionsService);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.lookupDataService.addBUData$.subscribe((parentOpco) => {
      this.opcoParent = parentOpco;
    });
    this.editForm = this.fb.group({
      buName: ["", Validators.required],
      buDesc: ["", Validators.required],
    });
  }

  submitForm(): void {
    let messagesValidationsList: string[] = [];
    let formBUName: string = this.editForm.get("buName").value;
    let formBUDesc: string = this.editForm.get("buDesc").value;

    if (formBUName === "")
      messagesValidationsList.push(
        "Please enter the name of an Business Unit."
      );

    if (formBUDesc === "")
      messagesValidationsList.push(
        "Please enter a description of the Business Unit."
      );

    this.validationMessages = messagesValidationsList;

    if (this.editForm.valid && this.validationMessages.length === 0) {
      this.data = {
        name: formBUName,
        description: formBUDesc,
      };

      const opcoId: number = this.opcoParent.lookupId;
      this.busOpcosDataService.addBU(this.data, opcoId).subscribe({
        next: () => {
          this.router.navigate(["/administration/business-unit-opcos"]);
        },
        error: (response) => {
          if (
            response &&
            response.error &&
            Array.isArray(response.error) &&
            response.error.some(
              (e) => e.errorMessage && e.errorMessage.includes("already exists")
            )
          ) {
            this.validationMessages.push(
              "The Operating Company with the same name already exists."
            );
          } else {
            console.error(response);
          }
        },
      });
    }
  }

  cancel(): void {
    this.router.navigate(["/administration/business-unit-opcos"]);
  }
}
