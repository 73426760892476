import { NgModule } from "@angular/core";
import { MenuModule } from "../menu/menu.module";
import { AgenciesAndInspectorsComponent } from "./agencies-and-inspectors/agencies-and-inspectors-component";
import { AgenciesComponent } from "./agencies-and-inspectors/agencies/agencies-component";
import { BuyersEngineersQaesComponent } from "./buyers-engineers-qaes/buyers-engineers-qaes-component";
import { BuyersEngineersQaesCreateComponent } from "./buyers-engineers-qaes/buyers-engineers-qaes-create-component";
import { BuyersEngineersQaesEditComponent } from "./buyers-engineers-qaes/buyers-engineers-qaes-edit-component";
import { EditInspectorFormComponent } from "./agencies-and-inspectors/inspectors/edit-inspector-form.component";
import { InspectorsComponent } from "./agencies-and-inspectors/inspectors/inspectors-component";
import { AddInspectorFormComponent } from "./agencies-and-inspectors/inspectors/add-inspector-form.component";
import { EditAgencyFormComponent } from "./agencies-and-inspectors/agencies/edit-agency-form.component";
import { AddAgencyFormComponent } from "./agencies-and-inspectors/agencies/add-agency-form.component";
import { WorkRequestsComponent } from "./work-requests/work-requests-component";
import { ManufacturerAuditAndObservationComponent } from "./manufacturer-audit-and-observation/mao.component";
import { ManufacturerAuditAndObservationTableComponent } from "./manufacturer-audit-and-observation/mao-table-result/mao-table-result.component";
import { AddWorkRequestFormComponent } from "./work-requests/add-work-request-form.component";
import { ManufacturerAuditAndObservationDetailsComponent } from "./manufacturer-audit-and-observation/mao-details/mao-details.component";
import { EditWorkRequestFormComponent } from "./work-requests/edit-work-request-form.component";
import { RFQsComponent } from "./work-requests/rfq/rfqs-component";
import { WorkRequestActivityComponent } from "./work-requests/activity/activity-component";
import { InspectionReportsComponent } from "./work-requests/inspection-reports/inspection-reports-component";
import { WorkRequestDocumentsComponent } from "./work-requests/documents/documents-component";
import { AddManufacturerAuditAndObservationComponent } from "./manufacturer-audit-and-observation/add-mao/add-mao.component";
import { AddCommodityMAOComponent } from "./manufacturer-audit-and-observation/add-mao/add-commodity-mao/add-commodity-mao.component";
import { InspectorEvaluationComponent } from "./inspector-evaluation/inspector-evaluation.component";
import { InspectorEvaluationDetailsComponent } from "./inspector-evaluation/ie-details/ie-details.component";
import { AddCommodityIEComponent } from "./inspector-evaluation/add-ie/add-commodity-ie/add-commodity-ie.component";
import { AddInspectorEvaluationComponent } from "./inspector-evaluation/add-ie/add-ie.component";
import { UploadDocumentsComponent } from "./upload-documents/upload-documents.component";
import { UploadInspectionReportComponent } from "./upload-inspection-report/upload-inspection-report.component";
import { AddInspectionReportFormComponent } from "./work-requests/inspection-reports/add-inspection-report-form.component";
import { AddRFQFormComponent } from "./work-requests/rfq/add-rfq-form.component";
import { EditRFQFormComponent } from "./work-requests/rfq/edit-rfq-form.component";
import { EditInspectionReportFormComponent } from "./work-requests/inspection-reports/edit-inspection-report-form.component";
import { EmailIcontractComponent } from "./work-requests/email-icontract/email-icontract.component";
import { CopyWorkRequestFormComponent } from "./work-requests/copy-work-request-form.component";
import { RevisionHistoryComponent } from "./work-requests/revision-history/revision-history-component";
import { MFRRatingsComponent } from "./work-requests/mfr-ratings/mfr-ratings-component";
import { CloseiContractNoticeFormComponent } from "./work-requests/emails/close-icontract-notice-form.component";
import { AddActivityFormComponent } from "./work-requests/activity/add-activity-form.component";
import { EmailIContractToAgencyComponent } from "./work-requests/emails/email-icontract-agency.component";

const componentList = [
  AgenciesAndInspectorsComponent,
  AgenciesComponent,
  BuyersEngineersQaesComponent,
  BuyersEngineersQaesCreateComponent,
  BuyersEngineersQaesEditComponent,
  AgenciesAndInspectorsComponent,
  AgenciesComponent,
  InspectorsComponent,
  EditInspectorFormComponent,
  AddInspectorFormComponent,
  EditAgencyFormComponent,
  AddAgencyFormComponent,
  WorkRequestsComponent,
  AddWorkRequestFormComponent,
  EditWorkRequestFormComponent,
  ManufacturerAuditAndObservationComponent,
  ManufacturerAuditAndObservationTableComponent,
  ManufacturerAuditAndObservationDetailsComponent,
  AddManufacturerAuditAndObservationComponent,
  AddCommodityMAOComponent,
  InspectorEvaluationComponent,
  InspectorEvaluationDetailsComponent,
  AddInspectorEvaluationComponent,
  AddCommodityIEComponent,
  RFQsComponent,
  RevisionHistoryComponent,
  WorkRequestActivityComponent,
  InspectionReportsComponent,
  WorkRequestDocumentsComponent,
  UploadDocumentsComponent,
  UploadInspectionReportComponent,
  AddInspectionReportFormComponent,
  EditInspectionReportFormComponent,
  AddRFQFormComponent,
  EditRFQFormComponent,
  EmailIcontractComponent,
  CopyWorkRequestFormComponent,
  MFRRatingsComponent,
  CloseiContractNoticeFormComponent,
  EmailIContractToAgencyComponent,
  AddActivityFormComponent,
];

@NgModule({
  imports: [MenuModule],
  declarations: [componentList],
})
export class QaManagementModule {}
