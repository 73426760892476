import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { MsaDataService } from "../../services/administration/msa-data-service";
import { BaseMsaData } from "../../interfaces/administration/msa/baseMsaData";
import { AgenciesAndInspectorsDataService } from "../../services/qa-management/agencies-inspectors.service";
import { IAgencyData } from "../../interfaces/qa-management/agencyData";
import { BudgetScopesData } from "../../interfaces/administration/msa/budgetScopesData";
import { MSABudgetUpdateData } from "../../interfaces/administration/msa/msaBudgetUpdateData";
import { MSADetailsData } from "../../interfaces/administration/msa/msaDetailsData";
import { BaseComponent } from "../../shared/base/base.component";
import { UserPermissionsService } from "../../services/common/user-permissions.service";

@Component({
  selector: "msa-component",
  templateUrl: "./msa.component.html",
  styles: [
    `
      .compact-form .form-group {
        margin-bottom: 5px;
      }

      .compact-input {
        padding: 5px;
        margin: 0;
        width: 100%;
        box-sizing: border-box;
      }

      .compact-table th,
      .compact-table td {
        padding: 5px;
        text-align: left;
      }

      .compact-table th {
        background-color: #f2f2f2;
      }

      button {
        padding: 5px 10px;
        margin: 2px;
      }

      .button-container {
        display: flex !important;
        justify-content: space-between !important;
      }
    `,
  ],
})
export class MSAComponent extends BaseComponent implements OnInit {
  public filterForm: FormGroup;
  public budgetEntries: MSADetailsData[] = [];
  public newEntry: MSADetailsData = this.createEmptyEntry();
  public agencies: IAgencyData[] = [];
  public scopes: BudgetScopesData[] = [];
  public isEditing: boolean = false;
  public showNewBudgetForm: boolean = false;
  public originalEntry: MSADetailsData | null = null;
  public validationMessages: string[] = [];

  constructor(
    private fb: FormBuilder,
    private service: MsaDataService,
    private agenciesService: AgenciesAndInspectorsDataService,
    userPermissionsService: UserPermissionsService
  ) {
    super(userPermissionsService);
    this.filterForm = this.fb.group({
      budgetYear: [""],
      agency: [""],
      scope: [""],
    });
  }

  ngOnInit() {
    super.ngOnInit();
    this.loadAgencies();
    this.loadScopes();
    this.applyFilters();
  }

  loadAgencies() {
    this.agenciesService.getAllAgencies().subscribe((data) => {
      this.agencies = data;
    });
  }

  loadScopes() {
    this.service.getAllScopes().subscribe((data) => {
      this.scopes = data;
    });
  }

  applyFilters() {
    const filters = this.filterForm.value;
    const currentYear = new Date().getFullYear();
    const budgetYear = filters.budgetYear || currentYear;
    const agency = filters.agency || "0";
    const scope = filters.scope || "0";

    this.service
      .getMsaData(budgetYear, agency, scope, "Id")
      .subscribe((data) => {
        this.budgetEntries = data;
        console.log(this.budgetEntries);
      });
  }

  clearFilters() {
    this.filterForm.reset();
    this.applyFilters();
  }

  createEmptyEntry(): MSADetailsData {
    return {
      id: "",
      budgetYear: new Date().getFullYear(),
      agencyId: 0,
      msaSrNo: "",
      startDate: "",
      endDate: "",
      currentBudget: 0,
      irEstimates: 0,
      notes: "",
      editRecord: "New",
      agencyName: "",
      scopeId: 0,
      scopeDesc: "",
      createdBy: "",
      createdOn: new Date(),
      committed: 0,
      isEditing: false,
    };
  }

  editEntry(entry: MSADetailsData) {
    this.originalEntry = { ...entry }; // Store a copy of the original entry
    entry.isEditing = true;
  }

  addEntry(form: FormGroup): void {
    this.validationMessages = this.validateForm(form);
    if (this.validationMessages.length > 0) {
      return;
    }
    if (!this.budgetEntries) {
      this.budgetEntries = [];
    }
    this.service.saveMsaData(this.newEntry).subscribe((data) => {
      this.budgetEntries.push({ ...this.newEntry });
      console.log(data);
      this.newEntry = this.createEmptyEntry();
      this.showNewBudgetForm = false;
    });
  }

  saveEntry(index: number): void {
    console.log(this.budgetEntries[index]);
    this.service.updateMsaData(this.budgetEntries[index]).subscribe((data) => {
      this.budgetEntries[index] = data;
      this.budgetEntries[index].isEditing = false;
      this.originalEntry = null; // Clear the original entry
    });
  }

  cancelEdit(entry: MSADetailsData) {
    if (this.originalEntry) {
      Object.assign(entry, this.originalEntry); // Revert to the original entry
    }
    entry.isEditing = false;
    this.originalEntry = null; // Clear the original entry
  }

  getAgencyName(agencyId: number): string {
    const agency = this.agencies.find((a) => a.agencyId === agencyId);
    return agency ? agency.name : "";
  }

  getScopeDesc(scopeId: number): string {
    const scope = this.scopes.find((s) => s.id === scopeId);
    return scope ? scope.scopeDesc : "";
  }

  generateReport() {
    this.service.generateReport().subscribe((data) => {
      const blob = new Blob([data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download =
        "AQUA2_MSAs_" +
        new Date().toISOString().slice(0, 19).replace(/:/g, "-") +
        ".xls";
      a.click();
      window.URL.revokeObjectURL(url);
    });
  }

  manageBudget() {
    if (this.budgetEntries.length > 0) {
      this.budgetEntries.forEach((entry) => (entry.isEditing = true));
    }
  }

  toggleNewBudgetForm() {
    this.showNewBudgetForm = !this.showNewBudgetForm;
  }

  validateForm(form: FormGroup): string[] {
    const messages: string[] = [];
    if (!this.newEntry.budgetYear) {
      messages.push("Budget Year is required.");
    }
    if (!this.newEntry.agencyId) {
      messages.push("Agency is required.");
    }
    if (!this.newEntry.msaSrNo) {
      messages.push("MSA/SR No is required.");
    }
    if (!this.newEntry.startDate) {
      messages.push("Start Date is required.");
    }
    if (!this.newEntry.endDate) {
      messages.push("End Date is required.");
    }
    if (!this.newEntry.scopeId) {
      messages.push("Scope is required.");
    }
    if (!this.newEntry.currentBudget) {
      messages.push("Current Budget is required.");
    }
    if (!this.newEntry.committed) {
      messages.push("Committed is required.");
    }
    if (!this.newEntry.irEstimates) {
      messages.push("IR Estimates are required.");
    }
    if (!this.newEntry.notes) {
      messages.push("Notes are required.");
    }
    return messages;
  }
}
