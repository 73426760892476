import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
 
@Component({
  selector: 'reports-forms-component',
  templateUrl: './reports-forms.component.html'
})
export class ReportsFormsComponent implements OnInit {
  title = 'Reports & Forms';
  activeTab: string = 'reports';
 
  constructor(private route: ActivatedRoute) {}
 
  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      const tab = params['tab'];
      if (tab) {
        this.activeTab = tab;
      }
    });
  }
 
  setActiveTab(tabName: string): void {
    this.activeTab = tabName;
  }
}
 