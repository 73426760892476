import { Injector, NgModule } from "@angular/core";
import { HomeComponent } from "./home/home.component";
import { AppComponent } from "./app.component";
import { HTTP_INTERCEPTORS, HttpClientModule } from "@angular/common/http";
import { FAQComponent } from "./help/faq/faq.component";
import { ContactComponent } from "./help/contact/contact.component";
import { HelpComponent } from "./help/help/help.component";
import {
  IPublicClientApplication,
  PublicClientApplication,
  InteractionType,
} from "@azure/msal-browser";
import {
  MSAL_INSTANCE,
  MsalGuardConfiguration,
  MSAL_GUARD_CONFIG,
  MsalService,
  MsalBroadcastService,
  MsalGuard,
  MsalRedirectComponent,
  MsalInterceptor,
  MSAL_INTERCEPTOR_CONFIG,
  MsalInterceptorConfiguration,
  MsalModule,
} from "@azure/msal-angular";
import { msalConfig, loginRequest, protectedResources } from "./auth-config";
import { getClaimsFromStorage } from "./utils/storage-utils";
import { GraphService } from "./graph.service";
import { VendorDataService } from "./services/vendor-data.service";
import { TableSortService } from "./services/table-sort.service";
import { CountriesAndStatesService } from "./services/countries-states.service";
import { AdministrationModule } from "./administration/administration.module";
import { MenuModule } from "./menu/menu.module";
import { CustomErrorComponent } from "./shared/error-page/custom-error-page.component";
import { HttpErrorInterceptor } from "./interceptors/http-error-interceptor";
import { QaManagementModule } from "./qa-management/qa-management.module";
import { ApprovedVendorListModule } from "./approved-vendor-list/approved-vendor-list.module";
import { environment } from "../environments/environment";
import { VendorInformationModule } from "./vendor-information/vendor-information.module";
import { ReportsFormsModule } from "./reports-forms/reports-forms.module";

const componentList = [
  AppComponent,
  FAQComponent,
  ContactComponent,
  HelpComponent,
  HomeComponent,
  CustomErrorComponent,
];

@NgModule({
  declarations: [componentList],
  imports: [
    HttpClientModule,
    AdministrationModule,
    ApprovedVendorListModule,
    VendorInformationModule,
    QaManagementModule,
    ReportsFormsModule,
    VendorInformationModule,
    MsalModule,
    MenuModule,
  ],
  exports: [],
  bootstrap: [AppComponent, MsalRedirectComponent],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true,
    },
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory,
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: MsalGuardConfigurationFactory,
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: MSALInterceptorConfigFactory,
    },
    ...(environment.production
      ? [
          {
            provide: HTTP_INTERCEPTORS,
            useClass: HttpErrorInterceptor,
            multi: true,
          },
        ]
      : []),
    VendorDataService,
    MsalService,
    MsalBroadcastService,
    MsalGuard,
    GraphService,
    TableSortService,
    CountriesAndStatesService,
  ],
})
export class AppTest {
  constructor(private injector: Injector) {}
}

/**
 * Here we pass the configuration parameters to create an MSAL instance.
 * For more info, visit: https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-angular/docs/v2-docs/configuration.md
 */
export function MSALInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication(msalConfig);
}

/**
 * Set your default interaction type for MSALGuard here. If you have any
 * additional scopes you want the user to consent upon login, add them here as well.
 */
export function MsalGuardConfigurationFactory(): MsalGuardConfiguration {
  return {
    interactionType: InteractionType.Redirect,
    authRequest: loginRequest,
  };
}

/**
 * MSAL Angular will automatically retrieve tokens for resources
 * added to protectedResourceMap. For more info, visit:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-angular/docs/v2-docs/initialization.md#get-tokens-for-web-api-calls
 */
export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
  const protectedResourceMap = new Map<string, Array<string>>();

  protectedResourceMap.set(
    protectedResources.graphMe.endpoint,
    protectedResources.graphMe.scopes
  );
  protectedResourceMap.set(
    protectedResources.api.endpoint,
    protectedResources.api.scopes
  );

  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap,
    authRequest: (msalService, httpReq, originalAuthRequest) => {
      const resource = new URL(httpReq.url).hostname;
      let claim =
        msalService.instance.getActiveAccount()! &&
        getClaimsFromStorage(
          `cc.${msalConfig.auth.clientId}.${
            msalService.instance.getActiveAccount()?.idTokenClaims?.oid
          }.${resource}`
        )
          ? window.atob(
              getClaimsFromStorage(
                `cc.${msalConfig.auth.clientId}.${
                  msalService.instance.getActiveAccount()?.idTokenClaims?.oid
                }.${resource}`
              )
            )
          : undefined;
      return {
        ...originalAuthRequest,
        claims: claim,
      };
    },
  };
}
