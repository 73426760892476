import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { LoggedUserService } from "./common/logged-user.service";
import { BehaviorSubject, Observable } from "rxjs";
import { IAq2QaqcbuyerEngineerQae } from "../interfaces/qa-management/aq2QaqcbuyerEngineerQae";
import { environment } from "../../environments/environment";
import { FormGroup } from "@angular/forms";
import { IBuyerEngineerQaeDetailViewData } from "../interfaces/qa-management/buyerEngineerQaeDetailViewData";
const apiUrl = environment.apiUrl;

@Injectable({
  providedIn: "root",
})
export class QaqcbuyerEngineerQaeService {
  constructor(
    private http: HttpClient,
    private loggedUserService: LoggedUserService
  ) {}

  private currentBuyerEngineerQAEs: BehaviorSubject<IAq2QaqcbuyerEngineerQae> =
    new BehaviorSubject(null);
  currentBuyerEngineerQAEs$: Observable<IAq2QaqcbuyerEngineerQae> =
    this.currentBuyerEngineerQAEs.asObservable();

  public getQaqcbuyerEngineerQae() {
    return this.http.get<IAq2QaqcbuyerEngineerQae[]>(
      `${apiUrl}/qaqcbuyerengineerqae`
    );
  }

  public getQaqcbuyerEngineerQaeById(
    id: number
  ): Observable<IAq2QaqcbuyerEngineerQae> {
    return this.http.get<IAq2QaqcbuyerEngineerQae>(
      `${apiUrl}/qaqcbuyerengineerqae/${id}`
    );
  }

  public saveQaqcbuyerEngineerQae(
    newBuyer: IAq2QaqcbuyerEngineerQae
  ): Observable<any> {
    return this.http.post<IAq2QaqcbuyerEngineerQae>(
      `${apiUrl}/qaqcbuyerengineerqae`,
      newBuyer
    );
  }

  public updateQaqcbuyerEngineerQae(
    buyer: IAq2QaqcbuyerEngineerQae
  ): Observable<any> {
    return this.http.put<IAq2QaqcbuyerEngineerQae>(
      `${apiUrl}/qaqcbuyerengineerqae/${buyer.id}`,
      buyer
    );
  }

  public setCurrentAq2QaqcbuyerEngineerQae(data: IAq2QaqcbuyerEngineerQae) {
    this.currentBuyerEngineerQAEs.next(data);
  }

  public validateForm(form: FormGroup): string[] {
    let messagesValidationsList: string[] = [];

    if (form.get("cai").value != "" && form.get("cai").value.length > 4) {
      messagesValidationsList.push("CAI must be 4 characters long.");
      form.get("cai").setValue(form.get("cai").value.slice(0, 4));
    }

    if (form.get("middleInitial").value.length > 1) {
      messagesValidationsList.push("Middle Initial must be 1 character long.");
      form
        .get("middleInitial")
        .setValue(form.get("middleInitial").value[0].slice(0, 1));
    }

    if (form.get("role").value === "")
      messagesValidationsList.push("Please select Role.");

    if (form.get("lastName").value === "")
      messagesValidationsList.push("Please enter Last Name.");

    if (form.get("firstName").value === "")
      messagesValidationsList.push("Please enter First Name.");

    if (form.get("email").value === "")
      messagesValidationsList.push("Please enter Email.");

    return messagesValidationsList;
  }

  public getActiveQAEs() {
    return this.http.get<IBuyerEngineerQaeDetailViewData[]>(
      `${apiUrl}/qaqcbuyerengineerqae/activeQaes`
    );
  }

  public getActiveEngineers() {
    return this.http.get<IBuyerEngineerQaeDetailViewData[]>(
      `${apiUrl}/qaqcbuyerengineerqae/activeEngineers`
    );
  }

  public getActiveBuyers() {
    return this.http.get<IBuyerEngineerQaeDetailViewData[]>(
      `${apiUrl}/qaqcbuyerengineerqae/activeBuyers`
    );
  }
}
