import { Component, OnInit } from "@angular/core";
import { IAttachmentTypeData } from "../../interfaces/qa-management/attachmentTypeData";
import { UploadDocumentService } from "../../services/qa-management/upload-document.service";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { InspectionReportService } from "../../services/inspection-report.service";
import { BaseComponent } from "../../shared/base/base.component";
import { UserPermissionsService } from "../../services/common/user-permissions.service";

@Component({
  selector: "app-upload-inspection-report",
  templateUrl: "./upload-inspection-report.component.html",
  styles: [
    `
      .button-container {
        display: flex !important;
        justify-content: space-between !important;
      }
    `,
  ],
})
export class UploadInspectionReportComponenet
  extends BaseComponent
  implements OnInit
{
  public selectedFile: File | null = null;
  public validationMessages: string[] = [];
  constructor(
    private fb: FormBuilder,
    private inspectionReportService: InspectionReportService,
    userpermissionsService: UserPermissionsService
  ) {
    super(userpermissionsService);
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  downloadTemplate() {
    let blob = this.inspectionReportService.generateInspectionReport();

    // Create a link element to trigger the download
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = "InspectionReport_Template.xlsx";
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);
    document.body.removeChild(a);
  }

  onFileSelected(event: any) {
    const file: File = event.target.files[0];
    if (file) {
      this.selectedFile = file;
    }
  }

  async uploadFile() {
    if (!this.selectedFile) {
      this.validationMessages.push("No file selected.");
      return;
    }

    try {
      const fileContent = await this.readFile(this.selectedFile);
      const dataset = this.parseExcel(fileContent);

      // Validate dataset
      const isValid = this.inspectionReportService.validateDataset(dataset);
      if (!isValid) {
        this.validationMessages.push(
          "Please enter mandatory fields (iContractNo, Agency, Inspector, Agency Job No, Inspector Report No and Inspection Visit Date) in uploaded Spreadsheet"
        );
        return;
      }

      // Check and remove duplicates
      const dedupedDataset =
        this.inspectionReportService.checkAndRemoveDuplicates(dataset);

      this.inspectionReportService
        .uploadInspectionReport(dedupedDataset)
        .subscribe((response) => {
          this.validationMessages.push("File uploaded successfully.");
        });
      // Proceed with further processing such as sending to the server
    } catch (error) {
      this.validationMessages.push("Error processing file: ", error);
    }
  }

  private readFile(file: File): Promise<ArrayBuffer> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (event: any) => {
        resolve(event.target.result);
      };
      reader.onerror = (error) => {
        reject(error);
      };
      reader.readAsArrayBuffer(file);
    });
  }

  private parseExcel(fileContent: ArrayBuffer): any[] {
    let jsonData = this.inspectionReportService.parseExcel(fileContent);

    // Transform jsonData to match your dataset structure
    return jsonData.map((item: any) => ({
      ETCiContractNo: item["ETC iContract #"] || "",
      Agency: item["Agency"] || "",
      Inspector: item["Inspector"] || "",
      ReportInvoiceDate: item["Inspection Visit Date"] || "",
      ReportInvoiceNumber: item["Inspection Report No"] || "",
      MilesKilometers: item["Mileage"] || 0,
      CalculatedCost: item["Calculated Cost"] || 0,
      InspectionHours: item["Inspection Hours"] || 0,
      TravelHours: item["Travel Hours"] || 0,
      CoordinationHours: item["Coordination Hours"] || 0,
      OvertimeHours: item["Overtime Hours"] || 0,
      AdditionalCost: item["Other Expenses"] || 0,
      AgencyJobNo: item["Agency Job #"] || "",
    }));
  }

  clearFile() {
    this.selectedFile = null;
    this.validationMessages = [];
  }
}
