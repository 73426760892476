import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { FormBuilder, FormGroup } from "@angular/forms";

@Component({
  selector: "app-comment-box-dialog",
  styles: [
    `
      .button-container {
        display: flex !important;
        justify-content: space-between !important;
        padding: 16px;
      }

      .mat-dialog-content {
        padding: 16px;
      }

      .mat-dialog-actions {
        padding: 16px;
      }
    `,
  ],
  templateUrl: "./comment-box-dialog.component.html",
})
export class CommentBoxDialogComponent {
  commentForm: FormGroup;

  constructor(
    public dialogRef: MatDialogRef<CommentBoxDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder
  ) {
    this.commentForm = this.fb.group({
      comment: [""],
    });
  }

  onCancel(): void {
    this.dialogRef.close();
  }

  onSave(): void {
    const comment = this.commentForm.get("comment")?.value;
    this.dialogRef.close(comment);
  }
}
