import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, switchMap } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { environment } from "projects/aqua3/src/environments/environment";
import { ICommodityRequestData } from "../../interfaces/administration/commodityRequestData";
import { LoggedUserService } from "../common/logged-user.service";
import { ILoggedUserData } from "../../interfaces/common/loggedUserData";
import { IVendorCommodityViewData } from "../../interfaces/administration/vendorCommodityViewData";
import { IVendorCommodityData } from "../../interfaces/administration/vendorCommodityData";
import { IAdminHomeRequestsCount } from "../../interfaces/administration/adminHomeRequestsCount";
const apiUrl = environment.apiUrl;

@Injectable({
  providedIn: "root",
})
export class CommodityRequestsDataService {
  constructor(
    private http: HttpClient,
    private loggedUserService: LoggedUserService
  ) { }

  private performActionOnCommodityRequestDataSubject: BehaviorSubject<ICommodityRequestData> =
    new BehaviorSubject(null);
  performActionOnCommodityRequestData$: Observable<ICommodityRequestData> =
    this.performActionOnCommodityRequestDataSubject.asObservable();

  public getApprovedCommodityRequests(): Observable<ICommodityRequestData[]> {
    return this.http.get<ICommodityRequestData[]>(
      `${apiUrl}/commodityRequests/approved`
    );
  }

  public getPendingCommodityRequests(): Observable<ICommodityRequestData[]> {
    return this.http.get<ICommodityRequestData[]>(
      `${apiUrl}/commodityRequests/pending`
    );
  }

  public getCountOfCommodityRequests(): Observable<IAdminHomeRequestsCount> {
    return this.http.get<IAdminHomeRequestsCount>(`${apiUrl}/commodityRequests/count`);
  };

  public getRejectedCommodityRequests(): Observable<ICommodityRequestData[]> {
    return this.http.get<ICommodityRequestData[]>(
      `${apiUrl}/commodityRequests/rejected`
    );
  }

  performActionOnCommodityRequest(commodity: ICommodityRequestData): void {
    this.performActionOnCommodityRequestDataSubject.next(commodity);
  }

  public performAction(
    vendorCommodityData: IVendorCommodityData,
    isApproved: boolean
  ): Observable<any> {
    return this.loggedUserService.getLoggedUserData().pipe(
      switchMap((loggedUserData) => {
        // Combine loggedUserData and vendorCommodity into a single object
        const body = {
          loggedUserData: loggedUserData,
          vendorCommodityData: vendorCommodityData,
          isActionApproved: isApproved,
        };

        return this.http.post<ILoggedUserData>(
          `${apiUrl}/commodityRequests/performAction`,
          body
        );
      })
    );
  }

  public getVendorCommodityViewById(
    id: number
  ): Observable<IVendorCommodityViewData> {
    return this.http.get<IVendorCommodityViewData>(
      `${apiUrl}/commodityRequests/vendorCommoditiesView/${id}`
    );
  }
}
