import { Component, Input, OnInit } from "@angular/core";
import {
  FormGroup,
  FormBuilder,
  Validators,
  FormControl,
} from "@angular/forms";
import { ILookupData } from "projects/aqua3/src/app/interfaces/administration/lookupData";
import { Router } from "@angular/router";
import { HttpClient } from "@angular/common/http";
import { environment } from "projects/aqua3/src/environments/environment";
import { UserRequestsDataService } from "../../../services/administration/user-requests.service";
import { IUserData } from "../../../interfaces/administration/userData";
import { EmailRequestData } from "../../../interfaces/common/emailRequestData";
import { EmailTemplateService } from "../../../services/common/email-template.service";
import { EmailService } from "../../../services/common/email.service";
import { UserPermissionsService } from "../../../services/common/user-permissions.service";
import { BaseComponent } from "../../../shared/base/base.component";
const apiUrl = environment.apiUrl;

@Component({
  selector: "edit-user-request-form.component",
  templateUrl: "./edit-user-request-form.component.html",
})
export class EditUserRequestsFormComponent
  extends BaseComponent
  implements OnInit
{
  title = "User Request Details";
  user: IUserData;
  editForm: FormGroup;
  validationMessages = [];

  private radioButton_UserIsApproved: FormControl = new FormControl({
    value: "userApproved",
    disabled: false,
  });

  constructor(
    private userRequestDataService: UserRequestsDataService,
    private fb: FormBuilder,
    private router: Router,

    private emailTemplateService: EmailTemplateService,
    private emailService: EmailService,
    userPermissionsService: UserPermissionsService
  ) {
    super(userPermissionsService);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.userRequestDataService.editUserRequestsData$.subscribe((data) => {
      this.user = data;

      this.editForm = this.fb.group({
        userRequestedComments: [
          this.user.requestedComments,
          Validators.required,
        ],
        userApprovedComments: [this.user.approvedComments, Validators.required],
        userIsApproved: this.radioButton_UserIsApproved,
      });
    });
  }

  submitForm(): void {
    let messagesValidationsList: string[] = [];
    let formUserApprovedComments: string = this.editForm.get(
      "userApprovedComments"
    ).value;
    let formUserIsApproved: boolean = this.getUserIsApproved(
      this.editForm.get("userIsApproved").value
    );

    if (formUserApprovedComments === null || formUserApprovedComments === "")
      messagesValidationsList.push(
        "You must enter some comments about the approval"
      );

    this.validationMessages = messagesValidationsList;

    if (this.editForm.valid && this.validationMessages.length === 0) {
      this.user.isApproved = formUserIsApproved;
      this.user.isActive = formUserIsApproved;
      this.user.approvedByDate = new Date();
      this.user.approvedComments = formUserApprovedComments;
      this.userRequestDataService.performAction(this.user).subscribe({
        next: () => {
          this.sendEmail();
          this.router.navigate(["/administration/adminHome"]);
        },
        error: (error) => {
          console.error(error);
        },
      });
    }
  }

  sendEmail() {
    let emailList: string[] = [];

    this.userRequestDataService.getEmailNotificationsList().subscribe({
      next: (data) => {
        emailList = data;
        emailList.push(this.user.email);

        let status = this.user.isApproved ? "Approved" : "Denied";

        let emailData = {
          toAddressList: emailList,
          subject: `Access ${status}: ${this.user.lastName}, ${this.user.firstName} (${this.user.cai})`,
          templateVariables: {
            SUBJECT: `Access ${status}: ${this.user.lastName}, ${this.user.firstName} (${this.user.cai})`,
            FIRST_NAME: this.user.firstName,
            LAST_NAME: this.user.lastName,
            CAI: this.user.cai,
            EMAIL: this.user.email,
            DATE_SENT: new Date(),
            APPROVER_COMMENTS: this.user.approvedComments,
            STATUS: status,
          },
        };

        let templateName = "user-access-request-update";
        this.emailTemplateService
          .generateEmailContent(templateName, emailData.templateVariables)
          .subscribe({
            next: (emailContent) => {
              let emailRequest: EmailRequestData = {
                toAddresses: emailData.toAddressList,
                subject: emailData.subject,
                body: emailContent,
              };
              //TODO: Uncomment this code after email service is implemented
              // this.emailService.sendEmail(emailRequest).subscribe({
              //   next: (response) => {
              //     console.log("Email sent successfully", response);
              //     this.router.navigate([
              //       `/qa-management/work-requests/edit-work-request/${this.workRequestId}`,
              //     ]);
              //   },
              // });
            },
          });
      },
    });
  }

  getUserIsApproved(formUserIsApproved: string): boolean {
    return formUserIsApproved === "userApproved";
  }

  cancel(): void {
    this.router.navigate(["/administration/adminHome"]);
  }
}
