import { HttpClient } from "@angular/common/http";
import { Component, OnInit } from "@angular/core";
import { AlertService } from "../../services/administration/alert.service";
import { IAlertData } from "../../interfaces/administration/alertData";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { IVendorData } from "../../interfaces/administration/vendorData";
import { VendorDataService } from "../../services/vendor-data.service";
import { Router } from "@angular/router";

@Component({
  selector: "add-alert-component",
  templateUrl: "./add-alert.component.html",
})
export class AddAlertComponent implements OnInit {
  title = "Add new Alert";
  alertForm: FormGroup;
  vendors: IVendorData[] = [];
  validationMessages: string[] = [];

  constructor(
    private fb: FormBuilder,
    private alertService: AlertService,
    private vendorDataService: VendorDataService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.initializeForm();
    this.loadVendors();
  }

  private initializeForm() {
    this.alertForm = this.fb.group({
      alertName: ["", Validators.required],
      isActive: [false],
      vendors: [[], Validators.required],
    });
  }

  private loadVendors() {
    this.vendorDataService.getVendors().subscribe((data: IVendorData[]) => {
      this.vendors = data;
    });
  }

  private mapFormToAlertData(vendorId: number): IAlertData {
    const formValues = this.alertForm.value;

    return {
      alertId: 0,
      alertName: formValues.alertName,
      vendorId: vendorId,
      vendor: this.vendors.find((v) => v.vendorId === vendorId),
      addedDate: new Date(),
      active: formValues.isActive,
    };
  }

  onSubmit() {
    this.validationMessages = this.alertService.validateAlertForm(
      this.alertForm
    );
    if (this.validationMessages.length === 0) {
      const selectedVendorIds: number[] = this.alertForm.value.vendors;
      selectedVendorIds.forEach((vendorId) => {
        const alertData: IAlertData = this.mapFormToAlertData(vendorId);
        this.alertService.addAlert(alertData).subscribe((response) => {
          this.router.navigate(["/administration/alerts"]);
        });
      });
    }
  }

  onCancel() {
    this.router.navigate(["/administration/alerts"]);
  }
}
