import { Component, OnInit } from "@angular/core";
import { CountriesAndStatesService } from "../../services/countries-states.service";
import { Router } from "@angular/router";
import { Sort } from "@angular/material/sort";
import { ILookupData } from "../../interfaces/administration/lookupData";
import { MatTableDataSource } from "@angular/material/table";
import { TableSortService } from "../../services/table-sort.service";
import { BaseComponent } from "../../shared/base/base.component";
import { UserPermissionsService } from "../../services/common/user-permissions.service";

@Component({
  selector: "countries-states-component",
  templateUrl: "./countries-states.component.html",
})
export class CountriesAndStatesComponent extends BaseComponent implements OnInit {
  title = "Countries & States";
  links: any[] = [];
  usersTable: any[];
  countriesAndStatesPath = "administration/countries-states";

  public countriesColDef = ["name", "action"];
  public statesColDef = ["name", "action"];

  public countriesData!: MatTableDataSource<ILookupData>;
  private oryginalCountriesData!: MatTableDataSource<ILookupData>;
  public statesData!: MatTableDataSource<ILookupData>;
  private oryginalStatesData!: MatTableDataSource<ILookupData>;
  public showStatesTable: boolean = false;
  public countriesLoading: boolean = true;
  public statesLoading: boolean = false;
  public choosenCountry: ILookupData;

  constructor(
    private router: Router,
    private countriesAndStatesService: CountriesAndStatesService,
    private tableSortService: TableSortService,
    userPermissionsService: UserPermissionsService
  ) {
    super(userPermissionsService);
    const navigation = this.router.getCurrentNavigation();
    const state = navigation?.extras.state as { currentCountry: ILookupData };
    if (state) {
      this.choosenCountry = state.currentCountry;
      this.onCountryRowSelected(this.choosenCountry);
    }
  }

  ngOnInit() {
    super.ngOnInit();
    this.loadCountries();
  }

  loadCountries() {
    this.countriesAndStatesService.getAllCountries().subscribe((countries) => {
      this.oryginalCountriesData = new MatTableDataSource<ILookupData>(
        countries
      );
      this.countriesData = new MatTableDataSource<ILookupData>(countries);
      this.countriesLoading = false;
    });
  }

  editCountry(countryData: ILookupData) {
    this.countriesAndStatesService.updateCountryData(countryData);
    this.router.navigate([this.countriesAndStatesPath + "/edit-country"]);
  }

  createCountry() {
    this.router.navigate([this.countriesAndStatesPath + "/create-country"]);
  }

  confirmDeleteCountry(country) {
    if (window.confirm("Are you sure you want to delete this country?")) {
      this.deleteCountry(country);
    }
  }

  deleteCountry(countryData: ILookupData) {
    this.countriesAndStatesService
      .deleteCountry(countryData.lookupId.toString())
      .subscribe((result) => {
        this.loadCountries();
      });
  }

  createState() {
    this.router.navigate([this.countriesAndStatesPath + "/create-state"]);
  }

  editState(stateData: ILookupData) {
    this.countriesAndStatesService.updateStateData(stateData);
    this.router.navigate([this.countriesAndStatesPath + "/edit-state"]);
  }

  confirmDeleteState(state) {
    if (window.confirm("Are you sure you want to delete this state?")) {
      this.deleteState(state);
    }
  }

  deleteState(stateData: ILookupData) {
    this.countriesAndStatesService
      .deleteState(stateData.lookupId.toString())
      .subscribe((result) => {
        this.loadStates();
      });
  }

  loadStates() {
    const countryId = this.choosenCountry.lookupId.toString();
    this.statesLoading = true;
    this.countriesAndStatesService
      .getStateByCountryId(countryId)
      .subscribe((states) => {
        this.oryginalStatesData = new MatTableDataSource<ILookupData>(states);
        this.statesData = new MatTableDataSource<ILookupData>(states);
        this.showStatesTable = this.statesData.data.length > 0;
        this.statesLoading = false;
      });
  }

  onCountryRowSelected(countryData: ILookupData) {
    this.choosenCountry = countryData;
    this.countriesAndStatesService.updateCountryData(countryData);
    this.loadStates();
  }

  sortCountriesChange(event: Sort) {
    if (this.oryginalCountriesData?.data.length > 0) {
      this.countriesData.data = this.tableSortService.sortDataSource(
        this.oryginalCountriesData.data,
        event
      );
    }
  }

  sortStatesChange(event: Sort) {
    if (this.oryginalStatesData?.data.length > 0) {
      this.statesData.data = this.tableSortService.sortDataSource(
        this.oryginalStatesData.data,
        event
      );
    }
  }
}
