import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class InputNumberHelperService {
    filterInput(event) {
        var keyCode = ('which' in event) ? event.which : event.keyCode;

        var isNotWanted = (keyCode == 69 || keyCode == 101 || keyCode == 187);
        return !isNotWanted;
    };

    handlePaste(e) {
        var clipboardData, pastedData;

        clipboardData = e.clipboardData || window.ClipboardItem;
        pastedData = clipboardData.getData('Text').toUpperCase();

        if (pastedData.indexOf('E') > -1 || pastedData.indexOf('e') > -1) {
            e.stopPropagation();
            e.preventDefault();
        }
    };
}


