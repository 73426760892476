import { Component, OnInit } from "@angular/core"; // Assuming the interface is in this file
import { IVendorData } from "../../interfaces/administration/vendorData";
import { HttpClient } from "@angular/common/http";
import { ActivatedRoute, Router } from "@angular/router";
import { VendorDataService } from "../../services/vendor-data.service";
import { Observable } from "rxjs";
import { ILookupData } from "../../interfaces/administration/lookupData";
import { CountriesAndStatesService } from "../../services/countries-states.service";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { BaseComponent } from "../../shared/base/base.component";
import { UserPermissionsService } from "../../services/common/user-permissions.service";

@Component({
  selector: "app-create-vendor",
  templateUrl: "./create-vendor.component.html",
  styles: [
    `
      fieldset {
        border: none !important;
      }
    `,
  ],
})
export class CreateVendorComponent extends BaseComponent implements OnInit {
  public vendorData: IVendorData;
  countries$: Observable<ILookupData[]>;
  states$: Observable<ILookupData[]>;
  vendorForm: FormGroup;
  validationMessages: string[] = [];

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private countriesAndStatesService: CountriesAndStatesService,
    private vendorDataService: VendorDataService,
    userPermissionService: UserPermissionsService
  ) {
    super(userPermissionService);
    this.vendorForm = this.fb.group({
      vendorName: ["", Validators.required],
      vendorURL: [""],
      isSupplier: [false],
      isManufacturer: [false],
      isApprovedByETC: [false],
      isAllianceOnly: [false],
      isRML: [false],
      streetAddress: ["", Validators.required],
      countryId: ["", Validators.required],
      stateId: [{ value: 0, disabled: true }],
      newState: [""],
      countyEquivlient: [""],
      city: ["", Validators.required],
      postalCode: [""],
      url: [""],
      contactName: [""],
      emailAddress: ["", [Validators.email]],
      phoneNumber: [""],
      comments: [""],
      fax: [""],
      deactivationComments: [""],
      stateName: [""],
      countryName: [""],
      isMinorityFirm: [false],
      imaNumber: [""],
      isActive: [true],
      lastAuditDate: [null],
      auditId: [0],
      oldSupplierId: [0],
      oldManufacturerId: [0],
    });
  }

  get countryId() {
    return this.vendorForm.get("countryId");
  }

  ngOnInit() {
    super.ngOnInit();
    this.countries$ = this.countriesAndStatesService.getAllCountries();
    // Retrieve stored data
    this.vendorDataService.getVendorData().subscribe((data) => {
      if (data) {
        this.vendorData = data;
        this.vendorForm.patchValue(data);
        if (this.vendorData && this.vendorData.countryId) {
          this.onCountryChange(this.vendorData.countryId.toString());
        }

        if (this.vendorData && this.vendorData.stateId) {
          this.vendorForm.get("stateId").enable();
          this.vendorForm.patchValue({ stateId: this.vendorData.stateId });
        }
      }
    });
  }

  onCountryChange(countryId: string): void {
    const stateControl = this.vendorForm.get("stateId");
    if (countryId) {
      this.states$ =
        this.countriesAndStatesService.getStateByCountryId(countryId);
      stateControl.enable();
    } else {
      this.states$ = null;
      stateControl.disable();
    }
  }

  onSubmit() {
    const validationMessages = this.vendorDataService.validateForm(
      this.vendorForm
    );

    if (validationMessages.length === 0) {
      const formData = this.vendorForm.value as IVendorData;
      this.vendorDataService.changeVendorData(formData);
      this.router.navigate(["administration/vendors/preview"]);
    } else {
      this.validationMessages = validationMessages;
    }
  }
}
