import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "projects/aqua3/src/environments/environment";
import { BehaviorSubject, Observable } from "rxjs";
import { ProjectsAndAvls } from "../../interfaces/administration/projectsAndAvls";
import { ICapitalProjectData } from "../../interfaces/administration/capitalProjectData";

const apiUrl = environment.apiUrl;

@Injectable({
  providedIn: "root",
})
export class ProjectAndAvlService {
  private projectsAndAvlsDataSource =
    new BehaviorSubject<ProjectsAndAvls | null>(null);
  currentProjectsAndAvlsData = this.projectsAndAvlsDataSource.asObservable();

  constructor(private http: HttpClient) { }

  changeProjectsAndAvlsData(projectsAndAvlsData: ProjectsAndAvls) {
    this.projectsAndAvlsDataSource.next(projectsAndAvlsData);
  }

  public getProjectsAndAvls(): Observable<ProjectsAndAvls> {
    return this.projectsAndAvlsDataSource.asObservable();
  }

  public getData(): Observable<ProjectsAndAvls[]> {
    return this.http.get<ProjectsAndAvls[]>(`${apiUrl}/project-avl`);
  }

  public getAvlData(): Observable<ICapitalProjectData[]> {
    return this.http.get<ICapitalProjectData[]>(
      `${apiUrl}/approved-vendor-lists`
    );
  }

  public archiveAvl(data: ProjectsAndAvls) {
    return this.http.put(
      `${apiUrl}/project-avl/archive-avl/${data.approvedVendorListId}`,
      data
    );
  }

  public archiveProject(data: ProjectsAndAvls) {
    return this.http.put(
      `${apiUrl}/project-avl/archive-project/${data.projectId}`,
      data
    );
  }
}
