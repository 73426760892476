import { Component, Input, OnInit } from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { ILookupData } from "projects/aqua3/src/app/interfaces/administration/lookupData";
import { Router } from "@angular/router";
import { environment } from "projects/aqua3/src/environments/environment";
import { BusOpcosDataService } from "../../../services/administration/bus-opcos-data-service";
import { BaseComponent } from "../../../shared/base/base.component";
import { UserPermissionsService } from "../../../services/common/user-permissions.service";
const apiUrl = environment.apiUrl;

@Component({
  selector: "add-opco-data-form.component",
  templateUrl: "./add-opco-data-form.component.html",
})
export class AddOpcoDataFormComponent extends BaseComponent implements OnInit {
  data: ILookupData;
  editForm: FormGroup;
  validationMessages = [];

  constructor(
    private busOpcosDataService: BusOpcosDataService,
    private fb: FormBuilder,
    private router: Router,
    userPermissionsService: UserPermissionsService
  ) {
    super(userPermissionsService);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.editForm = this.fb.group({
      opcoName: ["", Validators.required],
      opcoDesc: ["", Validators.required],
    });
  }

  submitForm(): void {
    let messagesValidationsList: string[] = [];
    let formOpcoName: string = this.editForm.get("opcoName").value;
    let formOpcoDesc: string = this.editForm.get("opcoDesc").value;

    if (formOpcoName === "")
      messagesValidationsList.push(
        "Please enter the name of an Operating Company (OPCO)."
      );

    if (formOpcoDesc === "")
      messagesValidationsList.push(
        "Please enter a description of the Operating Company (OPCO)."
      );

    this.validationMessages = messagesValidationsList;

    if (this.editForm.valid && this.validationMessages.length === 0) {
      this.data = {
        name: formOpcoName,
        description: formOpcoDesc,
      };

      this.busOpcosDataService.addOpco(this.data).subscribe({
        next: () => {
          this.router.navigate(["/administration/business-unit-opcos"]);
        },
        error: (response) => {
          if (response && response.error && Array.isArray(response.error) && response.error.some(e => e.errorMessage && e.errorMessage.includes('already exists'))) {
            this.validationMessages.push('The Operating Company with the same name already exists.');
          } else {
            console.error(response);
          }
        },
      });
    }
  }

  cancel(): void {
    this.router.navigate(['/administration/business-unit-opcos']);
  }
}
