import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, tap } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { environment } from "projects/aqua3/src/environments/environment";
import { IUserData } from "../../interfaces/administration/userData";
import { IUserNotificationData } from "../../interfaces/administration/userNotificationData";
const apiUrl = environment.apiUrl;

@Injectable({
  providedIn: "root",
})
export class UserRequestsDataService {
  constructor(private http: HttpClient) {}

  private editUserRequestsDataSubject: BehaviorSubject<IUserData> =
    new BehaviorSubject(null);
  editUserRequestsData$: Observable<IUserData> =
    this.editUserRequestsDataSubject.asObservable();

  private actualLoggedUserDataSubject: BehaviorSubject<IUserData> =
    new BehaviorSubject(null);
  actualLoggedUserData$: Observable<IUserData> =
    this.actualLoggedUserDataSubject.asObservable();

  private smeUsersDataSubject: BehaviorSubject<IUserData[]> =
    new BehaviorSubject(null);
  smeUsersData$: Observable<IUserData[]> =
    this.smeUsersDataSubject.asObservable();

  private activeUsersDataSubject: BehaviorSubject<IUserData[]> =
    new BehaviorSubject(null);
  activeUsersData$: Observable<IUserData[]> =
    this.activeUsersDataSubject.asObservable();

  public getAllActiveUsers(): Observable<IUserData[]> {
    return this.http.get<IUserData[]>(`${apiUrl}/userRequests/all`);
  }

  public getUserByEmail(email: string): Observable<IUserData> {
    return this.http.get<IUserData>(
      `${apiUrl}/userRequests/getUserByEmail/${email}`
    );
  }

  public getUserById(id: number): Observable<IUserData> {
    return this.http.get<IUserData>(`${apiUrl}/userRequests/${id}`);
  }

  public getUserFullNameById(id: number): Observable<string> {
    return this.http.get<string>(`${apiUrl}/userRequests/fullName/${id}`);
  }

  public getPendingUserRequests(): Observable<IUserData[]> {
    return this.http.get<IUserData[]>(`${apiUrl}/userRequests/pending`);
  }

  public getSMEUsers(): Observable<IUserData[]> {
    return this.http.get<IUserData[]>(`${apiUrl}/userRequests/sme`).pipe(
      tap((users) => {
        this.smeUsersDataSubject.next(users);
      })
    );
  }

  editUserRequestsData(user: IUserData): void {
    this.editUserRequestsDataSubject.next(user);
  }

  setActualLoggedUserData(user: IUserData): void {
    this.actualLoggedUserDataSubject.next(user);
  }

  public performAction(userData: IUserData): Observable<any> {
    return this.http.post<IUserData>(
      `${apiUrl}/userRequests/performAction`,
      userData
    );
  }

  public updateUsersEmailNotifications(
    userNotifsData: IUserNotificationData[]
  ): Observable<IUserNotificationData[]> {
    return this.http.put<IUserNotificationData[]>(
      `${apiUrl}/userRequests/updateUsersEmailNotifications`,
      userNotifsData
    );
  }

  public getEmailNotificationsList(): Observable<string[]> {
    this.getAllActiveUsers().subscribe({
      next: (users: IUserData[]) => {
        let emailList: string[] = [];
        users
          .filter(
            (x) => x.emailNotification && x.isSystemAdministrator && x.isActive
          )
          .forEach((user) => {
            emailList.push(user.email);
          });
        return emailList;
      },
    });
    return null;
  }
}
