import { ChangeDetectorRef, Component, OnInit, ViewChild } from "@angular/core";
import { CapitalProjectsDataService } from "../../services/capital-projects.service";
import { MatTableDataSource } from "@angular/material/table";
import { MatPaginator } from "@angular/material/paginator";
import { TableSortService } from "../../services/table-sort.service";
import { Sort } from "@angular/material/sort";
import { GraphService } from "../../services/administration/graph.service";
import { IViewProjectAlertData } from "../../interfaces/administration/viewProjectAlertData";
import { BaseComponent } from "../../shared/base/base.component";
import { UserPermissionsService } from "../../services/common/user-permissions.service";

@Component({
  selector: "supplier-alerts-component",
  templateUrl: "./supplier-alerts.component.html",
  styles: [
    `
      .button-container {
        display: flex !important;
        justify-content: space-between !important;
      }
    `,
  ],
})
export class SupplierAlertsComponent extends BaseComponent implements OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  public dataSource: MatTableDataSource<IViewProjectAlertData>;
  public originalDataSource: MatTableDataSource<IViewProjectAlertData>;
  public dataSourceLoading: boolean = false;
  displayedColumns: string[] = ["AlertName", "AddedDate", "Active"];

  constructor(
    private tableSortService: TableSortService,
    private capitalProjectsDataService: CapitalProjectsDataService,
    private changeDetectorRefs: ChangeDetectorRef,
    private graphService: GraphService,
    userPermissionService: UserPermissionsService
  ) {
    super(userPermissionService);
  }

  loadSupplierAlerts() {
    this.capitalProjectsDataService.loadSupplierAlerts$.subscribe((alerts) => {
      this.dataSourceLoading = true;

      this.originalDataSource = new MatTableDataSource<IViewProjectAlertData>(
        alerts
      );
      this.dataSource = new MatTableDataSource<IViewProjectAlertData>(
        this.originalDataSource.data
      );

      this.dataSourceLoading = false;
      this.dataSource.paginator = this.paginator;
      this.paginator.pageSize = 10;
      this.changeDetectorRefs.detectChanges();
    });
  }

  ngOnInit(): void {
    this.dataSource = new MatTableDataSource<IViewProjectAlertData>();
    this.originalDataSource = new MatTableDataSource<IViewProjectAlertData>();

    this.loadSupplierAlerts();
  }

  announceSortChange(event: Sort) {
    this.dataSource.data = this.tableSortService.sortDataSource(
      this.originalDataSource.data,
      event
    );
  }
}
