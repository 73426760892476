import { HttpClient } from "@angular/common/http";
import { Component } from "@angular/core";

@Component({
  selector: "agencies-and-inspectors-component",
  templateUrl: "./agencies-and-inspectors-component.html",
})
export class AgenciesAndInspectorsComponent {
  title = "Agencies and Inspectors";
  links: any[] = [];
  activeTab: string = "agencies"; // Default to the first tab

  constructor(private http: HttpClient) {}

  ngOnInit(): void {}

  setActiveTab(tabName: string): void {
    this.activeTab = tabName;
  }
}
