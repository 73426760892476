import { Injectable } from "@angular/core";
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from "@angular/common/http";
import { Observable, switchMap } from "rxjs";
import { CalAngularService } from "@cvx/cal-angular";
import { environment } from "../../environments/environment";

import { getClaimsFromStorage } from "../utils/storage-utils";

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  constructor(private calService: CalAngularService) {}

  intercept(
    req: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    const resource = new URL(req.url).hostname;
    const account = this.calService.getAccount();

    let claims: string | undefined;

    if (account) {
      const key = `cc.${environment.clientId}.${account.idTokenClaims?.oid}.${resource}`;
      const storedClaims = getClaimsFromStorage(key);

      if (storedClaims) {
        claims = window.atob(storedClaims);
      }
    }

    const authRequest = {
      scopes: this.getScopes(),
      account: account,
      claims: claims,
    };

    return this.calService.getAADToken(authRequest.scopes, false).pipe(
      switchMap((result) => {
        let token = "";

        if (typeof result === "string") {
          token = result;
        } else if (result && "accessToken" in result) {
          token = result.accessToken;
        }

        const headers = req.headers
          .set("Authorization", `Bearer ${token}`)
          .append("Content-Type", "application/json")
          .append("Accept", "application/json");

        const requestClone = req.clone({
          headers,
        });

        return next.handle(requestClone);
      })
    );
  }

  private getScopes(): string[] {
    return [environment.clientId + "/user_impersonation"];
  }
}
