import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "projects/aqua3/src/environments/environment";
import { Observable } from "rxjs";
import { IAlertData } from "../../interfaces/administration/alertData";
import { FormGroup } from "@angular/forms";

const apiUrl = environment.apiUrl;

@Injectable({
  providedIn: "root",
})
export class AlertService {
  constructor(private http: HttpClient) {}

  addAlert(alert: IAlertData): Observable<IAlertData> {
    return this.http.post<IAlertData>(`${apiUrl}/alerts`, alert);
  }

  getAlerts(): Observable<IAlertData[]> {
    return this.http.get<IAlertData[]>(`${apiUrl}/alerts`);
  }

  getAlertById(alertId: number): Observable<IAlertData> {
    return this.http.get<IAlertData>(`${apiUrl}/alerts/${alertId}`);
  }

  updateAlert(alert: IAlertData): Observable<IAlertData> {
    return this.http.put<IAlertData>(`${apiUrl}/alerts`, alert);
  }

  deleteAlert(alert: IAlertData): Observable<boolean> {
    return this.http.delete<boolean>(`${apiUrl}/alerts/${alert.alertId}`);
  }

  validateAlertForm(alertForm: FormGroup<any>): string[] {
    const validationMessages: string[] = [];

    if (alertForm.get("alertName").value.length === 0) {
      validationMessages.push("Alert name is required");
    }

    if (
      alertForm.get("vendors") &&
      alertForm.get("vendors").value.length === 0
    ) {
      validationMessages.push("At least one vendor must be selected");
    }

    if (alertForm.get("vendorId") && !alertForm.get("vendorId").value) {
      validationMessages.push("Vendor is required");
    }

    return validationMessages;
  }
}
