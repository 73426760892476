import { Component, OnInit } from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { AgenciesAndInspectorsDataService } from "../../../services/qa-management/agencies-inspectors.service";
import { IAgencyData } from "../../../interfaces/qa-management/agencyData";
import { LookupDataService } from "../../../services/administration/lookup-data-service";
import { ILookupData } from "../../../interfaces/administration/lookupData";
import { CountriesAndStatesService } from "../../../services/countries-states.service";
import { LoggedUserService } from "../../../services/common/logged-user.service";
import { ILoggedUserData } from "../../../interfaces/common/loggedUserData";
import { CurrenciesDataService } from "../../../services/qa-management/currencies.service";
import { ICurrencyData } from "../../../interfaces/qa-management/currencyData";
import { NavigationCachingService } from "../../../services/qa-management/navigationCaching.service";
import { UserPermissionsService } from "../../../services/common/user-permissions.service";
import { BaseComponent } from "../../../shared/base/base.component";

@Component({
  selector: "edit-agency-form.component",
  templateUrl: "./edit-agency-form.component.html",
})
export class EditAgencyFormComponent extends BaseComponent implements OnInit {
  editForm: FormGroup;
  validationMessages = [];
  agencyData: IAgencyData;
  countryName: string = "";
  stateName: string = "";
  lastUpdatedBy: string = "";
  formEditable: boolean = false;
  public countriesData: ILookupData[] = [];
  public statesData: ILookupData[] = [];
  currenciesData: ICurrencyData[] = [];

  sourcePage: string;

  constructor(
    private fb: FormBuilder,
    private loggedUserService: LoggedUserService,
    private agenciesAndInspectorsDataService: AgenciesAndInspectorsDataService,
    private currencyDataService: CurrenciesDataService,
    private lookupsDataService: LookupDataService,
    private countriesAndStatesService: CountriesAndStatesService,
    private router: Router,
    private route: ActivatedRoute,
    userPermissionsService: UserPermissionsService
  ) {
    super(userPermissionsService);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.route.queryParams.subscribe((params) => {
      this.sourcePage = params["source"];
    });

    if (this.sourcePage == null)
      this.sourcePage = "/qa-management/agencies-and-inspectors";

    this.loadCountries();
    this.loadCurrencies();

    this.editForm = this.fb.group({
      agencyName: [
        { value: "", disabled: !this.formEditable },
        Validators.required,
      ],
      address: [{ value: "", disabled: !this.formEditable }, ,],
      country: [
        { value: "countryDefault", disabled: !this.formEditable },
        Validators.required,
      ],
      city: [{ value: "", disabled: !this.formEditable }],
      state: [
        { value: "stateDefault", disabled: !this.formEditable },
        Validators.required,
      ],
      zip: [{ value: "", disabled: !this.formEditable }],
      contact: [{ value: "", disabled: !this.formEditable }],
      taxId: [{ value: "", disabled: !this.formEditable }],
      phone: [{ value: "", disabled: !this.formEditable }],
      rateLetterDate: [{ value: null, disabled: !this.formEditable }],
      fax: [{ value: "", disabled: !this.formEditable }],
      nsCertDate: [{ value: null, disabled: !this.formEditable }],
      email: [{ value: "", disabled: !this.formEditable }],
      currency: [
        { value: "currencyDefault", disabled: !this.formEditable },
        Validators.required,
      ],
      inspectionRate: [{ value: null, disabled: !this.formEditable }],
      travelRate: [{ value: null, disabled: !this.formEditable }],
      reportRate: [{ value: null, disabled: !this.formEditable }],
      mileageRate: [{ value: null, disabled: !this.formEditable }],
      overtimeRate: [{ value: null, disabled: !this.formEditable }],
      contract: [{ value: "", disabled: !this.formEditable }],
      minority: [{ value: false, disabled: !this.formEditable }],
      preferred: [{ value: false, disabled: !this.formEditable }],
      active: [{ value: false, disabled: !this.formEditable }],
    });

    const id = this.route.snapshot.paramMap.get("id");
    if (id) {
      this.agenciesAndInspectorsDataService
        .getAgencyById(id)
        .subscribe((data) => {
          if (data) {
            this.agencyData = data;
            this.fillTheReadableForm();
          }
        });
    } else {
      this.router.navigate(["/qa-management/agencies-and-inspectors"]);
    }
  }

  loadCountries() {
    this.countriesAndStatesService.getAllCountries().subscribe((data) => {
      this.countriesData = data;

      this.loadStates("countryDefault");
    });
  }

  loadStates(selectedCountryName: string) {
    let countryId: string;

    if (selectedCountryName === "countryDefault") {
      countryId = "118";
    } else {
      const selectedCountry: ILookupData =
        this.getCountryByName(selectedCountryName);
      countryId = selectedCountry.lookupId.toString();
    }

    this.countriesAndStatesService
      .getStateByCountryId(countryId)
      .subscribe((data) => {
        this.statesData = data;
      });
  }

  onCountryChange(selectedCountryName: string) {
    this.loadStates(selectedCountryName);
  }

  getCountryByName(name: string): ILookupData | null {
    var country = this.countriesData.find((country) => country.name === name);

    if (country === undefined) return null;
    else return country;
  }

  getStateByName(name: string): ILookupData | undefined {
    return this.statesData.find((state) => state.name === name);
  }

  loadCurrencies() {
    this.currencyDataService.getCurrencies().subscribe((data) => {
      this.currenciesData = data;
    });
  }

  fillTheReadableForm(): void {
    if (this.agencyData.countryId != null)
      this.lookupsDataService
        .getLookupNameById(this.agencyData.countryId)
        .subscribe((data) => {
          if (data) {
            this.countryName = data;
          }
        });

    if (this.agencyData.stateId != null)
      this.lookupsDataService
        .getLookupNameById(this.agencyData.stateId)
        .subscribe((data) => {
          if (data) {
            this.stateName = data;
          }
        });

    let reformattedDate: string = this.reformatDate(this.agencyData.updatedOn);
    this.lastUpdatedBy = `
    Last updated by [${this.agencyData.updatedBy}] on
    ${reformattedDate}
  `;
  }

  reformatDate(date: Date): string {
    let dateTyped: Date = new Date(date);
    return dateTyped.toLocaleString("en-US", {
      month: "numeric",
      day: "numeric",
      year: "numeric",
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
      hour12: true,
    });
  }

  reformatDateWithoutHours(date: Date): string {
    let dateTyped: Date = new Date(date);
    return dateTyped.toLocaleString("en-US", {
      month: "numeric",
      day: "numeric",
      year: "numeric",
    });
  }

  backToAgenciesView(): void {
    this.router.navigate([this.sourcePage]);
  }

  toggleEdit(): void {
    this.formEditable = !this.formEditable;

    if (this.formEditable) {
      this.editForm.patchValue({
        agencyName: this.agencyData.name,
        address: this.agencyData.address,
        country: this.countryName || "countryDefault",
        city: this.agencyData.city,
        state: this.stateName || "stateDefault",
        zip: this.agencyData.postalCode,
        contact: this.agencyData.contact,
        taxId: this.agencyData.taxId,
        phone: this.agencyData.phone,
        rateLetterDate: this.formatDateForInput(this.agencyData.rateLetterDate),
        fax: this.agencyData.fax,
        nsCertDate: this.formatDateForInput(this.agencyData.nsCertDate),
        email: this.agencyData.email,
        currency: this.agencyData.currency || "currencyDefault",
        inspectionRate: this.agencyData.inspectionRate,
        travelRate: this.agencyData.travelRate,
        reportRate: this.agencyData.reportRate,
        mileageRate: this.agencyData.mileageRate,
        overtimeRate: this.agencyData.overtimeRate,
        contract: this.agencyData.contract,
        minority: this.agencyData.minority,
        preferred: this.agencyData.preferred,
        active: this.agencyData.active,
      });

      this.enableFormControls();
    }
  }

  formatDateForInput(date: Date): string {
    if (!date) return "";
    const d = new Date(date);
    const month = ("0" + (d.getMonth() + 1)).slice(-2);
    const day = ("0" + d.getDate()).slice(-2);
    const year = d.getFullYear();
    return `${year}-${month}-${day}`;
  }

  enableFormControls(): void {
    const controls = [
      "agencyName",
      "address",
      "country",
      "city",
      "state",
      "zip",
      "contact",
      "taxId",
      "phone",
      "rateLetterDate",
      "fax",
      "nsCertDate",
      "email",
      "currency",
      "inspectionRate",
      "travelRate",
      "reportRate",
      "mileageRate",
      "overtimeRate",
      "contract",
      "minority",
      "preferred",
      "active",
    ];
    controls.forEach((control) => this.editForm.get(control).enable());
  }

  submitForm(): void {
    let messagesValidationsList: string[] = [];
    let formAgencyName: string = this.editForm.get("agencyName").value;
    let formAddress: string = this.editForm.get("address").value;
    let formCountry: string = this.editForm.get("country").value;
    let formCity: string = this.editForm.get("city").value;
    let formState: string = this.editForm.get("state").value;
    let formZip: string = this.editForm.get("zip").value;
    let formContact: string = this.editForm.get("contact").value;
    let formTaxId: string = this.editForm.get("taxId").value;
    let formPhone: string = this.editForm.get("phone").value;
    let formRateLetterDate: Date =
      this.editForm.get("rateLetterDate").value == ""
        ? null
        : this.editForm.get("rateLetterDate").value;
    let formFax: string = this.editForm.get("fax").value;
    let formNsCertDate: Date = this.editForm.get("nsCertDate").value;
    let formEmail: string = this.editForm.get("email").value;
    let formCurrency: string = this.editForm.get("currency").value;
    let formInspectionRate: number = this.editForm.get("inspectionRate").value;
    let formTravelRate: number = this.editForm.get("travelRate").value;
    let formReportRate: number = this.editForm.get("reportRate").value;
    let formMileageRate: number = this.editForm.get("mileageRate").value;
    let formOvertimeRate: number = this.editForm.get("overtimeRate").value;
    let formContract: string = this.editForm.get("contract").value;
    let formMinority: boolean = this.editForm.get("minority").value;
    let formPreferred: boolean = this.editForm.get("preferred").value;
    let formActive: boolean = this.editForm.get("active").value;

    if (formAgencyName === "")
      messagesValidationsList.push("Agency Name is Required.");
    if (formInspectionRate == null)
      messagesValidationsList.push("Inspection Rate is Required.");
    if (formTravelRate == null)
      messagesValidationsList.push("Travel Rate is Required.");
    if (formReportRate == null)
      messagesValidationsList.push("Coordination Rate is Required.");
    if (formMileageRate == null)
      messagesValidationsList.push("Mileage Rate is Required.");
    if (formOvertimeRate == null)
      messagesValidationsList.push("Overtime Rate is Required.");
    if (formCurrency === "currencyDefault")
      messagesValidationsList.push("Currency is Required.");

    this.validationMessages = messagesValidationsList;
    if (this.editForm.valid && this.validationMessages.length === 0) {
      let country: ILookupData | undefined = this.getCountryByName(formCountry);
      let state: ILookupData | undefined = this.getStateByName(formState);
      this.agencyData.name = formAgencyName;
      this.agencyData.address = formAddress;
      this.agencyData.countryId = country?.lookupId;
      this.agencyData.city = formCity;
      this.agencyData.stateId = state?.lookupId;
      this.agencyData.postalCode = formZip;
      this.agencyData.contact = formContact;
      this.agencyData.taxId = formTaxId;
      this.agencyData.phone = formPhone;
      this.agencyData.rateLetterDate = formRateLetterDate;
      this.agencyData.nsCertDate = formNsCertDate;
      this.agencyData.fax = formFax;
      this.agencyData.email = formEmail;
      this.agencyData.inspectionRate = formInspectionRate;
      this.agencyData.travelRate = formTravelRate;
      this.agencyData.reportRate = formReportRate;
      this.agencyData.mileageRate = formMileageRate;
      this.agencyData.overtimeRate = formOvertimeRate;
      if (formCurrency !== "currencyDefault")
        this.agencyData.currency = formCurrency;
      this.agencyData.contract = formContract;
      this.agencyData.minority = formMinority;
      this.agencyData.preferred = formPreferred;
      this.agencyData.active = formActive;
      this.agencyData.updatedOn = new Date();
      this.loggedUserService
        .getLoggedUserData()
        .subscribe((loggedUser: ILoggedUserData | null) => {
          if (loggedUser) {
            this.agencyData.updatedBy = loggedUser.userId.toString();
            this.agenciesAndInspectorsDataService
              .updateAgency(this.agencyData)
              .subscribe({
                next: () => {
                  this.router.navigate([
                    "/qa-management/agencies-and-inspectors",
                  ]);
                },
                error: (error) => {
                  console.error(error);
                },
              });
          } else
            console.error("Cannot fetch logged user data in edit-agency form.");
        });
    }
  }

  newEntry() {
    this.router.navigate(
      ["/qa-management/agencies-and-inspectors/add-agency"],
      {
        queryParams: {
          source:
            "/qa-management/agencies-and-inspectors/edit-agency/" +
            this.agencyData.agencyId,
        },
      }
    );
  }
}
