import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { MatTableDataSource } from "@angular/material/table";
import { DatePipe, CurrencyPipe } from "@angular/common";
import { Sort } from "@angular/material/sort";
import { TableSortService } from "projects/aqua3/src/app/services/table-sort.service";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { MatPaginator, PageEvent } from "@angular/material/paginator";
import { Router } from "@angular/router";
import { IWorkRequestViewData } from "../../interfaces/qa-management/work-requests/workRequestViewData";
import { ILookupData } from "../../interfaces/administration/lookupData";
import { IAgencyData } from "../../interfaces/qa-management/agencyData";
import { ICapitalProjectData } from "../../interfaces/administration/capitalProjectData";
import { AgenciesAndInspectorsDataService } from "../../services/qa-management/agencies-inspectors.service";
import { CountriesAndStatesService } from "../../services/countries-states.service";
import { CapitalProjectsDataService } from "../../services/capital-projects.service";
import { QaqcbuyerEngineerQaeService } from "../../services/qaqc-buyer-engineer-qae.service";
import { BusOpcosDataService } from "../../services/administration/bus-opcos-data-service";
import { IBuyerEngineerQaeDetailViewData } from "../../interfaces/qa-management/buyerEngineerQaeDetailViewData";
import { IInspectorViewData } from "../../interfaces/qa-management/inspectorViewData";
import { WorkRequestsDataService } from "../../services/qa-management/work-requests/work-requests.service";
import { VendorInformationDataService } from "../../services/vendor-information-data.service";
import { IVendorInformationData } from "../../interfaces/vendor-information/vendorInformationData";
import * as ExcelJS from "exceljs";
import { ICommodity } from "../../interfaces/administration/commodity";
import { BaseComponent } from "../../shared/base/base.component";
import { UserPermissionsService } from "../../services/common/user-permissions.service";

@Component({
  selector: "work-requests-component",
  templateUrl: "./work-requests-component.html",
  styleUrls: ["./work-requests-component.css"],
})
export class WorkRequestsComponent extends BaseComponent implements OnInit, AfterViewInit {
  title = "Work Requests";
  dataSource = new MatTableDataSource<IWorkRequestViewData>();
  originalDataSource = new MatTableDataSource<IWorkRequestViewData>();

  @ViewChild("paginator") paginator: MatPaginator;
  @Output() clearCommodityCodesEvent = new EventEmitter<void>();

  listingsForm: FormGroup = this.fb.group({});

  public countriesData: ILookupData[] = [];
  public businessUnitsData: ILookupData[] = [];
  public agenciesData: IAgencyData[] = [];
  public capitalProjectsData: ICapitalProjectData[] = [];
  public qaesData: IBuyerEngineerQaeDetailViewData[] = [];
  public inspectorsData: IInspectorViewData[] = [];
  public manufacturersData: IVendorInformationData[] = [];
  listingsVisible: boolean = false;
  selectedCommodityObjects: ICommodity[] = [];
  totalData: number;

  statusMapping = [
    { id: 1, name: "Assigned" },
    { id: 2, name: "Canceled" },
    { id: 3, name: "Final" },
    { id: 4, name: "Inactive" },
    { id: 5, name: "Unassigned" },
  ];

  displayedColumns = [
    "workRequestId",
    "statusId",
    "qae",
    "receivedDate",
    "requiredDate",
    "sapexpectedLimit",
    "sapso",
    "agency",
    "inspector",
    "expeditorName",
    "vendorName",
    "commodityCode+commodityDescriptionPath",
    "po",
    "poamount",
    "vendorRef",
    "workRequestCostCenter",
    "clientBudget",
    "nextActionOn",
    "description",
    "engineerName",
    "opconame",
    "businessUnitId",
    "projectLongName",
    "etccosts",
    "closedDate",
  ];

  dataSourceLoading: boolean = true;

  constructor(
    private tableSortService: TableSortService,
    private agenciesAndInspectorsDataService: AgenciesAndInspectorsDataService,
    private fb: FormBuilder,
    private countriesAndStatesService: CountriesAndStatesService,
    private capitalProjectsService: CapitalProjectsDataService,
    private buyerEngineerQaeService: QaqcbuyerEngineerQaeService,
    private busOpcosDataService: BusOpcosDataService,
    private vendorInformationDataService: VendorInformationDataService,
    private workRequestsService: WorkRequestsDataService,
    private cdr: ChangeDetectorRef,
    private router: Router,
    userPermissionsService: UserPermissionsService
  ) {
    super(userPermissionsService);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.loadCountries();
    this.loadAgencies();
    this.loadBusinessUnits();
    this.loadCapitalProjects();
    this.loadBuyerEngineerQaes();
    this.loadInspectors();
    this.loadManufacturers();

    let formGroupConfig = {
      poNumber: [""],
      agencySRnumber: [""],
      etcChargeCode: [""],
      status: ["statusDefault", Validators.required],
      businessUnit: ["businessUnitDefault", Validators.required],
      capitalProject: ["capitalProjectDefault", Validators.required],
      agency: ["agencyDefault", Validators.required],
      qae: ["qaeDefault", Validators.required],
      inspector: ["inspectorDefault", Validators.required],
      iContractNo: [null],
      manufacturer: ["manufacturerDefault", Validators.required],
      country: ["countryDefault", Validators.required],
      description: [""],
      city: [""],
      criticality: ["criticalityDefault", Validators.required],
      commodityIds: [null],
    };

    this.listingsForm = this.fb.group(formGroupConfig);
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;

    this.paginator.page.subscribe((event: PageEvent) => {
      this.updateListings(event.pageIndex + 1, event.pageSize);
    });
  }

  getCountryById(id: number): ILookupData | null {
    var country = this.countriesData.find((country) => country.lookupId === id);

    if (country === undefined) return null;
    else return country;
  }

  getBusinessUnitNameById(id: number): string | null {
    const businessUnit = this.businessUnitsData.find(
      (unit) => unit.lookupId === id
    );

    if (businessUnit === undefined) return null;
    else return businessUnit.name;
  }

  getStatusNameById(id: number): string {
    const status = this.statusMapping.find((status) => status.id === id);

    if (status === undefined) return "-";
    else return status.name;
  }

  getBUById(id: number): ILookupData | null {
    var bu = this.businessUnitsData.find((bu) => bu.lookupId === id);

    if (bu === undefined) return null;
    else return bu;
  }

  getAgencyById(id: number): IAgencyData | undefined {
    var agency = this.agenciesData.find((agency) => agency.agencyId === id);

    if (agency === undefined) return null;
    else return agency;
  }

  getInspectorById(id: number): IInspectorViewData | undefined {
    var inspector = this.inspectorsData.find(
      (inspector) => inspector.inspectorId === id
    );

    if (inspector === undefined) return null;
    else return inspector;
  }

  getCapitalProjectNameById(id: number): string | null {
    const capitalProject = this.capitalProjectsData.find(
      (project) => project.projectId === id
    );

    if (capitalProject === undefined) return null;
    else return capitalProject.longName;
  }

  getCapitalProjectById(id: number): ICapitalProjectData | undefined {
    return this.capitalProjectsData.find(
      (capitalProject) => capitalProject.projectId === id
    );
  }

  loadCountries() {
    this.countriesAndStatesService.getAllCountries().subscribe((data) => {
      this.countriesData = data;
    });
  }

  loadBusinessUnits() {
    this.busOpcosDataService.getActiveBUs().subscribe((data) => {
      this.businessUnitsData = data;
    });
  }

  loadCapitalProjects() {
    this.capitalProjectsService.getAllProjects().subscribe((data) => {
      this.capitalProjectsData = data;
    });
  }

  loadBuyerEngineerQaes() {
    this.buyerEngineerQaeService.getActiveQAEs().subscribe((data) => {
      this.qaesData = data;
    });
  }

  loadManufacturers() {
    this.vendorInformationDataService
      .getAllManufacturerVendors()
      .subscribe((data) => {
        this.manufacturersData = data;
      });
  }

  loadAgencies() {
    this.agenciesAndInspectorsDataService.getAllAgencies().subscribe((data) => {
      this.agenciesData = data;
    });
  }

  loadInspectors() {
    this.agenciesAndInspectorsDataService
      .getAllInspectors()
      .subscribe((data) => {
        this.inspectorsData = data;
      });
  }

  getInspectorName(expeditorId: string): string {
    let inspectorName: string = "";
    this.agenciesAndInspectorsDataService
      .getInspectorNameById(expeditorId)
      .subscribe((inspName) => {
        inspectorName = inspName;
      });
    return inspectorName;
  }

  announceSortChange(event: Sort) {
    this.dataSource.data = this.tableSortService.sortDataSource(
      this.originalDataSource.data,
      event
    );
  }

  updateListings(pageIndex: number = 1, pageSize: number = 10) {
    this.listingsVisible = true;

    let formDescription: string = this.listingsForm.get("description").value;
    let formEtcChargeCode: string =
      this.listingsForm.get("etcChargeCode").value;
    let formCity: string = this.listingsForm.get("city").value;
    let formPONumber: string = this.listingsForm.get("poNumber").value;
    let formSAPSO: string = this.listingsForm.get("agencySRnumber").value;

    let formIContractNo: number = Number(
      this.listingsForm.get("iContractNo").value
    );

    let countryIdValue = this.listingsForm.get("country").value;
    let formCountryId: number = isNaN(Number(countryIdValue))
      ? 0
      : Number(countryIdValue);

    let agencyIdValue = this.listingsForm.get("agency").value;
    let formAgencyId: number = isNaN(Number(agencyIdValue))
      ? 0
      : Number(agencyIdValue);

    let inspectorIdValue = this.listingsForm.get("inspector").value;
    let formInspectorId: number = isNaN(Number(inspectorIdValue))
      ? 0
      : Number(inspectorIdValue);

    let manufacturerIdValue = this.listingsForm.get("manufacturer").value;
    let formManufacturerId: number = isNaN(Number(manufacturerIdValue))
      ? 0
      : Number(manufacturerIdValue);

    let qaeIdValue = this.listingsForm.get("qae").value;
    let formQaeId: number = isNaN(Number(qaeIdValue)) ? 0 : Number(qaeIdValue);

    let statusIdValue = this.listingsForm.get("status").value;
    let formStatusId: number = isNaN(Number(statusIdValue))
      ? 0
      : Number(statusIdValue);

    let businessUnitIdValue = this.listingsForm.get("businessUnit").value;
    let formBusinessUnitId: number = isNaN(Number(businessUnitIdValue))
      ? 0
      : Number(businessUnitIdValue);

    let capitalProjectIdValue = this.listingsForm.get("capitalProject").value;
    let formCapitalProjectId: number = isNaN(Number(capitalProjectIdValue))
      ? 0
      : Number(capitalProjectIdValue);

    let criticalityIdValue = this.listingsForm.get("criticality").value;
    let formCriticalityId: number = isNaN(Number(criticalityIdValue))
      ? 0
      : Number(criticalityIdValue);

    let commodityIdsValue = this.listingsForm.get("commodityIds").value;
    let formCommodityIds: number[] =
      commodityIdsValue === null ? [] : commodityIdsValue;

    this.workRequestsService
      .getWorkRequests(
        formCountryId,
        formAgencyId,
        formInspectorId,
        formManufacturerId,
        formQaeId,
        formStatusId,
        formDescription,
        formEtcChargeCode,
        formCity,
        formPONumber,
        formSAPSO,
        formBusinessUnitId,
        formCapitalProjectId,
        formIContractNo,
        formCriticalityId,
        formCommodityIds,
        pageIndex,
        pageSize
      )
      .subscribe({
        next: (data) => {
          this.dataSourceLoading = true;
          this.originalDataSource =
            new MatTableDataSource<IWorkRequestViewData>(data.items);
          this.dataSource = new MatTableDataSource<IWorkRequestViewData>(
            this.originalDataSource.data
          );
          this.dataSourceLoading = false;
          // Delay the initialization of the paginator until the data is assigned
          setTimeout(() => {
            this.paginator.pageSize = pageSize;
            this.totalData = data.totalCount; // Set total count for paginator
            this.paginator.pageIndex = pageIndex - 1; // Set current page index
          });
        },
        error: (error) => {
          console.error(error);
        },
      });
  }

  clearFilters() {
    var countryFormControl = this.listingsForm.get("country") as FormControl;
    countryFormControl.setValue("countryDefault");

    var agencyFormControl = this.listingsForm.get("agency") as FormControl;
    agencyFormControl.setValue("agencyDefault");

    var inspectorFormControl = this.listingsForm.get(
      "inspector"
    ) as FormControl;
    inspectorFormControl.setValue("inspectorDefault");

    var qaeFormControl = this.listingsForm.get("qae") as FormControl;
    qaeFormControl.setValue("qaeDefault");

    var businessUnitFormControl = this.listingsForm.get(
      "businessUnit"
    ) as FormControl;
    businessUnitFormControl.setValue("businessUnitDefault");

    var capitalProjectFormControl = this.listingsForm.get(
      "capitalProject"
    ) as FormControl;
    capitalProjectFormControl.setValue("capitalProjectDefault");

    var manufacturerFormControl = this.listingsForm.get(
      "manufacturer"
    ) as FormControl;
    manufacturerFormControl.setValue("manufacturerDefault");

    var statusFormControl = this.listingsForm.get("status") as FormControl;
    statusFormControl.setValue("statusDefault");

    var criticalityFormControl = this.listingsForm.get(
      "criticality"
    ) as FormControl;
    criticalityFormControl.setValue("criticalityDefault");

    var iContractNoFormControl = this.listingsForm.get(
      "iContractNo"
    ) as FormControl;
    iContractNoFormControl.setValue(null);

    var cityFormControl = this.listingsForm.get("city") as FormControl;
    cityFormControl.setValue("");

    var descriptionFormControl = this.listingsForm.get(
      "description"
    ) as FormControl;
    descriptionFormControl.setValue("");

    var poNumberFormControl = this.listingsForm.get("poNumber") as FormControl;
    poNumberFormControl.setValue("");

    var agencySRNumberFormControl = this.listingsForm.get(
      "agencySRnumber"
    ) as FormControl;
    agencySRNumberFormControl.setValue("");

    var etcChargeCodeFormControl = this.listingsForm.get(
      "etcChargeCode"
    ) as FormControl;
    etcChargeCodeFormControl.setValue("");

    var commodityIdsFormControl = this.listingsForm.get(
      "commodityIds"
    ) as FormControl;
    commodityIdsFormControl.setValue(null);

    this.clearCommodityCodesEvent.emit();
  }

  exportResultsToExcel() {
    if (
      this.listingsVisible &&
      this.dataSource !== undefined &&
      this.dataSource.data.length
    ) {
      // Define header mapping
      const headerMapping = {
        workRequestId: "iContract No",
        statusId: "Status",
        qae: "QAE",
        receivedDate: "Original Issuance Date",
        requiredDate: "PO Required Date",
        sapexpectedLimit: "Current Budget",
        sapso: "Agency SR Number",
        agency: "Agency",
        inspector: "Inspector",
        expeditorName: "Coordinator",
        vendorName: "Manufacturer",
        "commodityCode+commodityDescriptionPath": "Commodity",
        po: "PO Number",
        poamount: "PO Value",
        vendorRef: "Vendor Reference",
        workRequestCostCenter: "ETC Charge Code",
        clientBudget: "Original Budget",
        nextActionOn: "Current Issuance Date",
        description: "Description",
        engineerName: "Responsible Engineer",
        opconame: "OPCO",
        businessUnitId: "Business Unit",
        projectLongName: "Client / Project",
        etccosts: "Final Costs",
        closedDate: "Closeout Date",
      };

      // Create the header row
      const headers = this.displayedColumns.map(
        (col) => headerMapping[col] || col
      );
      const headerRow = headers;

      // Instantiate the DatePipe and CurrencyPipe
      const datePipe = new DatePipe("en-US");
      const currencyPipe = new CurrencyPipe("en-US");

      // Extract data rows with transformation
      const data = this.dataSource.data.map((row) => {
        const rowData = {};
        this.displayedColumns.forEach((col) => {
          let value = row[col];
          // Apply transformations for specific fields
          if (col === "statusId") {
            value = this.getStatusNameById(row.statusId);
          } else if (col === "businessUnitId") {
            value = this.getBusinessUnitNameById(row.businessUnitId);
          } else if (col === "commodityCode+commodityDescriptionPath") {
            value = `${row.commodityCode} ${row.commodityDescriptionPath}`;
          } else if (col === "vendorName") {
            value = `${row.vendorName} ${row.city} ${row.state}`;
          } else if (
            col === "receivedDate" ||
            col === "requiredDate" ||
            col === "closedDate"
          ) {
            value = datePipe.transform(row[col], "M/d/yyyy");
          } else if (
            col === "sapexpectedLimit" ||
            col === "poamount" ||
            col === "clientBudget" ||
            col === "etccosts"
          ) {
            value = currencyPipe.transform(row[col], "USD", "symbol", "1.2-2");
          }
          rowData[headerMapping[col] || col] = value;
        });
        return rowData;
      });

      // Create a new workbook and worksheet using ExcelJS
      const workbook = new ExcelJS.Workbook();
      const worksheet = workbook.addWorksheet("WorkRequests");

      // Add the header row
      worksheet.addRow(headerRow);

      // Add data rows
      data.forEach((rowData) => {
        worksheet.addRow(Object.values(rowData));
      });

      // Style the header row
      worksheet.getRow(1).eachCell((cell) => {
        cell.font = { bold: true, size: 12, color: { argb: "000000" } };
        cell.alignment = { horizontal: "center", vertical: "middle" };
        cell.fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: "FFFF00" },
        };
        cell.border = {
          top: { style: "thin" },
          bottom: { style: "thin" },
          left: { style: "thin" },
          right: { style: "thin" },
        };
      });

      // Set column widths for better readability
      worksheet.columns.forEach((column) => {
        column.width = 20;
      });

      // Convert the workbook to a buffer
      workbook.xlsx.writeBuffer().then((buffer) => {
        // Use XLSX to save the file
        const blob = new Blob([buffer], { type: "application/octet-stream" });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = `WorkRequests_${datePipe.transform(
          new Date(),
          "M_d_yyyy"
        )}.xlsx`;
        a.click();
        window.URL.revokeObjectURL(url);
      });
    }
  }

  addNewWorkRequest() {
    this.router.navigate(["qa-management/work-requests/add-work-request"]);
  }

  handleSelectedCommodityNames(selectedCommodityNames: ICommodity[]) {
    this.selectedCommodityObjects = selectedCommodityNames;
    if (selectedCommodityNames.length != 0) {
      this.listingsForm.patchValue({
        commodityIds: selectedCommodityNames.map(
          (commodity) => commodity.commodityId
        ),
      });
    } else {
      this.listingsForm.patchValue({
        commodityIds: null,
      });
    }
  }
}
