import { Component, Input, OnInit, SimpleChanges } from "@angular/core";
import { AuditObservationService } from "../../../services/qa-management/audit-observation.service";
import { PageViewMode } from "../../../interfaces/common/pageViewMode";
import { FormArray, FormBuilder, FormGroup } from "@angular/forms";
import { Router } from "@angular/router";
import { IInspectorEvaluationDetails } from "../../../interfaces/qa-management/inspector-evaluation/inspectorEvaluationDetails";
import { InspectorEvaluationService } from "../../../services/qa-management/inspector-evaluation.service";

@Component({
    selector: 'inspector-evaluation-details',
    templateUrl: './ie-details.component.html',
    styleUrls: ['./ie-details.component.css',
        '../inspector-evaluation.component.css']
})
export class InspectorEvaluationDetailsComponent implements OnInit {
    @Input() recordNo: number;
    inspectorEvaluationDetails: IInspectorEvaluationDetails;
    editMode = false;
    detailsForm: FormGroup;
    tableDataSource: FormArray;

    displayedColumns: string[] = ['name', 'result', 'completionDate', 'rating', 'notes'];

    constructor(
        private auditObservationService: AuditObservationService,
        private inspectorEvaluationService: InspectorEvaluationService,
        private fb: FormBuilder,
        private router: Router,
    ) { }

    ngOnInit(): void {
        this.detailsForm = this.fb.group({
            recordNo: [''],
            lead: [''],
            otherParticipants: [''],
            manufacturer: [''],
            inspector: [''],
            agency: [''],
            isRecommended: [''],
            fileAttachmentLink: [''],
            evaluationDate: [''],
            status: [''],
            notes: [''],
            commodities: this.fb.array([]),
        });

        this.tableDataSource = this.detailsForm.get('commodities') as FormArray;
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.recordNo) {
            this.inspectorEvaluationService.getDetailsById(this.recordNo).subscribe((data) => {
                this.inspectorEvaluationDetails = data;
                this.populateForm(data);
            });
        }
    }

    populateForm(details: IInspectorEvaluationDetails): void {
        this.detailsForm.patchValue({
            recordNo: details.id,
            lead: details.fullName,
            otherParticipants: details.participants,
            manufacturer: details.manufacturerName,
            inspector: details.inspectorName,
            agency: details.agencyName,
            isRecommended: details.recommended,
            fileAttachmentLink: details.fileURLLink,
            evaluationDate: details.recordDate,
            status: details.status,
            notes: details.notes
        });

        this.tableDataSource.clear();
        details.commodities.forEach(commodity => {
            this.tableDataSource.push(this.fb.group({
                name: [commodity.commodityName],
                result: [commodity.resultDesc],
                completionDate: [commodity.completionDate],
                rating: [commodity.ratingDesc],
                notes: [commodity.notes]
            }));
        });
    }

    getRatingClass(rating: string): string {
        return this.auditObservationService.getRatingClass(rating);
    }

    backToSearch(): void {
        this.inspectorEvaluationService.setIEMode(PageViewMode.Base);
    }

    editIE(): void {
        this.inspectorEvaluationService.setIEAddMode(PageViewMode.Edit);
        this.inspectorEvaluationService.setIEObject({
            evaluation: {
                id: this.inspectorEvaluationDetails.id,
                responsibleCai: this.inspectorEvaluationDetails.responsibleCAI,
                participants: this.inspectorEvaluationDetails.participants,
                manufacturerId: this.inspectorEvaluationDetails.manufacturerId,
                agencyId: this.inspectorEvaluationDetails.agencyId,
                inspectorId: this.inspectorEvaluationDetails.inspectorId,
                recommended: this.inspectorEvaluationDetails.recommended,
                fileUrl: this.inspectorEvaluationDetails.fileURL,
                fileUrllink: this.inspectorEvaluationDetails.fileURLLink,
                recordDate: this.inspectorEvaluationDetails.recordDate,
                notes: this.inspectorEvaluationDetails.notes,
                statusId: this.inspectorEvaluationDetails.statusId
            },
            commodities: this.inspectorEvaluationDetails.commodities.map(commodity => {
                return {
                    id: commodity.id,
                    commodityName: commodity.commodityName,
                    resultId: commodity.resultId,
                    completionDate: commodity.completionDate,
                    ratingId: commodity.ratingId,
                    notes: commodity.notes,
                    commodityId: commodity.commodityId
                };
            })
        });
        this.router.navigate(["/qa-management/inspector-evaluation/add-inspector-evaluation"]);

    }
}  