import { HttpClient } from "@angular/common/http";
import { Component, OnInit } from "@angular/core";
import { AlertService } from "../../services/administration/alert.service";
import { IAlertData } from "../../interfaces/administration/alertData";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { IVendorData } from "../../interfaces/administration/vendorData";
import { VendorDataService } from "../../services/vendor-data.service";
import { ActivatedRoute, Router } from "@angular/router";
import { LoggedUserService } from "../../services/common/logged-user.service";
import { UserPermissionsService } from "../../services/common/user-permissions.service";
import { BaseComponent } from "../../shared/base/base.component";

@Component({
  selector: "update-alert-component",
  templateUrl: "./update-alert.component.html",
})
export class UpdateAlertComponent extends BaseComponent implements OnInit {
  title = "Update Alert";
  alertForm: FormGroup;
  vendors: IVendorData[] = [];
  validationMessages: string[] = [];
  alertId: number | null = null;

  constructor(
    private fb: FormBuilder,
    private alertService: AlertService,
    private vendorDataService: VendorDataService,
    private router: Router,
    private route: ActivatedRoute,
    userPermissionService: UserPermissionsService
  ) {
    super(userPermissionService);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.initializeForm();
    this.alertId = Number(this.route.snapshot.paramMap.get("id"));
    this.loadVendors();
    if (this.alertId) {
      this.loadAlert(this.alertId);
    }
  }

  private initializeForm(): void {
    this.alertForm = this.fb.group({
      alertName: ["", Validators.required],
      isActive: [false],
      vendorId: [null, Validators.required],
    });
  }

  private loadVendors(): void {
    this.vendorDataService.getVendors().subscribe((data: IVendorData[]) => {
      this.vendors = data;
    });
  }

  private loadAlert(alertId: number): void {
    this.alertService.getAlertById(alertId).subscribe(
      (alert: IAlertData) => {
        this.populateForm(alert);
      },
      (error) => {
        console.error("Error loading alert", error);
      }
    );
  }

  private populateForm(alert: IAlertData): void {
    this.alertForm.patchValue({
      alertName: alert.alertName,
      isActive: alert.active,
      vendorId: alert.vendorId,
    });
  }

  private mapFormToAlertData(): IAlertData {
    const formValues = this.alertForm.value;
    return {
      alertId: this.alertId || 0,
      alertName: formValues.alertName,
      vendorId: formValues.vendorId,
      vendor: this.vendors.find((v) => v.vendorId === formValues.vendorId),
      addedDate: new Date(),
      active: formValues.isActive,
    };
  }

  onSubmit(): void {
    this.validationMessages = this.alertService.validateAlertForm(
      this.alertForm
    );
    if (this.validationMessages.length === 0) {
      const alertData: IAlertData = this.mapFormToAlertData();
      this.alertService.updateAlert(alertData).subscribe((response) => {
        this.router.navigate(["/administration/alerts"]);
      });
    }
  }

  onCancel(): void {
    this.router.navigate(["/administration/alerts"]);
  }
}
