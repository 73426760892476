// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
      .red-color {
        color: red;
      }
      .accordion-outline {
        margin: 0px 0pc 25px 0px;
      }
    `, "",{"version":3,"sources":["webpack://./projects/aqua3/src/app/administration/vendors/preview-vendor.component.ts"],"names":[],"mappings":";MACM;QACE,UAAU;MACZ;MACA;QACE,wBAAwB;MAC1B","sourcesContent":["\n      .red-color {\n        color: red;\n      }\n      .accordion-outline {\n        margin: 0px 0pc 25px 0px;\n      }\n    "],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
