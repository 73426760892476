import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewChild,
} from "@angular/core";
import { MatTableDataSource } from "@angular/material/table";
import { TableSortService } from "projects/aqua3/src/app/services/table-sort.service";
import { MatPaginator } from "@angular/material/paginator";
import { IWorkRequestRFQData } from "../../../interfaces/qa-management/work-requests/workRequestRFQData";
import { WorkRequestsDataService } from "../../../services/qa-management/work-requests/work-requests.service";
import { Sort } from "@angular/material/sort";
import { Router } from "@angular/router";

@Component({
  selector: "rfqs-component",
  templateUrl: "./rfqs-component.html",
  styleUrls: ["./rfqs-component.css"],
})
export class RFQsComponent implements OnInit, OnChanges {
  @Input() workRequestId: number;
  @Input() formEditable: boolean;

  title = "RFQs";
  dataSource: MatTableDataSource<IWorkRequestRFQData>;
  originalDataSource: MatTableDataSource<IWorkRequestRFQData>;

  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;

  displayedColumns = [
    "rfqId",
    "agencyNames",
    "dateSent",
    "notes",
    "createdBy",
    "createdOn",
    "updatedBy",
    "updatedOn",
  ];

  dataSourceLoading: boolean = true;

  constructor(
    private tableSortService: TableSortService,
    private workRequestsService: WorkRequestsDataService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.loadRFQs();
  }

  ngOnChanges(changes: SimpleChanges): void {
    // React to changes in the input property
    if (changes.workRequestId && !changes.workRequestId.isFirstChange()) {
      this.loadRFQs();
    }
  }

  loadRFQs(): void {
    if (this.workRequestId !== null && this.workRequestId !== undefined) {
      this.workRequestsService
        .getWorkRequestRFQsByWorkRequestId(this.workRequestId)
        .subscribe({
          next: (data) => {
            this.dataSourceLoading = true;

            this.originalDataSource =
              new MatTableDataSource<IWorkRequestRFQData>(data);
            this.dataSource = new MatTableDataSource<IWorkRequestRFQData>(
              this.originalDataSource.data
            );

            this.dataSourceLoading = false;
            // Delay the initialization of the paginator until the data is assigned
            setTimeout(() => {
              this.dataSource.paginator = this.paginator;
              this.paginator.pageSize = 10;
            });
          },
          error: (error) => {
            console.error(error);
          },
        });
    }
  }

  announceSortChange(event: Sort) {
    this.dataSource.data = this.tableSortService.sortDataSource(
      this.originalDataSource.data,
      event
    );
  }

  addRFQ(): void {
    this.router.navigate([
      `/qa-management/work-requests/add-rfq/${this.workRequestId}`,
    ]);
  }
}
