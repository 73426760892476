import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { EmailRequestData } from "../../interfaces/common/emailRequestData";
import { environment } from "projects/aqua3/src/environments/environment";
const apiUrl = environment.apiUrl;

@Injectable({
  providedIn: "root",
})
export class EmailService {
  constructor(private http: HttpClient) {}

  sendEmail(request: EmailRequestData): Observable<any> {
    return this.http.post("/emails/send", request);
  }
}
