import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import * as XLSX from "xlsx";
import { environment } from "../../environments/environment";
const apiUrl = environment.apiUrl;

@Injectable({
  providedIn: "root",
})
export class InspectionReportService {
  constructor(private http: HttpClient) {}

  generateInspectionReport(): Blob {
    const workbook = XLSX.utils.book_new();
    const worksheetData = [
      [
        "ETC iContract #",
        "iContract Amount",
        "ETC QA issue SO #",
        "Chevron responsible QAE",
        "ChargeCode",
        "Project",
        "PO #",
        "Vendor",
        "City, Country",
        "Agency",
        "Inspector",
        "Agency Job #",
        "Inspection Report No",
        "Inspection Visit Date",
        "Inspection Hours",
        "Travel Hours",
        "Coordination Hours",
        "Overtime Hours",
        "Mileage",
        "Other Expenses",
        "Total",
      ],
    ];

    const ws = XLSX.utils.aoa_to_sheet(worksheetData);
    XLSX.utils.book_append_sheet(workbook, ws, "Weekly Tracking Sheet");

    // Set styles for the first row
    const range = XLSX.utils.decode_range(ws["!ref"]!);
    for (let C = range.s.c; C <= range.e.c; ++C) {
      const cell = ws[XLSX.utils.encode_cell({ r: 0, c: C })];
      if (!cell.s) cell.s = {};
      cell.s.font = { bold: true };
      cell.s.fill = { fgColor: { rgb: "C5D9F1" } };
      cell.s.border = {
        top: { style: "thin", color: { rgb: "000000" } },
        bottom: { style: "thin", color: { rgb: "000000" } },
        left: { style: "thin", color: { rgb: "000000" } },
        right: { style: "thin", color: { rgb: "000000" } },
      };
      ws["!cols"] = ws["!cols"] || [];
      ws["!cols"][C] = { wch: 15 };
      ws["!rows"] = ws["!rows"] || [];
      ws["!rows"][0] = { hpt: 25 };
    }

    // Add data validations (for simplicity, not adding all as in the original example)
    ws["!dataValidations"] = [
      { type: "whole", allowBlank: false, formula1: "1", sqref: "A2:A100" },
      { type: "whole", allowBlank: false, formula1: "1", sqref: "B2:B100" },
    ];

    // Create additional sheets for data validation lists (hidden)
    const agencySheet = XLSX.utils.aoa_to_sheet([
      ["Agency 1"],
      ["Agency 2"],
      ["Agency 3"],
    ]);
    XLSX.utils.book_append_sheet(workbook, agencySheet, "Sheet2");
    agencySheet["!hidden"] = true;

    const inspectorSheet = XLSX.utils.aoa_to_sheet([
      ["Inspector 1"],
      ["Inspector 2"],
      ["Inspector 3"],
    ]);
    XLSX.utils.book_append_sheet(workbook, inspectorSheet, "Sheet3");
    inspectorSheet["!hidden"] = true;

    // Data validation linking to hidden sheets
    ws["!dataValidations"].push(
      {
        type: "list",
        allowBlank: false,
        formula1: "Sheet2!$A$1:$A$3",
        sqref: "J2:J100",
      },
      {
        type: "list",
        allowBlank: false,
        formula1: "Sheet3!$A$1:$A$3",
        sqref: "K2:K100",
      }
    );

    // Generate the file and prompt for download
    const wbout = XLSX.write(workbook, { bookType: "xlsx", type: "array" });
    const blob = new Blob([wbout], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });

    return blob;
  }

  // Method to validate required fields in the dataset
  validateDataset(dataset: any[]): boolean {
    let validationCheckFail = false;

    dataset.forEach((row) => {
      if (
        !row.ETCiContractNo ||
        !row.Agency ||
        !row.Inspector ||
        !row.AgencyJobNo ||
        !row.ReportInvoiceNumber ||
        !row.ReportInvoiceDate
      ) {
        validationCheckFail = true;
      }
    });

    return validationCheckFail;
  }

  // Method to check for duplicates in the dataset
  checkAndRemoveDuplicates(dataset: any[]): any[] {
    const recordsMap = new Map();

    dataset.forEach((row, index) => {
      const key = `${row.ETCiContractNo}-${row.Agency}-${row.Inspector}-${row.AgencyJobNo}-${row.ReportInvoiceNumber}-${row.ReportInvoiceDate}`;
      if (!recordsMap.has(key)) {
        recordsMap.set(key, []);
      }
      recordsMap.get(key).push(index);
    });

    const indicesToRemove = [];

    recordsMap.forEach((indices) => {
      if (indices.length > 1) {
        // Keep the last occurrence and mark others for removal
        indices.pop();
        indicesToRemove.push(...indices);
      }
    });

    // Remove duplicates
    indicesToRemove
      .sort((a, b) => b - a)
      .forEach((index) => {
        dataset.splice(index, 1);
      });

    return dataset;
  }

  parseExcel(fileContent: ArrayBuffer) {
    const workbook = XLSX.read(fileContent, { type: "array" });
    const firstSheetName = workbook.SheetNames[0];
    const worksheet = workbook.Sheets[firstSheetName];
    return XLSX.utils.sheet_to_json(worksheet, { defval: "" });
  }

  uploadInspectionReport(dedupedDataset: any[]) {
    return this.http.post(`${apiUrl}/inspection-report/upload`, dedupedDataset);
  }
}
