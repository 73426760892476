import { Component, OnInit } from '@angular/core';
import { DatePipe } from '@angular/common';
import { HttpParams } from '@angular/common/http';
import { ManufacturersNameService } from '../../../services/qa-reports/manufacturers-name.service';
import { Router } from '@angular/router';
 
@Component({
  selector: 'app-qabyclient',
  templateUrl: './qabyclient.component.html',
  styleUrls: ['./qabyclient.component.css'],
  providers: [DatePipe]
})
export class QabyclientComponent implements OnInit {
  startDate: string;
  endDate: string;
  validationMessages: string[] = [];
  isLoading = false;
 
  constructor(private datePipe: DatePipe, private manufacturersNameService: ManufacturersNameService,
    private router: Router
  ) { }
 
  ngOnInit(): void {
    const currentYear = new Date().getFullYear();
    this.startDate = this.datePipe.transform(new Date(currentYear, 0, 1), 'yyyy-MM-dd') || '';
    this.endDate = this.datePipe.transform(new Date(), 'yyyy-MM-dd') || '';
  }
 
  // Reset Start date
  resetStartDate() {
    this.startDate = '';
  }
 
  // Reset End date
  resetEndDate() {
    this.endDate = '';
  }
  cancel() {
    this.router.navigate(['/reports-forms'], { queryParams: { tab: 'qaReports' } });
  }
 
  generateReport() {
    this.isLoading = true;
    this.validationMessages = [];
    if (!this.startDate) {
      this.validationMessages.push('Start date is required.');
      this.isLoading = false;
    }
    if (!this.endDate) {
      this.validationMessages.push('End date is required.');
      this.isLoading = false;
    }
    if (new Date(this.startDate) > new Date(this.endDate)) {
      this.validationMessages.push('Start date cannot be after end date.');
      this.isLoading = false;
    }
 
    if (this.validationMessages.length > 0) {
      return;
    }
    const formattedStartDate = this.datePipe.transform(this.startDate, 'yyyy-MM-dd');
    const formattedEndDate = this.datePipe.transform(this.endDate, 'yyyy-MM-dd');
 
    const params = new HttpParams()
      .set('StartDate', formattedStartDate || '')
      .set('EndDate', formattedEndDate || '');
 
    this.manufacturersNameService.inspecterByClientDetails(params).subscribe(response => {
      const blob = new Blob([response], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = `AQUA3_QAActivityReportByClient_${new Date().toISOString().slice(0, 19).replace(/:/g, '-')}.xlsx`;
      a.click();
      window.URL.revokeObjectURL(url);
      this.isLoading = false;
    }, error => {
      console.error('Error downloading the report', error);
    });
  }
}
 
 