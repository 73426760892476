import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { BehaviorSubject, map, Observable } from "rxjs";
import { environment } from "projects/aqua3/src/environments/environment";
import { CommodityLevel, CommoditySorter, ICommodity } from "../../interfaces/administration/commodity";
import { CommodityViewMode } from "../../interfaces/administration/commodityViewMode";
import { ICommodityResult } from "../../interfaces/qa-management/commodityResult";
import { ICommodityRating } from "../../interfaces/qa-management/commodityRating";

@Injectable({
  providedIn: "root",
})
export class CommodityCodesService {
  private commodityApiUrl = environment.apiUrl + '/commodities';

  private commoditiesDataTreeSource = new BehaviorSubject<ICommodity[] | null>(null);
  currentCommoditiesTreeData = this.commoditiesDataTreeSource.asObservable();

  private commoditiesDataSource = new BehaviorSubject<ICommodity[] | null>(null);
  currentCommoditiesData = this.commoditiesDataSource.asObservable();

  private choosenNodeSubject = new BehaviorSubject<any>(null);
  choosenNode$: Observable<any> = this.choosenNodeSubject.asObservable();

  private commodityViewModeSubject = new BehaviorSubject<CommodityViewMode>(CommodityViewMode.Base);
  commodityViewMode$: Observable<CommodityViewMode> = this.commodityViewModeSubject.asObservable();

  private commoditiesIsLoadingSubject = new BehaviorSubject<boolean>(false);
  commoditiesIsLoading$ = this.commoditiesIsLoadingSubject.asObservable();

  private lastCommoditySearch: ICommodity[] = [];

  constructor(private http: HttpClient) { }

  sendChoosenNode(node: any): void {
    this.choosenNodeSubject.next(node);
  }

  updateCommodityViewMode(viewMode: CommodityViewMode): void {
    this.commodityViewModeSubject.next(viewMode);
  }

  setCommoditiesIsLoading(isLoading: boolean): void {
    this.commoditiesIsLoadingSubject.next(isLoading);
  }

  public getAllCommodities(): Observable<ICommodity[]> {
    this.setCommoditiesIsLoading(true);
    const url = `${this.commodityApiUrl}`;
    this.http.get<ICommodity[]>(url).subscribe((data) => {
      data = CommoditySorter.sortCommodities(data);
      this.commoditiesDataSource.next(data);
      var result = this.getCommoditiesTree(data);
      this.commoditiesDataTreeSource.next(result);
      this.setCommoditiesIsLoading(false);
    });
    return this.currentCommoditiesTreeData;
  }

  public getAllCommodityResults(): Observable<ICommodityResult[]> {
    const url = `${this.commodityApiUrl}/results`;
    return this.http.get<ICommodityResult[]>(url);
  }

  public getAllCommodityRatings(): Observable<ICommodityRating[]> {
    const url = `${this.commodityApiUrl}/ratings`;
    return this.http.get<ICommodityRating[]>(url);
  }

  public getCommodityById(commodityId: string): Observable<ICommodity> {
    const url = `${this.commodityApiUrl}/${commodityId}`;
    return this.http.get<ICommodity>(url);
  }

  public getAllChildrenAPI(commodityId: number): Observable<ICommodity[]> {
    const url = `${this.commodityApiUrl}/childrens/${commodityId}`;
    return this.http.get<ICommodity[]>(url).pipe(map(data => {
      data = CommoditySorter.sortCommodities(data);
      return data;
    }));
  }

  public getCommodityPath(commodityId: string): Observable<string> {
    return this.http.get<string>(`${this.commodityApiUrl}/path/${commodityId}`);
  }

  public getChildren(parentId: number): ICommodity[] {
    var childrens = [];
    this.currentCommoditiesTreeData.subscribe((data) => {
      childrens = data.filter((x) => x.commodityCodeParentId == parentId);
    });
    return childrens;
  }

  public getCommodity(commodityId: number): ICommodity {
    var commodity = null;
    this.currentCommoditiesTreeData.subscribe((data) => {
      commodity = data.find((x) => x.commodityId == commodityId);
    });
    return commodity;
  }

  public getParentCommodity(commodity: ICommodity): ICommodity {
    return this.getCommodity(commodity.commodityCodeParentId);
  }

  public searchCommodity(searchText: string): Observable<void> {
    return this.searchCommodityAPICall(searchText).pipe(map(data => {
      this.clearSearching();
      this.lastCommoditySearch = [];
      data.forEach(x => {
        this.expandAndSearched(x);
      });
    }));
  }

  public searchDeactivatedCommodity(): Observable<void> {
    return this.searchDeactivatedCommodityAPICall().pipe(map(data => {
      this.clearSearching();
      this.lastCommoditySearch = [];
      data.forEach(x => {
        this.expandAndSearched(x);
      });
    }));
  }

  public getLastCommoditySearch(): ICommodity[] {
    return this.lastCommoditySearch;
  }

  public searchCommodityAPICall(searchString: string): Observable<ICommodity[]> {
    const url = `${this.commodityApiUrl}/search/${searchString}`;
    return this.http.get<ICommodity[]>(url);
  }

  public searchDeactivatedCommodityAPICall(): Observable<ICommodity[]> {
    const url = `${this.commodityApiUrl}/searchDeactivated`;
    return this.http.get<ICommodity[]>(url);
  }

  public updateCommodity(
    id: string,
    commodityData: ICommodity
  ): Observable<ICommodity> {
    return this.http.put<ICommodity>(
      `${this.commodityApiUrl}/${id}/update`,
      commodityData
    );
  }

  public addCommodity(
    commodityData: ICommodity
  ): Observable<ICommodity> {
    return this.http.post<ICommodity>(
      `${this.commodityApiUrl}/add`,
      commodityData
    );
  }

  public activateDeactivateCommodity(
    id: string,
  ): Observable<ICommodity> {
    return this.http.put<ICommodity>(
      `${this.commodityApiUrl}/activatedeactivate/${id}`,
      null
    );
  }

  private getCommoditiesTree(allCommodities: ICommodity[]): ICommodity[] {
    var commoditiesTree: ICommodity[] = [];

    var mainNode: ICommodity = {
      commodityId: 0,
      childrenCount: 0,
      description: 'AQUA2 Commodity Codes',
      commodityDescriptionPath: '',
      commodityCodeParentId: 0,
      commodityLevel: 0,
      commodityCodeLevel1: 0,
      commodityCodeLevel2: 0,
      commodityCodeLevel3: 0,
      commodityCodeLevel4: 0,
      commodityCodeTrimmed: '',
      isActive: true,
      isApproved: true,
      subjectMatterExpertUserId: 0,
      approvedByUserId: 0,
      approvedDate: '',
      approvedComments: '',
      approverFirstName: '',
      approverLastName: '',
      approverEmail: '',
      approverCai: '',
      smefirstName: '',
      smelastName: '',
      smeemail: '',
      smecai: '',
      children: commoditiesTree,
      shouldExpand: true,
      searched: false,
      isLoading: false,
      isExpandable: commoditiesTree.length > 0,
      commodityPath: '',
    };

    commoditiesTree.push(this.fillTreeNode(mainNode, allCommodities));

    return commoditiesTree;
  }


  private fillTreeNode(parentCommodity: ICommodity, allCommodities: ICommodity[]): ICommodity {
    var children = allCommodities.filter(commodity => commodity.commodityCodeParentId == parentCommodity.commodityId);
    if (parentCommodity.children == null) {
      parentCommodity.children = [];
    }

    if (children.length > 0) {
      parentCommodity.children = [];
      parentCommodity.children.push(...children);
      parentCommodity.children.forEach(nodeLevel1 => {
        var nodesLevel2 = allCommodities.filter(commodity => commodity.commodityCodeParentId == nodeLevel1.commodityId && commodity.commodityLevel == 2);
        if (nodesLevel2.length > 0) {
          nodeLevel1.children = [];
          nodeLevel1.children.push(...nodesLevel2);
          nodeLevel1.children.forEach(nodeLevel2 => {

            var nodesLevel3 = allCommodities.filter(commodity => commodity.commodityCodeParentId == nodeLevel2.commodityId);
            if (nodesLevel3.length > 0) {
              nodeLevel2.children = [];
              nodeLevel2.children.push(...nodesLevel3);
              nodeLevel2.children.forEach(nodeLevel3 => {

                var nodesLevel4 = allCommodities.filter(commodity => commodity.commodityCodeParentId == nodeLevel3.commodityId);
                if (nodesLevel4.length > 0) {
                  nodeLevel3.children = [];
                  nodeLevel3.children.push(...nodesLevel4);
                }
              });
            }
          });
        }
      });
    };
    return parentCommodity;
  }

  private getCommodityChildrensRecursive(parentCommodity: ICommodity, allCommodities: ICommodity[]): ICommodity {
    var childrens = allCommodities.filter(commodity => commodity.commodityCodeParentId == parentCommodity.commodityId);
    if (parentCommodity.children == null) {
      parentCommodity.children = [];
    }

    if (childrens.length > 0) {
      childrens.forEach(commodity => {
        parentCommodity.children.push(this.getCommodityChildrensRecursive(commodity, allCommodities));
      });
    }
    return parentCommodity;
  }


  expandAndSearched(commodity: ICommodity): void {
    this.commoditiesDataTreeSource.subscribe((data) => {
      var resultsCommodities = this.findCommodityAndItParentsInTree(data[0], commodity);
      resultsCommodities.forEach(element => {
        if (element.commodityId == commodity.commodityId) {
          element.searched = true;
        } else {
          element.shouldExpand = true;
        }
      });
      this.lastCommoditySearch.push(...resultsCommodities);
    });
  }

  clearSearching(): void {
    if (this.lastCommoditySearch != null) {
      this.commoditiesDataTreeSource.subscribe((data) => {
        this.lastCommoditySearch.forEach(previouslySearched => {
          var resultsCommodities = this.findCommodityAndItParentsInTree(data[0], previouslySearched);
          resultsCommodities.forEach(element => {
            if (element.commodityId == previouslySearched.commodityId) {
              element.searched = false;
            } else {
              element.shouldExpand = false;
            }
          });
        });
      })
    }
  }

  findCommodityInTree(commodityTree: ICommodity, searchedCommodity: ICommodity): ICommodity {
    var searchedCommodityLevel = searchedCommodity.commodityLevel;
    var actualTreeNode = commodityTree;

    for (var i = 1; i <= searchedCommodityLevel; i++) {
      actualTreeNode.shouldExpand = true;
      if (actualTreeNode.children.length > 0) {
        if (i == searchedCommodityLevel) {
          actualTreeNode.children.find(element => {
            if (searchedCommodity.commodityId == element.commodityId) {
              element.searched = true;
              return;
            }
          });
        } else {
          actualTreeNode.children.find(element => {
            if (CommodityLevel.returnCommodityCodeLevel(element, i) == CommodityLevel.returnCommodityCodeLevel(searchedCommodity, i)) {
              element.shouldExpand = true;
              actualTreeNode = element;
              return;
            }
          });
        }
      } else {
        console.log('ERROR! We didn\'t find this commodity: ' + searchedCommodity.description);
        return;
      }
    };
  }

  findCommodityAndItParentsInTree(commodityTree: ICommodity, searchedCommodity: ICommodity): ICommodity[] {
    var searchedCommodityLevel = searchedCommodity.commodityLevel;
    var actualTreeNode = commodityTree;
    var resultsCommodities: ICommodity[] = [];

    for (var i = 1; i <= searchedCommodityLevel; i++) {
      actualTreeNode.shouldExpand = true;
      if (actualTreeNode.children.length > 0) {
        if (i == searchedCommodityLevel) {
          actualTreeNode.children.find(element => {
            if (searchedCommodity.commodityId == element.commodityId) {
              resultsCommodities.push(element);
              return;
            }
          });
        } else {
          actualTreeNode.children.find(element => {
            if (CommodityLevel.returnCommodityCodeLevel(element, i) == CommodityLevel.returnCommodityCodeLevel(searchedCommodity, i)) {
              resultsCommodities.push(element);
              actualTreeNode = element;
              return;
            }
          });
        }
      } else {
        console.log('ERROR! We didn\'t find this commodity: ' + searchedCommodity.description);
        return resultsCommodities;
      }
    };
    return resultsCommodities;
  }

}
