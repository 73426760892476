import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { CountriesAndStatesService } from '../../../services/countries-states.service';
import { CommodityCodesService } from "../../../services/administration/commodity-codes.service";
import { ManufacturersNameService } from "../../../services/qa-reports/manufacturers-name.service";
import { ILookupData } from "../../../interfaces/administration/lookupData";
import { ICommodity } from "../../../interfaces/administration/commodity";
import { HttpParams } from "@angular/common/http";
 
@Component({
  selector: 'app-manufacturebyname',
  templateUrl: './manufacturebyname.component.html'
})
export class ManufacturebynameComponent implements OnInit {
  manufacturerForm: FormGroup;
  statesData: ILookupData[] = [];
  commoditiesData: ICommodity[] = [];
  selectedCommodityObjects: ICommodity[] = [];
 
  constructor(
    private fb: FormBuilder,
    private countriesAndStatesService: CountriesAndStatesService,
    private commodityCodesService: CommodityCodesService,
    private manufacturersNameService:ManufacturersNameService
  ) {
    this.manufacturerForm = this.fb.group({
      ManufacturerName: [''],
      ManufacturerCity: [''],
      state: ['stateDefault'],
      commodity: ['']
    });
  }
 
  ngOnInit(): void {
    this.loadStates();
    this.loadCommodities();
  }
 
  loadStates(): void {
    this.countriesAndStatesService.getAllStates().subscribe(
      (data: ILookupData[]) => {
        this.statesData = data.sort((a, b) => a.name.localeCompare(b.name));
      },
      (error) => {
        console.error('Error fetching states data', error);
      }
    );
  }
 
  loadCommodities(): void {
    this.commodityCodesService.getAllCommodities().subscribe(
      (data: ICommodity[]) => {
        this.commoditiesData = data;
      },
      (error) => {
        console.error('Error fetching commodities data', error);
      }
    );
  }
 
  handleSelectedCommodityNames(selectedCommodityNames: ICommodity[]) {
    this.selectedCommodityObjects = selectedCommodityNames;
    if (selectedCommodityNames.length !== 0) {
      this.manufacturerForm.patchValue({
        commodity: selectedCommodityNames.map(commodity => commodity.commodityId)
      });
    } else {
      this.manufacturerForm.patchValue({
        commodity: null
      });
    }
  }
 
 onSubmit(): void {
    const formValues = this.manufacturerForm.value;
    const params = new HttpParams()
      .set('commodityId', formValues.commodity)
      .set('mfrName', formValues.ManufacturerName)
      .set('mfrCity', formValues.ManufacturerCity)
      .set('mfrState', formValues.state);

    this.manufacturersNameService.getManufacturersbyNameDetails(params)
   .subscribe((data) => {
      const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = `AQUA2_ManufacturersReport_${new Date().toISOString().slice(0, 19).replace(/:/g, '-')}.xlsx`;
      a.click();
      window.URL.revokeObjectURL(url);
    }, error => {
      console.error('Error downloading the report', error);
    });
  }
}