import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "../../environments/environment";
import { BehaviorSubject, Observable } from "rxjs";
import { ILookupData } from "../interfaces/administration/lookupData";

@Injectable({
  providedIn: "root",
})
export class CountriesAndStatesService {
  private countriesApiUrl = environment.apiUrl + "/countries";
  private statesApiUrl = environment.apiUrl + "/states";

  private countryDataSource = new BehaviorSubject<ILookupData[] | null>(null);
  currentCountriesData = this.countryDataSource.asObservable();

  private currentCountryDataSubject: BehaviorSubject<ILookupData> =
    new BehaviorSubject(null);
  currentCountryData$: Observable<ILookupData> =
    this.currentCountryDataSubject.asObservable();

  private currentStateDataSubject: BehaviorSubject<ILookupData> =
    new BehaviorSubject(null);
  currentStateData$: Observable<ILookupData> =
    this.currentStateDataSubject.asObservable();

  constructor(private http: HttpClient) { }

  public getAllCountries(): Observable<ILookupData[]> {
    const url = `${this.countriesApiUrl}`;
    this.currentCountriesData = this.http.get<ILookupData[]>(url);
    return this.currentCountriesData;
  }

  public updateCountry(
    id: string,
    countryData: ILookupData
  ): Observable<ILookupData> {
    return this.http.put<ILookupData>(
      `${this.countriesApiUrl}/${id}/update`,
      countryData
    );
  }

  public createCountry(countryData: ILookupData): Observable<any> {
    return this.http.post<ILookupData>(
      `${this.countriesApiUrl}/create`,
      countryData
    );
  }

  public deleteCountry(id: string): Observable<any> {
    let result = this.http.delete(`${this.countriesApiUrl}/${id}`);
    return result;
  }

  public getAllStates(): Observable<ILookupData[]> {
    const url = `${this.statesApiUrl}`;
    return this.http.get<ILookupData[]>(url);
  }

  public getStateById(id: string): Observable<ILookupData[]> {
    return this.http.get<ILookupData[]>(`${this.statesApiUrl}/${id}`);
  }

  public getStateByCountryId(id: string): Observable<ILookupData[]> {
    return this.http.get<ILookupData[]>(`${this.statesApiUrl}/country/${id}`);
  }

  public updateState(
    id: string,
    stateData: ILookupData
  ): Observable<ILookupData> {
    return this.http.put<ILookupData>(
      `${this.statesApiUrl}/${id}/update`,
      stateData
    );
  }

  public createState(stateData: ILookupData): Observable<any> {
    return this.http.post<ILookupData>(
      `${this.statesApiUrl}/create`,
      stateData
    );
  }

  public deleteState(id: string): Observable<any> {
    let result = this.http.delete(`${this.statesApiUrl}/${id}`);
    return result;
  }

  public updateCountryData(data: ILookupData): void {
    this.currentCountryDataSubject.next(data);
  }

  public updateStateData(data: ILookupData): void {
    this.currentStateDataSubject.next(data);
  }
}
