import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core";
import { MatTableDataSource } from "@angular/material/table";
import { IAuditObservationDetail } from "../../../interfaces/qa-management/audit-observation/auditObservationDetailData";
import { AuditObservationService } from "../../../services/qa-management/audit-observation.service";
import { MatPaginator } from "@angular/material/paginator";
import { TableSortService } from "../../../services/table-sort.service";
import { Sort } from "@angular/material/sort";

@Component({
    selector: 'manufacturer-audit-and-observation-table',
    templateUrl: './mao-table-result.component.html',
    styleUrls: ['./mao-table-result.component.css',
        '../mao.component.css'
    ]
})
export class ManufacturerAuditAndObservationTableComponent implements OnInit {
    @Input() records: IAuditObservationDetail[] = [];
    @Input() isManufacturer: boolean = true;
    @Output() recordNumberClicked = new EventEmitter<number>();
    @ViewChild(MatPaginator) paginator: MatPaginator;
    displayedColumns: string[] = ['recordNo', 'manufacturerName', 'isRML', 'recordType', 'leadName', 'commodityName', 'rating', 'recordDate', 'status'];
    dataSource: MatTableDataSource<IAuditObservationDetail>;
    originalDataSource: MatTableDataSource<IAuditObservationDetail>;

    constructor(
        private auditObservationService: AuditObservationService,
        private tableSortService: TableSortService,
        private changeDetectorRefs: ChangeDetectorRef
    ) {

    }

    ngOnInit(): void {
        if (!this.isManufacturer) {
            this.displayedColumns = ['recordNo', 'manufacturerName', 'isRML', 'leadName', 'inspectorName', 'agencyName', 'commodityName', 'rating', 'recordDate', 'status'];
        }
        this.dataSource = new MatTableDataSource(this.records);
        this.originalDataSource = new MatTableDataSource(this.records);
    }

    ngAfterViewInit(): void {
        this.dataSource.paginator = this.paginator;
        this.paginator.pageSize = 5;
        this.changeDetectorRefs.detectChanges();
    }

    getRatingClass(rating: string): string {
        return this.auditObservationService.getRatingClass(rating);
    }

    getRatingStringValue(rating: string): string {
        return this.auditObservationService.getRatingStringValue(rating);
    }

    onRecordNoClick(recordNo: number): void {
        window.scrollTo({ top: 0, behavior: 'smooth' });
        this.recordNumberClicked.emit(recordNo);
    }

    sortChange(event: Sort) {
        this.dataSource.data = this.tableSortService.sortDataSource(
            this.originalDataSource.data,
            event
        );
    }
}  