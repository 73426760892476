import { Component, EventEmitter, Input, Output } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { CommodityCodesService } from "projects/aqua3/src/app/services/administration/commodity-codes.service";
import { ICommodityResult } from "projects/aqua3/src/app/interfaces/qa-management/commodityResult";
import { ICommodity } from "projects/aqua3/src/app/interfaces/administration/commodity";
import { ICommodityRating } from "projects/aqua3/src/app/interfaces/qa-management/commodityRating";
import { IAuditObservationCommodity } from "projects/aqua3/src/app/interfaces/qa-management/audit-observation/auditObservationEvaluationDetails";

@Component({
  selector: "add-commodity-into-manufacturer-audit-and-observation",
  templateUrl: "./add-commodity-mao.component.html",
  styleUrls: ["./add-commodity-mao.component.css",
    '../../mao.component.css'
  ],
})
export class AddCommodityMAOComponent {
  @Input() editingCommodity: IAuditObservationCommodity;
  @Output() commoditiesDetails = new EventEmitter<IAuditObservationCommodity[]>();
  title = "Audit - New Commodity Details";
  detailsForm: FormGroup;

  resultCommodityList: IAuditObservationCommodity[] = [];

  commodityResultsDropdownData: ICommodityResult[] = [];
  commodityRatingsDropdownData: ICommodityRating[] = [];
  selectedCommodityObjects: ICommodity[] = [];
  isEditSingleCommodityMode = false;

  validationMessages = [];

  constructor(private fb: FormBuilder,
    private commodityCodesService: CommodityCodesService,
  ) {
    this.detailsForm = this.fb.group({
      commodityIds: [null, Validators.required],
      results: ['', Validators.required],
      auditDate: ['', Validators.required],
      rating: [''],
      notes: ['']
    });
  }

  ngOnInit(): void {
    this.getCommodityResults();
    this.getCommodityRatings();

    if (this.editingCommodity) {
      this.loadData(this.editingCommodity);
      this.isEditSingleCommodityMode = true;
    }
  }

  loadData(element: IAuditObservationCommodity): void {
    this.detailsForm.patchValue({
      commodityIds: [element.commodityId],
      results: element.resultId,
      auditDate: element.completionDate,
      rating: element.ratingId,
      notes: element.notes
    });
  }

  handleSelectedCommodityNames(selectedCommodityNames: ICommodity[]) {
    this.selectedCommodityObjects = selectedCommodityNames;
    if (selectedCommodityNames.length != 0) {
      this.detailsForm.patchValue({
        commodityIds: selectedCommodityNames.map(commodity => commodity.commodityId)
      });
    } else {
      this.detailsForm.patchValue({
        commodityIds: null
      });
    }
  }

  onSave() {
    this.validationMessages = [];

    if (this.detailsForm.valid) {
      if (this.isEditSingleCommodityMode) {
        this.resultCommodityList.push({
          id: this.editingCommodity.id,
          commodityId: this.editingCommodity.commodityId,
          commodityName: this.editingCommodity.commodityName,
          resultDesc: this.commodityResultsDropdownData.find(x => x.id == this.detailsForm.value.results).description,
          resultId: this.detailsForm.value.results,
          completionDate: this.detailsForm.value.auditDate,
          ratingDesc: this.commodityRatingsDropdownData.find(x => x.id == this.detailsForm.value.rating).description,
          ratingId: this.detailsForm.value.rating,
          notes: this.detailsForm.value.notes,
        });
      } else {
        this.selectedCommodityObjects.forEach(commodity => {
          this.resultCommodityList.push({
            id: 0,
            commodityId: commodity.commodityId,
            commodityName: commodity.commodityCodeTrimmed + ' - ' + commodity.description,
            resultId: this.detailsForm.value.results,
            resultDesc: this.commodityResultsDropdownData.find(x => x.id == this.detailsForm.value.results).description,
            completionDate: this.detailsForm.value.auditDate,
            ratingId: this.detailsForm.value.rating,
            ratingDesc: this.commodityRatingsDropdownData.find(x => x.id == this.detailsForm.value.rating).description,
            notes: this.detailsForm.value.notes
          });
        });
      }
      this.commoditiesDetails.emit(this.resultCommodityList);
    } else {
      if (this.detailsForm.get('commodityIds').value == null) {
        this.validationMessages.push('Commodity is required');
      }
      if (this.detailsForm.get('results').value == '') {
        this.validationMessages.push('Result is required');
      }
      if (this.detailsForm.get('auditDate').value == '') {
        this.validationMessages.push('Audit Date is required');
      }
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  }

  onCancel() {
    this.commoditiesDetails.emit([]);
  }

  getCommodityResults(): void {
    this.commodityCodesService
      .getAllCommodityResults()
      .subscribe((response) => {
        this.commodityResultsDropdownData = response;
      });
  }

  getCommodityRatings(): void {
    this.commodityCodesService
      .getAllCommodityRatings()
      .subscribe((response) => {
        this.commodityRatingsDropdownData = response;
      });
  }

  getSelectedCommoditiesString(): string {
    return this.selectedCommodityObjects
      .map(commodity => `${commodity.commodityCodeTrimmed} - ${commodity.description}`)
      .join('  |  ');
  }
}
