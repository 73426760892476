// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.action-buttons {
  display: flex;
  gap: 4px;
}

.read-only {
  color: blue;
}

.read-write {
  color: green;
}

.no-access {
  color: red;
}
`, "",{"version":3,"sources":["webpack://./projects/aqua3/src/app/administration/users-roles/roles-and-privileges/rap.component.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,QAAQ;AACV;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,UAAU;AACZ","sourcesContent":[".action-buttons {\n  display: flex;\n  gap: 4px;\n}\n\n.read-only {\n  color: blue;\n}\n\n.read-write {\n  color: green;\n}\n\n.no-access {\n  color: red;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
