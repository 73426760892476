import { AfterViewInit, Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { ILookupData } from "../interfaces/administration/lookupData";
import { BusOpcosDataService } from "../services/administration/bus-opcos-data-service";
import { ICapitalProjectData } from "../interfaces/administration/capitalProjectData";
import { PurchaserManagementService } from "../services/administration/purchaser-management.service";
import { CapitalProjectsDataService } from "../services/capital-projects.service";

@Component({
  selector: "edit-project-component",
  templateUrl: "./edit-project.component.html",
  styles: [
    `
      .button-container {
        display: flex !important;
        justify-content: space-between !important;
      }
    `,
  ],
})
export class EditProjectComponent implements OnInit, AfterViewInit {
  public title = "Edit project: ";
  public projectData: ICapitalProjectData;
  public backRoute = "";
  public isLoading = false;
  public projectForm: FormGroup;
  public validationMessages = [];
  public opcoids: ILookupData[] = [];
  public businessUnits: ILookupData[] = [];
  public purchasers: ILookupData[] = []; // Add the purchasers array back

  constructor(
    private service: BusOpcosDataService,
    private capitalProjectsDataService: CapitalProjectsDataService,
    private purchaserService: PurchaserManagementService,
    private router: Router,
    private fb: FormBuilder
  ) {
    this.projectData = {
      projectId: 0,
      longName: "",
      shortName: "",
      opcoid: 0,
      sbuid: 0,
      description: "",
      location: "",
      epcid: 0,
      isProjectArchived: false,
    };
    this.projectForm = this.fb.group({
      longName: ["", Validators.required],
      shortName: ["", Validators.required],
      location: ["", Validators.required],
      opco: ["", Validators.required],
      businessUnit: [{ value: "0", disabled: false }, Validators.required],
      description: ["", Validators.required],
      purchaser: ["", Validators.required],
    });
  }

  ngAfterViewInit(): void {}

  ngOnInit(): void {
    let id = this.router.url.split("/")[3];

    this.capitalProjectsDataService
      .getCapitalProjectById(Number(id))
      .subscribe((data) => {
        if (data != null) {
          this.projectData = data;
          this.title += this.projectData.longName;
          this.getOpcos();
          this.getPurchasers();

          if (data.opcoid) {
            this.projectForm.get("opco").setValue(data.opcoid);
            this.onOpcoChange(data.opcoid);
          }
          if (data.epcid) {
            this.projectForm.get("purchaser").setValue(data.epcid);
          }
          this.projectForm
            .get("opco")
            .valueChanges.subscribe((selectedOpco) => {
              if (selectedOpco) {
                this.onOpcoChange(selectedOpco);
              }
            });

          this.projectForm.patchValue(data);
        }
      });
  }

  getOpcos() {
    this.service.getOpcos().subscribe((data: ILookupData[]) => {
      this.opcoids = data;
    });
  }

  getPurchasers() {
    this.purchaserService.getPurchasers().subscribe((data: ILookupData[]) => {
      this.purchasers = data;
    });
  }

  onOpcoChange(opcoId: number) {
    if (opcoId > 0) {
      this.service.getBUsByOpcoId(opcoId).subscribe((data: ILookupData[]) => {
        this.businessUnits = data;
        this.projectForm.get("businessUnit")?.enable();
        // Set the value of the 'businessUnit' control
        if (this.projectData && this.projectData.sbuid) {
          this.projectForm.get("businessUnit").setValue(this.projectData.sbuid);
        }
      });
    } else {
      this.projectForm.get("businessUnit").disable();
    }
  }

  onSubmit() {
    this.isLoading = true;

    this.validationMessages = this.capitalProjectsDataService.validateForm(
      this.projectForm
    );

    if (this.projectForm.valid && this.validationMessages.length === 0) {
      this.projectData.longName = this.projectForm.get("longName").value;
      this.projectData.shortName = this.projectForm.get("shortName").value;
      this.projectData.location = this.projectForm.get("location").value;
      this.projectData.description = this.projectForm.get("description").value;
      this.projectData.opcoid = this.projectForm.get("opco").value;
      this.projectData.sbuid = this.projectForm.get("businessUnit").value;
      this.projectData.epcid = this.projectForm.get("purchaser").value; // Use the selected purchaser

      this.capitalProjectsDataService
        .updateProject(this.projectData)
        .subscribe({
          next: (data) => {
            if (data) {
              this.capitalProjectsDataService.currentCapitaProject$.subscribe(
                (data) => {
                  this.capitalProjectsDataService.setCurrentCapitalProject(
                    data
                  );
                }
              );
              let route = this.capitalProjectsDataService.getBackRoute();
              if (route !== "") {
                this.router.navigate([route]);
              } else {
                this.router.navigate(["/approved-vendor-list"]);
              }
            }
          },
          error: (error) => {
            this.validationMessages.push(error);
          },
          complete: () => {},
        });
    }

    this.isLoading = false;
  }

  cancelRequest() {
    this.capitalProjectsDataService.loadBackRoute$.subscribe((route) => {
      this.router.navigate([route]);
    });
  }
}
