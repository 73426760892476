import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { environment } from "../../environments/environment";
import { IVendorInformationData } from "../interfaces/vendor-information/vendorInformationData";
const apiUrl = environment.apiUrl + "/vendorInformation";

@Injectable({
  providedIn: "root",
})
export class VendorInformationDataService {
  private vendorInformationManufacturerDataSource = new BehaviorSubject<
    IVendorInformationData[] | null
  >(null);
  currentVendorInformationManufacturerData =
    this.vendorInformationManufacturerDataSource.asObservable();

  private vendorInformationSupplierDataSource = new BehaviorSubject<
    IVendorInformationData[] | null
  >(null);
  currentVendorInformationSupplierData =
    this.vendorInformationSupplierDataSource.asObservable();

  constructor(private http: HttpClient) {}

  changeVendorManufacturerData(vendorData: IVendorInformationData[]) {
    this.vendorInformationManufacturerDataSource.next(vendorData);
  }

  changeVendorSupplierData(vendorData: IVendorInformationData[]) {
    this.vendorInformationSupplierDataSource.next(vendorData);
  }

  public getManufacturerVendors(
    searchText: string
  ): Observable<IVendorInformationData[]> {
    return this.http.get<IVendorInformationData[]>(
      `${apiUrl}/manufacturers/${searchText}`
    );
  }

  public getSuppliersVendors(
    searchText: string
  ): Observable<IVendorInformationData[]> {
    return this.http.get<IVendorInformationData[]>(
      `${apiUrl}/suppliers/${searchText}`
    );
  }

  public getAllShortInfoManufacturerVendors(): Observable<
    IVendorInformationData[]
  > {
    return this.http.get<IVendorInformationData[]>(
      `${apiUrl}/manufacturers/all/short`
    );
  }

  public getAllManufacturerVendors(): Observable<IVendorInformationData[]> {
    return this.http.get<IVendorInformationData[]>(
      `${apiUrl}/manufacturers/all`
    );
  }

  public getAllSuppliersVendors(): Observable<IVendorInformationData[]> {
    return this.http.get<IVendorInformationData[]>(`${apiUrl}/suppliers/all`);
  }

  public getVendorInformationById(
    id: number
  ): Observable<IVendorInformationData> {
    return this.http.get<IVendorInformationData>(`${apiUrl}/${id}`);
  }
}
