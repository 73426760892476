import { HttpClient } from '@angular/common/http';
import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-qa-reports',
  templateUrl: './qa-reports.component.html',
})
export class QaReportsComponent {
  links: any[] = [];
  constructor (private http:HttpClient,private router: Router){}

}
