import { Component, OnInit } from '@angular/core';
import { CapitalProjectsDataService } from '../../../services/capital-projects.service';
import { ICapitalProjectData } from '../../../interfaces/administration/capitalProjectData';
import { Router } from '@angular/router';
import { CommodityCodesService } from '../../../services/administration/commodity-codes.service';
import { ICommodity } from '../../../interfaces/administration/commodity';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HttpParams } from '@angular/common/http';
import { ManufacturersNameService } from '../../../services/qa-reports/manufacturers-name.service';
import { PerformanceTrackingSearchFilter } from '../../../interfaces/administration/PerformanceTrackingSearchFilter';
 
@Component({
  selector: 'app-spt-reports',
  templateUrl: './spt-reports.component.html',
})
export class SptReportsComponent implements OnInit {
  filter: PerformanceTrackingSearchFilter = {
    projectId: 0,
    vendor: '',
    commodityId: [],
    commodityDescription: '',
    projectLocation: '',
    epcId: 0,
    poNumber: ''
  };
  public capitalProjectsData: ICapitalProjectData[] = [];
  selectedCommodityObjects: ICommodity[] = [];
  commoditiesData: ICommodity[] = [];
  isLoading = false; // Add loading state
  validationMessages: string[] = [];
  sptForm: FormGroup;
  errorMessage: string = '';
  constructor(
    private capitalProjectsService: CapitalProjectsDataService,
    private router: Router,
    private commodityCodesService: CommodityCodesService,
    private fb: FormBuilder, // Add FormBuilder
    private manufacturersNameService: ManufacturersNameService
  ) {
 
  }
 
  ngOnInit() {
    this.loadCapitalProjects();
  }
 
  loadCapitalProjects() {
    this.capitalProjectsService.getAllProjects().subscribe((data) => {
      this.capitalProjectsData = data;
    });
  }
 
  cancel() {
    this.router.navigate(['/reports-forms'], { queryParams: { tab: 'Reports' } });
  }
 
  loadCommodities(): void {
    this.isLoading = true; // Set loading to true when fetching starts
    this.commodityCodesService.getAllCommodities().subscribe(
      (data: ICommodity[]) => {
        this.commoditiesData = data;
        this.isLoading = false; // Set loading to false when data is fetched
      },
      (error) => {
        console.error('Error fetching commodities data', error);
        this.isLoading = false; // Set loading to false in case of error
      }
    );
  }
 
  handleSelectedCommodityNames(selectedCommodityNames: ICommodity[]): void {
    this.selectedCommodityObjects = selectedCommodityNames;
    if (selectedCommodityNames.length !== 0) {
      this.filter.commodityId = selectedCommodityNames.map(commodity => commodity.commodityCodeTrimmed);
   } else {
      this.filter.commodityId = [];
    }
  }
 
    onSubmit(): void {
        this.isLoading = true;
    if (!this.filter.projectId && !this.filter.vendor && !this.filter.commodityId.length && !this.filter.fromDate && !this.filter.toDate) {
      this.errorMessage = 'You have to enter at least one filter to run the report';
      this.isLoading=false;
    }
    else {
      this.errorMessage = '';
      this.manufacturersNameService.getsptreport(this.filter) // change the method name
        .subscribe((data) => {
          const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement('a');
          a.href = url;
          a.download = `AQUA3_SupplierPerformaceTrackingReport_${new Date().toISOString().slice(0, 19).replace(/:/g, '-')}.xlsx`;
          a.click();
            window.URL.revokeObjectURL(url);
            this.isLoading = false;
        }, error => {
          console.error('Error downloading the report', error);
        });
    }
  }
}
 