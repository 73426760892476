import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewChild,
} from "@angular/core";
import { MatTableDataSource } from "@angular/material/table";
import { TableSortService } from "projects/aqua3/src/app/services/table-sort.service";
import { MatPaginator } from "@angular/material/paginator";
import { WorkRequestsDataService } from "../../../services/qa-management/work-requests/work-requests.service";
import { Sort } from "@angular/material/sort";
import { IWorkRequestActivityData } from "../../../interfaces/qa-management/work-requests/workRequestActivityData";
import { Router } from "@angular/router";

@Component({
  selector: "activity-component",
  templateUrl: "./activity-component.html",
})
export class WorkRequestActivityComponent implements OnInit, OnChanges {
  @Input() workRequestId: number;
  @Input() agencyName: string;
  @Input() formEditable: boolean;
  title = "Activities";
  dataSource: MatTableDataSource<IWorkRequestActivityData>;
  originalDataSource: MatTableDataSource<IWorkRequestActivityData>;

  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;

  displayedColumns = [
    "actionDate",
    "actionBy",
    "acceptanceDate",
    "receivedDate",
    "acceptedBy",
    "status",
    "notes",
  ];

  dataSourceLoading: boolean = true;

  constructor(
    private tableSortService: TableSortService,
    private workRequestsService: WorkRequestsDataService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.loadActivities();
  }

  ngOnChanges(changes: SimpleChanges): void {
    // React to changes in the input property
    if (changes.workRequestId && !changes.workRequestId.isFirstChange()) {
      this.loadActivities();
    }
  }

  loadActivities(): void {
    if (this.workRequestId !== null && this.workRequestId !== undefined) {
      this.workRequestsService
        .getWorkRequestActivitiesByWorkRequestId(this.workRequestId)
        .subscribe({
          next: (data) => {
            this.dataSourceLoading = true;

            this.originalDataSource =
              new MatTableDataSource<IWorkRequestActivityData>(data);
            this.dataSource = new MatTableDataSource<IWorkRequestActivityData>(
              this.originalDataSource.data
            );

            this.dataSourceLoading = false;
            // Delay the initialization of the paginator until the data is assigned
            setTimeout(() => {
              this.dataSource.paginator = this.paginator;
              this.paginator.pageSize = 10;
            });
          },
          error: (error) => {
            console.error(error);
          },
        });
    }
  }

  announceSortChange(event: Sort) {
    this.dataSource.data = this.tableSortService.sortDataSource(
      this.originalDataSource.data,
      event
    );
  }

  addActivity(): void {
    this.router.navigate([`/qa-management/work-requests/add-activity`], {
      queryParams: {
        workRequestId: this.workRequestId,
        agencyName: this.agencyName,
      },
    });
  }
}
