import { HttpClient } from "@angular/common/http";
import { Component, OnInit } from "@angular/core";
import { UserPermissionsService } from "../../services/common/user-permissions.service";
import { BaseComponent } from "../../shared/base/base.component";

@Component({
  selector: "faq-component",
  templateUrl: "./faq.component.html",
})
export class FAQComponent extends BaseComponent implements OnInit {
  links: any[] = [];

  constructor(
    private http: HttpClient,
    userPermissionsService: UserPermissionsService
  ) {
    super(userPermissionsService);
  }

  ngOnInit(): void {
    super.ngOnInit();
  }
}
