import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReportsFormsComponent } from './reports-forms.component';
import { MenuModule } from '../menu/menu.module';
import { QaReportsComponent } from './qa-reports/qa-reports.component';
import { FormsComponent } from './forms/forms.component';
import { ProceduresComponent } from './procedures/procedures.component';
import { ReportsComponent  } from './reports/reports.component';
import { SptReportsComponent } from './reports/spt-reports/spt-reports.component';
import { ManufacturebynameComponent } from './qa-reports/manufacturebyname/manufacturebyname.component';
import { ManufacturebycommodityComponent } from './qa-reports/manufacturebycommodity/manufacturebycommodity.component';
import { InspectorsbycommodityComponent } from './qa-reports/inspectorsbycommodity/inspectorsbycommodity.component';
import { AssignmentlogComponent } from './qa-reports/assignmentlog/assignmentlog.component';
import { ClosedjoblistingComponent } from './qa-reports/closedjoblisting/closedjoblisting.component';
import { QabydateComponent } from './qa-reports/qabydate/qabydate.component';
import { QabypoComponent } from './qa-reports/qabypo/qabypo.component';
import { QabyqaeComponent } from './qa-reports/qabyqae/qabyqae.component';
import { QabyclientComponent } from './qa-reports/qabyclient/qabyclient.component';
import { QabyinspectorComponent } from './qa-reports/qabyinspector/qabyinspector.component';
import { AgencyratingsComponent } from './qa-reports/agencyratings/agencyratings.component';
const componentList = [
ReportsFormsComponent,
ReportsComponent,
QaReportsComponent,
FormsComponent,
ProceduresComponent,
SptReportsComponent,
ManufacturebynameComponent,
ManufacturebycommodityComponent,
InspectorsbycommodityComponent,
AssignmentlogComponent,
ClosedjoblistingComponent,
QabydateComponent,
QabypoComponent,
QabyqaeComponent,
QabyclientComponent,
QabyinspectorComponent,
AgencyratingsComponent,
];
@NgModule({
  declarations: [componentList],
  imports: [
    CommonModule,
    MenuModule,
  ]
})
export class ReportsFormsModule { }
