import { Component, EventEmitter, Output } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { ICommodity } from "../../interfaces/administration/commodity";
import { VendorInformationDataService } from "../../services/vendor-information-data.service";
import { IVendorInformationData } from "../../interfaces/vendor-information/vendorInformationData";
import { UserRequestsDataService } from "../../services/administration/user-requests.service";
import { IUserData } from "../../interfaces/administration/userData";
import { ICommodityResult } from "../../interfaces/qa-management/commodityResult";
import { CommodityCodesService } from "../../services/administration/commodity-codes.service";
import { AuditObservationService } from "../../services/qa-management/audit-observation.service";
import { IEvaluationStatus } from "../../interfaces/qa-management/audit-observation/evaluationStatus";
import { IAuditObservationDetail } from "../../interfaces/qa-management/audit-observation/auditObservationDetailData";
import { PageViewMode } from "../../interfaces/common/pageViewMode";
import { Subscription } from "rxjs";
import { Router } from "@angular/router";
import { BaseComponent } from "../../shared/base/base.component";
import { UserPermissionsService } from "../../services/common/user-permissions.service";

export enum RatingType {
  Qualified = "Qualified",
  ConditionallyQualified = "Conditionally Qualified",
  NotQualified = "Not Qualified",
  NotAvailable = "Not Available",
}

@Component({
  selector: "manufacturer-audit-and-observation",
  templateUrl: "./mao.component.html",
  styleUrls: ["./mao.component.css"],
})
export class ManufacturerAuditAndObservationComponent extends BaseComponent {
  @Output() clearCommodityCodesEvent = new EventEmitter<void>();
  title = "Manufacturer Audit And Observation";

  PageViewMode = PageViewMode;
  MAOModeSubscription: Subscription;
  MAOMode = PageViewMode.Base;

  selectedRecordNo: number;

  searchFiltersForm: FormGroup;
  selectedCommodityObjects: ICommodity[] = [];
  validationMessages = [];
  manufacturerDropdownData: IVendorInformationData[] = [];
  leadsDropdownData: IUserData[] = [];
  commodityRatingsDropdownData: ICommodityResult[] = [];
  auditObservationStatusesDropdownData: IEvaluationStatus[] =
    [];
  auditObservationDetailsTableData: IAuditObservationDetail[] = [];
  auditObservationDetailsTableFilteredData: IAuditObservationDetail[] = [];
  dataForTableLoading = false;
  isSearchedLaunched = false;

  qualifiedData: IAuditObservationDetail[] = [];
  conditionallyQualifiedData: IAuditObservationDetail[] = [];
  notQualifiedData: IAuditObservationDetail[] = [];
  notAvailableData: IAuditObservationDetail[] = [];

  constructor(
    private fb: FormBuilder,
    private vendorInformationDataService: VendorInformationDataService,
    private userRequestsDataService: UserRequestsDataService,
    private commodityCodesService: CommodityCodesService,
    private auditObservationService: AuditObservationService,
    private router: Router,
    userPermissionsService: UserPermissionsService,
  ) {
    super(userPermissionsService);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.getManufacturers();
    this.getLeads();
    this.getCommodityRatings();
    this.getEvaluationStatuses();
    this.searchFiltersForm = this.fb.group({
      manufacturerId: [-1],
      leadName: [""],
      commodityIds: [null],
      recordType: [""],
      rating: [""],
      dateFrom: [null],
      dateTo: [null],
      statusId: [-1],
    });

    this.auditObservationService.setMAOMode(PageViewMode.Base);

    this.MAOModeSubscription = this.auditObservationService.MAOMode$.subscribe(mode => {
      this.MAOMode = mode;
    });
    this.getAllDetails();
  }

  onClearFilters(): void {
    this.searchFiltersForm.reset({
      manufacturerId: -1,
      leadName: "",
      commodityIds: null,
      recordType: "",
      rating: "",
      dateFrom: null,
      dateTo: null,
      statusId: -1,
    });

    this.clearCommodityCodesEvent.emit();
  }

  onAddAuditObservation() {
    this.router.navigate(["/qa-management/manufacturer-audit-observation/addAuditObservation"]);
  }

  handleSelectedCommodityNames(selectedCommodityNames: ICommodity[]) {
    this.selectedCommodityObjects = selectedCommodityNames;
    if (selectedCommodityNames.length != 0) {
      this.searchFiltersForm.patchValue({
        commodityIds: selectedCommodityNames.map(commodity => commodity.commodityId)
      });
    } else {
      this.searchFiltersForm.patchValue({
        commodityIds: null
      });
    }
  }

  getManufacturers(): void {
    this.vendorInformationDataService
      .getAllManufacturerVendors()
      .subscribe(
        (response) => {
          this.vendorInformationDataService.changeVendorManufacturerData(
            response
          );
          this.manufacturerDropdownData = response;
        },
        (error) => {
          console.error(error);
        }
      );
  }

  getLeads(): void {
    this.userRequestsDataService.getAllActiveUsers().subscribe((response) => {
      this.leadsDropdownData = response;
    });
  }

  getCommodityRatings(): void {
    this.commodityCodesService
      .getAllCommodityResults()
      .subscribe((response) => {
        this.commodityRatingsDropdownData = response;
      });
  }

  getEvaluationStatuses(): void {
    this.auditObservationService
      .getAllEvaluationStatus()
      .subscribe((response) => {
        this.auditObservationStatusesDropdownData = response;
      });
  }

  getAllDetails(): void {
    this.dataForTableLoading = true;
    const filters = this.searchFiltersForm.value;

    if (filters.dateFrom) {
      filters.dateFrom = new Date(filters.dateFrom).toISOString();
    }
    if (filters.dateTo) {
      filters.dateTo = new Date(filters.dateTo).toISOString();
    }

    this.auditObservationService
      .getAllDetails(filters)
      .subscribe((response) => {
        this.auditObservationDetailsTableData = response;
        this.isSearchedLaunched = true;
        this.qualifiedData = response.filter(
          (item) => item.rating == RatingType.Qualified
        );
        this.conditionallyQualifiedData = response.filter(
          (item) => item.rating == RatingType.ConditionallyQualified
        );
        this.notQualifiedData = response.filter(
          (item) => item.rating == RatingType.NotQualified
        );
        this.notAvailableData = response.filter(
          (item) => item.rating == RatingType.NotAvailable || item.rating == null || item.rating == ""
        );
        this.dataForTableLoading = false;
      });
  }

  onRecordNoClicked(recordNo: number): void {
    this.selectedRecordNo = recordNo;
    this.auditObservationService.setMAOMode(PageViewMode.Edit);
    this.auditObservationService.setMAOAddMode(PageViewMode.Base);
  }
}
