// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.container {
  margin: 0 auto;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.record-details {
  margin: 20px 0;
}

.row {
  display: flex;
  margin-bottom: 10px;
}

.label {
  font-weight: bold;
  width: 150px;
}

.value {
  flex: 1;
}

.commodity-table {
  width: 100%;
}

textarea {
  width: 100%;
  height: 100px;
}

.button-container {
  display: flex;
  justify-content: flex-start;
  gap: 10px;
  margin-top: 30px;
}

.textarea-custom {
  border: 1px solid #bbbbbb;
  border-radius: 5px;
  padding: 10px;
}
`, "",{"version":3,"sources":["webpack://./projects/aqua3/src/app/qa-management/inspector-evaluation/ie-details/ie-details.component.css"],"names":[],"mappings":"AAAA;EACE,cAAc;AAChB;;AAEA;EACE,aAAa;EACb,8BAA8B;EAC9B,mBAAmB;AACrB;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,aAAa;EACb,mBAAmB;AACrB;;AAEA;EACE,iBAAiB;EACjB,YAAY;AACd;;AAEA;EACE,OAAO;AACT;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,WAAW;EACX,aAAa;AACf;;AAEA;EACE,aAAa;EACb,2BAA2B;EAC3B,SAAS;EACT,gBAAgB;AAClB;;AAEA;EACE,yBAAyB;EACzB,kBAAkB;EAClB,aAAa;AACf","sourcesContent":[".container {\n  margin: 0 auto;\n}\n\n.header {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n}\n\n.record-details {\n  margin: 20px 0;\n}\n\n.row {\n  display: flex;\n  margin-bottom: 10px;\n}\n\n.label {\n  font-weight: bold;\n  width: 150px;\n}\n\n.value {\n  flex: 1;\n}\n\n.commodity-table {\n  width: 100%;\n}\n\ntextarea {\n  width: 100%;\n  height: 100px;\n}\n\n.button-container {\n  display: flex;\n  justify-content: flex-start;\n  gap: 10px;\n  margin-top: 30px;\n}\n\n.textarea-custom {\n  border: 1px solid #bbbbbb;\n  border-radius: 5px;\n  padding: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
