import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { environment } from "../../../environments/environment";
import { IGroupMember } from "../../interfaces/administration/groupMemberData";
import { BehaviorSubject, Observable } from "rxjs";
import { IUserData } from "../../interfaces/administration/userData";
import { IUserNotificationData } from "../../interfaces/administration/userNotificationData";

@Injectable({
  providedIn: "root",
})
export class GraphService {
  private apiUrl = environment.apiUrl + "/graph";

  private currentUserDataSubject: BehaviorSubject<IUserData> =
    new BehaviorSubject(null);
  currentUserData$: Observable<IUserData> =
    this.currentUserDataSubject.asObservable();

  constructor(private http: HttpClient) { }

  public getMembersOfGroup(groupName: string): Observable<IGroupMember[]> {
    const url = `${this.apiUrl}/getMembersOfGroup/${groupName}`;
    return this.http.get<IGroupMember[]>(url);
  }

  public getAllUsers(): Observable<IUserData[]> {
    const url = `${this.apiUrl}/getAllUsers`;
    return this.http.get<IUserData[]>(url);
  }

  public getAdministratorsForEmailNotifs(
    roleGroupConfName: string
  ): Observable<IUserNotificationData[]> {
    const url = `${this.apiUrl}/getAdministratorsForEmailNotifs`;
    const params = new HttpParams().set("roleGroupConfName", roleGroupConfName);
    return this.http.get<IUserNotificationData[]>(url, { params });
  }

  public updateUserData(data: IUserData): void {
    this.currentUserDataSubject.next(data);
  }

  public updateUser(userData: IUserData): Observable<IUserData> {
    return this.http.put<IUserData>(`${this.apiUrl}/updateUser`, userData);
  }

  public searchForUserInEntra(searchText: String): Observable<IUserData[]> {
    const url = `${this.apiUrl}/search/${searchText}`;
    return this.http.get<IUserData[]>(url);
  }
}
