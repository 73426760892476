import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { RolePrivilegesDataService } from './services/administration/role-privileges.service';
import { UserPermissionsService } from './services/common/user-permissions.service';
import { PrivilegesAccessType } from './interfaces/common/privilegesAccessTypeData';

@Injectable({
    providedIn: 'root'
})
export class RoleGuard implements CanActivate {

    constructor(
        private router: Router,
        private rolePrivilegesDataService: RolePrivilegesDataService,
        private userPermissionsService: UserPermissionsService
    ) { }

    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
        let result = false;
        const pageName = next.data.pageName;
        const subPageName = next.data.subPageName;
        return this.rolePrivilegesDataService.getSpecificRolePrivilege(
            pageName, subPageName).toPromise()
            .then(data => {
                if (data.accessType == PrivilegesAccessType.RW || data.accessType == PrivilegesAccessType.R) {
                    result = true;
                    this.userPermissionsService.setPermissions(data.accessType);
                } else {
                    result = false;
                    this.userPermissionsService.setPermissions(PrivilegesAccessType.NoAccess);
                    this.router.navigate(['no-access']);
                }
                return result;
            });
    }
} 