import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, Subject } from "rxjs";
import { AdministrationTabs } from "../../interfaces/administration/administrationTabs";

@Injectable({
  providedIn: "root",
})
export class AdministrationLoadingDataService {
  private loadUserRequestsDataSubject = new Subject<void>();
  loadUserRequestsData$: Observable<void> =
    this.loadUserRequestsDataSubject.asObservable();

  private loadRejectedCommodityRequestsDataSubject = new Subject<void>();
  loadRejectedCommodityRequestsData$: Observable<void> =
    this.loadRejectedCommodityRequestsDataSubject.asObservable();

  private loadPendingCommodityRequestsDataSubject = new Subject<void>();
  loadPendingCommodityRequestsData$: Observable<void> =
    this.loadPendingCommodityRequestsDataSubject.asObservable();

  private loadApprovedCommodityRequestsDataSubject = new Subject<void>();
  loadApprovedCommodityRequestsData$: Observable<void> =
    this.loadApprovedCommodityRequestsDataSubject.asObservable();

  private activeTabDataSubject = new BehaviorSubject<AdministrationTabs>(AdministrationTabs.UserRequests);
  activeTabData$: Observable<AdministrationTabs> =
    this.activeTabDataSubject.asObservable();

  loadUserRequestsData(): void {
    this.loadUserRequestsDataSubject.next();
  }

  loadRejectedCommodityRequestsData(): void {
    this.loadRejectedCommodityRequestsDataSubject.next();
  }

  loadPendingCommodityRequestsData(): void {
    this.loadPendingCommodityRequestsDataSubject.next();
  }

  loadApprovedCommodityRequestsData(): void {
    this.loadApprovedCommodityRequestsDataSubject.next();
  }

  setActiveTab(tabName: AdministrationTabs): void {
    this.activeTabDataSubject.next(tabName);
  }

  getActiveTab(): AdministrationTabs {
    return this.activeTabDataSubject.getValue();
  }
}
