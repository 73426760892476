import { Injectable } from "@angular/core";
import {
  BehaviorSubject,
  Observable,
  Subject,
  catchError,
  switchMap,
  tap,
  throwError,
} from "rxjs";
import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { environment } from "projects/aqua3/src/environments/environment";
import { ICapitalProjectData } from "../interfaces/administration/capitalProjectData";
import { FormGroup } from "@angular/forms";
import { LoggedUserService } from "./common/logged-user.service";
import { ICapitalProjectUsersData } from "../interfaces/administration/capitalProjectUsersData";
import { IViewProjectAlertData } from "../interfaces/administration/viewProjectAlertData";
import { ProjectsAndAvls } from "../interfaces/administration/projectsAndAvls";
import { IExtendedCapitalProjectData } from "../approved-vendor-list/preview-project.component";
import { IViewPerformanceTrackingData } from "../interfaces/vendor-information/viewPerformanceTrackingData";
const apiUrl = environment.apiUrl;

@Injectable({
  providedIn: "root",
})
export class CapitalProjectsDataService {
  constructor(
    private http: HttpClient,
    private loggedUserService: LoggedUserService
  ) {}

  private currentCapitaProjectSubject: BehaviorSubject<ICapitalProjectData> =
    new BehaviorSubject(null);
  currentCapitaProject$: Observable<ICapitalProjectData> =
    this.currentCapitaProjectSubject.asObservable();

  private loadProjectMembersSubject = new Subject<ICapitalProjectUsersData[]>();
  loadProjectMembers$: Observable<ICapitalProjectUsersData[]> =
    this.loadProjectMembersSubject.asObservable();

  private loadProjectAvlsSubject = new Subject<ProjectsAndAvls[]>();
  loadProjectAvls$: Observable<ProjectsAndAvls[]> =
    this.loadProjectAvlsSubject.asObservable();

  private loadSupplierAlerts = new Subject<IViewProjectAlertData[]>();
  loadSupplierAlerts$: Observable<IViewProjectAlertData[]> =
    this.loadSupplierAlerts.asObservable();

  private loadPerformanceTrackingData = new Subject<
    IViewPerformanceTrackingData[]
  >();
  loadPerformanceTrackingData$: Observable<IViewPerformanceTrackingData[]> =
    this.loadPerformanceTrackingData.asObservable();

  private loadBackRouteSubject = new BehaviorSubject<string>("");
  loadBackRoute$: Observable<string> = this.loadBackRouteSubject.asObservable();

  public setBackRoute(route: string) {
    this.loadBackRouteSubject.next(route);
  }

  public getBackRoute(): string {
    return this.loadBackRouteSubject.getValue();
  }

  public setCurrentCapitalProject(project: ICapitalProjectData) {
    this.currentCapitaProjectSubject.next(project);
  }

  public getCapitalProjectById(id: number): Observable<ICapitalProjectData> {
    return this.http.get<ICapitalProjectData>(
      `${apiUrl}/capitalProjects/${id}`
    );
  }

  public getAllProjects(): Observable<ICapitalProjectData[]> {
    return this.http.get<ICapitalProjectData[]>(`${apiUrl}/capitalProjects`);
  }

  public getProjectsLocations(): Observable<string[]> {
    return this.http.get<string[]>(`${apiUrl}/capitalProjects/locations`);
  }

  public updateProject(
    projectData: ICapitalProjectData
  ): Observable<ICapitalProjectData> {
    return this.http
      .put<ICapitalProjectData>(
        `${apiUrl}/capitalProjects/${projectData.projectId}`,
        projectData
      )
      .pipe(catchError(this.handleError));
  }

  public saveProject(
    projectData: ICapitalProjectData
  ): Observable<ICapitalProjectData> {
    return this.loggedUserService.getLoggedUserData().pipe(
      switchMap((loggedUserData) => {
        // Combine loggedUserData and vendorCommodity into a single object
        const body = {
          userData: loggedUserData,
          projectData: projectData,
        };

        return this.http
          .post<ICapitalProjectData>(`${apiUrl}/capitalProjects`, body)
          .pipe(catchError(this.handleError));
      })
    );
  }

  public loadAvlsForProject(projectData: ICapitalProjectData) {
    return this.http
      .get<ProjectsAndAvls[]>(
        `${apiUrl}/capitalProjects/${projectData.projectId}/avls`
      )
      .pipe(catchError(this.handleError))
      .subscribe((avls) => {
        this.loadProjectAvlsSubject.next(avls);
      });
  }

  public deleteAvl(data: ProjectsAndAvls) {
    return this.http.delete<ProjectsAndAvls[]>(
      `${apiUrl}/project-avl/delete-avl/${data.approvedVendorListId}`
    );
  }

  public addAVL(avlName: string, projectData: ICapitalProjectData) {
    let obj: ProjectsAndAvls = {
      listName: avlName,
      projectId: projectData.projectId,
      longName: "",
      shortName: "",
      opcoid: 0,
      sbuid: 0,
      description: "",
      location: "",
      epcid: 0,
      isProjectArchived: false,
      approvedVendorListId: 0,
      isArchived: false,
      lastCommodityUpdate: new Date().toISOString().slice(0, 19),
    };

    return this.http.post<ProjectsAndAvls[]>(
      `${apiUrl}/project-avl/add-avl`,
      obj
    );
  }

  public updateAvl(selectedAvl: ProjectsAndAvls) {
    return this.http.put<ProjectsAndAvls>(
      `${apiUrl}/project-avl/update-avl/${selectedAvl.approvedVendorListId}`,
      selectedAvl
    );
  }

  public loadAlertsForProject(projectData: ICapitalProjectData) {
    return this.http
      .get<IViewProjectAlertData[]>(
        `${apiUrl}/capitalProjects/${projectData.projectId}/alerts`
      )
      .pipe(catchError(this.handleError))
      .subscribe((alerts) => {
        this.loadSupplierAlerts.next(alerts);
      });
  }

  public loadPerformanceTrackingForProject(
    projectData: IExtendedCapitalProjectData
  ) {
    return this.http
      .get<IViewPerformanceTrackingData[]>(
        `${apiUrl}/performance-tracking/project/${projectData.projectId}`
      )
      .pipe(catchError(this.handleError))
      .subscribe((data) => {
        this.loadPerformanceTrackingData.next(data);
      });
  }

  public loadMembers(
    projectId: number
  ): Observable<ICapitalProjectUsersData[]> {
    return this.http
      .get<ICapitalProjectUsersData[]>(
        `${apiUrl}/capitalProjects/${projectId}/members`
      )
      .pipe(
        catchError(this.handleError),
        tap((members) => this.loadProjectMembersSubject.next(members))
      );
  }

  public addProjectMember(projectId: number, userId: number) {
    return this.http.post<ICapitalProjectUsersData[]>(
      `${apiUrl}/capitalProjects/${projectId}/members`,
      { userId: userId }
    );
  }

  public deleteProjectMember(projectId: number, userId: number) {
    return this.http.delete<ICapitalProjectUsersData[]>(
      `${apiUrl}/capitalProjects/${projectId}/members/${userId}`
    );
  }

  public validateForm(form: FormGroup) {
    let formProjectName: string = form.get("longName").value;
    let formProjectCodeName: string = form.get("shortName").value;
    let formProjectLocation: string = form.get("location").value;
    let formOpco: string = form.get("opco").value;
    let formBusinessUnit: string = form.get("businessUnit").value;
    let formProjectDescription: string = form.get("description").value;
    let formPurchaser: string = form.get("purchaser").value;

    let messagesValidationsList: string[] = [];

    if (formProjectName === "")
      messagesValidationsList.push("Please enter Project Name.");

    if (formProjectCodeName === "")
      messagesValidationsList.push("Please enter Project Code Name.");

    if (formProjectLocation === "")
      messagesValidationsList.push("Please enter Project Location.");

    if (formOpco === "") messagesValidationsList.push("Please select Opco.");

    if (formBusinessUnit === "")
      messagesValidationsList.push("Please select Business Unit.");

    if (formProjectDescription === "")
      messagesValidationsList.push("Please enter Project Description.");

    if (formPurchaser === "")
      messagesValidationsList.push("Please select Purchaser.");

    return messagesValidationsList;
  }

  private handleError(error: HttpErrorResponse) {
    let errorMsgs: string[] = [];
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred.
      errorMsgs.push(`An error occurred: ${error.error.message}`);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain the validation errors.
      if (error.status === 400 && error.error instanceof Array) {
        // Extract the error messages from the error objects
        errorMsgs = error.error.map((err) => err.errorMessage);
      } else {
        errorMsgs.push(`Server returned code: ${error.status}`);
      }
    }
    // Return an observable with the error messages.
    return throwError(() => new Error(errorMsgs.join(",")));
  }
}
