import { Component } from "@angular/core";
import { AdministrationLoadingDataService } from "../services/administration/administration-loading-data-service";
import { AdministrationTabs } from "../interfaces/administration/administrationTabs";
import { UserRequestsDataService } from "../services/administration/user-requests.service";
import { CommodityRequestsDataService } from "../services/administration/commodity-requests.service";
import { IAdminHomeRequestsCount } from "../interfaces/administration/adminHomeRequestsCount";

@Component({
  selector: "administration-component",
  templateUrl: "./administration.component.html",
})
export class AdministrationComponent {
  title = "Administration";
  links: any[] = [];
  AdministrationTabs = AdministrationTabs;
  activeTab: AdministrationTabs = AdministrationTabs.UserRequests;

  adminHomeRequestsCount: IAdminHomeRequestsCount = {
    commodityApprovedRequestsCount: 0,
    commodityPendingCount: 0,
    commodityRejectedCount: 0,
    userRequestsCount: 0
  };

  constructor(
    private userRequestsDataService: UserRequestsDataService,
    private commodityRequestsDataService: CommodityRequestsDataService,
    private administrationLoadingDataService: AdministrationLoadingDataService
  ) { }

  ngOnInit(): void {
    this.administrationLoadingDataService.activeTabData$.subscribe((tab) => {
      this.activeTab = tab;
      this.loadActiveTab(tab);
    });
    this.loadTabNumbers();
  }

  ngAfterViewInit(): void {
    this.loadActiveTab(this.activeTab);
  }

  setActiveTab(tabName: AdministrationTabs): void {
    this.administrationLoadingDataService.setActiveTab(tabName);
  }

  loadActiveTab(tabName: AdministrationTabs): void {
    if (tabName == AdministrationTabs.UserRequests)
      this.administrationLoadingDataService.loadUserRequestsData();
    else if (tabName == AdministrationTabs.PendingCR)
      this.administrationLoadingDataService.loadPendingCommodityRequestsData();
    else if (tabName == AdministrationTabs.ApprovedCR)
      this.administrationLoadingDataService.loadApprovedCommodityRequestsData();
    else if (tabName == AdministrationTabs.RejectedCR)
      this.administrationLoadingDataService.loadRejectedCommodityRequestsData();
  }

  loadTabNumbers(): void {
    this.commodityRequestsDataService.getCountOfCommodityRequests().subscribe({
      next: (count) => {
        this.adminHomeRequestsCount.commodityApprovedRequestsCount = count.commodityApprovedRequestsCount;
        this.adminHomeRequestsCount.commodityPendingCount = count.commodityPendingCount;
        this.adminHomeRequestsCount.commodityRejectedCount = count.commodityRejectedCount;
      },
      error: (error) => {
        console.error('Error fetching count of commodity requests', error);
      }
    }
    );

    this.userRequestsDataService.getCountOfPendingUserRequests().subscribe({
      next: (countUserRequest) => {
        this.adminHomeRequestsCount.userRequestsCount = countUserRequest;
      },
      error: (error) => {
        console.error('Error fetching count of user requests', error);
      }
    });
  }
}
