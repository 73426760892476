import { Component } from "@angular/core";
import { FormArray, FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MatTableDataSource } from "@angular/material/table";
import { ILoggedUserData } from "../../../interfaces/common/loggedUserData";
import { PageViewMode } from "../../../interfaces/common/pageViewMode";
import { IAuditObservationCommodity } from "../../../interfaces/qa-management/audit-observation/auditObservationEvaluationDetails";
import { RolePrivilegesDataService } from "../../../services/administration/role-privileges.service";
import { IRolePrivilegesData } from "../../../interfaces/administration/rolePrivilegesData";
import { LoggedUserService } from "../../../services/common/logged-user.service";
import { PrivilegesAccessType } from "../../../interfaces/common/privilegesAccessTypeData";
import { Sort } from "@angular/material/sort";
import { TableSortService } from "../../../services/table-sort.service";

export enum Roles {
  IsSystemAdministrator = "System Administrator",
  IsProjectTeamMember = "Project Team Member",
  IsQualityAssuranceAdministrator = "Quality Assurance Administrator",
  IsQualityAssuranceAndControlUser = "Quality Assurance And Control User",
}

@Component({
  selector: "roles-and-privileges",
  templateUrl: "./rap.component.html",
  styleUrls: ["./rap.component.css"],
})
export class RoleAndPrivilegesComponent {
  title = "";
  detailsForm: FormGroup;
  rolePrivilagesLoading = false;
  updatingRolePrivilagesSuccess = false;

  PageViewMode = PageViewMode;
  addingDetailsMode: PageViewMode;
  previousMode: PageViewMode;

  PrivilegesAccessType = PrivilegesAccessType;

  userLoggedData: ILoggedUserData | null = null;
  rolesDropdownData = [];

  rolePrivilegesData = [];

  tableDataSource = new MatTableDataSource<IAuditObservationCommodity>();
  displayedColumns: string[] = ['pageCategory', 'pageSubCategory', 'accessType'];

  accessTypes = [
    { id: PrivilegesAccessType.R, name: 'Read Only' },
    { id: PrivilegesAccessType.RW, name: 'Read & Write' },
    { id: PrivilegesAccessType.NoAccess, name: 'No Access' },
  ];
  validationMessages = [];

  dataSource: MatTableDataSource<IRolePrivilegesData>;
  originalDataSource: MatTableDataSource<IRolePrivilegesData>;

  constructor(private fb: FormBuilder,
    private rolePrivilegesDataService: RolePrivilegesDataService,
    private loggedUserService: LoggedUserService,
    private tableSortService: TableSortService,
  ) {
    this.detailsForm = this.fb.group({
      roles: ['', Validators.required],
      rolePrivileges: this.fb.array([]), // Add FormArray for role privileges  
    });
    this.rolesDropdownData = this.enumToArray(Roles);
  }

  ngOnInit(): void {
    this.getActualLoggedInUser();
  }

  getActualLoggedInUser(): void {
    this.loggedUserService
      .getLoggedUserData()
      .subscribe((loggedUser: ILoggedUserData | null) => {
        if (loggedUser) {
          this.userLoggedData = loggedUser;
        } else
          console.error("Cannot fetch logged user data.");
      });
  }

  enumToArray(enumObj: any): { id: string, name: string }[] {
    return Object.keys(enumObj).map(key => ({ id: key, name: enumObj[key] }));
  }

  onRoleChange(event: Event): void {
    const selectedRoleId = (event.target as HTMLSelectElement).value;
    if (selectedRoleId != '') {
      this.getRolePrivileges(selectedRoleId);
      this.validationMessages = [];
    } else {
      this.rolePrivilegesData = [];
    }
  }

  getRolePrivileges(dbRoleName: string): void {
    this.rolePrivilagesLoading = true;
    this.rolePrivilegesDataService
      .getRolePrivileges(dbRoleName)
      .subscribe(
        (response) => {
          this.originalDataSource = new MatTableDataSource<IRolePrivilegesData>(response);
          this.dataSource = new MatTableDataSource<IRolePrivilegesData>(
            this.originalDataSource.data
          );
          this.populateRolePrivilegesFormArray(this.dataSource.data);
          this.rolePrivilagesLoading = false;
        },
        (error) => {
          this.rolePrivilagesLoading = false;
          this.rolePrivilegesData = [];
          console.error(error);
        }
      );
  }

  populateRolePrivilegesFormArray(rolePrivileges: IRolePrivilegesData[]): void {
    const controlArray = this.detailsForm.get('rolePrivileges') as FormArray;
    controlArray.clear();

    rolePrivileges.forEach((privilege) => {
      controlArray.push(
        this.fb.group({
          accessType: [privilege.accessType, Validators.required],
          rpid: [privilege.rpid],
          userRole: [privilege.userRole]
        })
      );
    });

    this.rolePrivilegesData = rolePrivileges;
  }

  get rolePrivilegesFormArray(): FormArray {
    return this.detailsForm.get('rolePrivileges') as FormArray;
  }

  onSubmit(): void {
    if (this.detailsForm.valid) {
      const formData = this.detailsForm.value;

      const rolePrivilegesData: IRolePrivilegesData[] = formData.rolePrivileges.map((privilege: any) => ({
        accessType: privilege.accessType,
        rpid: privilege.rpid,
        userRole: privilege.userRole,
        lastUpdatedBy: this.userLoggedData.cai,
      }));

      this.rolePrivilegesDataService.updateRolePrivileges(rolePrivilegesData).subscribe(
        (response) => {
          this.updatingRolePrivilagesSuccess = true;
        },
        (error) => {
          this.validationMessages.push(`There was a problem with update privileges (${error.message}).`);
        }
      );
    } else {
      this.validationMessages.push(`Form is invalid, choose role and accesses.`);
    }
  }

  sortRows(event: Sort) {
    this.dataSource.data = this.tableSortService.sortDataSource(
      this.originalDataSource.data,
      event
    );
  }
}
