import { Component, OnInit } from "@angular/core"; // Assuming the interface is in this file
import { IVendorData } from "../../interfaces/administration/vendorData";
import { environment } from "projects/aqua3/src/environments/environment";
import { ActivatedRoute, Router } from "@angular/router";
import { VendorDataService } from "../../services/vendor-data.service";
import { Subscription } from "rxjs";
import { BaseComponent } from "../../shared/base/base.component";
import { UserPermissionsService } from "../../services/common/user-permissions.service";
const apiUrl = environment.apiUrl;

@Component({
  selector: "app-vendor-preview",
  templateUrl: "./preview-vendor.component.html",
})
export class PreviewVendorComponent extends BaseComponent implements OnInit {
  vendorData: IVendorData;
  private subscription: Subscription;
  private exists: boolean;
  private vendorId: string;
  public isLoading = true;

  constructor(
    private vendorDataService: VendorDataService,
    private router: Router,
    private route: ActivatedRoute,
    userPermissionService: UserPermissionsService
  ) {
    super(userPermissionService);
    this.exists = false;
  }

  ngOnInit() {
    super.ngOnInit();
    this.vendorId = this.route.snapshot.paramMap.get("id");
    if (this.vendorId) {
      this.vendorDataService.getVendorById(this.vendorId).subscribe((data) => {
        if (data) {
          this.exists = true;
          this.vendorData = data;
        }
        this.isLoading = false;
      });
    } else {
      this.vendorDataService.currentVendorData.subscribe((data) => {
        this.vendorData = data;
        this.isLoading = false;
      });
    }
  }

  editNewVendor() {
    this.vendorDataService.changeVendorData(this.vendorData);
    this.router.navigate(["administration/vendors/create"]);
  }

  backToList() {
    this.vendorDataService.changeVendorData(null);
    this.router.navigate(["administration/vendors"]);
  }

  editVendor(id: string) {
    this.router.navigate(["administration/vendors/edit/", id]);
  }

  activateDeactivateVendor() {
    this.vendorDataService.activateDeactivateVendor(this.vendorId).subscribe({
      next: (data) => {
        this.vendorData = data;
      },
      error: (error) => {
        console.error(error);
      },
    });
  }

  saveNewVendor() {
    this.vendorDataService.saveVendor(this.vendorData).subscribe({
      next: (response) => {
        // Redirect to the vendors page
        this.router.navigate(["administration/vendors"]);
      },
      error: (error) => {
        console.error(error);
      },
    });
  }

  updateVendor() {
    this.vendorDataService
      .updateVendor(this.vendorId, this.vendorData)
      .subscribe({
        next: (response) => {
          // Redirect to the vendors page
          this.router.navigate(["administration/vendors"]);
        },
        error: (error) => {
          console.error(error);
        },
      });
  }
}
