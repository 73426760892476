import { OnInit } from '@angular/core';
import { UserPermissionsService } from '../../services/common/user-permissions.service';
import { Component } from '@angular/core';
import { PrivilegesAccessType } from '../../interfaces/common/privilegesAccessTypeData';

@Component({
    selector: 'app-base',
    template: ''
})
export abstract class BaseComponent implements OnInit {
    userPermissions: string;
    isEditingEnabled: boolean;
    constructor(private userPermissionsService: UserPermissionsService) { }

    ngOnInit(): void {
        this.userPermissionsService.permissions$.subscribe(permissions => {
            this.userPermissions = permissions;
            this.canUserEdit();
        });
    }

    private canUserEdit(): boolean {
        this.isEditingEnabled = this.userPermissions == PrivilegesAccessType.RW;
        return this.isEditingEnabled;
    }
}