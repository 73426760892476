import { Component, OnInit } from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { CapitalProjectsDataService } from "../../services/capital-projects.service";
import { ActivatedRoute, Router } from "@angular/router";
import { IExtendedCapitalProjectData } from "../../approved-vendor-list/preview-project.component";
import { ICapitalProjectData } from "../../interfaces/administration/capitalProjectData";
import { IVendorData } from "../../interfaces/administration/vendorData";
import { VendorDataService } from "../../services/vendor-data.service";
import { VendorCommodityDataService } from "../../services/vendor-information/vendor-commodity.service";
import { forkJoin, switchMap, tap } from "rxjs";
import { VendorCommodityData } from "../../interfaces/vendor-information/vendorCommodityData";
import { VendorCommoditySearchData } from "../../interfaces/vendor-information/viewVendorCommoditySearchData";
import { PerformanceTrackingService } from "../../services/vendor-information/performance-tracking.service";
import { IPerformanceTrackingData } from "../../interfaces/vendor-information/performanceTrackingData";
import { BaseComponent } from "../../shared/base/base.component";
import { UserPermissionsService } from "../../services/common/user-permissions.service";

@Component({
  selector: "app-edit-performance-tracking",
  templateUrl: "./edit-performance-tracking.component.html",
  styles: [
    `
      .button-container {
        display: flex !important;
        justify-content: space-between !important;
      }

      .unchecked {
        color: red;
      }

      .mt-1 {
        margin-top: 1rem !important;
      }
    `,
  ],
})
export class EditPerformanceTrackingComponent
  extends BaseComponent
  implements OnInit
{
  public performanceForm: FormGroup;
  public sptId: number = 0;
  public project: ICapitalProjectData;
  public vendors: IVendorData[] = [];
  public commodities: any[] = [];
  public selectedVendor: IVendorData;
  public isLoading: boolean = false;
  public validationMessages = [];
  public activeTab: string = "ratingexplanations"; // Default to the first tab

  constructor(
    private fb: FormBuilder,
    private capitalProjectService: CapitalProjectsDataService,
    private vendorService: VendorDataService,
    private performanceService: PerformanceTrackingService,
    private route: ActivatedRoute,
    private router: Router,
    private commodityService: VendorCommodityDataService,
    userPermissionService: UserPermissionsService
  ) {
    super(userPermissionService);
    this.performanceForm = this.fb.group({
      performanceTrackingId: [""],
      project: [""],
      poNumber: ["", Validators.required],
      poValue: [""],
      poRequiredDate: ["", Validators.required],
      vendor: ["", Validators.required],
      commodity: ["", Validators.required],
      description: [""],
      critcality: [""],
    });
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.loadSpt();
  }

  loadSpt() {
    this.route.paramMap.subscribe((params) => {
      this.isLoading = true;
      this.sptId = Number(params.get("sptId"));

      this.vendorService.getVendors().subscribe((data) => {
        if (data) {
          this.vendors = data;
          this.performanceService
            .getPerformanceTrackingDataById(this.sptId)
            .subscribe((spt: IPerformanceTrackingData) => {
              if (spt) {
                this.selectedVendor = this.vendors.find(
                  (vendor) => vendor.vendorId === spt.vendorId
                );
                this.loadProject(spt.projectId, spt.vendorId);
                const parsedDate = new Date(spt.porequiredDate);
                this.performanceForm.patchValue({
                  performanceTrackingId: spt.performanceTrackingId,
                  project: spt.projectId,
                  poNumber: spt.ponumber,
                  poValue: spt.povalue,
                  poRequiredDate: parsedDate.toISOString().split("T")[0],
                  vendor: spt.vendorId,
                  commodity: spt.commodityId,
                  description: spt.commodityDescriptionFromUser,
                  critcality: spt.critcality,
                });

                this.performanceForm.get("commodity").setValue(spt.commodityId);
              }
            });
        }
      });
    });
  }

  loadProject(projectId, vendroId) {
    this.capitalProjectService
      .getCapitalProjectById(projectId)
      .subscribe((data) => {
        if (data) {
          this.project = data;
          this.loadCommoditiesByVendorId(vendroId.toString());
          this.isLoading = false;
        }
      });
  }

  onVendorChange(event: Event) {
    const vendorId = (event.target as HTMLSelectElement).value;
    this.loadCommoditiesByVendorId(vendorId);
  }

  public loadCommoditiesByVendorId(vendorId: string) {
    this.selectedVendor = this.vendors.find(
      (vendor) => vendor.vendorId === Number(vendorId)
    );
    if (this.selectedVendor) {
      this.loadCommodities();
    } else {
      this.commodities = [];
    }
  }

  loadCommodities(): void {
    if (this.selectedVendor) {
      this.commodityService
        .getCommoditiesForVendor(this.selectedVendor.vendorId)
        .subscribe((data: VendorCommoditySearchData[]) => {
          this.commodities = data;
        });
    } else {
      this.commodities = [];
    }
  }

  public onSubmit() {
    this.validationMessages = this.performanceService.validatePerformanceForm(
      this.performanceForm
    );

    if (this.performanceForm.valid && this.validationMessages.length === 0) {
      const performanceTracking: IPerformanceTrackingData = {
        projectId: this.performanceForm.get("project").value,
        ponumber: this.performanceForm.get("poNumber").value,
        povalue: this.performanceForm.get("poValue").value,
        porequiredDate: this.performanceForm.get("poRequiredDate").value,
        vendorId: this.performanceForm.get("vendor").value,
        commodityId: this.performanceForm.get("commodity").value,
        commodityDescriptionFromUser:
          this.performanceForm.get("description").value,
        critcality: this.performanceForm.get("critcality").value,
        createdDate: new Date(),
        isApproved: false,
        performanceTrackingId: Number(this.sptId),
      };

      this.performanceService
        .updatePerformanceTracking(performanceTracking)
        .subscribe((response) => {
          if (response) {
            let id = response.performanceTrackingId;
            this.router.navigate([
              "/vendor-information/performance-tracking/preview-spt/",
              id,
            ]);
          }
        });
    }
  }

  onCancel() {
    this.performanceForm.reset();
    this.router.navigate([
      "/vendor-information/performance-tracking/preview-spt/",
      this.sptId,
    ]);
  }

  setActiveTab(tabName: string = "ratingexplanations"): void {
    this.activeTab = tabName;

    if (tabName === "ratingexplanations") this.activeTab = "ratingexplanations";
    else if (tabName === "engineering")
      this.performanceService.loadRatingDataTab(this.sptId, 1);
    else if (tabName === "procurement")
      this.performanceService.loadRatingDataTab(this.sptId, 6);
    else if (tabName === "construction")
      this.performanceService.loadRatingDataTab(this.sptId, 0);
    else if (tabName === "manufacturing")
      this.performanceService.loadRatingDataTab(this.sptId, 2);
  }
}
