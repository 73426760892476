import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  Input,
  AfterViewInit,
  ChangeDetectorRef,
} from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { VendorCommodityDataService } from "../../../services/vendor-information/vendor-commodity.service";
import { IVendorData } from "../../../interfaces/administration/vendorData";
import { VendorDataService } from "../../../services/vendor-data.service";
import { CommodityCodesService } from "../../../services/administration/commodity-codes.service";
import { ICommodity } from "../../../interfaces/administration/commodity";
import { VendorCommodityRequest } from "../../../interfaces/vendor-information/vendorCommodityRequest";

@Component({
  selector: "app-create-commodity",
  templateUrl: "./create-commodity.component.html",
  styles: [
    `
      .button-container {
        display: flex !important;
        justify-content: space-between !important;
      }

      .button-group {
        display: flex;
        gap: 8px; /* Adjust the gap as needed */
      }
    `,
  ],
})
export class CreateCommodityComponent implements OnInit, AfterViewInit {
  @Output() formCancelled = new EventEmitter<void>();
  @Input() vendorId: number;
  commodityForm: FormGroup;
  validationMessages: string[] = [];
  vendor: IVendorData;
  selectedCommodityIds: number[] = [];
  suppliers: IVendorData[] = [];
  manufacturers: IVendorData[] = [];
  selectedCommodityObjects: ICommodity[] = [];
  public isLoading: boolean = false;
  isSupplier: boolean = false;
  isManufacturer: boolean = false;

  constructor(
    private fb: FormBuilder,
    private commodityCodesService: CommodityCodesService,
    private vendorCommodityService: VendorCommodityDataService,
    private vendorDataService: VendorDataService,
    private cdr: ChangeDetectorRef
  ) {
    this.commodityForm = this.fb.group({
      commodityCodes: [""],
      commodityBrand: [""],
      supplier: [this.vendorId],
      supplierRating: [""],
      supplierRatingComment: [""],
      imaNumber: [""],
      supplierSD: [false],
      manufacturer: [this.vendorId],
      manufacturerRating: [""],
      manufacturerRatingComment: [""],
      manufacturerIMANumber: [""],
      manufacturerSD: [false],
    });
  }

  ngOnInit(): void {
    this.loadVendor();
  }

  ngAfterViewInit() {}

  handleSelectedCommodityNames(selectedCommodityNames: ICommodity[]) {
    this.selectedCommodityObjects = selectedCommodityNames;
  }

  loadVendor() {
    if (this.vendorId) {
      this.isLoading = true; // Set isLoading to true at the start
      this.vendorDataService
        .getVendorById(this.vendorId.toString())
        .subscribe((data) => {
          setTimeout(() => {
            this.vendor = data;
            this.loadManufacturersAndSuppliers();
            this.isLoading = false;
          });
        });
    }
  }

  loadManufacturersAndSuppliers() {
    this.vendorDataService.currentVendorsList$.subscribe((data) => {
      if (data && data.length > 0) {
        this.setupLists(data);
      } else {
        this.vendorDataService.getVendors().subscribe((results) => {
          if (results) {
            this.setupLists(data);
          }
        });
      }
    });
  }

  setupLists(data: IVendorData[]) {
    if (data) {
      this.suppliers = data
        .filter((vendor) => vendor.isSupplier === true)
        .sort((a, b) => a.vendorName.localeCompare(b.vendorName));
      this.manufacturers = data
        .filter((vendor) => vendor.isManufacturer === true)
        .sort((a, b) => a.vendorName.localeCompare(b.vendorName));
    }

    this.commodityForm.controls["supplier"].setValue(this.vendorId);
    this.commodityForm.controls["manufacturer"].setValue(this.vendorId);
  }

  validateForm(
    supplierId: number,
    manufacturerId: number,
    requestCommodityIds: number[]
  ) {
    if (
      !this.selectedCommodityObjects ||
      this.selectedCommodityObjects.length == 0
    ) {
      this.validationMessages.push("Please select a commodity to request.");
    } else {
      requestCommodityIds.push(
        ...this.selectedCommodityObjects.map(
          (commodity) => commodity.commodityId
        )
      );
    }

    if (supplierId < 1 && manufacturerId < 1) {
      this.validationMessages.push("Supplier or Manufacturer are required.");
    }
  }

  onSubmit() {
    this.validationMessages = [];
    let requestCommodityIds: number[] = [];

    let supplierId = this.commodityForm.controls["supplier"].value;
    let manufacturerId = this.commodityForm.controls["manufacturer"].value;

    this.validateForm(supplierId, manufacturerId, requestCommodityIds);

    if (this.validationMessages.length > 0) {
      return;
    }

    for (let i = 0; i < requestCommodityIds.length; i++) {
      let commodityRequest = this.createCommodityRequest(
        supplierId,
        manufacturerId
      );
      this.addCommodity(requestCommodityIds[i], commodityRequest);
    }
  }

  addCommodity(commodityId: number, commodityRequest: VendorCommodityRequest) {
    this.vendorCommodityService
      .insertVendorCommodity(this.vendorId, commodityId, commodityRequest)
      .subscribe(() => {
        this.cancelForm();
      });
  }

  createCommodityRequest(
    supplierId: number,
    manufacturerId: number
  ): VendorCommodityRequest {
    return {
      SupplierId: supplierId,
      ManufacturerId: manufacturerId,
      SupplierRating: this.commodityForm.controls["supplierRating"].value || 0,
      SupplierRatingComment:
        this.commodityForm.controls["supplierRatingComment"].value,
      SupplierIMANumber: this.commodityForm.controls["imaNumber"].value,
      BrandName: this.commodityForm.controls["commodityBrand"].value,
      SupplierSD: this.commodityForm.controls["supplierSD"].value,
      ManufacturerRating:
        this.commodityForm.controls["manufacturerRating"].value || 0,
      ManufacturerRatingComment:
        this.commodityForm.controls["manufacturerRatingComment"].value,
      ManufacturerIMANumber:
        this.commodityForm.controls["manufacturerIMANumber"].value,
      ManufacturerSD: this.commodityForm.controls["manufacturerSD"].value,
      IsApproved: true,
      IsActive: true,
      IsEndorsedBySME: false,
      CommodityIdList: [],
      RequestorId: 0,
      RequestorDate: undefined,
      VendorCommodityId: 0,
      ApprovedUserID: 0,
      RejectedUserID: 0,
      ApprovedDate: undefined,
      RejectedDate: undefined,
      IsManufacturer: manufacturerId > 0,
      IsSupplier: supplierId > 0,
      DeactivationComments: "",
      ProjectName: "",
      CommodityCode: "",
      CommodityDescription: "",
    };
  }

  cancelForm() {
    this.formCancelled.emit();
  }
}
