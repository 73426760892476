import { Component, AfterViewInit, OnInit, OnDestroy } from "@angular/core";
import { Chart, ChartType, registerables } from "chart.js";
import { PerformanceTrackingService } from "../../../services/vendor-information/performance-tracking.service";
import { ViewPerformanceRatingData } from "../../../interfaces/vendor-information/viewPerformanceRatingData";

@Component({
  selector: "stats-tab-component",
  templateUrl: "./stats-tab.component.html",
})
export class StatsTabComponent implements AfterViewInit, OnInit, OnDestroy {
  public barChartLabels: string[] = [
    "Engineering",
    "Procurement",
    "Quality (Mfg.)",
    "Schedule (Mfg.)",
    "Cooperation (Mfg.)",
    "Construction",
  ];
  public barChartType: ChartType = "line";
  public barChartLegend = true;

  public barChartData: any[] = [
    {
      data: [],
      label: "Lowest Rating",
      borderColor: "red",
      fill: false,
      pointRadius: 5,
      pointBackgroundColor: "red",
    },
    {
      data: [],
      label: "Average Rating",
      borderColor: "blue",
      fill: false,
      pointRadius: 5,
      pointBackgroundColor: "blue",
    },
    {
      data: [],
      label: "Highest Rating",
      borderColor: "green",
      fill: false,
      pointRadius: 5,
      pointBackgroundColor: "green",
    },
  ];

  public dataSourceLoading: boolean = false;
  private chart: Chart | undefined;

  public categories = [
    { name: "Engineering", range: [1, 5] },
    { name: "Procurement", range: [6, 10] },
    { name: "Quality (Mfg.)", range: [11, 16] },
    { name: "Schedule (Mfg.)", range: [17, 22] },
    { name: "Cooperation (Mfg.)", range: [23, 28] },
    { name: "Construction", range: [29, 33] },
  ];

  public lowestRatings: number[] = [];
  public averageRatings: number[] = [];
  public highestRatings: number[] = [];

  constructor(private performanceService: PerformanceTrackingService) {
    // Register Chart.js components
    Chart.register(...registerables);
  }

  ngOnInit(): void {
    this.dataSourceLoading = true;
    if (this.chart) {
      this.chart.destroy();
    }
    this.performanceService.loadStatsData$.subscribe(
      (data: ViewPerformanceRatingData[]) => {
        if (data) {
          this.processData(data);
        }
      }
    );
  }

  ngOnDestroy(): void {
    if (this.chart) {
      this.chart.destroy();
    }
  }

  processData(data: ViewPerformanceRatingData[]): void {
    this.lowestRatings = [];
    this.averageRatings = [];
    this.highestRatings = [];

    this.categories.forEach((category) => {
      const ratings = data
        .filter(
          (item) =>
            item.keyPerformanceIndicatorId >= category.range[0] &&
            item.keyPerformanceIndicatorId < category.range[1]
        )
        .map((item) => item.rating);

      if (ratings.length > 0) {
        this.lowestRatings.push(Math.min(...ratings));
        this.highestRatings.push(Math.max(...ratings));
        this.averageRatings.push(
          ratings.reduce((sum, rating) => sum + rating, 0) / ratings.length
        );
      } else {
        this.lowestRatings.push(0);
        this.highestRatings.push(0);
        this.averageRatings.push(0);
      }
    });

    this.barChartData[0].data = this.lowestRatings;
    this.barChartData[1].data = this.averageRatings;
    this.barChartData[2].data = this.highestRatings;

    this.updateChart();
    this.dataSourceLoading = false;
  }

  updateChart(): void {
    if (this.chart) {
      this.chart.destroy();
    }
    const canvas = document.getElementById(
      "myChart"
    ) as HTMLCanvasElement | null;
    if (canvas) {
      const ctx = canvas.getContext("2d");
      if (ctx) {
        this.chart = new Chart(ctx, {
          type: this.barChartType,
          data: {
            labels: this.barChartLabels,
            datasets: this.barChartData,
          },
          options: {
            responsive: true,
            scales: {
              y: {
                min: 0,
                max: 6,
                ticks: {
                  stepSize: 1,
                },
              },
            },
          },
        });
      } else {
        console.error("Failed to get 2D context from canvas element.");
      }
    } else {
      console.error("Canvas element with ID 'myChart' not found.");
    }
  }

  ngAfterViewInit(): void {
    this.updateChart();
  }
}
