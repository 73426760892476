import { Component, OnInit } from '@angular/core';
import { DatePipe } from '@angular/common';
import { ManufacturersNameService } from '../../../services/qa-reports/manufacturers-name.service';
import { HttpParams } from '@angular/common/http';
import { InspectorEvaluationService } from '../../../services/qa-management/inspector-evaluation.service';
import { PageViewMode } from '../../../interfaces/common/pageViewMode';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Route, Router } from '@angular/router';

@Component({
  selector: 'app-qabypo',
  templateUrl: './qabypo.component.html',
  styleUrls: ['./qabypo.component.css'],
  providers: [DatePipe]
})
export class QabypoComponent implements OnInit {
  startDate: string; 
  endDate: string;
  validationMessages:string[]=[];
  detailsForm: FormGroup;

  constructor(private datePipe: DatePipe,
    private manufacturersNameService: ManufacturersNameService,
    private inspectorEvaluationService:InspectorEvaluationService,
    private router:Router,
    private fb: FormBuilder) {}

    ngOnInit(): void {
      const currentYear = new Date().getFullYear();
      this.startDate = this.datePipe.transform(new Date(currentYear, 0, 1), 'yyyy-MM-dd') || '';
      this.endDate = this.datePipe.transform(new Date(), 'yyyy-MM-dd') || '';
    
      // Initialize the form
      this.detailsForm = this.fb.group({
        startDate: [this.startDate],
        endDate: [this.endDate]
      });
    }
    
  resetStartDate() {
    this.startDate = '';
  }

  resetEndDate() {
    this.endDate ='';
  }

  cancel() {
    this.router.navigate(['/reports-forms'], { queryParams: { tab: 'qaReports' } });
  }
  
    
  generateReport() {
    this.validationMessages = [];

    if (!this.startDate) {
      this.validationMessages.push('Start date is required.');
    }
    if (!this.endDate) {
      this.validationMessages.push('End date is required.');
    }
    if (new Date(this.startDate) > new Date(this.endDate)) {
      this.validationMessages.push('Start date cannot be after end date.');
    }

    if (this.validationMessages.length > 0) {
        return;
    }
    const formattedStartDate = this.datePipe.transform(this.startDate, 'yyyy-MM-dd');
    const formattedEndDate = this.datePipe.transform(this.endDate, 'yyyy-MM-dd');
  
    const params = new HttpParams()
      .set('StartDate', formattedStartDate || '')
      .set('EndDate', formattedEndDate || '');

    this.manufacturersNameService.CreateQAActivityByPOReport(params).subscribe(response => {
      const blob = new Blob([response], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = `AQUA3_QAActivityByPO_${new Date().toISOString().slice(0, 19).replace(/:/g, '-')}.xlsx`;
      a.click();
      window.URL.revokeObjectURL(url);
    }, error => {
      console.error('Error downloading the report', error);
    });
  }

}
