import { NgModule } from "@angular/core";
import { AdministrationComponent } from "./administration.component";
import { VendorsComponent } from "./vendors/vendors.component";
import { CreateVendorComponent } from "./vendors/create-vendor.component";
import { EditVendorComponent } from "./vendors/edit-vendor.component";
import { PreviewVendorComponent } from "./vendors/preview-vendor.component";
import { AlertsComponent } from "./alerts/alerts.component";
import { UsersAndRolesComponent } from "./users-roles/users-roles.component";
import { CountriesAndStatesComponent } from "./countries-states/countries-states.component";
import { UserRequestComponent } from "./administration-home-subcomponents/user-requests/user-requests-component";
import { RejectedCommodityRequestsComponent } from "./administration-home-subcomponents/commodity-requests/rejected-commodity-requests/rejected-commodity-requests-component";
import { PendingCommodityRequestsComponent } from "./administration-home-subcomponents/commodity-requests/pending-commodity-requests/pending-commodity-requests-component";
import { ApprovedCommodityRequestsComponent } from "./administration-home-subcomponents/commodity-requests/approved-commodity-requests/approved-commodity-requests-component";
import { SettingsComponent } from "./administration-home-subcomponents/settings/settings-component";
import { BUsAndOpcosComponent } from "./bus-opcos/bus-opcos.component";
import { EditOpcoDataFormComponent } from "./bus-opcos/forms/edit-opco-data.form.component";
import { EditBUDataFormComponent } from "./bus-opcos/forms/edit-bu-data-form.component";
import { AddOpcoDataFormComponent } from "./bus-opcos/forms/add-opco-data-form.component";
import { AddBUDataFormComponent } from "./bus-opcos/forms/add-bu-data-form.component";
import { PurchaserManagementComponent } from "./purchaser-management/purchaser-management.component";
import { CreatePurchaserManagementComponent } from "./purchaser-management/create-purchaser-management.component";
import { EditPurchaserManagementComponent } from "./purchaser-management/edit-purchaser-management.component";
import { MenuModule } from "../menu/menu.module";
import { CreateCountryComponent } from "./countries-states/create-country/create-country.component";
import { EditCountryComponent } from "./countries-states/edit-country/edit-country.component";
import { CreateStateComponent } from "./countries-states/create-state/create-state.component";
import { EditStateComponent } from "./countries-states/edit-state/edit-state.component";
import { EditUserRequestsFormComponent } from "./administration-home-subcomponents/user-requests/edit-user-request-form.component";
import { CommodityCodesComponent } from "./commodity-codes/commodity-codes.component";
import { EditCommodityComponent } from "./commodity-codes/edit-commodity/edit-commodity.component";
import { AddCommodityComponent } from "./commodity-codes/add-commodity/add-commodity.component";
import { PerformActionCommodityRequestFormComponent } from "./administration-home-subcomponents/commodity-requests/edit-commodity-request/perform-action-commodity-request-form.component";
import { ProjectAndAvlComponent } from "./project-and-avl/project-and-avl.component";
import { EditUserComponent } from "./users-roles/edit-user/edit-user.component";
import { MSAComponent } from "./msa/msa.component";
import { AddAlertComponent } from "./alerts/add-alert.component";
import { UpdateAlertComponent } from "./alerts/update-alert.component";
import { RoleAndPrivilegesComponent } from "./users-roles/roles-and-privileges/rap.component";
import { HistoryTabComponent } from "./vendors/history/history-tab.component";
import { CommoditiesTabComponent } from "./vendors/commodities/commodities-tab.component";
import { CommentBoxDialogComponent } from "./vendors/comment-box-dialog.component";
import { CreateCommodityComponent } from "./vendors/commodities/create-commodity.component";
import { VendorEditCommodityComponent } from "./vendors/commodities/vendor-edit-commodity.component";

const componentList = [
  AdministrationComponent,
  VendorsComponent,
  CreateVendorComponent,
  EditVendorComponent,
  PreviewVendorComponent,
  HistoryTabComponent,
  CommoditiesTabComponent,
  CommentBoxDialogComponent,
  CreateCommodityComponent,
  VendorEditCommodityComponent,
  AlertsComponent,
  UsersAndRolesComponent,
  RoleAndPrivilegesComponent,
  EditUserComponent,
  CountriesAndStatesComponent,
  CreateCountryComponent,
  EditCountryComponent,
  CreateStateComponent,
  EditStateComponent,
  UserRequestComponent,
  RejectedCommodityRequestsComponent,
  PendingCommodityRequestsComponent,
  ApprovedCommodityRequestsComponent,
  SettingsComponent,
  BUsAndOpcosComponent,
  EditOpcoDataFormComponent,
  EditBUDataFormComponent,
  AddOpcoDataFormComponent,
  AddBUDataFormComponent,
  PurchaserManagementComponent,
  CreatePurchaserManagementComponent,
  EditPurchaserManagementComponent,
  EditUserRequestsFormComponent,
  ProjectAndAvlComponent,
  CommodityCodesComponent,
  EditCommodityComponent,
  AddCommodityComponent,
  PerformActionCommodityRequestFormComponent,
  MSAComponent,
  AddAlertComponent,
  UpdateAlertComponent,
];

@NgModule({
  imports: [MenuModule],
  declarations: [componentList],
})
export class AdministrationModule {}
