// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
      .compact-form .form-group {
        margin-bottom: 5px;
      }

      .compact-input {
        padding: 5px;
        margin: 0;
        width: 100%;
        box-sizing: border-box;
      }

      .compact-table th,
      .compact-table td {
        padding: 5px;
        text-align: left;
      }

      .compact-table th {
        background-color: #f2f2f2;
      }

      button {
        padding: 5px 10px;
        margin: 2px;
      }

      .button-container {
        display: flex !important;
        justify-content: space-between !important;
      }
    `, "",{"version":3,"sources":["webpack://./projects/aqua3/src/app/administration/msa/msa.component.ts"],"names":[],"mappings":";MACM;QACE,kBAAkB;MACpB;;MAEA;QACE,YAAY;QACZ,SAAS;QACT,WAAW;QACX,sBAAsB;MACxB;;MAEA;;QAEE,YAAY;QACZ,gBAAgB;MAClB;;MAEA;QACE,yBAAyB;MAC3B;;MAEA;QACE,iBAAiB;QACjB,WAAW;MACb;;MAEA;QACE,wBAAwB;QACxB,yCAAyC;MAC3C","sourcesContent":["\n      .compact-form .form-group {\n        margin-bottom: 5px;\n      }\n\n      .compact-input {\n        padding: 5px;\n        margin: 0;\n        width: 100%;\n        box-sizing: border-box;\n      }\n\n      .compact-table th,\n      .compact-table td {\n        padding: 5px;\n        text-align: left;\n      }\n\n      .compact-table th {\n        background-color: #f2f2f2;\n      }\n\n      button {\n        padding: 5px 10px;\n        margin: 2px;\n      }\n\n      .button-container {\n        display: flex !important;\n        justify-content: space-between !important;\n      }\n    "],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
