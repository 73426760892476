import { Component, Input, OnInit } from "@angular/core";
import { FormGroup, FormBuilder } from "@angular/forms";
import { ViewPerformanceRatingData } from "../../../interfaces/vendor-information/viewPerformanceRatingData";
import { PerformanceTrackingService } from "../../../services/vendor-information/performance-tracking.service";
import { BaseComponent } from "../../../shared/base/base.component";
import { UserPermissionsService } from "../../../services/common/user-permissions.service";
import { IPerformanceRatingData } from "../../../interfaces/vendor-information/performanceRatingData";

@Component({
  selector: "manufacturing-tab-component",
  templateUrl: "./manufacturing-tab.component.html",
})
export class ManufacturingTabComponent extends BaseComponent implements OnInit {
  public manufacturingForm: FormGroup;
  public manufacturingRating: ViewPerformanceRatingData[] = [];
  public qualityAverage: string;
  public scheduleAverage: string;
  public cooperationAverage: string;
  public performanceTrackigId: number;
  public dataSourceLoading: boolean = false;

  @Input() viewFromWorkRequestDetailsPage: boolean = false;

  constructor(
    private fb: FormBuilder,
    private performanceService: PerformanceTrackingService,
    userPermissionsService: UserPermissionsService
  ) {
    super(userPermissionsService);
  }

  ngOnInit(): void {
    super.ngOnInit();
    // Initialize the form with empty values
    this.manufacturingForm = this.fb.group({
      // Quality Group
      supplierQA: [""],
      supplierQAComments: [""],
      adherencePO: [""],
      adherencePOComments: [""],
      adherenceDrawings: [""],
      adherenceDrawingsComments: [""],
      workmanship: [""],
      workmanshipComments: [""],
      painting: [""],
      paintingComments: [""],
      acceptanceTesting: [""],
      acceptanceTestingComments: [""],

      // Schedule Group
      workRequestPlanning: [""],
      workRequestPlanningComments: [""],
      timelinessDrawings: [""],
      timelinessDrawingsComments: [""],
      expeditingMaterials: [""],
      expeditingMaterialsComments: [""],
      maintainingWRSchedule: [""],
      maintainingWRScheduleComments: [""],
      addLabor: [""],
      addLaborComments: [""],
      meetingDelivery: [""],
      meetingDeliveryComments: [""],

      // Cooperation Group
      accessDocuments: [""],
      accessDocumentsComments: [""],
      advisesProblems: [""],
      advisesProblemsComments: [""],
      providesSchedules: [""],
      providesSchedulesComments: [""],
      resolvesProblems: [""],
      resolvesProblemsComments: [""],
      enforcesHoldpoints: [""],
      enforcesHoldpointsComments: [""],
      other: [""],
      otherComments: [""],
    });

    // Load the data and then update the form values
    this.performanceService.performanceTrackingId$.subscribe((data) => {
      if (data) {
        this.performanceTrackigId = data;

        this.performanceService.loadManufacturing$.subscribe(
          (manufacturingData) => {
            if (manufacturingData) {
              this.manufacturingRating = manufacturingData;

              this.updateFormValues();
              this.calculateAverages();
              this.dataSourceLoading = false;
            }
          }
        );
      }
    });
  }

  updateFormValues(): void {
    this.manufacturingForm.patchValue({
      // Quality Group
      supplierQA: this.manufacturingRating.find(
        (x) => x.keyPerformanceIndicatorId === 11
      )?.rating,
      supplierQAComments: this.manufacturingRating.find(
        (x) => x.keyPerformanceIndicatorId === 11
      )?.comments,
      adherencePO: this.manufacturingRating.find(
        (x) => x.keyPerformanceIndicatorId === 12
      )?.rating,
      adherencePOComments: this.manufacturingRating.find(
        (x) => x.keyPerformanceIndicatorId === 12
      )?.comments,
      adherenceDrawings: this.manufacturingRating.find(
        (x) => x.keyPerformanceIndicatorId === 13
      )?.rating,
      adherenceDrawingsComments: this.manufacturingRating.find(
        (x) => x.keyPerformanceIndicatorId === 13
      )?.comments,
      workmanship: this.manufacturingRating.find(
        (x) => x.keyPerformanceIndicatorId === 14
      )?.rating,
      workmanshipComments: this.manufacturingRating.find(
        (x) => x.keyPerformanceIndicatorId === 14
      )?.comments,
      painting: this.manufacturingRating.find(
        (x) => x.keyPerformanceIndicatorId === 15
      )?.rating,
      paintingComments: this.manufacturingRating.find(
        (x) => x.keyPerformanceIndicatorId === 15
      )?.comments,
      acceptanceTesting: this.manufacturingRating.find(
        (x) => x.keyPerformanceIndicatorId === 16
      )?.rating,
      acceptanceTestingComments: this.manufacturingRating.find(
        (x) => x.keyPerformanceIndicatorId === 16
      )?.comments,

      // Schedule Group
      workRequestPlanning: this.manufacturingRating.find(
        (x) => x.keyPerformanceIndicatorId === 17
      )?.rating,
      workRequestPlanningComments: this.manufacturingRating.find(
        (x) => x.keyPerformanceIndicatorId === 17
      )?.comments,
      timelinessDrawings: this.manufacturingRating.find(
        (x) => x.keyPerformanceIndicatorId === 18
      )?.rating,
      timelinessDrawingsComments: this.manufacturingRating.find(
        (x) => x.keyPerformanceIndicatorId === 18
      )?.comments,
      expeditingMaterials: this.manufacturingRating.find(
        (x) => x.keyPerformanceIndicatorId === 19
      )?.rating,
      expeditingMaterialsComments: this.manufacturingRating.find(
        (x) => x.keyPerformanceIndicatorId === 19
      )?.comments,
      maintainingWRSchedule: this.manufacturingRating.find(
        (x) => x.keyPerformanceIndicatorId === 20
      )?.rating,
      maintainingWRScheduleComments: this.manufacturingRating.find(
        (x) => x.keyPerformanceIndicatorId === 20
      )?.comments,
      addLabor: this.manufacturingRating.find(
        (x) => x.keyPerformanceIndicatorId === 21
      )?.rating,
      addLaborComments: this.manufacturingRating.find(
        (x) => x.keyPerformanceIndicatorId === 21
      )?.comments,
      meetingDelivery: this.manufacturingRating.find(
        (x) => x.keyPerformanceIndicatorId === 22
      )?.rating,
      meetingDeliveryComments: this.manufacturingRating.find(
        (x) => x.keyPerformanceIndicatorId === 22
      )?.comments,

      // Cooperation Group
      accessDocuments: this.manufacturingRating.find(
        (x) => x.keyPerformanceIndicatorId === 23
      )?.rating,
      accessDocumentsComments: this.manufacturingRating.find(
        (x) => x.keyPerformanceIndicatorId === 23
      )?.comments,
      advisesProblems: this.manufacturingRating.find(
        (x) => x.keyPerformanceIndicatorId === 24
      )?.rating,
      advisesProblemsComments: this.manufacturingRating.find(
        (x) => x.keyPerformanceIndicatorId === 24
      )?.comments,
      providesSchedules: this.manufacturingRating.find(
        (x) => x.keyPerformanceIndicatorId === 25
      )?.rating,
      providesSchedulesComments: this.manufacturingRating.find(
        (x) => x.keyPerformanceIndicatorId === 25
      )?.comments,
      resolvesProblems: this.manufacturingRating.find(
        (x) => x.keyPerformanceIndicatorId === 26
      )?.rating,
      resolvesProblemsComments: this.manufacturingRating.find(
        (x) => x.keyPerformanceIndicatorId === 26
      )?.comments,
      enforcesHoldpoints: this.manufacturingRating.find(
        (x) => x.keyPerformanceIndicatorId === 27
      )?.rating,
      enforcesHoldpointsComments: this.manufacturingRating.find(
        (x) => x.keyPerformanceIndicatorId === 27
      )?.comments,
      other: this.manufacturingRating.find(
        (x) => x.keyPerformanceIndicatorId === 28
      )?.rating,
      otherComments: this.manufacturingRating.find(
        (x) => x.keyPerformanceIndicatorId === 28
      )?.comments,
    });
  }

  calculateAverages(): void {
    this.qualityAverage = this.calculateGroupAverage([11, 12, 13, 14, 15, 16]);
    this.scheduleAverage = this.calculateGroupAverage([17, 18, 19, 20, 21, 22]);
    this.cooperationAverage = this.calculateGroupAverage([
      23, 24, 25, 26, 27, 28,
    ]);
  }

  calculateGroupAverage(ids: number[]): string {
    const ratings = ids
      .map(
        (id) =>
          this.manufacturingRating.find(
            (x) => x.keyPerformanceIndicatorId === id
          )?.rating
      )
      .map(Number);
    const validRatings = ratings.filter((rating) => !isNaN(rating));
    const sum = validRatings.reduce((a, b) => a + b, 0);
    return (sum / validRatings.length).toFixed(1);
  }

  onSubmit(): void {
    const formValues = this.manufacturingForm.value;
    this.dataSourceLoading = true;
    const kpiIds = [
      11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28,
    ];

    kpiIds.forEach((kpiId) => {
      const ratingValue = formValues[this.getFormControlName(kpiId)];
      const commentsValue = formValues[this.getFormControlName(kpiId, true)];

      const existingRating = this.manufacturingRating.find(
        (x) => x.keyPerformanceIndicatorId === kpiId
      );

      if (existingRating) {
        // Update existing rating
        const updatedRating: IPerformanceRatingData = {
          ...existingRating,
          rating: ratingValue,
          comments: commentsValue,
          ratedDate: new Date().toISOString(),
        };
        this.performanceService
          .updateRating(this.performanceTrackigId, updatedRating)
          .subscribe(() => {
            console.log("Rating updated");
          });
      } else {
        // Create new rating
        const ratingData: IPerformanceRatingData = {
          keyPerformanceIndicatorId: kpiId,
          rating: ratingValue,
          comments: commentsValue,
          performanceTrackingId: this.performanceTrackigId,
          performanceRatingId: 0,
          ratedByUserId: 0,
          ratedDate: new Date().toISOString(),
        };
        this.performanceService
          .createRating(this.performanceTrackigId, ratingData)
          .subscribe(() => {
            console.log("Rating created");
          });
      }
    });
    this.dataSourceLoading = false;
  }

  private getFormControlName(
    kpiId: number,
    isComment: boolean = false
  ): string {
    switch (kpiId) {
      case 11:
        return isComment ? "supplierQAComments" : "supplierQA";
      case 12:
        return isComment ? "adherencePOComments" : "adherencePO";
      case 13:
        return isComment ? "adherenceDrawingsComments" : "adherenceDrawings";
      case 14:
        return isComment ? "workmanshipComments" : "workmanship";
      case 15:
        return isComment ? "paintingComments" : "painting";
      case 16:
        return isComment ? "acceptanceTestingComments" : "acceptanceTesting";
      case 17:
        return isComment
          ? "workRequestPlanningComments"
          : "workRequestPlanning";
      case 18:
        return isComment ? "timelinessDrawingsComments" : "timelinessDrawings";
      case 19:
        return isComment
          ? "expeditingMaterialsComments"
          : "expeditingMaterials";
      case 20:
        return isComment
          ? "maintainingWRScheduleComments"
          : "maintainingWRSchedule";
      case 21:
        return isComment ? "addLaborComments" : "addLabor";
      case 22:
        return isComment ? "meetingDeliveryComments" : "meetingDelivery";
      case 23:
        return isComment ? "accessDocumentsComments" : "accessDocuments";
      case 24:
        return isComment ? "advisesProblemsComments" : "advisesProblems";
      case 25:
        return isComment ? "providesSchedulesComments" : "providesSchedules";
      case 26:
        return isComment ? "resolvesProblemsComments" : "resolvesProblems";
      case 27:
        return isComment ? "enforcesHoldpointsComments" : "enforcesHoldpoints";
      case 28:
        return isComment ? "otherComments" : "other";
      default:
        return "";
    }
  }
}
