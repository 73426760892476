import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { environment } from "projects/aqua3/src/environments/environment";
import { BehaviorSubject, Observable } from "rxjs";
import { IEvaluationStatus } from "../../interfaces/qa-management/audit-observation/evaluationStatus";
import { IAuditObservationDetail } from "../../interfaces/qa-management/audit-observation/auditObservationDetailData";
import { PageViewMode } from "../../interfaces/common/pageViewMode";
import { IAuditObservationEvaluationDetails } from "../../interfaces/qa-management/audit-observation/auditObservationEvaluationDetails";
import { RatingType } from "../../qa-management/manufacturer-audit-and-observation/mao.component";
import { IAddAuditObservationEvaluation } from "../../interfaces/qa-management/audit-observation/add-audit-observation-evaluation";
const apiUrl = environment.apiUrl + '/audit-observation';

@Injectable({
    providedIn: "root",
})
export class AuditObservationService {
    private MAOModeSubject = new BehaviorSubject<PageViewMode>(PageViewMode.Base);
    MAOMode$ = this.MAOModeSubject.asObservable();

    private MAOAddEditModeSubject = new BehaviorSubject<PageViewMode>(PageViewMode.Base);
    MAOAddEditMode$ = this.MAOAddEditModeSubject.asObservable();

    private MAOObjectSubject = new BehaviorSubject<IAddAuditObservationEvaluation>(null);
    MAOObject$ = this.MAOObjectSubject.asObservable();

    private commoditiesFormData: any;

    constructor(private http: HttpClient) { }

    public setMAOMode(mode: PageViewMode): void {
        this.MAOModeSubject.next(mode);
    }

    public setMAOAddMode(mode: PageViewMode): void {
        this.MAOAddEditModeSubject.next(mode);
    }

    public setMAOObject(obj: IAddAuditObservationEvaluation): void {
        this.MAOObjectSubject.next(obj);
    }

    public getAllEvaluationStatus(): Observable<IEvaluationStatus[]> {
        return this.http.get<IEvaluationStatus[]>(`${apiUrl}/evaluation-status/all`);
    }

    public getAllDetails(filters: any): Observable<IAuditObservationDetail[]> {
        let params = new HttpParams();
        Object.keys(filters).forEach(key => {
            if (filters[key] != null && filters[key] != undefined) {
                params = params.append(key, filters[key]);
            }
        });
        return this.http.get<IAuditObservationDetail[]>(`${apiUrl}/details/all`, { params });
    }

    public getDetailsById(id: number): Observable<IAuditObservationEvaluationDetails> {
        return this.http.get<IAuditObservationEvaluationDetails>(`${apiUrl}/details/${id}`);
    }

    public getDetailsByIdAndAssign(id: number): void {
        this.getDetailsById(id).subscribe((data) => {
            this.setMAOObject({
                evaluation: {
                    id: data.id,
                    recordType: data.recordType,
                    responsibleCai: data.responsibleCAI,
                    participants: data.participants,
                    manufacturerId: data.manufacturerId,
                    agencyId: data.agencyId,
                    inspectorId: data.inspectorId,
                    fileUrl: data.fileURL,
                    fileUrllink: data.fileURLLink,
                    recordDate: data.recordDate,
                    notes: data.notes,
                    statusId: data.statusId,
                },
                commodities: data.commodities,
            });
        });
    }

    public getRatingClass(rating: string): string {
        if (rating == RatingType.Qualified) {
            return 'rating-qualified';
        } else if (rating == RatingType.ConditionallyQualified) {
            return 'rating-conditionally-qualified';
        } else if (rating == RatingType.NotQualified) {
            return 'rating-not-qualified';
        } else if (rating == RatingType.NotAvailable || rating == null || rating == "") {
            return 'rating-not-available';
        }
    }

    public getRatingStringValue(rating: string): string {
        if (rating == RatingType.Qualified) {
            return RatingType.Qualified;
        } else if (rating == RatingType.ConditionallyQualified) {
            return RatingType.ConditionallyQualified;
        } else if (rating == RatingType.NotQualified) {
            return RatingType.NotQualified;
        } else if (rating == RatingType.NotAvailable || rating == null || rating == "") {
            return RatingType.NotAvailable;
        }
    }

    public addAuditObservation(newAuditObservation: IAddAuditObservationEvaluation
    ): Observable<number> {
        return this.http.post<number>(
            `${apiUrl}/evaluation`,
            newAuditObservation
        );
    }

    public updateAuditObservation(editingAuditObservation: IAddAuditObservationEvaluation): Observable<any> {
        return this.http.put<number>(`${apiUrl}/evaluation`, editingAuditObservation);
    }

    setCommoditiesFormData(data: any) {
        this.commoditiesFormData = data;
    }

    getCommoditiesFormData() {
        return this.commoditiesFormData;
    }
}
