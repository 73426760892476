// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.selected-commodity-text {
  color: green;
  font-size: 1.3em;
  font-weight: bold;
}

.form-group div:has(> label) {
  margin-top: 10px;
}
`, "",{"version":3,"sources":["webpack://./projects/aqua3/src/app/qa-management/manufacturer-audit-and-observation/add-mao/add-commodity-mao/add-commodity-mao.component.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,gBAAgB;EAChB,iBAAiB;AACnB;;AAEA;EACE,gBAAgB;AAClB","sourcesContent":[".selected-commodity-text {\n  color: green;\n  font-size: 1.3em;\n  font-weight: bold;\n}\n\n.form-group div:has(> label) {\n  margin-top: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
