import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { PrivilegesAccessType } from '../../interfaces/common/privilegesAccessTypeData';

@Injectable({
    providedIn: 'root'
})
export class UserPermissionsService {
    private permissionsSubject = new BehaviorSubject<string>(PrivilegesAccessType.NoAccess);
    permissions$ = this.permissionsSubject.asObservable();

    setPermissions(permissions: string): void {
        this.permissionsSubject.next(permissions);
    }
}