import { Component, OnInit } from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { CapitalProjectsDataService } from "../../services/capital-projects.service";
import { ActivatedRoute, Router } from "@angular/router";
import { IExtendedCapitalProjectData } from "../../approved-vendor-list/preview-project.component";
import { ICapitalProjectData } from "../../interfaces/administration/capitalProjectData";
import { IVendorData } from "../../interfaces/administration/vendorData";
import { VendorDataService } from "../../services/vendor-data.service";
import { VendorCommodityDataService } from "../../services/vendor-information/vendor-commodity.service";
import { forkJoin, switchMap, tap } from "rxjs";
import { VendorCommodityData } from "../../interfaces/vendor-information/vendorCommodityData";
import { VendorCommoditySearchData } from "../../interfaces/vendor-information/viewVendorCommoditySearchData";
import { PerformanceTrackingService } from "../../services/vendor-information/performance-tracking.service";
import { IPerformanceTrackingData } from "../../interfaces/vendor-information/performanceTrackingData";

@Component({
  selector: "app-create-performance-tracking",
  templateUrl: "./create-performance-tracking.component.html",
  styles: [
    `
      .button-container {
        display: flex !important;
        justify-content: space-between !important;
      }

      .unchecked {
        color: red;
      }

      .mt-1 {
        margin-top: 1rem !important;
      }
    `,
  ],
})
export class CreatePerformanceTrackingComponent implements OnInit {
  public performanceForm: FormGroup;
  public projectId: number = 0;
  public project: ICapitalProjectData;
  public vendors: IVendorData[] = [];
  public commodities: any[] = [];
  public selectedVendor: IVendorData;
  public isLoading: boolean = false;
  public validationMessages = [];

  constructor(
    private fb: FormBuilder,
    private capitalProjectService: CapitalProjectsDataService,
    private vendorService: VendorDataService,
    private performanceService: PerformanceTrackingService,
    private route: ActivatedRoute,
    private router: Router,
    private commodityService: VendorCommodityDataService
  ) {
    this.performanceForm = this.fb.group({
      project: [""],
      poNumber: ["", Validators.required],
      poValue: [""],
      poRequiredDate: ["", Validators.required],
      vendor: ["", Validators.required],
      commodity: ["", Validators.required],
      description: [""],
      critcality: [""],
    });
  }

  ngOnInit(): void {
    this.isLoading = true;
    this.loadProject();
    this.loadVendors();
    this.isLoading = false;
  }

  loadProject() {
    this.route.paramMap.subscribe((params) => {
      this.projectId = Number(params.get("projectId"));

      if (this.projectId > 0) {
        this.capitalProjectService
          .getCapitalProjectById(this.projectId)
          .subscribe((data) => {
            if (data) {
              this.project = data;
            }
          });
      }
    });
  }

  loadVendors() {
    this.vendorService.getVendors().subscribe((data) => {
      if (data) {
        this.vendors = data;
      }
    });
  }

  onVendorChange(event: Event) {
    const vendorId = (event.target as HTMLSelectElement).value;
    this.selectedVendor = this.vendors.find(
      (vendor) => vendor.vendorId === Number(vendorId)
    );
    if (this.selectedVendor) {
      this.loadCommodities();
    } else {
      this.commodities = [];
    }
  }

  loadCommodities(): void {
    if (this.selectedVendor) {
      this.commodityService
        .getCommoditiesForVendor(this.selectedVendor.vendorId)
        .subscribe((data: VendorCommoditySearchData[]) => {
          this.commodities = data;
        });
    } else {
      this.commodities = [];
    }
  }

  public onSubmit() {
    this.validationMessages = this.performanceService.validatePerformanceForm(
      this.performanceForm
    );

    if (this.performanceForm.valid && this.validationMessages.length === 0) {
      const performanceTracking: IPerformanceTrackingData = {
        projectId: this.project.projectId,
        ponumber: this.performanceForm.get("poNumber").value,
        povalue: this.performanceForm.get("poValue").value,
        porequiredDate: this.performanceForm.get("poRequiredDate").value,
        vendorId: this.performanceForm.get("vendor").value,
        commodityId: this.performanceForm.get("commodity").value,
        commodityDescriptionFromUser:
          this.performanceForm.get("description").value,
        critcality: this.performanceForm.get("critcality").value,
        createdDate: new Date(),
        isApproved: false,
        performanceTrackingId: 0,
      };
      console.log(performanceTracking);
      this.performanceService
        .createPerformanceTracking(performanceTracking)
        .subscribe((response) => {
          if (response) {
            let id = response.performanceTrackingId;
            this.router.navigate([
              "/vendor-information/performance-tracking/edit-spt/",
              id,
            ]);
          }
        });
    }
  }

  onCancel() {
    this.performanceForm.reset();
  }
}
