import { Component, Input, OnInit } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { PerformanceTrackingService } from "../../../services/vendor-information/performance-tracking.service";
import { ViewPerformanceRatingData } from "../../../interfaces/vendor-information/viewPerformanceRatingData";
import { IPerformanceRatingData } from "../../../interfaces/vendor-information/performanceRatingData";
import { BaseComponent } from "../../../shared/base/base.component";
import { UserPermissionsService } from "../../../services/common/user-permissions.service";

@Component({
  selector: "engineering-tab-component",
  templateUrl: "./engineering-tab.component.html",
})
export class EngineeringTabComponent extends BaseComponent implements OnInit {
  public engineeringForm: FormGroup;
  public engineeringRating: ViewPerformanceRatingData[] = [];
  public average: string;
  public performanceTrackigId: number;
  public dataSourceLoading: boolean = false;

  @Input() viewFromWorkRequestDetailsPage: boolean = false;

  constructor(
    private fb: FormBuilder,
    private performanceService: PerformanceTrackingService,
    userPermissionsService: UserPermissionsService
  ) {
    super(userPermissionsService);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.dataSourceLoading = true;
    // Initialize the form with empty values
    this.engineeringForm = this.fb.group({
      timelinessInitial: [""],
      timelinessInitialComments: [""],
      timelinessRevisions: [""],
      timelinessRevisionsComments: [""],
      incorporationComments: [""],
      incorporationCommentsComments: [""],
      engineeringAccuracy: [""],
      engineeringAccuracyComments: [""],
      supportRequired: [""],
      supportRequiredComments: [""],
    });

    this.performanceService.performanceTrackingId$.subscribe((data) => {
      if (data) {
        this.performanceTrackigId = data;

        this.performanceService.loadEngineering$.subscribe(
          (engineeringData) => {
            if (engineeringData) {
              this.engineeringRating = engineeringData;

              this.updateFormValues();
              this.calculateAverage();
              this.dataSourceLoading = false;
            }
          }
        );
      }
    });
  }

  updateFormValues(): void {
    this.engineeringForm.patchValue({
      timelinessInitial: this.engineeringRating.find(
        (x) => x.keyPerformanceIndicatorId === 1
      )?.rating,
      timelinessInitialComments: this.engineeringRating.find(
        (x) => x.keyPerformanceIndicatorId === 1
      )?.comments,
      timelinessRevisions: this.engineeringRating.find(
        (x) => x.keyPerformanceIndicatorId === 2
      )?.rating,
      timelinessRevisionsComments: this.engineeringRating.find(
        (x) => x.keyPerformanceIndicatorId === 2
      )?.comments,
      incorporationComments: this.engineeringRating.find(
        (x) => x.keyPerformanceIndicatorId === 3
      )?.rating,
      incorporationCommentsComments: this.engineeringRating.find(
        (x) => x.keyPerformanceIndicatorId === 3
      )?.comments,
      engineeringAccuracy: this.engineeringRating.find(
        (x) => x.keyPerformanceIndicatorId === 4
      )?.rating,
      engineeringAccuracyComments: this.engineeringRating.find(
        (x) => x.keyPerformanceIndicatorId === 4
      )?.comments,
      supportRequired: this.engineeringRating.find(
        (x) => x.keyPerformanceIndicatorId === 5
      )?.rating,
      supportRequiredComments: this.engineeringRating.find(
        (x) => x.keyPerformanceIndicatorId === 5
      )?.comments,
    });
  }

  calculateAverage(): void {
    const ratings = [
      this.engineeringForm.get("timelinessInitial")?.value,
      this.engineeringForm.get("timelinessRevisions")?.value,
      this.engineeringForm.get("incorporationComments")?.value,
      this.engineeringForm.get("engineeringAccuracy")?.value,
      this.engineeringForm.get("supportRequired")?.value,
    ].map(Number);

    const validRatings = ratings.filter((rating) => !isNaN(rating));
    const sum = validRatings.reduce((a, b) => a + b, 0);
    this.average = (sum / validRatings.length).toFixed(1);
  }

  onSubmit(): void {
    const formValues = this.engineeringForm.value;
    this.dataSourceLoading = true;
    const kpiIds = [1, 2, 3, 4, 5]; // Replace with actual KPI IDs for engineering

    kpiIds.forEach((kpiId) => {
      const ratingValue = formValues[this.getFormControlName(kpiId)];
      const commentsValue = formValues[this.getFormControlName(kpiId, true)];

      const existingRating = this.engineeringRating.find(
        (x) => x.keyPerformanceIndicatorId === kpiId
      );

      if (existingRating) {
        // Update existing rating
        const updatedRating: IPerformanceRatingData = {
          ...existingRating,
          rating: ratingValue,
          comments: commentsValue,
          ratedDate: new Date().toISOString(), // Ensure ratedDate is a string
        };
        this.performanceService
          .updateRating(this.performanceTrackigId, updatedRating)
          .subscribe(() => {
            console.log("Rating updated");
          });
      } else {
        // Create new rating
        const ratingData: IPerformanceRatingData = {
          keyPerformanceIndicatorId: kpiId,
          rating: ratingValue,
          comments: commentsValue,
          performanceTrackingId: this.performanceTrackigId,
          performanceRatingId: 0,
          ratedByUserId: 0,
          ratedDate: new Date().toISOString(), // Ensure ratedDate is a string
        };
        this.performanceService
          .createRating(this.performanceTrackigId, ratingData)
          .subscribe(() => {
            console.log("Rating created");
          });
      }
    });
    this.dataSourceLoading = false;
  }

  private getFormControlName(
    kpiId: number,
    isComment: boolean = false
  ): string {
    switch (kpiId) {
      case 1:
        return isComment ? "timelinessInitialComments" : "timelinessInitial";
      case 2:
        return isComment
          ? "timelinessRevisionsComments"
          : "timelinessRevisions";
      case 3:
        return isComment
          ? "incorporationCommentsComments"
          : "incorporationComments";
      case 4:
        return isComment
          ? "engineeringAccuracyComments"
          : "engineeringAccuracy";
      case 5:
        return isComment ? "supportRequiredComments" : "supportRequired";
      default:
        return "";
    }
  }
}
