import {
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
} from "@angular/core";
import { NgForm } from "@angular/forms";
import { CapitalProjectsDataService } from "../../services/capital-projects.service";
import { ProjectsAndAvls } from "../../interfaces/administration/projectsAndAvls";
import { MatTableDataSource } from "@angular/material/table";
import { MatPaginator } from "@angular/material/paginator";
import { debounceTime, Subject, switchMap, takeUntil, tap } from "rxjs";
import { BaseComponent } from "../../shared/base/base.component";
import { UserPermissionsService } from "../../services/common/user-permissions.service";

@Component({
  selector: "avl-tab-component",
  templateUrl: "./avl-tab.component.html",
  styles: [
    `
      .button-container {
        display: flex !important;
        justify-content: space-between !important;
      }
    `,
  ],
})
export class AvlTabComponent
  extends BaseComponent
  implements OnInit, OnDestroy
{
  private destroy$ = new Subject<void>();
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild("avlForm") avlForm: NgForm;
  public selectedAvl: ProjectsAndAvls | null = null;

  public displayedColumns: string[] = [
    "listName",
    "lastCommodityUpdate",
    "actions",
  ];
  public dataSource: MatTableDataSource<ProjectsAndAvls>;
  public originalDataSource: MatTableDataSource<ProjectsAndAvls>;
  public dataSourceLoading: boolean = false;

  constructor(
    private capitalProjectsDataService: CapitalProjectsDataService,
    private changeDetectorRefs: ChangeDetectorRef,
    userPermissionService: UserPermissionsService
  ) {
    super(userPermissionService);
  }

  loadAvls() {
    this.capitalProjectsDataService.loadProjectAvls$
      .pipe(takeUntil(this.destroy$))
      .subscribe((avls) => {
        this.dataSourceLoading = true;

        this.originalDataSource = new MatTableDataSource<ProjectsAndAvls>(avls);
        this.dataSource = new MatTableDataSource<ProjectsAndAvls>(
          this.originalDataSource.data
        );

        this.dataSourceLoading = false;
        this.dataSource.paginator = this.paginator;
        this.paginator.pageSize = 10;
        this.changeDetectorRefs.detectChanges();
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.dataSource = new MatTableDataSource<ProjectsAndAvls>();
    this.originalDataSource = new MatTableDataSource<ProjectsAndAvls>();

    this.loadAvls();
  }

  deleteAvl(data: ProjectsAndAvls): void {
    this.capitalProjectsDataService.deleteAvl(data).subscribe({
      next: (response) => {
        this.capitalProjectsDataService.loadAvlsForProject(data);
      },
    });
  }

  editAvl(element: ProjectsAndAvls) {
    this.selectedAvl = element;
    this.avlForm.setValue({
      listName: element.listName,
    });
  }

  createOrUpdateAVL(form: NgForm): void {
    if (form.valid) {
      let avlName = form.value.listName;
      if (this.selectedAvl) {
        // Update existing AVL
        this.selectedAvl.listName = avlName;
        this.capitalProjectsDataService.updateAvl(this.selectedAvl).subscribe({
          next: () => {
            this.loadAvls();
            this.resetForm(form);
          },
          error: (error) => {
            console.error("Error updating AVL", error);
          },
        });
      } else {
        // Create new AVL
        this.capitalProjectsDataService.currentCapitaProject$
          .pipe(
            debounceTime(300), // Debounce to prevent multiple submissions
            switchMap((data) =>
              this.capitalProjectsDataService
                .addAVL(avlName, data)
                .pipe(
                  tap(() =>
                    this.capitalProjectsDataService.loadAvlsForProject(data)
                  )
                )
            )
          )
          .subscribe({
            next: () => {
              this.loadAvls();
              this.resetForm(form);
            },
            error: (error) => {
              console.error("Error creating AVL", error);
            },
          });
      }
    } else {
      console.error("Form is not valid");
    }
  }

  resetForm(form: NgForm): void {
    form.reset();
    form.form.markAsPristine();
    this.selectedAvl = null;
  }
}
