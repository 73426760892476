import { Component, Input, OnInit } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { PerformanceTrackingService } from "../../../services/vendor-information/performance-tracking.service";
import { ViewPerformanceRatingData } from "../../../interfaces/vendor-information/viewPerformanceRatingData";
import { IPerformanceRatingData } from "../../../interfaces/vendor-information/performanceRatingData";
import { BaseComponent } from "../../../shared/base/base.component";
import { UserPermissionsService } from "../../../services/common/user-permissions.service";

@Component({
  selector: "construction-tab-component",
  templateUrl: "./construction-tab.component.html",
})
export class ConstructionTabComponent extends BaseComponent implements OnInit {
  public constructionForm: FormGroup;
  public constructionRating: ViewPerformanceRatingData[] = [];
  public average: string;
  public performanceTrackigId: number;
  public dataSourceLoading: boolean = false;

  @Input() viewFromWorkRequestDetailsPage: boolean = false;

  constructor(
    private fb: FormBuilder,
    private performanceService: PerformanceTrackingService,
    userPermissionService: UserPermissionsService
  ) {
    super(userPermissionService);
  }

  ngOnInit(): void {
    super.ngOnInit();
    // Initialize the form with empty values
    this.constructionForm = this.fb.group({
      closeOutIssues: [""],
      closeOutIssuesComments: [""],
      finalDocumentation: [""],
      finalDocumentationComments: [""],
      regulatoryReviews: [""],
      regulatoryReviewsComments: [""],
      supportPersonnel: [""],
      supportPersonnelComments: [""],
      serviceContracts: [""],
      serviceContractsComments: [""],
    });

    this.performanceService.performanceTrackingId$.subscribe((data) => {
      if (data) {
        this.performanceTrackigId = data;

        this.performanceService.loadConstruction$.subscribe(
          (constructionData) => {
            if (constructionData) {
              this.constructionRating = constructionData;

              this.updateFormValues();
              this.calculateAverage();
              this.dataSourceLoading = false;
            }
          }
        );
      }
    });
  }

  updateFormValues(): void {
    this.constructionForm.patchValue({
      closeOutIssues: this.constructionRating.find(
        (x) => x.keyPerformanceIndicatorId === 29
      )?.rating,
      closeOutIssuesComments: this.constructionRating.find(
        (x) => x.keyPerformanceIndicatorId === 29
      )?.comments,
      finalDocumentation: this.constructionRating.find(
        (x) => x.keyPerformanceIndicatorId === 30
      )?.rating,
      finalDocumentationComments: this.constructionRating.find(
        (x) => x.keyPerformanceIndicatorId === 30
      )?.comments,
      regulatoryReviews: this.constructionRating.find(
        (x) => x.keyPerformanceIndicatorId === 31
      )?.rating,
      regulatoryReviewsComments: this.constructionRating.find(
        (x) => x.keyPerformanceIndicatorId === 31
      )?.comments,
      supportPersonnel: this.constructionRating.find(
        (x) => x.keyPerformanceIndicatorId === 32
      )?.rating,
      supportPersonnelComments: this.constructionRating.find(
        (x) => x.keyPerformanceIndicatorId === 32
      )?.comments,
      serviceContracts: this.constructionRating.find(
        (x) => x.keyPerformanceIndicatorId === 33
      )?.rating,
      serviceContractsComments: this.constructionRating.find(
        (x) => x.keyPerformanceIndicatorId === 33
      )?.comments,
    });
  }

  calculateAverage(): void {
    const ratings = [
      this.constructionForm.get("closeOutIssues")?.value,
      this.constructionForm.get("finalDocumentation")?.value,
      this.constructionForm.get("regulatoryReviews")?.value,
      this.constructionForm.get("supportPersonnel")?.value,
      this.constructionForm.get("serviceContracts")?.value,
    ].map(Number);

    const validRatings = ratings.filter((rating) => !isNaN(rating));
    const sum = validRatings.reduce((a, b) => a + b, 0);
    this.average = (sum / validRatings.length).toFixed(1);
  }

  onSubmit(): void {
    const formValues = this.constructionForm.value;
    this.dataSourceLoading = true;
    const kpiIds = [29, 30, 31, 32, 33];

    kpiIds.forEach((kpiId) => {
      const ratingValue = formValues[this.getFormControlName(kpiId)];
      const commentsValue = formValues[this.getFormControlName(kpiId, true)];

      const existingRating = this.constructionRating.find(
        (x) => x.keyPerformanceIndicatorId === kpiId
      );

      if (existingRating) {
        // Update existing rating
        const updatedRating: IPerformanceRatingData = {
          ...existingRating,
          rating: ratingValue,
          comments: commentsValue,
          ratedDate: new Date().toISOString(),
        };
        console.log(existingRating);
        this.performanceService
          .updateRating(this.performanceTrackigId, updatedRating)
          .subscribe(() => {
            console.log("Rating updated");
          });
      } else {
        // Create new rating
        const ratingData: IPerformanceRatingData = {
          keyPerformanceIndicatorId: kpiId,
          rating: ratingValue,
          comments: commentsValue,
          performanceTrackingId: this.performanceTrackigId,
          performanceRatingId: 0,
          ratedByUserId: 0,
          ratedDate: new Date().toISOString(), // Added ratedDate
        };
        console.log(ratingData);
        this.performanceService
          .createRating(this.performanceTrackigId, ratingData)
          .subscribe(() => {
            console.log("Rating created");
          });
      }
    });
    this.dataSourceLoading = false;
  }

  private getFormControlName(
    kpiId: number,
    isComment: boolean = false
  ): string {
    switch (kpiId) {
      case 29:
        return isComment ? "closeOutIssuesComments" : "closeOutIssues";
      case 30:
        return isComment ? "finalDocumentationComments" : "finalDocumentation";
      case 31:
        return isComment ? "regulatoryReviewsComments" : "regulatoryReviews";
      case 32:
        return isComment ? "supportPersonnelComments" : "supportPersonnel";
      case 33:
        return isComment ? "serviceContractsComments" : "serviceContracts";
      default:
        return "";
    }
  }
}
