import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { IUserData } from "../../../interfaces/administration/userData";
import { GraphService } from "../../../services/administration/graph.service";

@Component({
  selector: "app-edit-user",
  templateUrl: "./edit-user.component.html",
  styles: [
    `
      fieldset {
        border: none !important;
      }
    `,
  ],
})
export class EditUserComponent implements OnInit {
  public userData: IUserData;

  constructor(
    private router: Router,
    private graphService: GraphService,
    private fb: FormBuilder,
  ) { }

  user: IUserData;
  editForm: FormGroup;

  ngOnInit() {
    this.graphService.currentUserData$.subscribe((data) => {
      if (data) {
        console.log(data);
        this.user = data;
      }
    });
    this.graphService.currentUserData$.subscribe(data => {
      this.user = data;
      this.editForm = this.fb.group({
        cai: [this.user.cai, Validators.required],
        firstName: [this.user.firstName, Validators.required],
        lastName: [this.user.lastName, Validators.required],
        emailAdress: [this.user.email, Validators.required],
        activeUser: [this.user.isActive],
        systemAdmin: [{ value: this.user.isSystemAdministrator, disabled: true }],
        teamMember: [{ value: this.user.isProjectTeamMember, disabled: true }],
        qaqcAdmin: [{ value: this.user.isQualityAssuranceAdministrator, disabled: true }],
        qaqcUser: [{ value: this.user.isQualityAssuranceAndControlUser, disabled: true }],
      });
    });
  }

  submitForm(): void {
    let formActiveUser: boolean = this.editForm.get('activeUser').value;

    if (this.editForm.valid) {
      this.user.isActive = formActiveUser;

      this.graphService.updateUser(this.user).subscribe((data) => {
        if (data) {
          this.backToUserAndRoles();
        }
      });
    }
  }

  cancel(): void {
    this.backToUserAndRoles();
  }

  private backToUserAndRoles() {
    this.router.navigate(["administration/user-roles"]);
  }
}
