//generate me vendor-details.component.ts

import { Component, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Observable } from "rxjs";
import { ILookupData } from "../../interfaces/administration/lookupData";
import { CountriesAndStatesService } from "../../services/countries-states.service";
import { IVendorData } from "../../interfaces/administration/vendorData";
import { VendorDataService } from "../../services/vendor-data.service";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { IVendorCommentData } from "../../interfaces/vendor-information/vendorCommentData";
import { LoggedUserService } from "../../services/common/logged-user.service";
import { MatTableDataSource } from "@angular/material/table";
import { IViewVendorCommodityData } from "../../interfaces/vendor-information/viewVendorCommodityData";
import { MatPaginator } from "@angular/material/paginator";
import { BaseComponent } from "../../shared/base/base.component";
import { UserPermissionsService } from "../../services/common/user-permissions.service";

@Component({
  selector: "app-vendor-details",
  templateUrl: "./vendor-details.component.html",
  styles: [
    `
      .button-container {
        display: flex !important;
        justify-content: space-between !important;
      }

      .unchecked {
        color: red;
      }

      .mt-1 {
        margin-top: 1rem !important;
      }
    `,
  ],
})
export class VendorDetailsComponent extends BaseComponent implements OnInit {
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  public vendorData: IVendorData = {} as IVendorData;
  public isLoading = false;
  public dataSourceLoading = false;
  public vendorId: string;
  public vendorCommentData: IVendorCommentData = {} as IVendorCommentData;
  public countries$: Observable<ILookupData[]>;
  public states$: Observable<ILookupData[]>;
  public isEditing = false;
  public vendorCommentForm: FormGroup;

  displayedColumns: string[] = [
    "commodityCode",
    "commodityCodeDescription",
    "supplierVendorName",
    "supplierImanumber",
    "supplierIsMinorityFirm",
    "supplierRating",
    "manufacturerVendorName",
    "manufacturerImanumber",
    "manufacturerIsMinorityFirm",
    "manufacturerRating",
    "brandName",
  ];
  public dataSource = new MatTableDataSource<IViewVendorCommodityData>();
  public hasAccess = false;

  constructor(
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private countriesAndStatesService: CountriesAndStatesService,
    private vendorDataService: VendorDataService,
    private loggedUserService: LoggedUserService,
    userPermissionsService: UserPermissionsService
  ) {
    super(userPermissionsService);
    this.loggedUserService.getLoggedUserData().subscribe((user) => {
      if (user) {
        this.hasAccess =
          user.isSystemAdministrator ||
          user.isQualityAssuranceAndControlUser ||
          user.isQualityAssuranceAdministrator ||
          user.isProjectTeamMember;
      }
    });
    this.vendorCommentForm = this.fb.group({
      plantReviewRevisions: [""],
      recommendations: [""],
      observedQuality: [""],
      qualityControl: [""],
    });
  }

  public ngOnInit(): void {
    super.ngOnInit();
    this.isLoading = true;
    this.dataSourceLoading = true;
    this.route.paramMap.subscribe((params) => {
      this.vendorId = params.get("vendorId");
      if (this.vendorId) {
        this.vendorDataService
          .getVendorById(this.vendorId)
          .subscribe((data) => {
            this.vendorData = data;
            this.vendorDataService
              .getVendorCommentData(this.vendorId)
              .subscribe((commentData) => {
                this.vendorCommentData = commentData;
                this.vendorCommentForm.patchValue(commentData);
              });
          });

        this.isLoading = false;

        this.vendorDataService
          .getVendorCommodityData(this.vendorId)
          .subscribe((data) => {
            this.dataSource.data = data;
            // Delay the initialization of the paginator until the data is assigned
            setTimeout(() => {
              this.dataSource.paginator = this.paginator;
              this.paginator.pageSize = 10;
            });
            this.dataSourceLoading = false;
          });
      }
    });
  }

  public toggleEdit(): void {
    this.isEditing = !this.isEditing;
  }

  public saveVendorComment(): void {
    let cai = "";
    this.loggedUserService.getLoggedUserData().subscribe((user) => {
      if (user) cai = user.cai;
    });

    this.vendorDataService
      .createUpdateVendorComment(this.vendorCommentForm, this.vendorId, cai)
      .subscribe((data) => {
        this.toggleEdit();

        this.vendorCommentData = data;
      });
  }
}
