import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { environment } from "projects/aqua3/src/environments/environment";
import { Observable, throwError } from "rxjs";
import { ICurrencyData } from "../../interfaces/qa-management/currencyData";
import { catchError } from "rxjs/operators";

const apiUrl = environment.apiUrl;

@Injectable({
  providedIn: "root",
})
export class CurrenciesDataService {
  constructor(private http: HttpClient) {}

  public getCurrencies(): Observable<ICurrencyData[]> {
    return this.http.get<ICurrencyData[]>(`${apiUrl}/currencies`).pipe(
      catchError((error) => {
        console.error("Error fetching currencies", error);
        return throwError(() => new Error("Error fetching currencies"));
      })
    );
  }

  public getCurrencySymbolByCode(code: string): Observable<string> {
    if (this.isValidCurrencyCode(code)) {
      const url = `${apiUrl}/currencies/${encodeURIComponent(code)}/symbol`;
      return this.http.get<string>(url).pipe(
        catchError((error) => {
          console.error("Error fetching currency symbol", error);
          return throwError(() => new Error("Error fetching currency symbol"));
        })
      );
    }
  }

  private isValidCurrencyCode(code: string): boolean {
    // Implement validation logic here (e.g., length check, character check)
    // Example: Check if the code is a 3-letter alphabetic string
    const regex = /^[A-Z]{3}$/;
    return regex.test(code.toUpperCase());
  }
}
