import { Component, OnInit } from "@angular/core";
import { FormGroup, FormBuilder } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { LoggedUserService } from "../../../services/common/logged-user.service";
import { ILoggedUserData } from "../../../interfaces/common/loggedUserData";
import { UserRequestsDataService } from "../../../services/administration/user-requests.service";
import { AgenciesAndInspectorsDataService } from "../../../services/qa-management/agencies-inspectors.service";
import { IAgencyData } from "../../../interfaces/qa-management/agencyData";
import { IAq2QaqcbuyerEngineerQae } from "../../../interfaces/qa-management/aq2QaqcbuyerEngineerQae";
import { QaqcbuyerEngineerQaeService } from "../../../services/qaqc-buyer-engineer-qae.service";
import { EmailTemplateService } from "../../../services/common/email-template.service";
import { EmailService } from "../../../services/common/email.service";
import { EmailRequestData } from "../../../interfaces/common/emailRequestData";
import { IWorkRequestActivityData } from "../../../interfaces/qa-management/work-requests/workRequestActivityData";
import { WorkRequestsDataService } from "../../../services/qa-management/work-requests/work-requests.service";
import { UserPermissionsService } from "../../../services/common/user-permissions.service";
import { BaseComponent } from "../../../shared/base/base.component";
import { IWorkRequestDetailsData } from "../../../interfaces/qa-management/work-requests/workRequestDetailsData";

@Component({
  selector: "email-icontract-agency",
  templateUrl: "./email-icontract-agency.component.html",
})
export class EmailIContractToAgencyComponent
  extends BaseComponent
  implements OnInit
{
  agencyId: number;
  qaeId: number;
  workRequestId: number;
  userFullName: string = "";
  loggedUserData: ILoggedUserData;
  public agencyData: IAgencyData;
  workRequestData: IWorkRequestDetailsData;
  public qaeData: IAq2QaqcbuyerEngineerQae;
  checkedAgencies: { [key: number]: boolean } = {};

  addForm: FormGroup;
  validationMessages = [];

  constructor(
    private fb: FormBuilder,
    private loggedUserService: LoggedUserService,
    private userRequestsService: UserRequestsDataService,
    private agenciesAndInspectorsDataService: AgenciesAndInspectorsDataService,
    private qaqcbuyerEngineerQaeService: QaqcbuyerEngineerQaeService,
    private workRequestsService: WorkRequestsDataService,
    private router: Router,
    private route: ActivatedRoute,
    private emailTemplateService: EmailTemplateService,
    private emailService: EmailService,
    userPermissionsService: UserPermissionsService
  ) {
    super(userPermissionsService);
  }
  ngOnInit(): void {
    super.ngOnInit();
    this.route.queryParams.subscribe((params) => {
      this.workRequestId = +params["workRequestId"];
      this.qaeId = +params["qaeId"];
      this.agencyId = +params["agencyId"];
    });

    if (this.workRequestId) {
      this.workRequestId = Number(this.workRequestId);
      this.workRequestsService
        .getWorkRequestDetailsById(this.workRequestId.toString())
        .subscribe((data) => {
          this.workRequestData = data;
        });
    }

    if (this.agencyId) {
      this.agencyId = Number(this.agencyId);
      this.agenciesAndInspectorsDataService
        .getAgencyById(this.agencyId.toString())
        .subscribe((data) => {
          this.agencyData = data;
        });
    }

    if (this.qaeId) {
      this.qaeId = Number(this.qaeId);
      this.qaqcbuyerEngineerQaeService
        .getQaqcbuyerEngineerQaeById(this.qaeId)
        .subscribe((data) => {
          this.qaeData = data;
        });
    }

    this.loggedUserService
      .getLoggedUserData()
      .subscribe((loggedUser: ILoggedUserData | null) => {
        if (loggedUser) {
          this.loggedUserData = loggedUser;
          this.userRequestsService
            .getUserFullNameById(loggedUser.userId)
            .subscribe((fullName) => {
              this.userFullName = fullName;
            });
        }
      });

    this.addForm = this.fb.group({
      notes: [""],
    });
  }

  sendEmail(): void {
    let messagesValidationsList: string[] = [];
    this.validationMessages = messagesValidationsList;

    // Validate necessary data
    if (this.agencyData == null)
      messagesValidationsList.push("No agency selected.");
    if (this.qaeData == null)
      messagesValidationsList.push("Please update QAE to send iContract.");
    if (this.agencyData?.email == null || this.agencyData?.email === "")
      messagesValidationsList.push(
        "Please update Agency Email Id to send close iContract notice."
      );
    if (this.qaeData?.email == null || this.qaeData?.email === "")
      messagesValidationsList.push(
        "Please update QAE Email Id to send close iContract notice."
      );

    let formNotes: string = this.addForm.get("notes").value;

    this.validationMessages = messagesValidationsList;
    if (this.addForm.valid && this.validationMessages.length === 0) {
      let activityDate: IWorkRequestActivityData = {
        typeId: this.workRequestId,
        actionDate: new Date(),
        actionBy: this.loggedUserData.cai,
        status: "Email Sent to Agency",
        notes: formNotes,
      };

      this.workRequestsService.addWorkRequestActivity(activityDate).subscribe({
        next: () => {
          this.router.navigate([
            `/qa-management/work-requests/edit-work-request/${this.workRequestId}`,
          ]);
        },
        error: (error) => {
          console.error(
            "POST /workRequests/add-activity in email-icontract-agency" + error
          );
        },
      });

      let emailData = {
        toAddressList: [this.agencyData.email],
        subject: "iContract",
        templateVariables: {
          AGENCYFIRST_NAME: this.agencyData.name,
          QAE: this.qaeData.firstName + " " + this.qaeData.lastName,
          AGENCY: this.agencyData.name,
          ICONTRACTNO: this.workRequestId.toString(),
          BLANKETNO: this.workRequestData?.workRequest?.sapso, // Replace with actual BlanketNo if available
        },
      };

      let ccAddressList = [this.qaeData.email];

      // Generate email content and send email
      let templateName = "iContractToAgency";
      this.emailTemplateService
        .generateEmailContent(templateName, emailData.templateVariables)
        .subscribe({
          next: (emailContent) => {
            let emailRequest: EmailRequestData = {
              toAddresses: emailData.toAddressList,
              ccAddresses: ccAddressList,
              subject: emailData.subject,
              body: emailContent,
              attachments: [], // Add attachments if needed
            };

            this.emailService.sendEmail(emailRequest).subscribe({
              next: (response) => {
                console.log("Email sent successfully", response);
                this.router.navigate([
                  `/qa-management/work-requests/edit-work-request/${this.workRequestId}`,
                ]);
              },
            });
          },
        });
    }
  }

  cancelChanges(): void {
    this.router.navigate([
      `/qa-management/work-requests/edit-work-request/${this.workRequestId}`,
    ]);
  }
}
