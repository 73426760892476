import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { environment } from "projects/aqua3/src/environments/environment";
import { BehaviorSubject, Observable, of } from "rxjs";
import { IWorkRequestViewData } from "../../../interfaces/qa-management/work-requests/workRequestViewData";
import { IWorkRequestData } from "../../../interfaces/qa-management/work-requests/workRequestData";
import { IInspectorPerformanceRatingData } from "../../../interfaces/qa-management/work-requests/inspectorPerformanceRatingData";
import {
  ICommodityDetail,
  IWorkRequestDetailsData,
} from "../../../interfaces/qa-management/work-requests/workRequestDetailsData";
import { IWorkRequestRFQData } from "../../../interfaces/qa-management/work-requests/workRequestRFQData";
import { IWorkRequestRevisionData } from "../../../interfaces/qa-management/work-requests/workRequestRevisionData";
import { IWorkRequestActivityData } from "../../../interfaces/qa-management/work-requests/workRequestActivityData";
import { IWorkRequestIRData } from "../../../interfaces/qa-management/work-requests/workRequestIRData";
import { IWorkRequestDocumentData } from "../../../interfaces/qa-management/work-requests/workRequestDocumentData";
import { IViewPerformanceRatingDataNew } from "../../../interfaces/vendor-information/viewPerformanceRatingDataNew";
import { IPerformanceTrackingData } from "../../../interfaces/vendor-information/performanceTrackingData";
import { ISingleWorkRequestRevisionData } from "../../../interfaces/qa-management/work-requests/singleWorkRrequestRevisionData";
const apiUrl = environment.apiUrl;

export interface PaginatedResponse<T> {
  items: T[];
  totalCount: number;
}

@Injectable({
  providedIn: "root",
})
export class WorkRequestsDataService {
  constructor(private http: HttpClient) {}

  private copyWorkRequestDataSubject: BehaviorSubject<IWorkRequestData> =
    new BehaviorSubject(null);
  copyWorkRequestData$: Observable<IWorkRequestData> =
    this.copyWorkRequestDataSubject.asObservable();

  copyWorkRequestData(workRequestData: IWorkRequestData): void {
    this.copyWorkRequestDataSubject.next(workRequestData);
  }

  public getWorkRequests(
    countryId?: number,
    agencyId?: number,
    inspectorId?: number,
    manufacturerId?: number,
    qaeId?: number,
    statusId?: number,
    description?: string,
    etcChargeCode?: string,
    city?: string,
    poNumber?: string,
    sapso?: string,
    businessUnitId?: number,
    capitalProjectId?: number,
    iContractNo?: number,
    criticalityId?: number,
    commodityIds?: number[],
    pageIndex: number = 1,
    pageSize: number = 10
  ): Observable<PaginatedResponse<IWorkRequestViewData>> {
    let params = new HttpParams();
    params = params.append("countryId", countryId);
    params = params.append("agencyId", agencyId);
    params = params.append("inspectorId", inspectorId);
    params = params.append("manufacturerId", manufacturerId);
    params = params.append("qaeId", qaeId);
    params = params.append("statusId", statusId);
    if (description !== undefined)
      params = params.append("description", description);
    if (etcChargeCode !== undefined)
      params = params.append("etcChargeCode", etcChargeCode);
    if (city !== undefined) params = params.append("city", city);
    if (poNumber !== undefined) params = params.append("poNumber", poNumber);
    if (sapso !== undefined) params = params.append("sapso", sapso);
    params = params.append("businessUnitId", businessUnitId);
    params = params.append("capitalProjectId", capitalProjectId);
    params = params.append("iContractNo", iContractNo);
    params = params.append("criticalityId", criticalityId);
    if (commodityIds && commodityIds.length > 0)
      params = params.append("commodityIds", commodityIds.join(","));
    params = params.append("pageIndex", pageIndex.toString());
    params = params.append("pageSize", pageSize.toString());

    return this.http.get<PaginatedResponse<IWorkRequestViewData>>(
      `${apiUrl}/workRequests`,
      { params }
    );
  }
  public addWorkRequest(
    inspectorData: IWorkRequestData
  ): Observable<IWorkRequestData> {
    return this.http.post<IWorkRequestData>(
      `${apiUrl}/workRequests`,
      inspectorData
    );
  }

  public copyWorkRequest(
    workRequestData: IWorkRequestData
  ): Observable<IWorkRequestData> {
    return this.http.post<IWorkRequestData>(
      `${apiUrl}/workRequests/copy`,
      workRequestData
    );
  }

  public addInspectorPerformanceRatings(
    InspectorPerformanceRating: IInspectorPerformanceRatingData
  ): Observable<any> {
    return this.http.post<IInspectorPerformanceRatingData>(
      `${apiUrl}/workRequests/inspectorPerformanceRatings`,
      InspectorPerformanceRating
    );
  }

  public getWorkRequestDetailsById(
    workRequestId: string
  ): Observable<IWorkRequestDetailsData> {
    return this.http.get<IWorkRequestDetailsData>(
      `${apiUrl}/workRequests/details/${workRequestId}`
    );
  }

  public getSPTForWorkRequest(
    workRequestId: number
  ): Observable<IViewPerformanceRatingDataNew> {
    return this.http.get<IViewPerformanceRatingDataNew>(
      `${apiUrl}/workRequests/spt/${workRequestId}`
    );
  }

  public createNewSptForWorkRequest(
    workRequestId: number,
    userId: number
  ): Observable<IPerformanceTrackingData> {
    return this.http.post<IPerformanceTrackingData>(
      `${apiUrl}/workRequests/createNewSpt/${workRequestId}`,
      { userId }
    );
  }

  public updatePerformanceTrackingForWorkRequest(
    workRequestData: IWorkRequestData
  ): Observable<IPerformanceTrackingData> {
    return this.http.put<IPerformanceTrackingData>(
      `${apiUrl}/workRequests/performance-tracking`,
      workRequestData
    );
  }

  public getInspectorPerformanceRatingsByWorkRequestId(
    workRequestId: string
  ): Observable<IInspectorPerformanceRatingData> {
    return this.http.get<IInspectorPerformanceRatingData>(
      `${apiUrl}/workRequests/inspectorPerformanceRatings/${workRequestId}`
    );
  }

  public updateWorkRequest(workRequestData: IWorkRequestData): Observable<any> {
    return this.http.put<IWorkRequestData>(
      `${apiUrl}/workRequests`,
      workRequestData
    );
  }

  public updateInspectorPerformanceRating(
    inspectorPerformanceRatingData: IInspectorPerformanceRatingData
  ): Observable<any> {
    return this.http.put<IInspectorPerformanceRatingData>(
      `${apiUrl}/workRequests/inspectorPerformanceRatings`,
      inspectorPerformanceRatingData
    );
  }

  public getWorkRequestRFQsByWorkRequestId(
    workRequestId: number
  ): Observable<IWorkRequestRFQData[]> {
    return this.http.get<IWorkRequestRFQData[]>(
      `${apiUrl}/workRequests/RFQs/${workRequestId}`
    );
  }

  public getWorkRequestActivitiesByWorkRequestId(
    workRequestId: number
  ): Observable<IWorkRequestActivityData[]> {
    return this.http.get<IWorkRequestActivityData[]>(
      `${apiUrl}/workRequests/activities/${workRequestId}`
    );
  }

  public getWorkRequestRevisionDetails(
    workRequestId: number
  ): Observable<IWorkRequestRevisionData[]> {
    return this.http.get<IWorkRequestRevisionData[]>(
      `${apiUrl}/workRequests/revisions/${workRequestId}`
    );
  }

  public getWorkRequestIRDataByWorkRequestId(
    workRequestId: number
  ): Observable<IWorkRequestIRData[]> {
    return this.http.get<IWorkRequestIRData[]>(
      `${apiUrl}/workRequests/inspectionReports/${workRequestId}`
    );
  }

  public getWorkRequestDocumentsByWorkRequestId(
    workRequestId: number
  ): Observable<IWorkRequestDocumentData[]> {
    // return this.http.get<IWorkRequestDocumentData[]>(
    //   `${apiUrl}/workRequests/documents/${workRequestId}`
    // );
    let emptyDocuments: IWorkRequestDocumentData[];
    return of(emptyDocuments);
  }

  public addWorkRequestRFQ(
    rfqData: IWorkRequestRFQData
  ): Observable<IWorkRequestRFQData> {
    return this.http.post<IWorkRequestRFQData>(
      `${apiUrl}/workRequests/RFQs`,
      rfqData
    );
  }

  public updateWorkRequestRFQ(
    rfqData: IWorkRequestRFQData
  ): Observable<IWorkRequestRFQData> {
    return this.http.put<IWorkRequestRFQData>(
      `${apiUrl}/workRequests/RFQs`,
      rfqData
    );
  }

  public getWorkRequestRFQById(rfqId: string): Observable<IWorkRequestRFQData> {
    return this.http.get<IWorkRequestRFQData>(
      `${apiUrl}/workRequests/RFQs/RFQ/${rfqId}`
    );
  }

  public getWorkRequestCommodities(
    vendorId: number
  ): Observable<ICommodityDetail[]> {
    return this.http.get<ICommodityDetail[]>(
      `${apiUrl}/workRequests/commodities/${vendorId}`
    );
  }

  public addWorkRequestCommodities(
    workRequestId: number,
    commodities: ICommodityDetail[]
  ): Observable<ICommodityDetail[]> {
    return this.http.post<ICommodityDetail[]>(
      `${apiUrl}/workRequests/commodities/${workRequestId}`,
      commodities
    );
  }

  public updateWorkRequestRevisionDetails(
    workRequestId: number,
    revisions: IWorkRequestRevisionData[]
  ): Observable<ISingleWorkRequestRevisionData[]> {
    return this.http.put<ISingleWorkRequestRevisionData[]>(
      `${apiUrl}/workRequests/revisions/${workRequestId}`,
      revisions
    );
  }

  public addWorkRequestRevisionDetails(
    workRequestId: number,
    revisions: IWorkRequestRevisionData[]
  ): Observable<ISingleWorkRequestRevisionData[]> {
    return this.http.post<ISingleWorkRequestRevisionData[]>(
      `${apiUrl}/workRequests/revisions/${workRequestId}`,
      revisions
    );
  }

  public addWorkRequestActivity(
    activityDate: IWorkRequestActivityData
  ): Observable<IWorkRequestActivityData> {
    return this.http.post<IWorkRequestActivityData>(
      `${apiUrl}/workRequests/activities`,
      activityDate
    );
  }

  public getRevisionsExist(workRequestId: number): Observable<boolean> {
    return this.http.get<boolean>(
      `${apiUrl}/workRequests/revisions/exist/${workRequestId}`
    );
  }

  public getIContractReport(workRequestId: number): Observable<Blob> {
    return this.http.get(
      `${apiUrl}/workRequests/iContractReport/${workRequestId}`,
      { responseType: "blob" }
    );
  }

  public getSummaryReport(workRequestId: number): Observable<Blob> {
    return this.http.get(
      `${apiUrl}/workRequests/summaryReport/${workRequestId}`,
      { responseType: "blob" }
    );
  }

  public getDetailsReport(workRequestId: number): Observable<Blob> {
    return this.http.get(
      `${apiUrl}/workRequests/detailsReport/${workRequestId}`,
      { responseType: "blob" }
    );
  }
}
