import {
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
  ViewChild,
} from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { BusOpcosDataService } from "../../services/administration/bus-opcos-data-service";
import { PurchaserManagementService } from "../../services/administration/purchaser-management.service";
import { CapitalProjectsDataService } from "../../services/capital-projects.service";
import { ICapitalProjectUsersData } from "../../interfaces/administration/capitalProjectUsersData";
import { MatTableDataSource } from "@angular/material/table";
import { MatPaginator } from "@angular/material/paginator";
import { TableSortService } from "../../services/table-sort.service";
import { Sort } from "@angular/material/sort";
import { UserRequestsDataService } from "../../services/administration/user-requests.service";
import { IUserData } from "../../interfaces/administration/userData";
import { GraphService } from "../../services/administration/graph.service";
import { IViewPerformanceTrackingData } from "../../interfaces/vendor-information/viewPerformanceTrackingData";
import { PerformanceTrackingService } from "../../services/vendor-information/performance-tracking.service";
import { BaseComponent } from "../../shared/base/base.component";
import { UserPermissionsService } from "../../services/common/user-permissions.service";

@Component({
  selector: "performance-tracking-component",
  templateUrl: "./performance-tracking.component.html",
  styles: [
    `
      .button-container {
        display: flex !important;
        justify-content: space-between !important;
      }
    `,
  ],
})
export class PerformanceTrackingComponent
  extends BaseComponent
  implements OnInit
{
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @Input() projectId: number = 0;

  public title = "Create new project";
  public dataSource: MatTableDataSource<IViewPerformanceTrackingData>;
  public originalDataSource: MatTableDataSource<IViewPerformanceTrackingData>;
  public dataSourceLoading: boolean = false;
  public dataUserLoading: boolean = false;
  public displayedColumns: string[] = [
    "actions",
    "commodityCodeTrimmed",
    "commodityDescriptionPath",
    "povalue",
    "vendorName",
    "eng",
    "pro",
    "mfg",
    "con",
  ];

  public users: IUserData[];
  public selectedUser: number = 0;

  constructor(
    private tableSortService: TableSortService,
    private capitalProjectsDataService: CapitalProjectsDataService,
    private performanceTrackingService: PerformanceTrackingService,
    private changeDetectorRefs: ChangeDetectorRef,
    private router: Router,
    userPermissionService: UserPermissionsService
  ) {
    super(userPermissionService);
  }

  loadData() {
    this.capitalProjectsDataService
      .loadPerformanceTrackingForProject(this.projectId)
      .subscribe((data) => {
        this.dataSourceLoading = true;

        this.originalDataSource =
          new MatTableDataSource<IViewPerformanceTrackingData>(data);
        this.dataSource = new MatTableDataSource<IViewPerformanceTrackingData>(
          this.originalDataSource.data
        );

        this.dataSourceLoading = false;
        this.dataSource.paginator = this.paginator;
        this.paginator.pageSize = 10;
        this.changeDetectorRefs.detectChanges();
      });
  }

  sortChange(event: Sort) {
    this.dataSource.data = this.tableSortService.sortDataSource(
      this.originalDataSource.data,
      event
    );
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.dataSourceLoading = true;
    this.dataSource = new MatTableDataSource<IViewPerformanceTrackingData>();
    this.originalDataSource =
      new MatTableDataSource<IViewPerformanceTrackingData>();

    this.loadData();
  }

  editPerformanceTracking(data: IViewPerformanceTrackingData) {
    this.router.navigate([
      "/vendor-information/performance-tracking/edit-spt",
      data.performanceTrackingId,
    ]);
  }

  onCreate() {
    this.router.navigate([
      "/vendor-information/performance-tracking/create-spt",
      this.projectId,
    ]);
  }
}
