import { Component, OnInit } from "@angular/core";
import {
  FormGroup,
  FormBuilder,
  Validators,
  FormControl,
} from "@angular/forms";
import { Router } from "@angular/router";
import { ILookupData } from "../../interfaces/administration/lookupData";
import { ICurrencyData } from "../../interfaces/qa-management/currencyData";
import { LoggedUserService } from "../../services/common/logged-user.service";
import { AgenciesAndInspectorsDataService } from "../../services/qa-management/agencies-inspectors.service";
import { IAgencyData } from "../../interfaces/qa-management/agencyData";
import { IVendorInformationData } from "../../interfaces/vendor-information/vendorInformationData";
import { ICapitalProjectData } from "../../interfaces/administration/capitalProjectData";
import { IBuyerEngineerQaeDetailViewData } from "../../interfaces/qa-management/buyerEngineerQaeDetailViewData";
import { IInspectorViewData } from "../../interfaces/qa-management/inspectorViewData";
import { CapitalProjectsDataService } from "../../services/capital-projects.service";
import { QaqcbuyerEngineerQaeService } from "../../services/qaqc-buyer-engineer-qae.service";
import { BusOpcosDataService } from "../../services/administration/bus-opcos-data-service";
import { VendorInformationDataService } from "../../services/vendor-information-data.service";
import { WorkRequestsDataService } from "../../services/qa-management/work-requests/work-requests.service";
import { IWorkRequestData } from "../../interfaces/qa-management/work-requests/workRequestData";
import { ILoggedUserData } from "../../interfaces/common/loggedUserData";
import { CurrenciesDataService } from "../../services/qa-management/currencies.service";
import { IInspectorPerformanceRatingData } from "../../interfaces/qa-management/work-requests/inspectorPerformanceRatingData";
import { ICommodityDetail } from "../../interfaces/qa-management/work-requests/workRequestDetailsData";
import { catchError, forkJoin, of } from "rxjs";
import { UserRequestsDataService } from "../../services/administration/user-requests.service";
import { IWorkRequestRevisionData } from "../../interfaces/qa-management/work-requests/workRequestRevisionData";
import { BaseComponent } from "../../shared/base/base.component";
import { UserPermissionsService } from "../../services/common/user-permissions.service";

@Component({
  selector: "add-work-request-form.component",
  templateUrl: "./add-work-request-form.component.html",
  styleUrls: ["./add-work-request-form.component.css"],
})
export class AddWorkRequestFormComponent
  extends BaseComponent
  implements OnInit
{
  addForm: FormGroup;
  activeTab: string = "general"; // Default to the first tab
  validationMessages = [];
  agencyName: string = "";
  countryName: string = "";
  stateName: string = "";
  showRates: boolean = false;
  public countriesData: ILookupData[] = [];
  public statesData: ILookupData[] = [];
  public manufacturersData: IVendorInformationData[] = [];
  public businessUnitsData: ILookupData[] = [];
  public capitalProjectsData: ICapitalProjectData[] = [];
  public qaesData: IBuyerEngineerQaeDetailViewData[] = [];
  public engineersData: IBuyerEngineerQaeDetailViewData[] = [];
  public buyersData: IBuyerEngineerQaeDetailViewData[] = [];
  public inspectorsData: IInspectorViewData[] = [];
  public expeditorsData: IInspectorViewData[] = [];
  public agenciesData: IAgencyData[] = [];
  currenciesData: ICurrencyData[] = [];
  commoditiesData: ICommodityDetail[] = [];
  userFullName: string = "";
  loggedUserData: ILoggedUserData;

  criticalities = [
    { value: 0, viewValue: "-- SELECT ONE --" },
    { value: 1, viewValue: "1" },
    { value: 2, viewValue: "2" },
    { value: 3, viewValue: "3" },
    { value: 4, viewValue: "4" },
    { value: 5, viewValue: "TBD" },
  ];

  displayedColumns: string[] = ["select", "vendorCommodity", "criticality"];

  inspectorRatingValue: number = null;
  expeditorRatingValue: number = null;
  agencyRatingValue: number = null;
  selectedInspectorId: string = "";
  selectedAgencyId: string = "";
  selectedExpeditorId: string = "";
  showRatingDefs: boolean = false;

  justificationMapping = [
    { id: 1, name: "Alliance Partner" },
    { id: 2, name: "Availability" },
    { id: 3, name: "Availability For Residency" },
    { id: 4, name: "Development" },
    { id: 5, name: "Distribution Of Business" },
    { id: 6, name: "Lowest Projected Cost" },
    { id: 7, name: "Specialized Expertise" },
  ];

  statusMapping = [
    { id: 1, name: "Assigned" },
    { id: 2, name: "Canceled" },
    { id: 3, name: "Final" },
    { id: 4, name: "Inactive" },
    { id: 5, name: "Unassigned" },
  ];

  ratings = [1, 2, 3, 4, 5];

  constructor(
    private fb: FormBuilder,
    private loggedUserService: LoggedUserService,
    private agenciesAndInspectorsDataService: AgenciesAndInspectorsDataService,
    private capitalProjectsService: CapitalProjectsDataService,
    private buyerEngineerQaeService: QaqcbuyerEngineerQaeService,
    private busOpcosDataService: BusOpcosDataService,
    private vendorInformationDataService: VendorInformationDataService,
    private workRequestsService: WorkRequestsDataService,
    private currencyDataService: CurrenciesDataService,
    private userRequestsService: UserRequestsDataService,
    private router: Router,
    userPermissionsService: UserPermissionsService
  ) {
    super(userPermissionsService);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.loadQaes();
    this.loadManufacturers();
    this.loadEngineers();
    this.loadBuyers();
    this.loadCapitalProjects();
    this.loadInspectorsAndExpeditors();
    this.loadAgencies();
    this.loadCurrencies();

    this.loggedUserService
      .getLoggedUserData()
      .subscribe((loggedUser: ILoggedUserData | null) => {
        if (loggedUser) {
          this.loggedUserData = loggedUser;
          this.userRequestsService
            .getUserFullNameById(loggedUser.userId)
            .subscribe((fullName) => {
              this.userFullName = fullName;
            });
        }
      });

    this.addForm = this.fb.group({
      manufacturer: ["manufacturerDefault", Validators.required],
      description: [""],
      qae: ["qaeDefault", Validators.required],
      vendorReference: [""],
      poNumber: [""],
      engineer: ["engineerDefault", Validators.required],
      originalIssuanceDate: [null],
      buyer: ["buyerDefault", Validators.required],
      capitalProject: ["capitalProjectDefault", Validators.required],
      poRequiredDate: [null],
      agencySRnumber: [""],
      currentBudget: [null],
      poValue: [null],
      finalCosts: [null],
      currentIssuanceDate: [null],
      status: ["statusDefault", Validators.required],
      closeoutDate: [null],
      etcChargeCode: [""],
      clientCostCenter: [""],
      originalBudget: [null],
      mfrPocName: [""],
      mfrPocPhone: [""],
      mfrPocEmail: [""],
      workScope: [""],
      comments: [""],
      inspector: ["inspectorDefault", Validators.required],
      expeditor: ["expeditorDefault", Validators.required],
      agency: ["agencyDefault", Validators.required],
      justification: ["justificationDefault", Validators.required],
      specialRates: [false],
      currency: ["currencyDefault", Validators.required],
      inspectionRate: [null],
      travelRate: [null],
      reportRate: [null],
      mileageRate: [null],
      overtimeRate: [null],
      ratingWorkRequestKnowledge: [
        "ratingWorkRequestKnowledgeDefault",
        Validators.required,
      ],
      ratingOnTheJobDependability: [
        "ratingOnTheJobDependabilityDefault",
        Validators.required,
      ],
      ratingThoroughnessOfInspection: [
        "ratingThoroughnessOfInspectionDefault",
        Validators.required,
      ],
      ratingPromptnessCompletenessOfReports: [
        "ratingPromptnessCompletenessOfReportsDefault",
        Validators.required,
      ],
      ratingWorkingRelationships: [
        "ratingWorkingRelationshipsDefault",
        Validators.required,
      ],
      ratingConscientiousAboutCosts: [
        "ratingConscientiousAboutCostsDefault",
        Validators.required,
      ],
      ratingExpeditor: ["ratingExpeditorDefault", Validators.required],
      ratingAgency: ["ratingAgencyDefault", Validators.required],
      ratingInspectorComments: [""],
      ratingAgencyComments: [""],
    });
  }

  loadCommodities(vendorId: number) {
    this.workRequestsService
      .getWorkRequestCommodities(vendorId)
      .subscribe((data) => {
        const previousCommoditiesData: ICommodityDetail[] =
          this.commoditiesData;
        this.commoditiesData = data;

        this.removePreviousCommodityControls(previousCommoditiesData);
        // Initialize dynamic commodity form controls
        this.initializeCommodityControls();
      });
  }

  initializeCommodityControls(): void {
    this.commoditiesData.forEach((commodity) => {
      this.addForm.addControl(
        commodity.commodityId.toString(),
        new FormControl(commodity.criticality)
      );
    });
  }

  removePreviousCommodityControls(previousCommoditiesData: ICommodityDetail[]) {
    previousCommoditiesData.forEach((commodity) => {
      this.addForm.removeControl(commodity.commodityId.toString());
    });
  }

  onManufacturerChange(event: Event): void {
    const selectElement = event.target as HTMLSelectElement;
    if (selectElement.value != "manufacturerDefault") {
      const vendorId = Number(selectElement.value);
      if (vendorId) {
        this.loadCommodities(vendorId);
      }
    } else {
      this.removePreviousCommodityControls(this.commoditiesData);
      this.commoditiesData = [];
    }
  }

  onCommoditySelect(commodity: ICommodityDetail): void {
    commodity.checked = !commodity.checked;
  }

  setCriticality(element: ICommodityDetail, value: number): void {
    element.criticality = value;
  }

  toggleRatingDefs(): void {
    this.showRatingDefs = !this.showRatingDefs;
  }

  onAgencyChange(agencyId: string) {
    this.selectedAgencyId = agencyId;
  }

  onExpeditorChange(expeditorId: string) {
    this.selectedExpeditorId = expeditorId;
  }

  onRatingChange() {
    this.updateRatings();
  }

  onInspectorChange(inspectorId: string) {
    this.selectedInspectorId = inspectorId;

    if (inspectorId !== "inspectorDefault") {
      this.agenciesAndInspectorsDataService
        .getInspectorById(inspectorId)
        .subscribe((inspectorData) => {
          this.addForm.get("agency").setValue(inspectorData.agencyId);

          if (inspectorData.inspectionRate > 0)
            this.addForm
              .get("inspectionRate")
              .setValue(inspectorData.inspectionRate);
          else this.addForm.get("inspectionRate").setValue(null);

          if (inspectorData.travelRate > 0)
            this.addForm.get("travelRate").setValue(inspectorData.travelRate);
          else this.addForm.get("travelRate").setValue(null);

          if (inspectorData.reportRate > 0)
            this.addForm.get("reportRate").setValue(inspectorData.reportRate);
          else this.addForm.get("reportRate").setValue(null);

          if (inspectorData.mileageRate > 0)
            this.addForm.get("mileageRate").setValue(inspectorData.mileageRate);
          else this.addForm.get("mileageRate").setValue(null);

          if (inspectorData.overtimeRate > 0)
            this.addForm
              .get("overtimeRate")
              .setValue(inspectorData.overtimeRate);
          else this.addForm.get("overtimeRate").setValue(null);

          if (inspectorData.currency && inspectorData.currency.trim() !== "") {
            this.addForm.get("currency").setValue(inspectorData.currency);
          }

          this.updateRatings();
        });
    } else {
      this.addForm.get("specialRates").setValue(false);
    }
  }

  updateRatings(): void {
    let inspectorRating: number = 0;

    let formRatingWorkRequestKnowledge: string = this.addForm.get(
      "ratingWorkRequestKnowledge"
    ).value;
    if (formRatingWorkRequestKnowledge !== "ratingWorkRequestKnowledgeDefault")
      inspectorRating += Number(
        this.addForm.get("ratingWorkRequestKnowledge").value
      );

    let formRatingOnTheJobDependability: string = this.addForm.get(
      "ratingOnTheJobDependability"
    ).value;
    if (
      formRatingOnTheJobDependability !== "ratingOnTheJobDependabilityDefault"
    )
      inspectorRating += Number(
        this.addForm.get("ratingOnTheJobDependability").value
      );

    let formRatingThoroughnessOfInspection: string = this.addForm.get(
      "ratingThoroughnessOfInspection"
    ).value;
    if (
      formRatingThoroughnessOfInspection !==
      "ratingThoroughnessOfInspectionDefault"
    )
      inspectorRating += Number(
        this.addForm.get("ratingThoroughnessOfInspection").value
      );

    let formRatingPromptnessCompletenessOfReports: string = this.addForm.get(
      "ratingPromptnessCompletenessOfReports"
    ).value;
    if (
      formRatingPromptnessCompletenessOfReports !==
      "ratingPromptnessCompletenessOfReportsDefault"
    )
      inspectorRating += Number(
        this.addForm.get("ratingPromptnessCompletenessOfReports").value
      );

    let formRatingWorkingRelationships: string = this.addForm.get(
      "ratingWorkingRelationships"
    ).value;
    if (formRatingWorkingRelationships !== "ratingWorkingRelationshipsDefault")
      inspectorRating += Number(
        this.addForm.get("ratingWorkingRelationships").value
      );

    let formRatingConscientiousAboutCosts: string = this.addForm.get(
      "ratingConscientiousAboutCosts"
    ).value;
    if (
      formRatingConscientiousAboutCosts !==
      "ratingConscientiousAboutCostsDefault"
    )
      inspectorRating += Number(
        this.addForm.get("ratingConscientiousAboutCosts").value
      );

    if (inspectorRating > 0) {
      inspectorRating /= 6; // divide by number of KPIs for inspector rating
      this.inspectorRatingValue = inspectorRating; // Set the value to the property
    } else {
      this.inspectorRatingValue = null;
    }

    this.expeditorRatingValue = null;
    let formRatingExpeditor: string = this.addForm.get("ratingExpeditor").value;
    if (formRatingExpeditor !== "ratingExpeditorDefault")
      this.expeditorRatingValue = Number(
        this.addForm.get("ratingExpeditor").value
      );

    this.agencyRatingValue = null;
    let formRatingAgency: string = this.addForm.get("ratingAgency").value;
    if (formRatingAgency !== "ratingAgencyDefault")
      this.agencyRatingValue = Number(this.addForm.get("ratingAgency").value);
  }

  onSpecialRatesChange(event: Event): void {
    let formInspector: string = this.addForm.get("inspector").value;
    if (formInspector !== "inspectorDefault") {
      this.agenciesAndInspectorsDataService
        .getInspectorById(formInspector)
        .subscribe((inspectorData) => {
          const inputCheckboxShowRates = event.target as HTMLInputElement;
          this.showRates = inputCheckboxShowRates.checked;

          if (inspectorData.inspectionRate > 0)
            this.addForm
              .get("inspectionRate")
              .setValue(inspectorData.inspectionRate);
          else this.addForm.get("inspectionRate").setValue(null);

          if (inspectorData.travelRate > 0)
            this.addForm.get("travelRate").setValue(inspectorData.travelRate);
          else this.addForm.get("travelRate").setValue(null);

          if (inspectorData.reportRate > 0)
            this.addForm.get("reportRate").setValue(inspectorData.reportRate);
          else this.addForm.get("reportRate").setValue(null);

          if (inspectorData.mileageRate > 0)
            this.addForm.get("mileageRate").setValue(inspectorData.mileageRate);
          else this.addForm.get("mileageRate").setValue(null);

          if (inspectorData.overtimeRate > 0)
            this.addForm
              .get("overtimeRate")
              .setValue(inspectorData.overtimeRate);
          else this.addForm.get("overtimeRate").setValue(null);

          if (inspectorData.currency && inspectorData.currency.trim() !== "") {
            this.addForm.get("currency").setValue(inspectorData.currency);
          }
        });
    }
  }

  loadBusinessUnits() {
    this.busOpcosDataService.getActiveBUs().subscribe((data) => {
      this.businessUnitsData = data;
    });
  }

  loadCapitalProjects() {
    this.capitalProjectsService.getAllProjects().subscribe((data) => {
      this.capitalProjectsData = data;
    });
  }

  loadQaes() {
    this.buyerEngineerQaeService.getActiveQAEs().subscribe((data) => {
      this.qaesData = data;
    });
  }

  loadEngineers() {
    this.buyerEngineerQaeService.getActiveEngineers().subscribe((data) => {
      this.engineersData = data;
    });
  }

  loadBuyers() {
    this.buyerEngineerQaeService.getActiveBuyers().subscribe((data) => {
      this.buyersData = data;
    });
  }

  loadManufacturers() {
    this.vendorInformationDataService
      .getAllManufacturerVendors()
      .subscribe((data) => {
        this.manufacturersData = data;
      });
  }

  loadAgencies() {
    this.agenciesAndInspectorsDataService.getAllAgencies().subscribe((data) => {
      this.agenciesData = data;
    });
  }

  loadInspectorsAndExpeditors() {
    this.agenciesAndInspectorsDataService
      .getAllInspectors()
      .subscribe((data) => {
        this.inspectorsData = data;
        this.expeditorsData = data;
      });
  }

  loadCurrencies() {
    this.currencyDataService.getCurrencies().subscribe((data) => {
      this.currenciesData = data;
    });
  }

  backToWorkRequestsView(): void {
    this.router.navigate(["/qa-management/work-requests"]);
  }

  submitForm(): void {
    let messagesValidationsList: string[] = [];
    let formManufacturer: string = this.addForm.get("manufacturer").value;
    let formDescription: string = this.addForm.get("description").value;
    let formQae: string = this.addForm.get("qae").value;
    let formVendorReference: string = this.addForm.get("vendorReference").value;
    let formPoNumber: string = this.addForm.get("poNumber").value;
    let formEngineer: string = this.addForm.get("engineer").value;
    let formOriginalIssuanceDate: Date = this.addForm.get(
      "originalIssuanceDate"
    ).value;
    let formBuyer: string = this.addForm.get("buyer").value;
    let formCapitalProject: string = this.addForm.get("capitalProject").value;
    let formPoRequiredDate: Date = this.addForm.get("poRequiredDate").value;
    let formAgencySRnumber: string = this.addForm.get("agencySRnumber").value;
    let formCurrentBudget: number = this.addForm.get("currentBudget").value;
    let formPoValue: number = this.addForm.get("poValue").value;
    let formFinalCosts: number = this.addForm.get("finalCosts").value;
    let formCurrentIssuanceDate: Date = this.addForm.get(
      "currentIssuanceDate"
    ).value;
    let formStatus: string = this.addForm.get("status").value;
    let formCloseoutDate: Date = this.addForm.get("closeoutDate").value;
    let formEtcChargeCode: string = this.addForm.get("etcChargeCode").value;
    let formClientCostCenter: string =
      this.addForm.get("clientCostCenter").value;
    let formOriginalBudget: number = this.addForm.get("originalBudget").value;
    let formMfrPocName: string = this.addForm.get("mfrPocName").value;
    let formMfrPocPhone: string = this.addForm.get("mfrPocPhone").value;
    let formMfrPocEmail: string = this.addForm.get("mfrPocEmail").value;
    let formWorkScope: string = this.addForm.get("workScope").value;
    let formComments: string = this.addForm.get("comments").value;

    // AGENCY/INSPECTOR TAB
    let formInspector: string = this.addForm.get("inspector").value;
    let formExpeditor: string = this.addForm.get("expeditor").value;
    let formAgency: string = this.addForm.get("agency").value;
    let formJustification: string = this.addForm.get("justification").value;
    let formSpecialRates: boolean = this.addForm.get("specialRates").value;
    let formCurrency: string = this.addForm.get("currency").value;
    let formInspectionRate: number = this.addForm.get("inspectionRate").value;
    let formTravelRate: number = this.addForm.get("travelRate").value;
    let formReportRate: number = this.addForm.get("reportRate").value;
    let formMileageRate: number = this.addForm.get("mileageRate").value;
    let formOvertimeRate: number = this.addForm.get("overtimeRate").value;

    // RATINGS TAB
    let formRatingInspectorComments: string = this.addForm.get(
      "ratingInspectorComments"
    ).value;
    let formRatingAgencyComments: string = this.addForm.get(
      "ratingAgencyComments"
    ).value;
    let formRatingWorkRequestKnowledge: string = this.addForm.get(
      "ratingWorkRequestKnowledge"
    ).value;
    let formRatingOnTheJobDependability: string = this.addForm.get(
      "ratingOnTheJobDependability"
    ).value;
    let formRatingThoroughnessOfInspection: string = this.addForm.get(
      "ratingThoroughnessOfInspection"
    ).value;
    let formRatingPromptnessCompletenessOfReports: string = this.addForm.get(
      "ratingPromptnessCompletenessOfReports"
    ).value;
    let formRatingWorkingRelationships: string = this.addForm.get(
      "ratingWorkingRelationships"
    ).value;
    let formRatingConscientiousAboutCosts: string = this.addForm.get(
      "ratingConscientiousAboutCosts"
    ).value;

    if (formDescription === "")
      messagesValidationsList.push("Work Request Description is Required.");

    if (formPoNumber === "")
      messagesValidationsList.push("PO Number is Required.");

    if (formPoRequiredDate == null || formPoRequiredDate.toString() == "") {
      messagesValidationsList.push("PO Required Date is Required.");
    }

    if (formQae === "qaeDefault") {
      messagesValidationsList.push("QAE is Required.");
    }

    if (formManufacturer === "manufacturerDefault") {
      messagesValidationsList.push("Manufacturer is Required.");
    }

    if (formCapitalProject === "capitalProjectDefault") {
      messagesValidationsList.push("Capital Project is Required.");
    }

    if (
      (formCloseoutDate !== null && formCloseoutDate.toString() != "") ||
      formStatus === "3"
    ) {
      // Logic to check commodity criticality
      let checkCriticality: boolean = false;
      for (const commodity of this.commoditiesData) {
        if (
          commodity.checked &&
          (commodity.criticality === 0 || commodity.criticality === 5)
        ) {
          checkCriticality = true;
          break;
        }
      }

      if (checkCriticality) {
        messagesValidationsList.push(
          "Selected commodity criticality to be updated when work request is to be closed."
        );
      }
    }

    this.validationMessages = messagesValidationsList;

    if (this.addForm.valid && this.validationMessages.length === 0) {
      let hasInspectorRatings: boolean = false;

      let formNewPoRequiredDate: Date = new Date(formPoRequiredDate);
      let formNewCloseoutDate: Date =
        formCloseoutDate == null ? null : new Date(formCloseoutDate);
      let workRequest: IWorkRequestData = {
        vendorId: Number(formManufacturer),
        description: formDescription,
        qaeid: Number(formQae),
        vendorRef: formVendorReference,
        po: formPoNumber,
        engineerId:
          formEngineer === "engineerDefault" ? null : Number(formEngineer),
        receivedDate: formOriginalIssuanceDate,
        buyerId: formBuyer === "buyerDefault" ? null : Number(formBuyer),
        capitalProjectId: Number(formCapitalProject),
        requiredDate: formNewPoRequiredDate,
        sapso: formAgencySRnumber,
        sapexpectedLimit: formCurrentBudget,
        poamount: formPoValue,
        etccosts: formFinalCosts,
        nextActionOn: formCurrentIssuanceDate,
        statusId: formStatus === "statusDefault" ? null : Number(formStatus),
        closedDate: formNewCloseoutDate,
        workRequestCostCenter: formEtcChargeCode,
        clientCostCenter: formClientCostCenter,
        clientBudget: formOriginalBudget,
        mfrPocname: formMfrPocName,
        mfrPocphone: formMfrPocPhone,
        mfrPocemail: formMfrPocEmail,
        workScope: formWorkScope,
        comments: formComments,
        updatedOn: new Date(),
        insertedOn: new Date(),
        commodityId: 0,
        performanceTrackingId: 0,
        inspectorId:
          formInspector === "inspectorDefault" ? null : Number(formInspector),
        expeditorId:
          formExpeditor === "expeditorDefault" ? null : Number(formExpeditor),
        agencyId: formAgency === "agencyDefault" ? null : Number(formAgency),
        justificationId:
          formJustification === "justificationDefault"
            ? null
            : Number(formJustification),
        inspectorRatingComments: formRatingInspectorComments,
        agencyRatingComments: formRatingAgencyComments,
        inspectorRating: this.inspectorRatingValue,
        agencyRating: this.agencyRatingValue,
        expeditorRating: this.expeditorRatingValue,
        isSpecialRatesChecked: formSpecialRates,
        inspectionRate: formInspectionRate,
        travelRate: formTravelRate,
        reportRate: formReportRate,
        mileageRate: formMileageRate,
        overtimeRate: formOvertimeRate,
        currency: formCurrency === "currencyDefault" ? "" : formCurrency,
      };

      let inspectorPerformanceRating: IInspectorPerformanceRatingData = {
        workRequestId: 0,
        workRequestKnowledge:
          formRatingWorkRequestKnowledge === "ratingWorkRequestKnowledgeDefault"
            ? null
            : Number(formRatingWorkRequestKnowledge),
        ontheJobDependability:
          formRatingOnTheJobDependability ===
          "ratingOnTheJobDependabilityDefault"
            ? null
            : Number(formRatingOnTheJobDependability),
        thoroughnessofInspection:
          formRatingThoroughnessOfInspection ===
          "ratingThoroughnessOfInspectionDefault"
            ? null
            : Number(formRatingThoroughnessOfInspection),
        promptnessofReports:
          formRatingPromptnessCompletenessOfReports ===
          "ratingPromptnessCompletenessOfReportsDefault"
            ? null
            : Number(formRatingPromptnessCompletenessOfReports),
        workingRelationships:
          formRatingWorkingRelationships === "ratingWorkingRelationshipsDefault"
            ? null
            : Number(formRatingWorkingRelationships),
        concientiousAboutCosts:
          formRatingConscientiousAboutCosts ===
          "ratingConscientiousAboutCostsDefault"
            ? null
            : Number(formRatingConscientiousAboutCosts),
        insertedOn: new Date(),
      };

      if (
        formRatingWorkRequestKnowledge !==
          "ratingWorkRequestKnowledgeDefault" ||
        formRatingOnTheJobDependability !==
          "ratingOnTheJobDependabilityDefault" ||
        formRatingThoroughnessOfInspection !==
          "ratingThoroughnessOfInspectionDefault" ||
        formRatingPromptnessCompletenessOfReports !==
          "ratingPromptnessCompletenessOfReportsDefault" ||
        formRatingWorkingRelationships !==
          "ratingWorkingRelationshipsDefault" ||
        formRatingConscientiousAboutCosts !==
          "ratingConscientiousAboutCostsDefault"
      ) {
        hasInspectorRatings = true;
      }

      if (this.loggedUserData) {
        workRequest.updatedBy = this.loggedUserData.cai;
        workRequest.insertedBy = this.loggedUserData.cai;
        this.workRequestsService.addWorkRequest(workRequest).subscribe({
          next: (createdWorkRequest: IWorkRequestData) => {
            this.createRevisionsFromScratch(
              createdWorkRequest,
              formCurrentBudget,
              formEtcChargeCode,
              formPoRequiredDate,
              formManufacturer,
              formAgency,
              formInspector,
              formQae,
              formDescription
            );

            const observables = [];

            const selectedCommodities = this.commoditiesData.filter(
              (c) => c.checked
            );
            if (selectedCommodities.length > 0) {
              observables.push(
                this.workRequestsService
                  .addWorkRequestCommodities(
                    createdWorkRequest.workRequestId,
                    selectedCommodities
                  )
                  .pipe(
                    catchError((error) => {
                      console.error(
                        "POST /workRequests/commodities Error adding commodities: " +
                          error
                      );
                      return of(null); // Continue with the next observable in case of error
                    })
                  )
              );
            }

            if (hasInspectorRatings) {
              inspectorPerformanceRating.workRequestId =
                createdWorkRequest.workRequestId;
              inspectorPerformanceRating.insertedBy =
                this.loggedUserData.userId;
              observables.push(
                this.workRequestsService
                  .addInspectorPerformanceRatings(inspectorPerformanceRating)
                  .pipe(
                    catchError((error) => {
                      console.error(
                        "POST /workRequests/inspectorPerformanceRatings" + error
                      );
                      return of(null); // Continue with the next observable in case of error
                    })
                  )
              );
            }

            forkJoin(observables).subscribe({
              next: () => {
                this.router.navigate(["/qa-management/work-requests"]);
              },
              error: (error) => {
                console.error(
                  "Error in forkJoin while adding workRequest: " + error
                );
              },
            });
          },
          error: (error) => {
            console.error("POST /workRequests" + error);
          },
        });
      } else {
        console.error(
          "Cannot fetch logged user data in add-work-request form."
        );
      }
    }
  }

  createRevisionsFromScratch(
    workRequestData: IWorkRequestData,
    formCurrentBudget: number,
    formEtcChargeCode: string,
    formPoRequiredDate: Date,
    formManufacturer: string,
    formAgency: string,
    formInspector: string,
    formQae: string,
    formDescription: string
  ) {
    const revisions: IWorkRequestRevisionData[] = [];
    let isRecordUpdated: boolean = false;

    if (formCurrentBudget !== null) {
      revisions.push(
        this.createRevisionDto(
          "Current Budget",
          workRequestData.sapexpectedLimit?.toString(),
          formCurrentBudget.toString()
        )
      );
      isRecordUpdated = true;
    }

    if (formEtcChargeCode.toString().trim() !== "") {
      revisions.push(
        this.createRevisionDto(
          "ETC Charge Code",
          workRequestData.workRequestCostCenter,
          formEtcChargeCode.toString()
        )
      );
      isRecordUpdated = true;
    }

    if (formPoRequiredDate != null && formPoRequiredDate.toString() != "") {
      revisions.push(
        this.createRevisionDto(
          "PO Required Date",
          formPoRequiredDate ? formPoRequiredDate.toString() : "",
          formPoRequiredDate ? formPoRequiredDate.toString() : ""
        )
      );
      isRecordUpdated = true;
    }

    if (formManufacturer.toString().trim() !== "manufacturerDefault") {
      revisions.push(
        this.createRevisionDto(
          "Manufacturer",
          workRequestData.vendorId?.toString(),
          formManufacturer.toString()
        )
      );
      isRecordUpdated = true;
    }

    if (formAgency.toString().trim() !== "agencyDefault") {
      revisions.push(
        this.createRevisionDto(
          "Agency",
          workRequestData.agencyId?.toString(),
          formAgency.toString()
        )
      );
      isRecordUpdated = true;
    }

    if (formInspector.toString().trim() !== "inspectorDefault") {
      revisions.push(
        this.createRevisionDto(
          "Inspector",
          workRequestData.inspectorId?.toString(),
          formInspector.toString()
        )
      );
      isRecordUpdated = true;
    }

    if (formQae.toString().trim() !== "qaeDefault") {
      revisions.push(
        this.createRevisionDto(
          "QAE",
          workRequestData.qaeid?.toString(),
          formQae.toString()
        )
      );
      isRecordUpdated = true;
    }

    if (formDescription.toString().trim() !== "") {
      revisions.push(
        this.createRevisionDto(
          "Description",
          workRequestData.description,
          formDescription.toString()
        )
      );
      isRecordUpdated = true;
    }

    if (isRecordUpdated) {
      if (this.loggedUserData && this.userFullName) {
        revisions.push(
          this.createRevisionDto("Updated By", "", this.userFullName)
        );
        revisions.push(
          this.createRevisionDto("Updated On", "", new Date().toISOString())
        );

        if (revisions.length > 0) {
          this.workRequestsService
            .addWorkRequestRevisionDetails(
              workRequestData.workRequestId,
              revisions
            )
            .subscribe({
              next: () => {},

              error: (error) => {
                console.error("POST /workRequests/revisions" + error);
              },
            });
        }
      }
    }
  }

  createRevisionDto(
    typeOfChange: string,
    oldValue: string,
    newValue: string
  ): IWorkRequestRevisionData {
    return {
      typeOfChange: typeOfChange,
      revisions: {
        OldValue: oldValue,
        NewValue: newValue,
      },
    };
  }

  setActiveTab(tabName: string): void {
    this.activeTab = tabName;
  }
}
