import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewChild,
} from "@angular/core";
import { MatTableDataSource } from "@angular/material/table";
import { TableSortService } from "projects/aqua3/src/app/services/table-sort.service";
import { MatPaginator } from "@angular/material/paginator";
import { WorkRequestsDataService } from "../../../services/qa-management/work-requests/work-requests.service";
import { Sort } from "@angular/material/sort";
import { IWorkRequestIRData } from "../../../interfaces/qa-management/work-requests/workRequestIRData";
import { Router } from "@angular/router";
import { FormGroup } from "@angular/forms";

@Component({
  selector: "inspection-reports-component",
  templateUrl: "./inspection-reports-component.html",
})
export class InspectionReportsComponent implements OnInit, OnChanges {
  @Input() workRequestId: number;
  @Input() formEditable: boolean;
  @Input() ratesForm: FormGroup;

  title = "Inspection Reports";
  dataSource: MatTableDataSource<IWorkRequestIRData>;
  originalDataSource: MatTableDataSource<IWorkRequestIRData>;

  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;

  displayedColumns = [
    "recordNo",
    "agencyName",
    "inspectorName",
    "reportInvoiceDate",
    "reportInvoiceNumber",
    "agencyJobNo",
    "calculatedCost",
    "notes",
    "createdBy",
    "createdOn",
    "updatedBy",
    "updatedOn",
  ];

  dataSourceLoading: boolean = true;

  constructor(
    private tableSortService: TableSortService,
    private workRequestsService: WorkRequestsDataService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.loadInspectionReports();
  }

  ngOnChanges(changes: SimpleChanges): void {
    // React to changes in the input property
    if (changes.workRequestId && !changes.workRequestId.isFirstChange()) {
      this.loadInspectionReports();
    }
  }

  loadInspectionReports(): void {
    if (this.workRequestId !== null && this.workRequestId !== undefined) {
      this.workRequestsService
        .getWorkRequestIRDataByWorkRequestId(this.workRequestId)
        .subscribe({
          next: (data) => {
            this.dataSourceLoading = true;

            this.originalDataSource =
              new MatTableDataSource<IWorkRequestIRData>(data);
            this.dataSource = new MatTableDataSource<IWorkRequestIRData>(
              this.originalDataSource.data
            );

            this.dataSourceLoading = false;
            // Delay the initialization of the paginator until the data is assigned
            setTimeout(() => {
              this.dataSource.paginator = this.paginator;
              this.paginator.pageSize = 10;
            });
          },
          error: (error) => {
            console.error(error);
          },
        });
    }
  }

  announceSortChange(event: Sort) {
    this.dataSource.data = this.tableSortService.sortDataSource(
      this.originalDataSource.data,
      event
    );
  }

  addInspectionReport(): void {
    this.router.navigate(
      [
        `/qa-management/work-requests/add-inspection-report/${this.workRequestId}`,
      ],
      {
        queryParams: {
          inspectionRate: this.ratesForm.get("inspectionRate").value,
          travelRate: this.ratesForm.get("travelRate").value,
          reportRate: this.ratesForm.get("reportRate").value,
          mileageRate: this.ratesForm.get("mileageRate").value,
          overtimeRate: this.ratesForm.get("overtimeRate").value,
          currency: this.ratesForm.get("currency").value,
        },
      }
    );
  }
}
