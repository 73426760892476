import { Component } from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { UploadDocumentService } from "../../../services/qa-management/upload-document.service";
import { MatTableDataSource } from "@angular/material/table";
import { IUserNotificationData } from "../../../interfaces/administration/userNotificationData";
import { GraphService } from "../../../services/administration/graph.service";
import { RoleGroupConfigurationNames } from "../../../utils/roleGroupConfigurationNames";
import { UserRequestsDataService } from "../../../services/administration/user-requests.service";
import { BaseComponent } from "../../../shared/base/base.component";
import { UserPermissionsService } from "../../../services/common/user-permissions.service";

@Component({
  selector: "settings-component",
  templateUrl: "./settings-component.html",
})
export class SettingsComponent extends BaseComponent {
  uploadForm: FormGroup;
  successMessage: string = "";
  file: File;
  dataUploadLoading: boolean = false;
  formData: FormData;
  dataSource: MatTableDataSource<IUserNotificationData>;
  originalDataSource: MatTableDataSource<IUserNotificationData>;
  infoAboutNotifs: string = "";

  displayedColumns = ["emailNotification", "lastName+firstName", "email"];

  dataSourceLoading: boolean = true;

  constructor(
    private fb: FormBuilder,
    private uploadService: UploadDocumentService,
    private graphService: GraphService,
    private userRequestsService: UserRequestsDataService,
    userPermissionsService: UserPermissionsService
  ) {
    super(userPermissionsService);

    this.formData = new FormData();
    this.uploadForm = this.fb.group({
      file: [null, Validators.required],
    });
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.loadUserNotificationData();
  }

  onFileChange(event: any) {
    const file = (event.target as HTMLInputElement).files;
    this.uploadForm.patchValue({
      file: file[0],
    });

    this.formData.append("file", file[0], file[0].name);
  }

  downloadiContract() {
    this.uploadService.getIContract().subscribe((blob) => {
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = "iContract.doc";
      a.click();
      window.URL.revokeObjectURL(url);
    });
  }

  clearFile() {
    this.uploadForm.reset();
    this.file = null;

    const fileInput = document.getElementById("file-input") as HTMLInputElement;
    if (fileInput) {
      fileInput.value = "";
    }
    this.dataUploadLoading = false;
  }

  onSubmit() {
    if (this.uploadForm.invalid) {
      return;
    }

    this.dataUploadLoading = true;

    this.uploadService.uploadIContract(this.formData).subscribe((response) => {
      if (response) {
        this.successMessage = response;
        this.clearFile();
      }
    });
  }

  loadUserNotificationData() {
    this.infoAboutNotifs = "";
    this.dataSourceLoading = true;
    this.graphService
      .getAdministratorsForEmailNotifs(
        RoleGroupConfigurationNames.CONF_GROUPROLE_SYSTEM_ADMINISTRATOR
      )
      .subscribe({
        next: (data) => {
          this.dataSource = new MatTableDataSource<IUserNotificationData>(data);

          this.dataSourceLoading = false;
        },
        error: (error) => {
          console.error("Error while fetching User Notification Data: ", error);
        },
      });
  }

  cancelNotifs() {
    this.loadUserNotificationData();
  }

  saveNotifs(): void {
    this.infoAboutNotifs = "Saving the Email Notification list...";
    this.userRequestsService
      .updateUsersEmailNotifications(this.dataSource.data)
      .subscribe({
        next: (data) => {
          this.loadUserNotificationData();
          this.infoAboutNotifs = "Email Notification list saved successfully";
        },
        error: (error) => {
          this.infoAboutNotifs = "Error in Email Notifications Update";
          console.error("Error while updating User Notification Data: ", error);
        },
      });
  }
}
