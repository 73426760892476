import { Component, OnInit, ViewChild } from "@angular/core";
import {
  FormGroup,
  FormBuilder,
  Validators,
  FormControl,
} from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { ILookupData } from "../../interfaces/administration/lookupData";
import { ICurrencyData } from "../../interfaces/qa-management/currencyData";
import { LoggedUserService } from "../../services/common/logged-user.service";
import { AgenciesAndInspectorsDataService } from "../../services/qa-management/agencies-inspectors.service";
import { IAgencyData } from "../../interfaces/qa-management/agencyData";
import { IVendorInformationData } from "../../interfaces/vendor-information/vendorInformationData";
import { ICapitalProjectData } from "../../interfaces/administration/capitalProjectData";
import { IBuyerEngineerQaeDetailViewData } from "../../interfaces/qa-management/buyerEngineerQaeDetailViewData";
import { IInspectorViewData } from "../../interfaces/qa-management/inspectorViewData";
import { CapitalProjectsDataService } from "../../services/capital-projects.service";
import { QaqcbuyerEngineerQaeService } from "../../services/qaqc-buyer-engineer-qae.service";
import { BusOpcosDataService } from "../../services/administration/bus-opcos-data-service";
import { VendorInformationDataService } from "../../services/vendor-information-data.service";
import { WorkRequestsDataService } from "../../services/qa-management/work-requests/work-requests.service";
import { IWorkRequestData } from "../../interfaces/qa-management/work-requests/workRequestData";
import { ILoggedUserData } from "../../interfaces/common/loggedUserData";
import { CurrenciesDataService } from "../../services/qa-management/currencies.service";
import { IInspectorPerformanceRatingData } from "../../interfaces/qa-management/work-requests/inspectorPerformanceRatingData";
import {
  ICommodityDetail,
  IWorkRequestDetailsData,
} from "../../interfaces/qa-management/work-requests/workRequestDetailsData";
import { LookupDataService } from "../../services/administration/lookup-data-service";
import { catchError, forkJoin, of } from "rxjs";
import { IWorkRequestRevisionData } from "../../interfaces/qa-management/work-requests/workRequestRevisionData";
import { UserRequestsDataService } from "../../services/administration/user-requests.service";
import { RevisionHistoryComponent } from "./revision-history/revision-history-component";
import { BaseComponent } from "../../shared/base/base.component";
import { UserPermissionsService } from "../../services/common/user-permissions.service";
import { InputNumberHelperService } from "../../services/common/input-number-helper.service";

@Component({
  selector: "edit-work-request-form.component",
  templateUrl: "./edit-work-request-form.component.html",
  styleUrls: ["./edit-work-request-form.component.css"],
})
export class EditWorkRequestFormComponent
  extends BaseComponent
  implements OnInit {
  @ViewChild(RevisionHistoryComponent)
  revisionHistoryComponent: RevisionHistoryComponent;
  activeTab: string = "general"; // Default to the first tab
  editForm: FormGroup;
  validationMessages = [];
  agencyName: string = "";
  countryName: string = "";
  stateName: string = "";
  showRates: boolean = false;
  lastUpdatedBy: string = "";
  workRequestData: IWorkRequestData;
  workRequestDetailsData: IWorkRequestDetailsData;
  inspectorPerformanceRatingData: IInspectorPerformanceRatingData;
  commoditiesData: ICommodityDetail[] = [];
  userFullName: string = "";
  loggedUserData: ILoggedUserData;
  isSubmitting: boolean = false;

  criticalities = [
    { value: 0, viewValue: "-- SELECT ONE --" },
    { value: 1, viewValue: "1" },
    { value: 2, viewValue: "2" },
    { value: 3, viewValue: "3" },
    { value: 4, viewValue: "4" },
    { value: 5, viewValue: "TBD" },
  ];

  displayedColumns: string[] = ["select", "vendorCommodity", "criticality"];
  displayedColumnsReadOnly: string[] = ["vendorCommodity", "criticality"];

  manufacturerDisplayName: string = "";
  qaeFullName: string = "";
  engineerFullName: string = "";
  buyerFullName: string = "";
  capitalProjectLongName: string = "";
  statusName: string = "";
  businessUnitName: string = "";
  opcoName: string = "";
  inspectorFullName: string = "";
  expeditorFullName: string = "";
  justificationName: string = "";

  public countriesData: ILookupData[] = [];
  public statesData: ILookupData[] = [];
  public manufacturersData: IVendorInformationData[] = [];
  public businessUnitsData: ILookupData[] = [];
  public capitalProjectsData: ICapitalProjectData[] = [];
  public qaesData: IBuyerEngineerQaeDetailViewData[] = [];
  public engineersData: IBuyerEngineerQaeDetailViewData[] = [];
  public buyersData: IBuyerEngineerQaeDetailViewData[] = [];
  public inspectorsData: IInspectorViewData[] = [];
  public expeditorsData: IInspectorViewData[] = [];
  public agenciesData: IAgencyData[] = [];
  currenciesData: ICurrencyData[] = [];

  inspectorRatingValue: number = null;
  expeditorRatingValue: number = null;
  agencyRatingValue: number = null;
  selectedInspectorId: string = "";
  selectedAgencyId: string = "";
  selectedExpeditorId: string = "";
  showRatingDefs: boolean = false;
  formEditable: boolean = false;
  revisionsExist: boolean = false;

  statusMapping = [
    { id: 1, name: "Assigned" },
    { id: 2, name: "Canceled" },
    { id: 3, name: "Final" },
    { id: 4, name: "Inactive" },
    { id: 5, name: "Unassigned" },
  ];

  justificationMapping = [
    { id: 1, name: "Alliance Partner" },
    { id: 2, name: "Availability" },
    { id: 3, name: "Availability For Residency" },
    { id: 4, name: "Development" },
    { id: 5, name: "Distribution Of Business" },
    { id: 6, name: "Lowest Projected Cost" },
    { id: 7, name: "Specialized Expertise" },
  ];

  ratings = [1, 2, 3, 4, 5];

  constructor(
    private fb: FormBuilder,
    private loggedUserService: LoggedUserService,
    private agenciesAndInspectorsDataService: AgenciesAndInspectorsDataService,
    private capitalProjectsService: CapitalProjectsDataService,
    private buyerEngineerQaeService: QaqcbuyerEngineerQaeService,
    private busOpcosDataService: BusOpcosDataService,
    private vendorInformationDataService: VendorInformationDataService,
    private workRequestsService: WorkRequestsDataService,
    private currencyDataService: CurrenciesDataService,
    private lookupsDataService: LookupDataService,
    private userRequestsService: UserRequestsDataService,
    private router: Router,
    private route: ActivatedRoute,
    private inputNumberHelperService: InputNumberHelperService,
    userPermissionsService: UserPermissionsService
  ) {
    super(userPermissionsService);
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.loadQaes();
    this.loadManufacturers();
    this.loadEngineers();
    this.loadBuyers();
    this.loadCapitalProjects();
    this.loadInspectorsAndExpeditors();
    this.loadAgencies();
    this.loadCurrencies();

    this.loggedUserService
      .getLoggedUserData()
      .subscribe((loggedUser: ILoggedUserData | null) => {
        if (loggedUser) {
          this.loggedUserData = loggedUser;
          this.userRequestsService
            .getUserFullNameById(loggedUser.userId)
            .subscribe((fullName) => {
              this.userFullName = fullName;
            });
        }
      });

    this.editForm = this.fb.group({
      manufacturer: [
        { value: "manufacturerDefault", disabled: !this.formEditable },
        Validators.required,
      ],
      description: [{ value: "", disabled: !this.formEditable }],
      qae: [
        { value: "qaeDefault", disabled: !this.formEditable },
        Validators.required,
      ],
      vendorReference: [{ value: "", disabled: !this.formEditable }],
      poNumber: [{ value: "", disabled: !this.formEditable }],
      engineer: [
        { value: "engineerDefault", disabled: !this.formEditable },
        Validators.required,
      ],
      originalIssuanceDate: [{ value: null, disabled: !this.formEditable }],
      buyer: [
        { value: "buyerDefault", disabled: !this.formEditable },
        Validators.required,
      ],
      capitalProject: [
        { value: "capitalProjectDefault", disabled: !this.formEditable },
        Validators.required,
      ],
      poRequiredDate: [{ value: null, disabled: !this.formEditable }],
      agencySRnumber: [{ value: "", disabled: !this.formEditable }],
      currentBudget: [{ value: null, disabled: !this.formEditable }],
      poValue: [{ value: null, disabled: !this.formEditable }],
      finalCosts: [{ value: null, disabled: !this.formEditable }],
      currentIssuanceDate: [{ value: null, disabled: !this.formEditable }],
      status: [
        { value: "statusDefault", disabled: !this.formEditable },
        Validators.required,
      ],
      closeoutDate: [{ value: null, disabled: !this.formEditable }],
      etcChargeCode: [{ value: "", disabled: !this.formEditable }],
      clientCostCenter: [{ value: "", disabled: !this.formEditable }],
      originalBudget: [{ value: null, disabled: !this.formEditable }],
      mfrPocName: [{ value: "", disabled: !this.formEditable }],
      mfrPocPhone: [{ value: "", disabled: !this.formEditable }],
      mfrPocEmail: [{ value: "", disabled: !this.formEditable }],
      workScope: [{ value: "", disabled: !this.formEditable }],
      comments: [{ value: "", disabled: !this.formEditable }],
      inspector: [
        { value: "inspectorDefault", disabled: !this.formEditable },
        Validators.required,
      ],
      expeditor: [
        { value: "expeditorDefault", disabled: !this.formEditable },
        Validators.required,
      ],
      agency: [
        { value: "agencyDefault", disabled: !this.formEditable },
        Validators.required,
      ],
      justification: [
        { value: "justificationDefault", disabled: !this.formEditable },
        Validators.required,
      ],
      specialRates: [{ value: false, disabled: !this.formEditable }],
      currency: [
        { value: "currencyDefault", disabled: !this.formEditable },
        Validators.required,
      ],
      inspectionRate: [{ value: null, disabled: !this.formEditable }],
      travelRate: [{ value: null, disabled: !this.formEditable }],
      reportRate: [{ value: null, disabled: !this.formEditable }],
      mileageRate: [{ value: null, disabled: !this.formEditable }],
      overtimeRate: [{ value: null, disabled: !this.formEditable }],
      ratingWorkRequestKnowledge: [
        {
          value: "ratingWorkRequestKnowledgenDefault",
          disabled: !this.formEditable,
        },
        Validators.required,
      ],
      ratingOnTheJobDependability: [
        {
          value: "ratingOnTheJobDependabilityDefault",
          disabled: !this.formEditable,
        },
        Validators.required,
      ],
      ratingThoroughnessOfInspection: [
        {
          value: "ratingThoroughnessOfInspectionDefault",
          disabled: !this.formEditable,
        },
        Validators.required,
      ],
      ratingPromptnessCompletenessOfReports: [
        {
          value: "ratingPromptnessCompletenessOfReportsDefault",
          disabled: !this.formEditable,
        },
        Validators.required,
      ],
      ratingWorkingRelationships: [
        {
          value: "ratingWorkingRelationshipsDefault",
          disabled: !this.formEditable,
        },
        Validators.required,
      ],
      ratingConscientiousAboutCosts: [
        {
          value: "ratingConscientiousAboutCostsDefault",
          disabled: !this.formEditable,
        },
        Validators.required,
      ],
      ratingExpeditor: [
        { value: "ratingExpeditorDefault", disabled: !this.formEditable },
        Validators.required,
      ],
      ratingAgency: [
        { value: "ratingAgencyDefault", disabled: !this.formEditable },
        Validators.required,
      ],
      ratingInspectorComments: [{ value: "", disabled: !this.formEditable }],
      ratingAgencyComments: [{ value: "", disabled: !this.formEditable }],
    });

    const id = this.route.snapshot.paramMap.get("id");
    if (id) {
      this.workRequestsService
        .getWorkRequestDetailsById(id)
        .subscribe((data) => {
          if (data) {
            this.workRequestDetailsData = data;
            this.workRequestData = this.workRequestDetailsData.workRequest;
            this.loadCommodities();
            this.fillTheReadableForm();
            this.getRevisionsExist();
          }
        });

      this.workRequestsService
        .getInspectorPerformanceRatingsByWorkRequestId(id)
        .subscribe((data) => {
          if (data) this.inspectorPerformanceRatingData = data;
          else this.inspectorPerformanceRatingData = null;
        });
    } else {
      this.router.navigate(["/qa-management/work-requests"]);
    }
  }

  initializeCommodityControls(): void {
    this.commoditiesData.forEach((commodity) => {
      this.editForm.addControl(
        commodity.commodityId.toString(),
        new FormControl({
          value: commodity.criticality,
          disabled: !this.formEditable,
        })
      );
    });
  }

  copyWorkRequest(): void {
    this.workRequestsService.copyWorkRequestData(this.workRequestData);
    this.router.navigate(["qa-management/work-requests/copy-work-request"]);
  }

  onCommoditySelect(commodity: ICommodityDetail): void {
    commodity.checked = !commodity.checked;
  }

  setCriticality(element: ICommodityDetail, value: number): void {
    element.criticality = value;
  }

  getRevisionsExist(): void {
    this.workRequestsService
      .getRevisionsExist(this.workRequestData.workRequestId)
      .subscribe((revisionsExist) => {
        if (revisionsExist) this.revisionsExist = revisionsExist;
      });
  }

  fillTheReadableForm(): void {
    if (this.workRequestData.vendorId != null)
      this.vendorInformationDataService
        .getVendorInformationById(this.workRequestData.vendorId)
        .subscribe((data) => {
          if (data) {
            this.manufacturerDisplayName = `${data.vendorName} (${data.city}, ${data.state}, ${data.country})`;
          }
        });

    if (this.workRequestData.qaeid != null && this.workRequestData.qaeid > 0)
      this.buyerEngineerQaeService
        .getQaqcbuyerEngineerQaeById(this.workRequestData.qaeid)
        .subscribe((data) => {
          if (data) {
            this.qaeFullName = `${data?.lastName}, ${data?.firstName}`;
          }
        });

    if (
      this.workRequestData.engineerId != null &&
      this.workRequestData.engineerId > 0
    )
      this.buyerEngineerQaeService
        .getQaqcbuyerEngineerQaeById(this.workRequestData.engineerId)
        .subscribe((data) => {
          if (data) {
            this.engineerFullName = `${data?.lastName}, ${data?.firstName}`;
          }
        });

    if (
      this.workRequestData.buyerId != null &&
      this.workRequestData.buyerId > 0
    )
      this.buyerEngineerQaeService
        .getQaqcbuyerEngineerQaeById(this.workRequestData.buyerId)
        .subscribe((data) => {
          if (data) {
            this.buyerFullName = `${data?.lastName}, ${data?.firstName}`;
          }
        });

    if (this.workRequestData.capitalProjectId != null)
      this.capitalProjectsService
        .getCapitalProjectById(this.workRequestData.capitalProjectId)
        .subscribe((data) => {
          if (data) {
            this.capitalProjectLongName = data?.longName;
          }
        });

    if (this.workRequestData.statusId != null)
      this.statusName = this.statusMapping.find(
        (status) => status.id === this.workRequestData.statusId
      ).name;

    if (this.workRequestData.businessUnitId != null)
      this.lookupsDataService
        .getLookupNameById(this.workRequestData.businessUnitId)
        .subscribe((data) => {
          if (data) {
            this.businessUnitName = data;
          }
        });

    if (this.workRequestData.opcoid != null)
      this.lookupsDataService
        .getLookupNameById(this.workRequestData.opcoid)
        .subscribe((data) => {
          if (data) {
            this.opcoName = data;
          }
        });

    if (this.workRequestData.inspectorId != null)
      this.agenciesAndInspectorsDataService
        .getInspectorById(this.workRequestData.inspectorId.toString())
        .subscribe((data) => {
          if (data) {
            this.inspectorFullName = `${data?.lastName}, ${data?.firstName}`;
          }
        });

    if (this.workRequestData.expeditorId != null)
      this.agenciesAndInspectorsDataService
        .getInspectorById(this.workRequestData.expeditorId.toString())
        .subscribe((data) => {
          if (data) {
            this.expeditorFullName = `${data?.lastName}, ${data?.firstName}`;
          }
        });

    if (this.workRequestData.agencyId != null)
      this.agenciesAndInspectorsDataService
        .getAgencyById(this.workRequestData.agencyId.toString())
        .subscribe((data) => {
          if (data) {
            this.agencyName = data?.name;
          }
        });

    if (
      this.workRequestData.justificationId != null &&
      this.workRequestData.justificationId > 0
    )
      this.justificationName = this.justificationMapping.find(
        (justification) =>
          justification.id === this.workRequestData.justificationId
      )?.name;

    let reformattedDate: string = this.reformatDate(
      this.workRequestData.updatedOn
    );
    this.lastUpdatedBy = `
    Last updated by [${this.workRequestData.updatedBy}] on
    ${reformattedDate}
  `;
  }

  formatDateForInput(date: Date): string {
    if (!date) return "";
    const d = new Date(date);
    const month = ("0" + (d.getMonth() + 1)).slice(-2);
    const day = ("0" + d.getDate()).slice(-2);
    const year = d.getFullYear();
    return `${year}-${month}-${day}`;
  }

  reformatDate(date: Date): string {
    let dateTyped: Date = new Date(date);
    return dateTyped.toLocaleString("en-US", {
      month: "numeric",
      day: "numeric",
      year: "numeric",
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
      hour12: true,
    });
  }

  toggleEdit(): void {
    this.formEditable = !this.formEditable;

    if (this.formEditable) {
      this.editForm.patchValue({
        manufacturer: this.workRequestData?.vendorId || "manufacturerDefault",
        description: this.workRequestData.description,
        qae: this.workRequestData?.qaeid || "qaeDefault",
        vendorReference: this.workRequestData.vendorRef,
        poNumber: this.workRequestData.po,
        engineer: this.workRequestData?.engineerId || "engineerDefault",
        originalIssuanceDate: this.formatDateForInput(
          this.workRequestData.receivedDate
        ),
        buyer: this.workRequestData?.buyerId || "buyerDefault",
        capitalProject:
          this.workRequestData?.capitalProjectId || "capitalProjectDefault",
        poRequiredDate: this.formatDateForInput(
          this.workRequestData.requiredDate
        ),
        agencySRnumber: this.workRequestData.sapso,
        currentBudget: this.workRequestData.sapexpectedLimit,
        poValue: this.workRequestData.poamount,
        finalCosts: this.workRequestData.etccosts,
        currentIssuanceDate: this.formatDateForInput(
          this.workRequestData.nextActionOn
        ),
        status:
          this.statusMapping.find((status) => status.name === this.statusName)
            ?.id || "statusDefault",
        closeoutDate: this.formatDateForInput(this.workRequestData.closedDate),
        etcChargeCode: this.workRequestData.workRequestCostCenter,
        clientCostCenter: this.workRequestData.clientCostCenter,
        originalBudget: this.workRequestData.clientBudget,
        mfrPocName: this.workRequestData.mfrPocname,
        mfrPocPhone: this.workRequestData.mfrPocphone,
        mfrPocEmail: this.workRequestData.mfrPocemail,
        workScope: this.workRequestData.workScope,
        comments: this.workRequestData.comments,
        inspector: this.workRequestData?.inspectorId || "inspectorDefault",
        expeditor: this.workRequestData?.expeditorId || "expeditorDefault",
        agency: this.workRequestData?.agencyId || "agencyDefault",
        justification:
          this.workRequestData?.justificationId || "justificationDefault",
        specialRates: this.workRequestData?.isSpecialRatesChecked || false,
        currency: this.workRequestData?.currency || "currencyDefault",
        inspectionRate: this.workRequestData?.inspectionRate || null,
        travelRate: this.workRequestData?.travelRate || null,
        reportRate: this.workRequestData?.reportRate || null,
        mileageRate: this.workRequestData?.mileageRate || null,
        overtimeRate: this.workRequestData?.overtimeRate || null,
        ratingWorkRequestKnowledge:
          this.inspectorPerformanceRatingData?.workRequestKnowledge ||
          "ratingWorkRequestKnowledgeDefault",
        ratingOnTheJobDependability:
          this.inspectorPerformanceRatingData?.ontheJobDependability ||
          "ratingOnTheJobDependabilityDefault",
        ratingThoroughnessOfInspection:
          this.inspectorPerformanceRatingData?.thoroughnessofInspection ||
          "ratingThoroughnessOfInspectionDefault",
        ratingPromptnessCompletenessOfReports:
          this.inspectorPerformanceRatingData?.promptnessofReports ||
          "ratingPromptnessCompletenessOfReportsDefault",
        ratingWorkingRelationships:
          this.inspectorPerformanceRatingData?.workingRelationships ||
          "ratingWorkingRelationshipsDefault",
        ratingConscientiousAboutCosts:
          this.inspectorPerformanceRatingData?.concientiousAboutCosts ||
          "ratingConscientiousAboutCostsDefault",
        ratingExpeditor:
          this.workRequestData?.expeditorRating || "ratingExpeditorDefault",
        ratingAgency:
          this.workRequestData?.agencyRating || "ratingAgencyDefault",
        ratingInspectorComments: this.workRequestData?.inspectorRatingComments,
        ratingAgencyComments: this.workRequestData?.agencyRatingComments,
      });

      this.agencyRatingValue = this.workRequestData?.agencyRating || null;
      this.expeditorRatingValue = this.workRequestData?.expeditorRating || null;
      this.inspectorRatingValue = this.workRequestData?.inspectorRating || null;

      Object.keys(this.editForm.controls).forEach((control) => {
        this.editForm.get(control).enable();
      });
    }
  }

  toggleRatingDefs(): void {
    this.showRatingDefs = !this.showRatingDefs;
  }

  onAgencyChange(agencyId: string) {
    this.selectedAgencyId = agencyId;
  }

  onExpeditorChange(expeditorId: string) {
    this.selectedExpeditorId = expeditorId;
  }

  onRatingChange() {
    this.updateRatings();
  }

  onInspectorChange(inspectorId: string) {
    this.selectedInspectorId = inspectorId;

    if (inspectorId !== "inspectorDefault") {
      this.agenciesAndInspectorsDataService
        .getInspectorById(inspectorId)
        .subscribe((inspectorData) => {
          this.editForm.get("agency").setValue(inspectorData.agencyId);

          if (inspectorData.inspectionRate > 0)
            this.editForm
              .get("inspectionRate")
              .setValue(inspectorData.inspectionRate);
          else this.editForm.get("inspectionRate").setValue(null);

          if (inspectorData.travelRate > 0)
            this.editForm.get("travelRate").setValue(inspectorData.travelRate);
          else this.editForm.get("travelRate").setValue(null);

          if (inspectorData.reportRate > 0)
            this.editForm.get("reportRate").setValue(inspectorData.reportRate);
          else this.editForm.get("reportRate").setValue(null);

          if (inspectorData.mileageRate > 0)
            this.editForm
              .get("mileageRate")
              .setValue(inspectorData.mileageRate);
          else this.editForm.get("mileageRate").setValue(null);

          if (inspectorData.overtimeRate > 0)
            this.editForm
              .get("overtimeRate")
              .setValue(inspectorData.overtimeRate);
          else this.editForm.get("overtimeRate").setValue(null);

          if (inspectorData.currency && inspectorData.currency.trim() !== "") {
            this.editForm.get("currency").setValue(inspectorData.currency);
          }

          this.updateRatings();
        });
    } else {
      this.editForm.get("specialRates").setValue(false);
    }
  }

  updateRatings(): void {
    let inspectorRating: number = 0;

    let formRatingWorkRequestKnowledge: string = this.editForm.get(
      "ratingWorkRequestKnowledge"
    ).value;
    if (formRatingWorkRequestKnowledge !== "ratingWorkRequestKnowledgenDefault")
      inspectorRating += Number(
        this.editForm.get("ratingWorkRequestKnowledge").value
      );

    let formRatingOnTheJobDependability: string = this.editForm.get(
      "ratingOnTheJobDependability"
    ).value;
    if (
      formRatingOnTheJobDependability !== "ratingOnTheJobDependabilityDefault"
    )
      inspectorRating += Number(
        this.editForm.get("ratingOnTheJobDependability").value
      );

    let formRatingThoroughnessOfInspection: string = this.editForm.get(
      "ratingThoroughnessOfInspection"
    ).value;
    if (
      formRatingThoroughnessOfInspection !==
      "ratingThoroughnessOfInspectionDefault"
    )
      inspectorRating += Number(
        this.editForm.get("ratingThoroughnessOfInspection").value
      );

    let formRatingPromptnessCompletenessOfReports: string = this.editForm.get(
      "ratingPromptnessCompletenessOfReports"
    ).value;
    if (
      formRatingPromptnessCompletenessOfReports !==
      "ratingPromptnessCompletenessOfReportsDefault"
    )
      inspectorRating += Number(
        this.editForm.get("ratingPromptnessCompletenessOfReports").value
      );

    let formRatingWorkingRelationships: string = this.editForm.get(
      "ratingWorkingRelationships"
    ).value;
    if (formRatingWorkingRelationships !== "ratingWorkingRelationshipsDefault")
      inspectorRating += Number(
        this.editForm.get("ratingWorkingRelationships").value
      );

    let formRatingConscientiousAboutCosts: string = this.editForm.get(
      "ratingConscientiousAboutCosts"
    ).value;
    if (
      formRatingConscientiousAboutCosts !==
      "ratingConscientiousAboutCostsDefault"
    )
      inspectorRating += Number(
        this.editForm.get("ratingConscientiousAboutCosts").value
      );

    if (inspectorRating > 0) {
      inspectorRating /= 6; // divide by number of KPIs for inspector rating
      this.inspectorRatingValue = inspectorRating; // Set the value to the property
    } else {
      this.inspectorRatingValue = null;
    }

    this.expeditorRatingValue = null;
    let formRatingExpeditor: string =
      this.editForm.get("ratingExpeditor").value;
    if (formRatingExpeditor !== "ratingExpeditorDefault")
      this.expeditorRatingValue = Number(
        this.editForm.get("ratingExpeditor").value
      );

    this.agencyRatingValue = null;
    let formRatingAgency: string = this.editForm.get("ratingAgency").value;
    if (formRatingAgency !== "ratingAgencyDefault")
      this.agencyRatingValue = Number(this.editForm.get("ratingAgency").value);
  }

  onSpecialRatesChange(event: Event): void {
    let formInspector: string = this.editForm.get("inspector").value;
    if (formInspector !== "inspectorDefault") {
      this.agenciesAndInspectorsDataService
        .getInspectorById(formInspector)
        .subscribe((inspectorData) => {
          const inputCheckboxShowRates = event.target as HTMLInputElement;
          this.showRates = inputCheckboxShowRates.checked;

          if (inspectorData.inspectionRate > 0)
            this.editForm
              .get("inspectionRate")
              .setValue(inspectorData.inspectionRate);
          else this.editForm.get("inspectionRate").setValue(null);

          if (inspectorData.travelRate > 0)
            this.editForm.get("travelRate").setValue(inspectorData.travelRate);
          else this.editForm.get("travelRate").setValue(null);

          if (inspectorData.reportRate > 0)
            this.editForm.get("reportRate").setValue(inspectorData.reportRate);
          else this.editForm.get("reportRate").setValue(null);

          if (inspectorData.mileageRate > 0)
            this.editForm
              .get("mileageRate")
              .setValue(inspectorData.mileageRate);
          else this.editForm.get("mileageRate").setValue(null);

          if (inspectorData.overtimeRate > 0)
            this.editForm
              .get("overtimeRate")
              .setValue(inspectorData.overtimeRate);
          else this.editForm.get("overtimeRate").setValue(null);

          if (inspectorData.currency && inspectorData.currency.trim() !== "") {
            this.editForm.get("currency").setValue(inspectorData.currency);
          }
        });
    }
  }

  removePreviousCommodityControls(previousCommoditiesData: ICommodityDetail[]) {
    previousCommoditiesData.forEach((commodity) => {
      this.editForm.removeControl(commodity.commodityId.toString());
    });
  }

  loadEmptyCommodities(vendorId: number) {
    this.workRequestsService
      .getWorkRequestCommodities(vendorId)
      .subscribe((data) => {
        const previousCommoditiesData: ICommodityDetail[] =
          this.commoditiesData;
        this.commoditiesData = data;

        this.removePreviousCommodityControls(previousCommoditiesData);
        // Initialize dynamic commodity form controls
        this.initializeCommodityControls();
      });
  }

  onManufacturerChange(event: Event): void {
    const selectElement = event.target as HTMLSelectElement;
    if (selectElement.value != "manufacturerDefault") {
      const vendorId = Number(selectElement.value);
      if (vendorId) {
        this.loadEmptyCommodities(vendorId);
      }
    } else {
      this.removePreviousCommodityControls(this.commoditiesData);
      this.commoditiesData = [];
    }
  }

  loadBusinessUnits() {
    this.busOpcosDataService.getActiveBUs().subscribe((data) => {
      this.businessUnitsData = data;
    });
  }

  loadCapitalProjects() {
    this.capitalProjectsService.getAllProjects().subscribe((data) => {
      this.capitalProjectsData = data;
    });
  }

  loadQaes() {
    this.buyerEngineerQaeService.getActiveQAEs().subscribe((data) => {
      this.qaesData = data;
    });
  }

  loadEngineers() {
    this.buyerEngineerQaeService.getActiveEngineers().subscribe((data) => {
      this.engineersData = data;
    });
  }

  loadBuyers() {
    this.buyerEngineerQaeService.getActiveBuyers().subscribe((data) => {
      this.buyersData = data;
    });
  }

  loadCommodities() {
    this.workRequestsService
      .getWorkRequestCommodities(this.workRequestData.vendorId)
      .subscribe((data) => {
        this.commoditiesData = data;
        this.commoditiesData = data.map((commodity: ICommodityDetail) => {
          const detail = this.workRequestDetailsData.commodityDetails.find(
            (cd) => cd.commodityId === commodity.commodityId
          );

          if (detail != null) {
            commodity.criticality = detail.criticality;
            commodity.checked = detail.checked;
          }
          return commodity;
        });

        // Initialize dynamic commodity form controls
        this.initializeCommodityControls();
      });
  }

  loadManufacturers() {
    this.vendorInformationDataService
      .getAllManufacturerVendors()
      .subscribe((data) => {
        this.manufacturersData = data;
      });
  }

  loadAgencies() {
    this.agenciesAndInspectorsDataService.getAllAgencies().subscribe((data) => {
      this.agenciesData = data;
    });
  }

  getAgencyNameForActivitiesById(): string {
    let formAgency: string = this.editForm.get("agency").value;

    const agencyId: number =
      formAgency === "agencyDefault" ? null : Number(formAgency);

    if (agencyId != null)
      return this.agenciesData.find((agency) => agency.agencyId === agencyId)
        ?.name;
    else return null;
  }

  loadInspectorsAndExpeditors() {
    this.agenciesAndInspectorsDataService
      .getAllInspectors()
      .subscribe((data) => {
        this.inspectorsData = data;
        this.expeditorsData = data;
      });
  }

  loadCurrencies() {
    this.currencyDataService.getCurrencies().subscribe((data) => {
      this.currenciesData = data;
    });
  }

  backToWorkRequestsView(): void {
    this.router.navigate(["/qa-management/work-requests"]);
  }

  submitForm(): void {
    this.isSubmitting = true;
    let messagesValidationsList: string[] = [];
    let formManufacturer: string = this.editForm.get("manufacturer").value;
    let formDescription: string = this.editForm.get("description").value;
    let formQae: string = this.editForm.get("qae").value;
    let formVendorReference: string =
      this.editForm.get("vendorReference").value;
    let formPoNumber: string = this.editForm.get("poNumber").value;
    let formEngineer: string = this.editForm.get("engineer").value;
    let formOriginalIssuanceDate: Date =
      this.editForm.get("originalIssuanceDate").value == ""
        ? null
        : this.editForm.get("originalIssuanceDate").value;
    let formBuyer: string = this.editForm.get("buyer").value;
    let formCapitalProject: string = this.editForm.get("capitalProject").value;
    let formPoRequiredDate: Date =
      this.editForm.get("poRequiredDate").value == ""
        ? null
        : this.editForm.get("poRequiredDate").value;
    let formAgencySRnumber: string = this.editForm.get("agencySRnumber").value;
    let formCurrentBudget: number = this.editForm.get("currentBudget").value;
    let formPoValue: number = this.editForm.get("poValue").value;
    let formFinalCosts: number = this.editForm.get("finalCosts").value;
    let formCurrentIssuanceDate: Date =
      this.editForm.get("currentIssuanceDate").value == ""
        ? null
        : this.editForm.get("currentIssuanceDate").value;
    let formStatus: string = this.editForm.get("status").value;
    let formCloseoutDate: Date =
      this.editForm.get("closeoutDate").value == ""
        ? null
        : this.editForm.get("closeoutDate").value;
    let formEtcChargeCode: string = this.editForm.get("etcChargeCode").value;
    let formClientCostCenter: string =
      this.editForm.get("clientCostCenter").value;
    let formOriginalBudget: number = this.editForm.get("originalBudget").value;
    let formMfrPocName: string = this.editForm.get("mfrPocName").value;
    let formMfrPocPhone: string = this.editForm.get("mfrPocPhone").value;
    let formMfrPocEmail: string = this.editForm.get("mfrPocEmail").value;
    let formWorkScope: string = this.editForm.get("workScope").value;
    let formComments: string = this.editForm.get("comments").value;

    // AGENCY/INSPECTOR TAB
    let formInspector: string = this.editForm.get("inspector").value;
    let formExpeditor: string = this.editForm.get("expeditor").value;
    let formAgency: string = this.editForm.get("agency").value;
    let formJustification: string = this.editForm.get("justification").value;
    let formSpecialRates: boolean = this.editForm.get("specialRates").value;
    let formCurrency: string = this.editForm.get("currency").value;
    let formInspectionRate: number = this.editForm.get("inspectionRate").value;
    let formTravelRate: number = this.editForm.get("travelRate").value;
    let formReportRate: number = this.editForm.get("reportRate").value;
    let formMileageRate: number = this.editForm.get("mileageRate").value;
    let formOvertimeRate: number = this.editForm.get("overtimeRate").value;

    // RATINGS TAB
    let formRatingInspectorComments: string = this.editForm.get(
      "ratingInspectorComments"
    ).value;
    let formRatingAgencyComments: string = this.editForm.get(
      "ratingAgencyComments"
    ).value;
    let formRatingWorkRequestKnowledge: string = this.editForm.get(
      "ratingWorkRequestKnowledge"
    ).value;
    let formRatingOnTheJobDependability: string = this.editForm.get(
      "ratingOnTheJobDependability"
    ).value;
    let formRatingThoroughnessOfInspection: string = this.editForm.get(
      "ratingThoroughnessOfInspection"
    ).value;
    let formRatingPromptnessCompletenessOfReports: string = this.editForm.get(
      "ratingPromptnessCompletenessOfReports"
    ).value;
    let formRatingWorkingRelationships: string = this.editForm.get(
      "ratingWorkingRelationships"
    ).value;
    let formRatingConscientiousAboutCosts: string = this.editForm.get(
      "ratingConscientiousAboutCosts"
    ).value;

    if (formDescription === "") {
      messagesValidationsList.push("Work Request Description is Required.");
      this.editForm.get("description").setErrors({ required: true });
    }

    if (formPoNumber === "") {
      messagesValidationsList.push("PO Number is Required.");
      this.editForm.get("poNumber").setErrors({ required: true });
    }

    if (formPoRequiredDate == null || formPoRequiredDate.toString() == "") {
      messagesValidationsList.push("PO Required Date is Required.");
      this.editForm.get("poRequiredDate").setErrors({ required: true });
    }

    if (formQae === "qaeDefault") {
      messagesValidationsList.push("QAE is Required.");
      this.editForm.get("qae").setErrors({ required: true });
    }

    if (formManufacturer === "manufacturerDefault") {
      messagesValidationsList.push("Manufacturer is Required.");
      this.editForm.get("manufacturer").setErrors({ required: true });
    }

    if (formCapitalProject === "capitalProjectDefault") {
      messagesValidationsList.push("Capital Project is Required.");
      this.editForm.get("capitalProject").setErrors({ required: true });
    }

    if (this.commoditiesData.filter((c) => c.checked).length === 0) {
      messagesValidationsList.push("Commodity Code is Required.");
      this.editForm.get("commodities").setErrors({ required: true });
    }

    if (
      (formCloseoutDate !== null && formCloseoutDate.toString() != "") ||
      formStatus === "3"
    ) {
      // Logic to check commodity criticality
      let checkCriticality: boolean = false;
      for (const commodity of this.commoditiesData) {
        if (
          commodity.checked &&
          (commodity.criticality === 0 || commodity.criticality === 5)
        ) {
          checkCriticality = true;
          break;
        }
      }

      if (checkCriticality) {
        messagesValidationsList.push(
          "Selected commodity criticality to be updated when work request is to be closed."
        );
      }
    }

    this.validationMessages = messagesValidationsList;

    if (this.editForm.valid && this.validationMessages.length === 0) {
      let hasInspectorRatings: boolean = false;

      const previousWorkRequestData = { ...this.workRequestData };

      let formNewPoRequiredDate: Date = new Date(formPoRequiredDate);
      let formNewCloseoutDate: Date =
        formCloseoutDate == null ? null : new Date(formCloseoutDate);

      this.workRequestData.vendorId = Number(formManufacturer);
      this.workRequestData.description = formDescription;
      this.workRequestData.qaeid = Number(formQae);
      this.workRequestData.vendorRef = formVendorReference;
      this.workRequestData.po = formPoNumber;
      this.workRequestData.engineerId =
        formEngineer === "engineerDefault" ? null : Number(formEngineer);
      this.workRequestData.receivedDate = formOriginalIssuanceDate;
      this.workRequestData.buyerId =
        formBuyer === "buyerDefault" ? null : Number(formBuyer);
      this.workRequestData.capitalProjectId = Number(formCapitalProject);
      this.workRequestData.requiredDate = formNewPoRequiredDate;
      this.workRequestData.sapso = formAgencySRnumber;
      this.workRequestData.sapexpectedLimit = formCurrentBudget;
      this.workRequestData.poamount = formPoValue;
      this.workRequestData.etccosts = formFinalCosts;
      this.workRequestData.nextActionOn = formCurrentIssuanceDate;
      this.workRequestData.statusId =
        formStatus === "statusDefault" ? null : Number(formStatus);
      this.workRequestData.closedDate = formNewCloseoutDate;
      this.workRequestData.workRequestCostCenter = formEtcChargeCode;
      this.workRequestData.clientCostCenter = formClientCostCenter;
      this.workRequestData.clientBudget = formOriginalBudget;
      this.workRequestData.mfrPocname = formMfrPocName;
      this.workRequestData.mfrPocphone = formMfrPocPhone;
      this.workRequestData.mfrPocemail = formMfrPocEmail;
      this.workRequestData.workScope = formWorkScope;
      this.workRequestData.comments = formComments;
      this.workRequestData.updatedOn = new Date();
      this.workRequestData.commodityId = 0;
      this.workRequestData.performanceTrackingId = 0;
      this.workRequestData.inspectorId =
        formInspector === "inspectorDefault" ? null : Number(formInspector);
      this.workRequestData.expeditorId =
        formExpeditor === "expeditorDefault" ? null : Number(formExpeditor);
      this.workRequestData.agencyId =
        formAgency === "agencyDefault" ? null : Number(formAgency);
      this.workRequestData.justificationId =
        formJustification === "justificationDefault"
          ? null
          : Number(formJustification);
      this.workRequestData.inspectorRatingComments =
        formRatingInspectorComments;
      this.workRequestData.agencyRatingComments = formRatingAgencyComments;
      this.workRequestData.inspectorRating = this.inspectorRatingValue;
      this.workRequestData.agencyRating = this.agencyRatingValue;
      this.workRequestData.expeditorRating = this.expeditorRatingValue;
      this.workRequestData.isSpecialRatesChecked = formSpecialRates;
      this.workRequestData.inspectionRate = formInspectionRate;
      this.workRequestData.travelRate = formTravelRate;
      this.workRequestData.reportRate = formReportRate;
      this.workRequestData.mileageRate = formMileageRate;
      this.workRequestData.overtimeRate = formOvertimeRate;
      this.workRequestData.currency =
        formCurrency === "currencyDefault" ? "" : formCurrency;

      if (
        formRatingWorkRequestKnowledge !==
        "ratingWorkRequestKnowledgeDefault" ||
        formRatingOnTheJobDependability !==
        "ratingOnTheJobDependabilityDefault" ||
        formRatingThoroughnessOfInspection !==
        "ratingThoroughnessOfInspectionDefault" ||
        formRatingPromptnessCompletenessOfReports !==
        "ratingPromptnessCompletenessOfReportsDefault" ||
        formRatingWorkingRelationships !==
        "ratingWorkingRelationshipsDefault" ||
        formRatingConscientiousAboutCosts !==
        "ratingConscientiousAboutCostsDefault"
      ) {
        hasInspectorRatings = true;
      }

      let addInspectorPerformanceRating: boolean = false;
      if (hasInspectorRatings) {
        if (this.inspectorPerformanceRatingData === null)
          addInspectorPerformanceRating = true;
        else addInspectorPerformanceRating = false;
      }

      if (hasInspectorRatings) {
        if (addInspectorPerformanceRating) {
          this.inspectorPerformanceRatingData = {
            workRequestId: this.workRequestData?.workRequestId,
            workRequestKnowledge:
              formRatingWorkRequestKnowledge ===
                "ratingWorkRequestKnowledgenDefault"
                ? null
                : Number(formRatingWorkRequestKnowledge),
            ontheJobDependability:
              formRatingOnTheJobDependability ===
                "ratingOnTheJobDependabilityDefault"
                ? null
                : Number(formRatingOnTheJobDependability),
            thoroughnessofInspection:
              formRatingThoroughnessOfInspection ===
                "ratingThoroughnessOfInspectionDefault"
                ? null
                : Number(formRatingThoroughnessOfInspection),
            promptnessofReports:
              formRatingPromptnessCompletenessOfReports ===
                "ratingPromptnessCompletenessOfReportsDefault"
                ? null
                : Number(formRatingPromptnessCompletenessOfReports),
            workingRelationships:
              formRatingWorkingRelationships ===
                "ratingWorkingRelationshipsDefault"
                ? null
                : Number(formRatingWorkingRelationships),
            concientiousAboutCosts:
              formRatingConscientiousAboutCosts ===
                "ratingConscientiousAboutCostsDefault"
                ? null
                : Number(formRatingConscientiousAboutCosts),
            insertedOn: new Date(),
          };
        } else {
          this.inspectorPerformanceRatingData.workRequestKnowledge =
            formRatingWorkRequestKnowledge ===
              "ratingWorkRequestKnowledgenDefault"
              ? null
              : Number(formRatingWorkRequestKnowledge);
          this.inspectorPerformanceRatingData.ontheJobDependability =
            formRatingOnTheJobDependability ===
              "ratingOnTheJobDependabilityDefault"
              ? null
              : Number(formRatingOnTheJobDependability);
          this.inspectorPerformanceRatingData.thoroughnessofInspection =
            formRatingThoroughnessOfInspection ===
              "ratingThoroughnessOfInspectionDefault"
              ? null
              : Number(formRatingThoroughnessOfInspection);
          this.inspectorPerformanceRatingData.promptnessofReports =
            formRatingPromptnessCompletenessOfReports ===
              "ratingPromptnessCompletenessOfReportsDefault"
              ? null
              : Number(formRatingPromptnessCompletenessOfReports);
          this.inspectorPerformanceRatingData.workingRelationships =
            formRatingWorkingRelationships ===
              "ratingWorkingRelationshipsDefault"
              ? null
              : Number(formRatingWorkingRelationships);
          this.inspectorPerformanceRatingData.concientiousAboutCosts =
            formRatingConscientiousAboutCosts ===
              "ratingConscientiousAboutCostsDefault"
              ? null
              : Number(formRatingConscientiousAboutCosts);
          this.inspectorPerformanceRatingData.updatedOn = new Date();
        }
      }

      if (this.loggedUserData) {
        this.workRequestData.updatedBy = this.loggedUserData.cai;
        this.workRequestsService
          .updateWorkRequest(this.workRequestData)
          .subscribe({
            next: () => {
              this.createRevisionDtos(
                previousWorkRequestData,
                formCurrentBudget,
                formEtcChargeCode,
                formPoRequiredDate,
                formManufacturer,
                formAgency,
                formInspector,
                formQae,
                formDescription
              );

              const observables = [];

              const selectedCommodities = this.commoditiesData.filter(
                (c) => c.checked
              );
              if (selectedCommodities.length > 0) {
                observables.push(
                  this.workRequestsService
                    .addWorkRequestCommodities(
                      this.workRequestData.workRequestId,
                      selectedCommodities
                    )
                    .pipe(
                      catchError((error) => {
                        console.error(
                          "POST /workRequests/commodities Error adding commodities: " +
                          error
                        );
                        return of(null); // Continue with the next observable in case of error
                      })
                    )
                );
              }

              if (hasInspectorRatings) {
                if (addInspectorPerformanceRating) {
                  this.inspectorPerformanceRatingData.insertedBy =
                    this.loggedUserData.userId;
                  observables.push(
                    this.workRequestsService
                      .addInspectorPerformanceRatings(
                        this.inspectorPerformanceRatingData
                      )
                      .pipe(
                        catchError((error) => {
                          console.error(
                            "POST /workRequests/inspectorPerformanceRatings Error: " +
                            error
                          );
                          return of(null); // Continue with the next observable in case of error
                        })
                      )
                  );
                } else {
                  this.inspectorPerformanceRatingData.updatedBy =
                    this.loggedUserData.userId;
                  observables.push(
                    this.workRequestsService
                      .updateInspectorPerformanceRating(
                        this.inspectorPerformanceRatingData
                      )
                      .pipe(
                        catchError((error) => {
                          console.error(
                            "PUT /workRequests/inspectorPerformanceRatings Error: " +
                            error
                          );
                          return of(null); // Continue with the next observable in case of error
                        })
                      )
                  );
                }
              }

              observables.push(
                this.workRequestsService
                  .updatePerformanceTrackingForWorkRequest(this.workRequestData)
                  .pipe(
                    catchError((error) => {
                      console.error(
                        "PUT /workRequests/performance-tracking Error: " + error
                      );
                      return of(null); // Continue with the next observable in case of error
                    })
                  )
              );

              forkJoin(observables).subscribe({
                next: () => {
                  this.formEditable = false;
                  this.router.navigate(
                    [
                      "/qa-management/work-requests/edit-work-request/" +
                      this.workRequestData.workRequestId,
                    ],
                    { queryParams: { reload: true } }
                  );
                },
                error: (error) => {
                  this.isSubmitting = false;
                  console.error(
                    "Error in forkJoin while editing workRequest: " + error
                  );
                },
              });
            },
            error: (error) => {
              console.error("POST /workRequests Error: " + error);
            },
          });
      } else {
        this.isSubmitting = false;
        console.error(
          "Cannot fetch logged user data in edit-work-request form."
        );
      }
    } else {
      this.isSubmitting = false;
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    }
  }

  createRevisionDtos(
    previousWorkRequestData: IWorkRequestData,
    formCurrentBudget: number,
    formEtcChargeCode: string,
    formPoRequiredDate: Date,
    formManufacturer: string,
    formAgency: string,
    formInspector: string,
    formQae: string,
    formDescription: string
  ): void {
    if (this.revisionsExist) {
      this.createRevisionsForExisting(
        previousWorkRequestData,
        formCurrentBudget,
        formEtcChargeCode,
        formPoRequiredDate,
        formManufacturer,
        formAgency,
        formInspector,
        formQae,
        formDescription
      );
    } else {
      this.createRevisionsFromScratch(
        previousWorkRequestData,
        formCurrentBudget,
        formEtcChargeCode,
        formPoRequiredDate,
        formManufacturer,
        formAgency,
        formInspector,
        formQae,
        formDescription
      );
    }
  }

  createRevisionsFromScratch(
    workRequestData: IWorkRequestData,
    formCurrentBudget: number,
    formEtcChargeCode: string,
    formPoRequiredDate: Date,
    formManufacturer: string,
    formAgency: string,
    formInspector: string,
    formQae: string,
    formDescription: string
  ) {
    const revisions: IWorkRequestRevisionData[] = [];
    let isRecordUpdated: boolean = false;

    if (formCurrentBudget !== null) {
      revisions.push(
        this.createRevisionDto(
          "Current Budget",
          workRequestData.sapexpectedLimit?.toString(),
          formCurrentBudget.toString()
        )
      );
      isRecordUpdated = true;
    }

    if (formEtcChargeCode.toString().trim() !== "") {
      revisions.push(
        this.createRevisionDto(
          "ETC Charge Code",
          workRequestData.workRequestCostCenter,
          formEtcChargeCode.toString()
        )
      );
      isRecordUpdated = true;
    }

    if (formPoRequiredDate != null && formPoRequiredDate.toString() != "") {
      revisions.push(
        this.createRevisionDto(
          "PO Required Date",
          formPoRequiredDate ? formPoRequiredDate.toString() : "",
          formPoRequiredDate ? formPoRequiredDate.toString() : ""
        )
      );
      isRecordUpdated = true;
    }

    if (formManufacturer.toString().trim() !== "manufacturerDefault") {
      revisions.push(
        this.createRevisionDto(
          "Manufacturer",
          workRequestData.vendorId?.toString(),
          formManufacturer.toString()
        )
      );
      isRecordUpdated = true;
    }

    if (formAgency.toString().trim() !== "agencyDefault") {
      revisions.push(
        this.createRevisionDto(
          "Agency",
          workRequestData.agencyId?.toString(),
          formAgency.toString()
        )
      );
      isRecordUpdated = true;
    }

    if (formInspector.toString().trim() !== "inspectorDefault") {
      revisions.push(
        this.createRevisionDto(
          "Inspector",
          workRequestData.inspectorId?.toString(),
          formInspector.toString()
        )
      );
      isRecordUpdated = true;
    }

    if (formQae.toString().trim() !== "qaeDefault") {
      revisions.push(
        this.createRevisionDto(
          "QAE",
          workRequestData.qaeid?.toString(),
          formQae.toString()
        )
      );
      isRecordUpdated = true;
    }

    if (formDescription.toString().trim() !== "") {
      revisions.push(
        this.createRevisionDto(
          "Description",
          workRequestData.description,
          formDescription.toString()
        )
      );
      isRecordUpdated = true;
    }

    if (isRecordUpdated) {
      if (this.loggedUserData && this.userFullName) {
        revisions.push(
          this.createRevisionDto("Updated By", "", this.userFullName)
        );
        revisions.push(
          this.createRevisionDto("Updated On", "", new Date().toISOString())
        );

        if (revisions.length > 0) {
          this.workRequestsService
            .addWorkRequestRevisionDetails(
              workRequestData.workRequestId,
              revisions
            )
            .subscribe({
              next: () => { },

              error: (error) => {
                console.error("POST /workRequests/revisions" + error);
              },
            });
        }
      }
    }
  }
  createRevisionsForExisting(
    previousWorkRequestData: IWorkRequestData,
    formCurrentBudget: number,
    formEtcChargeCode: string,
    formPoRequiredDate: Date,
    formManufacturer: string,
    formAgency: string,
    formInspector: string,
    formQae: string,
    formDescription: string
  ) {
    const revisions: IWorkRequestRevisionData[] = [];
    let isRecordUpdated: boolean = false;

    if (formCurrentBudget !== previousWorkRequestData.sapexpectedLimit) {
      revisions.push(
        this.createRevisionDto(
          "Current Budget",
          previousWorkRequestData.sapexpectedLimit?.toString(),
          formCurrentBudget.toString()
        )
      );
      isRecordUpdated = true;
    }

    if (
      formEtcChargeCode.toString() !==
      previousWorkRequestData.workRequestCostCenter
    ) {
      revisions.push(
        this.createRevisionDto(
          "ETC Charge Code",
          previousWorkRequestData.workRequestCostCenter,
          formEtcChargeCode.toString()
        )
      );
      isRecordUpdated = true;
    }

    const workRequestDate = new Date(previousWorkRequestData.requiredDate);
    const formDate = new Date(formPoRequiredDate);
    if (
      workRequestDate.getFullYear() !== formDate.getFullYear() ||
      workRequestDate.getMonth() !== formDate.getMonth() ||
      workRequestDate.getDate() !== formDate.getDate()
    ) {
      revisions.push(
        this.createRevisionDto(
          "PO Required Date",
          previousWorkRequestData.requiredDate
            ? previousWorkRequestData.requiredDate?.toString()
            : "",
          formPoRequiredDate ? formPoRequiredDate.toString() : ""
        )
      );
      isRecordUpdated = true;
    }

    if (
      formManufacturer.toString() !==
      previousWorkRequestData.vendorId?.toString()
    ) {
      revisions.push(
        this.createRevisionDto(
          "Manufacturer",
          previousWorkRequestData.vendorId
            ? previousWorkRequestData.vendorId.toString()
            : "",
          formManufacturer.toString()
        )
      );
      isRecordUpdated = true;
    }

    if (formAgency.toString() == "agencyDefault") {
      if (previousWorkRequestData.agencyId) {
        revisions.push(
          this.createRevisionDto(
            "Agency",
            previousWorkRequestData.agencyId.toString(),
            ""
          )
        );
        isRecordUpdated = true;
      }
    } else {
      if (
        formAgency.toString() !== previousWorkRequestData.agencyId?.toString()
      ) {
        revisions.push(
          this.createRevisionDto(
            "Agency",
            previousWorkRequestData.agencyId
              ? previousWorkRequestData.agencyId.toString()
              : "",
            formAgency.toString()
          )
        );
        isRecordUpdated = true;
      }
    }

    if (formInspector.toString() == "inspectorDefault") {
      if (previousWorkRequestData.inspectorId) {
        revisions.push(
          this.createRevisionDto(
            "Inspector",
            previousWorkRequestData.inspectorId.toString(),
            ""
          )
        );
        isRecordUpdated = true;
      }
    } else {
      if (
        formInspector.toString() !==
        previousWorkRequestData.inspectorId?.toString()
      ) {
        revisions.push(
          this.createRevisionDto(
            "Inspector",
            previousWorkRequestData.inspectorId
              ? previousWorkRequestData.inspectorId.toString()
              : "",
            formInspector.toString()
          )
        );
        isRecordUpdated = true;
      }
    }

    if (formQae.toString() !== previousWorkRequestData.qaeid?.toString()) {
      revisions.push(
        this.createRevisionDto(
          "QAE",
          previousWorkRequestData.qaeid
            ? previousWorkRequestData.qaeid.toString()
            : "",
          formQae.toString()
        )
      );
      isRecordUpdated = true;
    }

    if (formDescription.toString() !== previousWorkRequestData.description) {
      revisions.push(
        this.createRevisionDto(
          "Description",
          previousWorkRequestData.description,
          formDescription.toString()
        )
      );
      isRecordUpdated = true;
    }

    if (isRecordUpdated) {
      if (this.loggedUserData && this.userFullName) {
        revisions.push(
          this.createRevisionDto(
            "Updated By",
            this.loggedUserData.cai,
            this.userFullName
          )
        );
        revisions.push(
          this.createRevisionDto("Updated On", "", new Date().toISOString())
        );

        if (revisions.length > 0) {
          this.workRequestsService
            .updateWorkRequestRevisionDetails(
              previousWorkRequestData.workRequestId,
              revisions
            )
            .subscribe({
              next: () => { },

              error: (error) => {
                console.error("PUT /workRequests/revisions" + error);
              },
            });
        }
      }
    }
  }

  createRevisionDto(
    typeOfChange: string,
    oldValue: string,
    newValue: string
  ): IWorkRequestRevisionData {
    return {
      typeOfChange: typeOfChange,
      revisions: {
        OldValue: oldValue,
        NewValue: newValue,
      },
    };
  }

  setActiveTab(tabName: string): void {
    this.activeTab = tabName;
  }

  downloadIContractReport() {
    this.workRequestsService
      .getIContractReport(this.workRequestData.workRequestId)
      .subscribe(
        (blob) => {
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement("a");
          a.href = url;
          a.download = `iContract_${this.workRequestData.workRequestId}.docx`;
          a.click();
          window.URL.revokeObjectURL(url);
        },
        (error) => {
          console.error("Error downloading the iContract report:", error);
        }
      );
  }

  downloadSummaryReport() {
    this.workRequestsService
      .getSummaryReport(this.workRequestData.workRequestId)
      .subscribe(
        (blob) => {
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement("a");
          a.href = url;
          a.download =
            "WorkRequestSummaryReport_" +
            this.workRequestData.workRequestId +
            ".doc";
          a.click();
          window.URL.revokeObjectURL(url);
        },
        (error) => {
          console.error("Error downloading the Summary report:", error);
        }
      );
  }

  downloadDetailsReport() {
    this.workRequestsService
      .getDetailsReport(this.workRequestData.workRequestId)
      .subscribe(
        (blob) => {
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement("a");
          a.href = url;
          a.download =
            "WorkRequestDetails_" + this.workRequestData.workRequestId + ".doc";
          a.click();
          window.URL.revokeObjectURL(url);
        },
        (error) => {
          console.error(
            "Error downloading the Work Request Details report:",
            error
          );
        }
      );
  }

  iContractNoticeButtonVisible(): boolean {
    const defaultDate = new Date("1900-01-01");
    const closedDate =
      this.workRequestData?.closedDate != null
        ? new Date(this.workRequestData?.closedDate)
        : null;
    if (
      this.workRequestData?.statusId === 3 ||
      (closedDate != null &&
        closedDate.getFullYear() === defaultDate.getFullYear() &&
        closedDate.getMonth() === defaultDate.getMonth() &&
        closedDate.getDate() === defaultDate.getDate())
    ) {
      return true;
    } else {
      return false;
    }
  }

  closeiContractNotice(route: string = "close-icontract-notice"): void {
    this.router.navigate([`/qa-management/work-requests/` + route], {
      queryParams: {
        agencyId: this.workRequestData?.agencyId,
        qaeId: this.workRequestData?.qaeid,
        workRequestId: this.workRequestData?.workRequestId,
      },
    });
  }

  filterInput(event: KeyboardEvent): boolean {
    return this.inputNumberHelperService.filterInput(event);
  }

  handlePaste(event: ClipboardEvent): void {
    this.inputNumberHelperService.handlePaste(event);
  }

  clearDate(controlName: string): void {
    this.editForm.get(controlName).setValue(null);
  }
}
