import { Component, Input } from "@angular/core";

@Component({
  selector: "action-button-component",
  templateUrl: "./action-button.component.html",
  styles: [
    `
      .dng-button {
        background-color: #97002e;
        border-color: #97002e;
      }
      .small-icon {
        font-size: 20px;
      }
    `,
  ],
})
export class ActionButtonComponent {
  value: any;
  @Input() buttonText: string;
  @Input() element: any;
  @Input() danger: boolean = false;
  @Input() classes: string = "";
  @Input() icon: string = "";
  @Input() label: string = "";
  @Input() actionFrom: () => void;

  buttonOnClick() {
    if (this.actionFrom) {
      this.actionFrom();
    }
  }
}
