import { Component, OnInit, ViewChild } from "@angular/core"; // Assuming the interface is in this file
import { IVendorData } from "../../interfaces/administration/vendorData";
import { environment } from "projects/aqua3/src/environments/environment";
import { ActivatedRoute, Router } from "@angular/router";
import { VendorDataService } from "../../services/vendor-data.service";
import { Subscription } from "rxjs";
import { BaseComponent } from "../../shared/base/base.component";
import { UserPermissionsService } from "../../services/common/user-permissions.service";
import { MatDialog } from "@angular/material/dialog";
import { CommentBoxDialogComponent } from "./comment-box-dialog.component";
import { CommoditiesTabComponent } from "./commodities/commodities-tab.component";
const apiUrl = environment.apiUrl;

@Component({
  selector: "app-vendor-preview",
  templateUrl: "./preview-vendor.component.html",
  styles: [
    `
      .red-color {
        color: red;
      }
      .accordion-outline {
        margin: 0px 0pc 25px 0px;
      }
    `,
  ],
})
export class PreviewVendorComponent extends BaseComponent implements OnInit {
  @ViewChild(CommoditiesTabComponent)
  commoditiesTabComponent: CommoditiesTabComponent; // Reference to the child component

  vendorData: IVendorData;
  private subscription: Subscription;
  private exists: boolean;
  private vendorId: string;
  public isLoading = true;
  public activeTab: string = "commodities"; // Default to the first tab
  public duplicates: IVendorData[] = [];

  constructor(
    private vendorDataService: VendorDataService,
    private router: Router,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    userPermissionService: UserPermissionsService
  ) {
    super(userPermissionService);
    this.exists = false;
  }

  ngOnInit() {
    super.ngOnInit();
    this.vendorId = this.route.snapshot.paramMap.get("id");
    this.setActiveTab();
    if (this.vendorId) {
      this.vendorDataService.getVendorById(this.vendorId).subscribe((data) => {
        if (data) {
          this.exists = true;
          this.vendorData = data;
        }
        this.isLoading = false;
      });
    } else {
      this.vendorDataService.currentVendorData.subscribe((data) => {
        this.vendorData = data;
        this.vendorDataService
          .searchVendorsAsync(data.vendorName, data.streetAddress, data.url)
          .subscribe((results) => {
            if (results) {
              this.duplicates = results;
            }
            this.isLoading = false;
          });
      });
    }
  }

  confirmAndViewVendor(vendor: IVendorData) {
    const confirmation = confirm(
      "Click okay to leave this page and view the selected vendor information. Click cancel to continue browsing any other vendors that may have been found.\n\n All data you entered will be lost if you jump to this vendor information"
    );
    if (confirmation) {
      this.router.navigate([
        "administration/vendors/preview/",
        vendor.vendorId,
      ]);
    }
  }

  editNewVendor() {
    this.vendorDataService.changeVendorData(this.vendorData);
    this.router.navigate(["administration/vendors/create"]);
  }

  backToList() {
    this.vendorDataService.changeVendorData(null);
    this.router.navigate(["administration/vendors"]);
  }

  editVendor(id: string) {
    this.router.navigate(["administration/vendors/edit/", id]);
  }

  activateDeactivateVendor(action: string): void {
    let comment =
      action == "activate"
        ? "Are you sure that you want to re-activate the following vendor? This action will not re-activate the associated vendor commodities."
        : "Are you sure that you want to deactivate the following vendor? This action will deactivate all the vendor commodities associated to this vendor.";
    const dialogRef = this.dialog.open(CommentBoxDialogComponent, {
      width: "400px",
      data: {
        comments: comment,
        title: action == "activate" ? "Activate vendor" : "Deactivate vendor",
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.vendorDataService
          .activateDeactivateVendor(this.vendorId, result)
          .subscribe({
            next: (data) => {
              this.vendorData = data;
              this.commoditiesTabComponent.refreshCommodities();
            },
            error: (error) => {
              console.error(error);
            },
          });
      } else {
      }
    });
  }

  saveNewVendor() {
    this.vendorDataService.saveVendor(this.vendorData).subscribe({
      next: (response) => {
        // Redirect to the vendors page
        this.router.navigate(["administration/vendors"]);
      },
      error: (error) => {
        console.error(error);
      },
    });
  }

  updateVendor() {
    this.vendorDataService
      .updateVendor(this.vendorId, this.vendorData)
      .subscribe({
        next: (response) => {
          // Redirect to the vendors page
          this.router.navigate(["administration/vendors"]);
        },
        error: (error) => {
          console.error(error);
        },
      });
  }

  setActiveTab(tabName: string = "commodities"): void {
    this.activeTab = tabName;

    if (tabName === "commodities") {
    } else if (tabName === "history") {
    }
  }
}
