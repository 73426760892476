import { HttpClient } from "@angular/common/http";
import { Component, OnInit } from "@angular/core";
import { AlertService } from "../../services/administration/alert.service";
import { IAlertData } from "../../interfaces/administration/alertData";
import { Router } from "@angular/router";
import { BaseComponent } from "../../shared/base/base.component";
import { UserPermissionsService } from "../../services/common/user-permissions.service";

@Component({
  selector: "alerts-component",
  templateUrl: "./alerts.component.html",
})
export class AlertsComponent extends BaseComponent implements OnInit {
  title = "Alerts";
  public alerts: IAlertData[] = [];
  displayedColumns: string[] = [
    "alertName",
    "vendorName",
    "addedDate",
    "active",
    "actions",
  ];

  constructor(
    private alertService: AlertService,
    private router: Router,
    userPermissionService: UserPermissionsService
  ) {
    super(userPermissionService);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.alertService.getAlerts().subscribe((data) => {
      this.alerts = data;
    });
  }

  toggleActive(alert: IAlertData): void {
    alert.active = !alert.active;
    this.alertService
      .updateAlert(alert)
      .subscribe(() => console.log("Alert status updated successfully"));
  }

  deleteAlert(alert: IAlertData): void {
    this.alertService.deleteAlert(alert).subscribe(() => {
      this.alerts = this.alerts.filter((a) => a.alertId !== alert.alertId);
    });
  }

  confirmDelete(alert: IAlertData) {
    if (window.confirm("Are you sure you want to delete this alert?")) {
      this.deleteAlert(alert);
    }
  }

  editAlert(alert: IAlertData): void {
    this.router.navigate(["/administration/alerts/update", alert.alertId]);
  }

  addAlert(): void {
    this.router.navigate(["/administration/alerts/add"]);
  }

  getAlertReport(): void {
    // Logic to get the alert report
    console.log("Get Alert Report button clicked");
  }
}
