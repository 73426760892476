import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Workbook } from "exceljs";
import { environment } from "../../environments/environment";
const apiUrl = environment.apiUrl;

@Injectable({
  providedIn: "root",
})
export class InspectionReportService {
  constructor(private http: HttpClient) {}

  async generateInspectionReport(): Promise<Blob> {
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet("Weekly Tracking Sheet");

    const headerRowData = [
      "ETC iContract #",
      "iContract Amount",
      "ETC QA issue SO #",
      "Chevron responsible QAE",
      "ChargeCode",
      "Project",
      "PO #",
      "Vendor",
      "City, Country",
      "Agency",
      "Inspector",
      "Agency Job #",
      "Inspection Report No",
      "Inspection Visit Date",
      "Inspection Hours",
      "Travel Hours",
      "Coordination Hours",
      "Overtime Hours",
      "Mileage",
      "Other Expenses",
      "Total",
    ];

    // Add header row
    const headerRow = worksheet.addRow(headerRowData);

    // Set styles for the header row
    headerRow.eachCell((cell) => {
      cell.font = { bold: true };
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "FFC5D9F1" },
      };
      cell.border = {
        top: { style: "thin", color: { argb: "FF000000" } },
        bottom: { style: "thin", color: { argb: "FF000000" } },
        left: { style: "thin", color: { argb: "FF000000" } },
        right: { style: "thin", color: { argb: "FF000000" } },
      };
    });

    // Set column widths and row height
    worksheet.columns = headerRowData.map(() => ({ width: 15 }));
    headerRow.height = 25;

    // Add data validations
    for (let i = 2; i <= 100; i++) {
      worksheet.getCell(`A${i}`).dataValidation = {
        type: "whole",
        operator: "greaterThan",
        formulae: ["0"],
        allowBlank: false,
      };
      worksheet.getCell(`B${i}`).dataValidation = {
        type: "whole",
        operator: "greaterThan",
        formulae: ["0"],
        allowBlank: false,
      };
    }

    // Create additional sheets for data validation lists (hidden)
    const agencySheet = workbook.addWorksheet("Sheet2", {
      state: "veryHidden",
    });
    agencySheet.addRows([["Agency 1"], ["Agency 2"], ["Agency 3"]]);

    const inspectorSheet = workbook.addWorksheet("Sheet3", {
      state: "veryHidden",
    });
    inspectorSheet.addRows([["Inspector 1"], ["Inspector 2"], ["Inspector 3"]]);

    // Data validation linking to hidden sheets
    for (let i = 2; i <= 100; i++) {
      worksheet.getCell(`J${i}`).dataValidation = {
        type: "list",
        allowBlank: false,
        formulae: [`=Sheet2!$A$1:$A$3`],
      };
      worksheet.getCell(`K${i}`).dataValidation = {
        type: "list",
        allowBlank: false,
        formulae: [`=Sheet3!$A$1:$A$3`],
      };
    }

    // Generate and return the Excel file as a Blob
    const buffer = await workbook.xlsx.writeBuffer();
    return new Blob([buffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
  }

  // Method to validate required fields in the dataset
  validateDataset(dataset: any[]): boolean {
    let validationCheckFail = false;

    dataset.forEach((row) => {
      if (
        !row.ETCiContractNo ||
        !row.Agency ||
        !row.Inspector ||
        !row.AgencyJobNo ||
        !row.ReportInvoiceNumber ||
        !row.ReportInvoiceDate
      ) {
        validationCheckFail = true;
      }
    });

    return validationCheckFail;
  }

  // Method to check for duplicates in the dataset
  checkAndRemoveDuplicates(dataset: any[]): any[] {
    const recordsMap = new Map();

    dataset.forEach((row, index) => {
      const key = `${row.ETCiContractNo}-${row.Agency}-${row.Inspector}-${row.AgencyJobNo}-${row.ReportInvoiceNumber}-${row.ReportInvoiceDate}`;
      if (!recordsMap.has(key)) {
        recordsMap.set(key, []);
      }
      recordsMap.get(key).push(index);
    });

    const indicesToRemove = [];

    recordsMap.forEach((indices) => {
      if (indices.length > 1) {
        // Keep the last occurrence and mark others for removal
        indices.pop();
        indicesToRemove.push(...indices);
      }
    });

    // Remove duplicates
    indicesToRemove
      .sort((a, b) => b - a)
      .forEach((index) => {
        dataset.splice(index, 1);
      });

    return dataset;
  }

  uploadInspectionReport(dedupedDataset: any[]) {
    return this.http.post(`${apiUrl}/inspection-report/upload`, dedupedDataset);
  }
}
