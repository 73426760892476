import { Component, OnInit } from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { CapitalProjectsDataService } from "../../services/capital-projects.service";
import { ActivatedRoute, Router } from "@angular/router";
import { IExtendedCapitalProjectData } from "../../approved-vendor-list/preview-project.component";
import { ICapitalProjectData } from "../../interfaces/administration/capitalProjectData";
import { IVendorData } from "../../interfaces/administration/vendorData";
import { VendorDataService } from "../../services/vendor-data.service";
import { VendorCommodityDataService } from "../../services/vendor-information/vendor-commodity.service";
import { forkJoin, switchMap, tap } from "rxjs";
import { VendorCommodityData } from "../../interfaces/vendor-information/vendorCommodityData";
import { VendorCommoditySearchData } from "../../interfaces/vendor-information/viewVendorCommoditySearchData";
import { PerformanceTrackingService } from "../../services/vendor-information/performance-tracking.service";
import { IPerformanceTrackingData } from "../../interfaces/vendor-information/performanceTrackingData";
import { CommodityCodesService } from "../../services/administration/commodity-codes.service";
import { ICommodity } from "../../interfaces/administration/commodity";
import { BaseComponent } from "../../shared/base/base.component";
import { UserPermissionsService } from "../../services/common/user-permissions.service";

@Component({
  selector: "app-preview-performance-tracking",
  templateUrl: "./preview-performance-tracking.component.html",
  styles: [
    `
      .button-container {
        display: flex !important;
        justify-content: space-between !important;
      }

      .unchecked {
        color: red;
      }

      .mt-1 {
        margin-top: 1rem !important;
      }
    `,
  ],
})
export class PreviewPerformanceTrackingComponent
  extends BaseComponent
  implements OnInit
{
  public sptId: number = 0;
  public project: ICapitalProjectData;
  public performanceTracking: IPerformanceTrackingData;
  public vendors: IVendorData[] = [];
  public commodity: ICommodity;
  public selectedVendor: IVendorData;
  public isLoading: boolean = false;
  public validationMessages = [];
  public activeTab: string = "stats"; // Default to the first tab

  sourcePage: string = "";

  constructor(
    private fb: FormBuilder,
    private capitalProjectService: CapitalProjectsDataService,
    private vendorService: VendorDataService,
    private performanceService: PerformanceTrackingService,
    private route: ActivatedRoute,
    private router: Router,
    private commodityService: CommodityCodesService,
    userPermissionsService: UserPermissionsService
  ) {
    super(userPermissionsService);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.route.queryParams.subscribe((params) => {
      this.sourcePage = params["source"];
    });

    this.isLoading = true;
    this.loadSpt();
  }

  loadSpt() {
    this.route.paramMap.subscribe((params) => {
      this.sptId = Number(params.get("sptId"));

      this.performanceService
        .getPerformanceTrackingDataById(this.sptId)
        .subscribe((data: IPerformanceTrackingData) => {
          if (data) {
            this.performanceTracking = data;
            this.loadProject(data.projectId);
            this.loadVendors(() => {
              this.selectedVendor = this.vendors.find(
                (vendor) => vendor.vendorId === data.vendorId
              );
            });
            this.loadCommodity(data.commodityId); // Pass commodityId to loadCommodity
            this.setActiveTab();
            this.isLoading = false;
          }
        });
    });
  }

  loadProject(projectId) {
    this.capitalProjectService
      .getCapitalProjectById(projectId)
      .subscribe((data) => {
        if (data) {
          this.project = data;
        }
      });
  }

  loadVendors(callback) {
    this.vendorService.getVendors().subscribe((data) => {
      if (data) {
        this.vendors = data;
        if (callback) {
          callback();
        }
      }
    });
  }

  loadCommodity(commodityId: number): void {
    this.commodityService
      .getCommodityById(commodityId.toString())
      .subscribe((data: ICommodity) => {
        this.commodity = data;
      });
  }

  setActiveTab(tabName: string = "stats"): void {
    this.activeTab = tabName;
    if (tabName === "stats")
      this.performanceService.loadStatsDataTab(this.sptId);
    else if (tabName === "engineering")
      this.performanceService.loadRatingDataTab(this.sptId, 1);
    else if (tabName === "procurement")
      this.performanceService.loadRatingDataTab(this.sptId, 6);
    else if (tabName === "construction")
      this.performanceService.loadRatingDataTab(this.sptId, 0);
    else if (tabName === "manufacturing")
      this.performanceService.loadRatingDataTab(this.sptId, 2);
  }

  edit(): void {
    this.router.navigate([
      "/vendor-information/performance-tracking/edit-spt/",
      this.sptId,
    ]);
  }

  list(): void {
    this.router.navigate(["/vendor-information/performance-tracking"]);
  }

  upload(): void {
    this.router.navigate([
      "/vendor-information/performance-tracking/upload-spt/",
      this.sptId,
    ]);
  }

  backToEditWorkRequestView(): void {
    this.router.navigate([this.sourcePage]);
  }
}
