// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bold-big-blue {
    font-weight: bold;
    font-size: larger;
    color: blue;
  }

  .small-text {
    font-size: 12px;
  }`, "",{"version":3,"sources":["webpack://./projects/aqua3/src/app/qa-management/work-requests/rfq/edit-rfq-form.component.css"],"names":[],"mappings":"AAAA;IACI,iBAAiB;IACjB,iBAAiB;IACjB,WAAW;EACb;;EAEA;IACE,eAAe;EACjB","sourcesContent":[".bold-big-blue {\n    font-weight: bold;\n    font-size: larger;\n    color: blue;\n  }\n\n  .small-text {\n    font-size: 12px;\n  }"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
