import { Component, OnInit } from "@angular/core";
import { FormGroup, FormBuilder, Validators, FormArray } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { WorkRequestsDataService } from "../../../services/qa-management/work-requests/work-requests.service";
import { LoggedUserService } from "../../../services/common/logged-user.service";
import { ILoggedUserData } from "../../../interfaces/common/loggedUserData";
import { IWorkRequestRFQData } from "../../../interfaces/qa-management/work-requests/workRequestRFQData";
import { UserRequestsDataService } from "../../../services/administration/user-requests.service";
import { AgenciesAndInspectorsDataService } from "../../../services/qa-management/agencies-inspectors.service";
import { IAgencyData } from "../../../interfaces/qa-management/agencyData";
import { BaseComponent } from "../../../shared/base/base.component";
import { UserPermissionsService } from "../../../services/common/user-permissions.service";

@Component({
  selector: "edit-rfq-form.component",
  templateUrl: "./edit-rfq-form.component.html",
  styleUrls: ["./edit-rfq-form.component.css"],
})
export class EditRFQFormComponent extends BaseComponent implements OnInit {
  workRequestRFQData: IWorkRequestRFQData;
  userFullName: string = "";
  loggedUserData: ILoggedUserData;
  public agenciesData: IAgencyData[] = [];
  checkedAgencies: { [key: number]: boolean } = {};

  editForm: FormGroup;
  validationMessages = [];

  constructor(
    private workRequestsService: WorkRequestsDataService,
    private fb: FormBuilder,
    private loggedUserService: LoggedUserService,
    private userRequestsService: UserRequestsDataService,
    private agenciesAndInspectorsDataService: AgenciesAndInspectorsDataService,
    private router: Router,
    private route: ActivatedRoute,
    userPermissionsService: UserPermissionsService
  ) {
    super(userPermissionsService);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.editForm = this.fb.group({
      notes: [""],
    });

    const rfqId = this.route.snapshot.paramMap.get("rfqId");
    this.loadRFQAndAgencies(rfqId);

    this.loggedUserService
      .getLoggedUserData()
      .subscribe((loggedUser: ILoggedUserData | null) => {
        if (loggedUser) {
          this.loggedUserData = loggedUser;
          this.userRequestsService
            .getUserFullNameById(loggedUser.userId)
            .subscribe((fullName) => {
              this.userFullName = fullName;
            });
        }
      });
  }

  onCheckboxChange(agencyId: number, isChecked: boolean) {
    if (isChecked) {
      this.checkedAgencies[agencyId] = true;
    } else {
      delete this.checkedAgencies[agencyId];
    }
  }

  getCheckedAgencies(): IAgencyData[] {
    return this.agenciesData.filter(
      (agency) => this.checkedAgencies[agency.agencyId!]
    );
  }

  loadRFQAndAgencies(rfqId: string): void {
    this.agenciesAndInspectorsDataService.getAllAgencies().subscribe((data) => {
      this.agenciesData = data;

      if (rfqId) {
        this.workRequestsService
          .getWorkRequestRFQById(rfqId)
          .subscribe((data) => {
            if (data) {
              this.workRequestRFQData = data;

              this.editForm.patchValue({
                notes: this.workRequestRFQData.notes,
              });

              if (this.workRequestRFQData.agencyIds) {
                this.workRequestRFQData.agencyIds.forEach((id) => {
                  this.checkedAgencies[id] = true;
                });
              }
            }
          });
      }
    });
  }

  submitForm(): void {
    let messagesValidationsList: string[] = [];
    let formNotes: string = this.editForm.get("notes").value;
    const selectedAgencyIds: number[] = Object.keys(this.checkedAgencies)
      .filter((key) => this.checkedAgencies[+key])
      .map((key) => +key);

    // Validate form controls
    if (selectedAgencyIds.length === 0) {
      messagesValidationsList.push("Please select at least one Agency.");
    }

    this.validationMessages = messagesValidationsList;
    if (this.editForm.valid && this.validationMessages.length === 0) {
      this.workRequestRFQData.notes = formNotes;
      this.workRequestRFQData.updatedBy = this.loggedUserData.cai;
      this.workRequestRFQData.updatedOn = new Date();
      this.workRequestRFQData.agencyIds = selectedAgencyIds;

      this.workRequestsService
        .updateWorkRequestRFQ(this.workRequestRFQData)
        .subscribe({
          next: () => {
            this.router.navigate([
              `/qa-management/work-requests/edit-work-request/${this.workRequestRFQData.workRequestId}`,
            ]);
          },
          error: (error) => {
            console.error("POST /workRequests/edit-rfq" + error);
          },
        });
    }
  }

  cancelChanges(): void {
    this.router.navigate([
      `/qa-management/work-requests/edit-work-request/${this.workRequestRFQData.workRequestId}`,
    ]);
  }
}
