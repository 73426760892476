import { Component } from "@angular/core";
import { AdministrationLoadingDataService } from "../services/administration/administration-loading-data-service";
import { AdministrationTabs } from "../interfaces/administration/administrationTabs";

@Component({
  selector: "administration-component",
  templateUrl: "./administration.component.html",
})
export class AdministrationComponent {
  title = "Administration";
  links: any[] = [];
  AdministrationTabs = AdministrationTabs;
  activeTab: AdministrationTabs = AdministrationTabs.UserRequests;

  constructor(
    private administrationLoadingDataService: AdministrationLoadingDataService
  ) { }

  ngOnInit(): void {
    this.administrationLoadingDataService.activeTabData$.subscribe((tab) => {
      this.activeTab = tab;
      this.loadActiveTab(tab);
    });
  }

  ngAfterViewInit(): void {
    this.loadActiveTab(this.activeTab);
  }

  setActiveTab(tabName: AdministrationTabs): void {
    this.administrationLoadingDataService.setActiveTab(tabName);
  }

  loadActiveTab(tabName: AdministrationTabs): void {
    if (tabName == AdministrationTabs.UserRequests)
      this.administrationLoadingDataService.loadUserRequestsData();
    else if (tabName == AdministrationTabs.PendingCR)
      this.administrationLoadingDataService.loadPendingCommodityRequestsData();
    else if (tabName == AdministrationTabs.ApprovedCR)
      this.administrationLoadingDataService.loadApprovedCommodityRequestsData();
    else if (tabName == AdministrationTabs.RejectedCR)
      this.administrationLoadingDataService.loadRejectedCommodityRequestsData();
  }
}
