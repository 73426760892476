export const RoleGroupConfigurationNames = {
  CONF_GROUPROLE_NORMAL_USER: "Aqua3-AAD-group-NormalUser",
  CONF_GROUPROLE_PROJECT_MANAGER: "Aqua3-AAD-group-ProjectManager",
  CONF_GROUPROLE_PROJECT_TEAM_MEMBER: "Aqua3-AAD-group-ProjectTeamMember",
  CONF_GROUPROLE_QUALITY_ASSURANCE_ADMINISTRATOR:
    "Aqua3-AAD-group-QualityAssuranceAdministrator",
  CONF_GROUPROLE_QUALITY_ASSURANCE_AND_CONTROL_USER:
    "Aqua3-AAD-group-QualityAssuranceAndControlUser",
  CONF_GROUPROLE_SUBJECT_MATTER_EXPERT: "Aqua3-AAD-group-SubjectMatterExpert",
  CONF_GROUPROLE_SYSTEM_ADMINISTRATOR: "Aqua3-AAD-group-SystemAdministrator",
  CONF_GROUPROLE_TEAM_LEAD: "Aqua3-AAD-group-TeamLead",
  CONF_GROUPROLE_VENDOR_INFORMATION_VIEWER:
    "Aqua3-AAD-group-VendorInformationViewer",
};
