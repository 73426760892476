import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  OnInit,
  ViewChild,
} from "@angular/core";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { Router } from "@angular/router";
import { MatPaginator } from "@angular/material/paginator";
import { ICapitalProjectData } from "../interfaces/administration/capitalProjectData";
import { ProjectAndAvlService } from "../services/administration/project-and-avl-service";
import { CapitalProjectsDataService } from "../services/capital-projects.service";

@Component({
  selector: "approved-vendor-list-component",
  templateUrl: "./approved-vendor-list.component.html",
  styles: [
    `
      .button-container {
        display: flex !important;
        justify-content: space-between !important;
      }
    `,
  ],
})
export class ApprovedVendorListComponent implements OnInit, AfterViewInit {
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  public projectData: MatTableDataSource<ICapitalProjectData>;
  public displayedColumns: string[] = [
    "longName",
    "shortName",
    "location",
    "actions",
  ];
  public isLoading = true;

  constructor(
    private service: ProjectAndAvlService,
    private capitalProjectsDataService: CapitalProjectsDataService,
    private router: Router,
    private changeDetectorRefs: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.loadData();
    this.paginator.pageSize = 10;
    this.sort.active = "name";
    this.sort.direction = "asc";
    this.sort.sortChange.emit({
      active: this.sort.active,
      direction: this.sort.direction,
    });
  }

  ngAfterViewInit() {}

  loadData() {
    this.isLoading = true;
    this.service.getAvlData().subscribe((data) => {
      this.projectData = new MatTableDataSource(data);
      this.projectData.sort = this.sort;
      this.projectData.paginator = this.paginator;
      this.paginator.pageSize = 10;
      this.isLoading = false;
      this.changeDetectorRefs.detectChanges();
    });
  }

  editAvl(data: ICapitalProjectData) {
    this.capitalProjectsDataService.setCurrentCapitalProject(data);
    this.capitalProjectsDataService.setBackRoute("/approved-vendor-list/");
    this.router.navigate(["/approved-vendor-list/edit/" + data.projectId]);
  }

  selectProject(data: ICapitalProjectData) {
    this.capitalProjectsDataService.setCurrentCapitalProject(data);
    this.capitalProjectsDataService.setBackRoute("/approved-vendor-list/");
    this.router.navigate(["/approved-vendor-list/preview/" + data.projectId]);
  }

  create() {
    this.router.navigate(["/approved-vendor-list/create"]);
  }
}
