import { Component, OnInit } from "@angular/core";
import { GraphService } from "../../services/administration/graph.service";
import { Sort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { TableSortService } from "../../services/table-sort.service";
import { IUserData } from "../../interfaces/administration/userData";
import { Router } from "@angular/router";
import { UserPermissionsService } from "../../services/common/user-permissions.service";
import { BaseComponent } from "../../shared/base/base.component";

@Component({
  selector: "users-roles-component",
  templateUrl: "./users-roles.component.html",
})
export class UsersAndRolesComponent extends BaseComponent implements OnInit {
  userPermissions: string;
  title = "Administration";
  subtitle = "Users & Roles";
  response: any[];
  usersLoading = true;

  userAndRolesPath = "administration/user-roles";

  dataSource: MatTableDataSource<IUserData>;
  originalDataSource: MatTableDataSource<IUserData>;

  displayedColumns = [
    "actions",
    "cai",
    "lastName",
    "firstName",
    "email",
    "userPermissions",
  ];

  ngOnInit(): void {
    super.ngOnInit();
    this.graphService.getAllUsers().subscribe(
      (data) => {
        this.originalDataSource = new MatTableDataSource<IUserData>(data);
        this.dataSource = new MatTableDataSource<IUserData>(
          this.originalDataSource.data
        );
        this.usersLoading = false;
      },
      (error) => {
        console.error(error);
      }
    );
  }

  constructor(
    private router: Router,
    private graphService: GraphService,
    private tableSortService: TableSortService,
    userPermissionsService: UserPermissionsService,
  ) {
    super(userPermissionsService);
  }

  announceSortChange(event: Sort) {
    this.dataSource.data = this.tableSortService.sortDataSource(
      this.originalDataSource.data,
      event
    );
  }

  editUser(userData: IUserData) {
    this.graphService.updateUserData(userData);
    this.router.navigate([this.userAndRolesPath + "/edit-user"]);
  }

  addUser() {
    this.router.navigate([this.userAndRolesPath + "/add-user"]);
  }
}
