import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "projects/aqua3/src/environments/environment";
import { catchError, Observable, Subject, throwError } from "rxjs";
import { IViewPerformanceTrackingData } from "../../interfaces/vendor-information/viewPerformanceTrackingData";
import { FormGroup } from "@angular/forms";
import { IPerformanceTrackingData } from "../../interfaces/vendor-information/performanceTrackingData";
import { ViewPerformanceRatingData } from "../../interfaces/vendor-information/viewPerformanceRatingData";
import { IPerformanceRatingData } from "../../interfaces/vendor-information/performanceRatingData";
import * as XLSX from "xlsx";

const apiUrl = environment.apiUrl;

@Injectable({
  providedIn: "root",
})
export class PerformanceTrackingService {
  private performanceTrackingId = new Subject<number>();
  performanceTrackingId$: Observable<number> =
    this.performanceTrackingId.asObservable();

  private loadEngineering = new Subject<ViewPerformanceRatingData[]>();
  loadEngineering$: Observable<ViewPerformanceRatingData[]> =
    this.loadEngineering.asObservable();

  private loadProcurement = new Subject<ViewPerformanceRatingData[]>();
  loadProcurement$: Observable<ViewPerformanceRatingData[]> =
    this.loadProcurement.asObservable();

  private loadConstruction = new Subject<ViewPerformanceRatingData[]>();
  loadConstruction$: Observable<ViewPerformanceRatingData[]> =
    this.loadConstruction.asObservable();

  private loadManufacturing = new Subject<ViewPerformanceRatingData[]>();
  loadManufacturing$: Observable<ViewPerformanceRatingData[]> =
    this.loadManufacturing.asObservable();

  private loadStatsData = new Subject<ViewPerformanceRatingData[]>();
  loadStatsData$: Observable<ViewPerformanceRatingData[]> =
    this.loadStatsData.asObservable();

  constructor(private http: HttpClient) {}

  public getPerformanceTrackingData(): Observable<
    IViewPerformanceTrackingData[]
  > {
    return this.http.get<IViewPerformanceTrackingData[]>(
      `${apiUrl}/performance-tracking`
    );
  }

  public createPerformanceTracking(
    perforamcneTracking: IPerformanceTrackingData
  ): Observable<IPerformanceTrackingData> {
    return this.http.post<IPerformanceTrackingData>(
      `${apiUrl}/performance-tracking`,
      perforamcneTracking
    );
  }

  public updatePerformanceTracking(
    performanceTracking: IPerformanceTrackingData
  ): Observable<IPerformanceTrackingData> {
    return this.http.put<IPerformanceTrackingData>(
      `${apiUrl}/performance-tracking`,
      performanceTracking
    );
  }

  public getPerformanceTrackingDataById(
    sptId: number
  ): Observable<IPerformanceTrackingData> {
    return this.http.get<IPerformanceTrackingData>(
      `${apiUrl}/performance-tracking/${sptId}`
    );
  }

  public getPerformanceRatingData(
    sptId: number,
    type: string
  ): Observable<ViewPerformanceRatingData[]> {
    return this.http.get<ViewPerformanceRatingData[]>(
      `${apiUrl}/performance-tracking/${sptId}/get-ratings/${type}/0`
    );
  }

  public getAllPerformanceRatingData(
    sptId: number
  ): Observable<ViewPerformanceRatingData[]> {
    return this.http.get<ViewPerformanceRatingData[]>(
      `${apiUrl}/performance-tracking/${sptId}/get-ratings`
    );
  }

  public createRating(
    sptId: number,
    data: IPerformanceRatingData
  ): Observable<IPerformanceRatingData> {
    return this.http
      .post<IPerformanceRatingData>(
        `${apiUrl}/performance-tracking/${sptId}/save-rating`,
        data
      )
      .pipe(
        catchError((error: HttpErrorResponse) => {
          console.error("Error creating rating:", error);
          return throwError(() => new Error("Error creating rating"));
        })
      );
  }

  public updateRating(
    sptId: number,
    data: IPerformanceRatingData
  ): Observable<IPerformanceRatingData> {
    return this.http
      .put<IPerformanceRatingData>(
        `${apiUrl}/performance-tracking/${sptId}/update-rating`,
        data
      )
      .pipe(
        catchError((error: HttpErrorResponse) => {
          console.error("Error updating rating:", error);
          return throwError(() => new Error("Error updating rating"));
        })
      );
  }

  private ratingNames: string[] = [
    "Construction",
    "Engineering",
    "Manufacturing",
    "Quality",
    "Schedule",
    "Cooperation",
    "Procurement",
    "Inspector",
  ];

  private getRatingNameById(id: number): string | undefined {
    if (id >= 0 && id < this.ratingNames.length) {
      return this.ratingNames[id];
    }
    return undefined; // or throw an error if you prefer
  }

  public loadRatingDataTab(sptId: number, type: number) {
    this.getPerformanceRatingData(
      sptId,
      this.getRatingNameById(type)
    ).subscribe((data: ViewPerformanceRatingData[]) => {
      if (data) {
        this.performanceTrackingId.next(sptId);
        if (this.getRatingNameById(type) === "Engineering") {
          this.loadEngineering.next(data);
        }
        if (this.getRatingNameById(type) === "Procurement") {
          this.loadProcurement.next(data);
        }
        if (this.getRatingNameById(type) === "Construction") {
          this.loadConstruction.next(data);
        }
        if (this.getRatingNameById(type) === "Manufacturing") {
          this.loadManufacturing.next(data);
        }
      }
    });
  }

  public loadStatsDataTab(sptId: number) {
    this.getAllPerformanceRatingData(sptId).subscribe(
      (data: ViewPerformanceRatingData[]) => {
        this.loadStatsData.next(data);
      }
    );
  }

  public validatePerformanceForm(form: FormGroup) {
    let poNumber: string = form.get("poNumber").value;
    let poRequiredDate: string = form.get("poRequiredDate").value;
    let vendor: string = form.get("vendor").value;
    let commodity: string = form.get("commodity").value;

    let messagesValidationsList: string[] = [];

    if (poNumber === "")
      messagesValidationsList.push(
        "A P.O. number associated with the purchase of the selected commodity from the selected vendor is required to enter performance tracking."
      );

    if (poRequiredDate === "")
      messagesValidationsList.push(
        "A P.O. required date associated with the purchase of the selected commodity from the selected vendor is required to enter performance tracking."
      );

    if (vendor === "")
      messagesValidationsList.push(
        "A vendor is required for performance tracking."
      );

    if (commodity === "")
      messagesValidationsList.push(
        "A commodity is required for performance tracking."
      );

    return messagesValidationsList;
  }

  public processFile(file: File, sptid: number): string[] {
    // Return array of strings
    const errors: string[] = [];
    const reader = new FileReader();

    reader.onload = (e: any) => {
      const arrayBuffer: ArrayBuffer = e.target.result;
      const data = new Uint8Array(arrayBuffer);
      const arr = Array.from(data)
        .map((byte) => String.fromCharCode(byte))
        .join("");
      const wb: XLSX.WorkBook = XLSX.read(arr, { type: "binary" });

      const wsname: string = "Form";
      const ws: XLSX.WorkSheet = wb.Sheets[wsname];

      if (!ws) {
        errors.push(
          "Could not find a valid worksheet within the uploaded file."
        );
        return;
      }

      const jsonData = XLSX.utils.sheet_to_json(ws, { header: 1 }) as any[][];
      this.processWorksheetData(jsonData, sptid, errors); // Pass errors array to processWorksheetData
    };

    reader.readAsArrayBuffer(file);
    return errors;
  }

  private processWorksheetData(
    data: any[][],
    sptid: number,
    errors: string[]
  ): void {
    // Added errors parameter
    const worksheetIdentifier = data[1][4]; // Assuming "E2" is in the second row, fifth column
    const worksheetVersion = data[2][4]; // Assuming "E3" is in the third row, fifth column

    if (!worksheetIdentifier || !worksheetVersion) {
      errors.push("Invalid worksheet version or identifier.");
      return;
    }

    if (
      worksheetVersion.startsWith("Rev.") &&
      worksheetVersion.endsWith("3") &&
      worksheetIdentifier === "QMS-2330-PUR-004-01-FRM"
    ) {
      const performanceData = this.extractPerformanceData(data);
      this.createPerformanceRatingData(performanceData, sptid, errors); // Pass errors array to createPerformanceRatingData
    } else {
      errors.push("Unsupported worksheet version.");
    }
  }

  private createPerformanceRatingData(
    performanceData: any,
    sptid: number,
    errors: string[]
  ): void {
    // Added errors parameter
    const ratings: IPerformanceRatingData[] = [];

    for (const section in performanceData) {
      if (performanceData.hasOwnProperty(section)) {
        performanceData[section].forEach((item: any) => {
          const ratingData: IPerformanceRatingData = {
            performanceRatingId: 0, // Assuming 0 for new records; adjust as needed
            performanceTrackingId: sptid,
            keyPerformanceIndicatorId: item.kpi, // Assuming item.kpi holds the KPI ID
            rating: item.rating,
            comments: item.comments,
            ratedByUserId: 1, // Replace with actual user ID
            ratedDate: new Date().toISOString(),
            performanceRatingApprovalId: 0, // Assuming 0 for new records; adjust as needed
          };
          ratings.push(ratingData);
        });
      }
    }

    ratings.forEach((rating) => {
      this.updateRating(sptid, rating).subscribe((response) => {
        console.log("Rating updated successfully.");
      });
    });
  }

  private extractPerformanceData(data: any[][]): { [key: string]: any[] } {
    const performanceData: { [key: string]: any[] } = {};

    performanceData["engineering"] = this.extractSectionData(data, 14, 18);
    performanceData["procurement"] = this.extractSectionData(data, 21, 25);
    performanceData["manufacturing quality"] = this.extractSectionData(
      data,
      29,
      34
    );
    performanceData["manufacturing schedule"] = this.extractSectionData(
      data,
      37,
      42
    );
    performanceData["manufacturing cooperation"] = this.extractSectionData(
      data,
      45,
      50
    );
    performanceData["construction"] = this.extractSectionData(data, 53, 57);

    return performanceData;
  }

  private extractSectionData(
    data: any[][],
    startRow: number,
    endRow: number
  ): any[] {
    const sectionData: any[] = [];

    for (let row = startRow; row <= endRow; row++) {
      const kpi = data[row][2]; // Assuming KPI ID is in column C
      const rating = data[row][3]; // Assuming rating is in column D
      const comments = data[row][4]; // Assuming comments are in column E

      if (kpi && rating) {
        sectionData.push({
          kpi,
          rating,
          comments,
        });
      }
    }

    return sectionData;
  }
}
