import { NgModule } from "@angular/core";
import { MenuModule } from "../menu/menu.module";
import { AddVendorComponent } from "./add-vendor/add-vendor.component";
import { CreateNewVendorComponent } from "./add-vendor/create-new-vendor.component";
import { VendorSearchComponent } from "./vendor-search/vendor-search.component";
import { AddCommodityToVendorComponent } from "./add-commodity-to-vendor/add-commodity-to-vendor.component";
import { VendorDetailsComponent } from "./vendor-search/vendor-details.component";
import { PerformanceTrackingComponent } from "./performance-tracking/performance-tracking.component";
import { CreatePerformanceTrackingComponent } from "./performance-tracking/create-performance-tracking.component";
import { EditPerformanceTrackingComponent } from "./performance-tracking/edit-performance-tracking.component";
import { RatingExplanationsTabComponent } from "./performance-tracking/tabs/rating-explanations-tab.component";
import { EngineeringTabComponent } from "./performance-tracking/tabs/engineering-tab.component";
import { ProcurementTabComponent } from "./performance-tracking/tabs/procurement-tab.component";
import { ConstructionTabComponent } from "./performance-tracking/tabs/construction-tab.component";
import { ManufacturingTabComponent } from "./performance-tracking/tabs/manufacturing-tab.component";
import { PreviewPerformanceTrackingComponent } from "./performance-tracking/preview-performance-tracking.component";
import { StatsTabComponent } from "./performance-tracking/tabs/stats-tab.component";
import { UploadPerformanceTrackingComponent } from "./performance-tracking/upload-performance-tracking.component";

const componentList = [
  AddVendorComponent,
  CreateNewVendorComponent,
  VendorSearchComponent,
  VendorDetailsComponent,
  AddCommodityToVendorComponent,
  PerformanceTrackingComponent,
  CreatePerformanceTrackingComponent,
  EditPerformanceTrackingComponent,
  RatingExplanationsTabComponent,
  PreviewPerformanceTrackingComponent,
  UploadPerformanceTrackingComponent,
];

@NgModule({
  imports: [MenuModule],
  declarations: [componentList],
})
export class VendorInformationModule {}
