import { Injectable } from '@angular/core';
import { BehaviorSubject, map, Observable } from 'rxjs';
import { ILoggedUserData } from '../../interfaces/common/loggedUserData';
import { UserRequestsDataService } from '../administration/user-requests.service';
import { MsalService } from '@azure/msal-angular';
import { IUserData } from '../../interfaces/administration/userData';

@Injectable({
  providedIn: 'root'
})
export class LoggedUserService {
  private loggedUsername: string = "";
  private loggedUserDataSubject = new BehaviorSubject<ILoggedUserData | null>(null);
  public loggedUserData$: Observable<ILoggedUserData | null> = this.loggedUserDataSubject.asObservable();

  constructor(private userRequestsDataService: UserRequestsDataService, private msalService: MsalService,) { }

  setLoggedUserData(data: ILoggedUserData): void {
    this.loggedUserDataSubject.next(data);
  }

  getLoggedUserData(): Observable<ILoggedUserData> {
    const currentValue = this.loggedUserDataSubject.getValue();

    if (currentValue !== null) {
      return this.loggedUserData$;
    } else {
      let accountInfo = this.msalService.instance.getActiveAccount();
      this.loggedUsername = encodeURIComponent(accountInfo.username);

      return this.userRequestsDataService.getUserByEmail(this.loggedUsername).pipe(
        map((data) => {
          const userData: IUserData = data;
          this.userRequestsDataService.setActualLoggedUserData(userData);
          const loggedUserData: ILoggedUserData = {
            userId: userData.userId,
            cai: userData.cai,
            email: userData.email,
            isSystemAdministrator: userData.isSystemAdministrator,
            isQualityAssuranceAndControlUser: userData.isQualityAssuranceAndControlUser,
            isProjectTeamMember: userData.isProjectTeamMember,
            isQualityAssuranceAdministrator: userData.isQualityAssuranceAdministrator,
          };

          this.setLoggedUserData(loggedUserData);
          return loggedUserData;
        })
      );
    }
  }

  getLoggedUserDataFromApi(): Observable<ILoggedUserData> {
    let accountInfo = this.msalService.instance.getActiveAccount();
    this.loggedUsername = accountInfo.username;
    return this.userRequestsDataService
      .getUserByEmail(this.loggedUsername)
      .pipe(
        map((data) => {
          const userData: IUserData = data;
          this.userRequestsDataService.setActualLoggedUserData(userData);
          const loggedUserData: ILoggedUserData = {
            userId: userData.userId,
            cai: userData.cai,
            email: userData.email,
            isSystemAdministrator: userData.isSystemAdministrator,
            isQualityAssuranceAndControlUser: userData.isQualityAssuranceAndControlUser,
            isProjectTeamMember: userData.isProjectTeamMember,
            isQualityAssuranceAdministrator: userData.isQualityAssuranceAdministrator,
          };

          this.setLoggedUserData(loggedUserData);
          return loggedUserData;
        })
      );
  }

}