import { Component } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { CommodityCodesService } from '../../services/administration/commodity-codes.service';
import { ICommodity } from '../../interfaces/administration/commodity';
import { MatTreeNestedDataSource } from '@angular/material/tree';
import { NestedTreeControl } from '@angular/cdk/tree';
import { CommodityViewMode } from '../../interfaces/administration/commodityViewMode';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BaseComponent } from '../../shared/base/base.component';
import { UserPermissionsService } from '../../services/common/user-permissions.service';

@Component({
  selector: 'commodity-codes',
  templateUrl: './commodity-codes.component.html',
  styleUrls: ["./commodity-codes.component.css"],
})
export class CommodityCodesComponent extends BaseComponent {
  ViewMode = CommodityViewMode;
  title = "Commodity Codes";
  public commoditiesData!: MatTableDataSource<ICommodity>;
  public commoditiesIsLoading: boolean = true;
  public choosenNode: ICommodity;
  public currentViewMode: CommodityViewMode = CommodityViewMode.Base;
  public parentId: number = 0;
  commoditySearchForm: FormGroup;
  searchDropdownOptions = [
    {
      id: 1,
      name: "Search by Commodity Description",
    },
    {
      id: 2,
      name: "Select for DeActivated Commodities",
    }
  ];
  isSearchInputDisabled = false;
  validationMessages = [];

  treeControl = new NestedTreeControl<ICommodity>(node => node.children || []);
  dataSource = new MatTreeNestedDataSource<ICommodity>();

  constructor(private commodityCodesService: CommodityCodesService,
    private fb: FormBuilder,
    userPermissionsService: UserPermissionsService
  ) {
    super(userPermissionsService);
  }

  ngOnInit() {
    super.ngOnInit();
    this.commoditySearchForm = this.fb.group({
      searchInput: ['', Validators.required],
      searchDropdown: [1, Validators.required]
    });
    this.loadCommodities();
    this.commodityCodesService.commodityViewMode$.subscribe((viewMode) => {
      this.currentViewMode = viewMode;
    });
    this.commodityCodesService.choosenNode$.subscribe((node) => {
      this.choosenNode = node;
    });
    this.commodityCodesService.commoditiesIsLoading$.subscribe((isLoading) => {
      this.commoditiesIsLoading = isLoading;
    });
  }

  loadCommodities() {
    this.commodityCodesService.setCommoditiesIsLoading(true);
    this.commoditySearchForm.get('searchInput').setValue('');
    this.commodityCodesService.getAllCommodities().subscribe(
      (commodities) => {
        this.dataSource.data = commodities;
        this.expandNode(this.dataSource.data[0]);
      }
    );
  }

  getChildren(node: ICommodity) {
    return this.commodityCodesService.getChildren(node.commodityId);
  }

  hasChild(_: number, node: ICommodity) {
    if (node.children != null) {
      return node.children.length > 0;
    } else {
      return false;
    }
  }

  showDeactivated(node: ICommodity): string {
    return node.isActive ? '' : '(Deactivated) ';
  }

  chooseNode(node: ICommodity) {
    this.changeViewMode(CommodityViewMode.Information);
    this.commodityCodesService.sendChoosenNode(node);
  }

  getNodeStatus(node: ICommodity): string {
    return node.isActive ? 'Active Commodity' : 'Inactive Commodity';
  }

  editCommodity() {
    this.changeViewMode(CommodityViewMode.Edit);
  }

  addChildCommodity(parentId: number) {
    this.parentId = parentId;
    this.changeViewMode(CommodityViewMode.Add);
  }

  backToSearch() {
    this.changeViewMode(CommodityViewMode.Base);
    this.choosenNode = null;
  }

  smeCaiAndName(node: ICommodity): string {
    if (node.subjectMatterExpertUserId > 0) {
      return node.smecai + ': ' + node.smefirstName + ' ' + node.smelastName;
    } else {
      return 'No Specific SME. Defaults to SME of the Parent Commodity';
    }
  }

  sendEmail(choosenNode: ICommodity) {
    if (choosenNode.subjectMatterExpertUserId > 0) {
      const email = choosenNode.smeemail;
      const subject = encodeURIComponent(`Regarding Commodity Code ${choosenNode.commodityCodeTrimmed}`);
      window.location.href = `mailto:${email}?subject=${subject}`;
    }
  }

  changeViewMode(viewMode: CommodityViewMode): void {
    this.commodityCodesService.updateCommodityViewMode(viewMode);
  }

  onDropdownChange(event: Event): void {
    const selectedValue = (event.target as HTMLSelectElement).value;
    console.log("selectedValue" + selectedValue);
    this.isSearchInputDisabled = selectedValue == '2';
    if (this.isSearchInputDisabled) {
      var searchInput = this.commoditySearchForm.get('searchInput');
      searchInput.setValue('');
      searchInput.disable();
    } else {
      this.commoditySearchForm.get('searchInput')?.enable();
    }
  }

  searchCommodity() {
    this.commoditySearchForm.markAllAsTouched();
    var searchOption = this.commoditySearchForm.get('searchDropdown').value;
    var searchText = this.commoditySearchForm.get('searchInput').value;

    this.validationMessages = [];

    if (searchOption == 1 && searchText.length < 3) {
      this.validationMessages.push("Search text must be at least 3 characters long");
    }

    if (this.commoditySearchForm.valid && this.validationMessages.length == 0) {
      this.collapsePrevoiusSearchedNodes();

      if (searchOption == 1) {
        this.commodityCodesService.searchCommodity(searchText).subscribe(
          (_) => {
            this.expandSearchedNodes();
          }
        );
      } else {
        this.commodityCodesService.searchDeactivatedCommodity().subscribe(
          () => {
            this.expandSearchedNodes();
          }
        );
      }
    }
  }

  expandSearchedNodes() {
    this.expandSearchedNodesRecursive(this.dataSource.data[0]);
  }

  expandSearchedNodesRecursive(node: ICommodity) {
    if (node.children?.length > 0) {
      node.children.forEach(child => {
        if (child.shouldExpand) {
          this.expandNode(child);
        }
        this.expandSearchedNodesRecursive(child);
      });
    }
  }

  expandNode(node: ICommodity) {
    node.shouldExpand = true;
    this.treeControl.expand(node);
  }

  collapseNode(node: ICommodity) {
    node.shouldExpand = false;
    this.treeControl.collapse(node);
  }

  collapsePrevoiusSearchedNodes() {
    this.dataSource.data[0].children.forEach(element => {
      this.treeControl.collapseDescendants(element);
    });
  }

  activateDeactivateCommodity() {
    this.commodityCodesService.activateDeactivateCommodity(this.choosenNode.commodityId.toString()).subscribe(
      (_) => {
        this.changeViewMode(CommodityViewMode.Base);
        this.loadCommodities();
      });
  }
}