import {
  Component,
  OnInit,
  AfterViewInit,
  ViewChild,
  Output,
  EventEmitter,
} from "@angular/core";
import { FormGroup, FormBuilder } from "@angular/forms";
import { MatPaginator, PageEvent } from "@angular/material/paginator";
import { MatSort, Sort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { Router } from "@angular/router";
import { Observable } from "rxjs";
import { ICommodity } from "../../interfaces/administration/commodity";
import { ILookupData } from "../../interfaces/administration/lookupData";
import { ICommoditySearchData } from "../../interfaces/vendor-information/ICommoditySearchData";
import { UserPermissionsService } from "../../services/common/user-permissions.service";
import { CountriesAndStatesService } from "../../services/countries-states.service";
import { TableSortService } from "../../services/table-sort.service";
import { VendorDataService } from "../../services/vendor-data.service";
import { BaseComponent } from "../../shared/base/base.component";

@Component({
  selector: "vendor-search-component",
  templateUrl: "./vendor-search.component.html",
  styles: [
    `
      .button-container {
        display: flex !important;
        justify-content: space-between !important;
      }

      .form-check-inline {
        display: inline-block;
        margin-right: 10px;
      }

      .form-group.row.align-items-center {
        display: flex;
        align-items: center;
      }

      .tbl {
        border: 1px solid #ccc;
        padding: 10px;
        margin-top: 20px;
      }

      .tbl p {
        font-size: 0.9em;
      }
    `,
  ],
})
export class VendorSearchComponent
  extends BaseComponent
  implements OnInit, AfterViewInit
{
  title = "Agencies";
  dataSource: MatTableDataSource<ICommoditySearchData> =
    new MatTableDataSource<ICommoditySearchData>();
  originalDataSource: MatTableDataSource<ICommoditySearchData> =
    new MatTableDataSource<ICommoditySearchData>();
  filtersForm: FormGroup;
  countries$: Observable<ILookupData[]>;
  states$: Observable<ILookupData[]>;
  totalData: number = 0;

  @ViewChild("paginator") paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @Output() clearCommodityCodesEvent = new EventEmitter<void>();

  displayedColumns: string[] = [
    "supplierVendorName",
    "etcAmlSupplier",
    "imaSupplier",
    "supCr",
    "manufacturerVendorName",
    "etcAmlManufacturer",
    "imaManufacturer",
    "mfgCr",
    "brandName",
    "commodityCodeTrimmed",
    "commodityDescriptionPath",
  ];

  dataSourceLoading: boolean = true;
  commoditiesData: ICommodity[] = [];
  selectedCommodityObjects: ICommodity[] = [];

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private service: VendorDataService,
    private tableSortService: TableSortService,
    private countriesAndStatesService: CountriesAndStatesService,
    userPermissionsService: UserPermissionsService
  ) {
    super(userPermissionsService);
    this.filtersForm = this.fb.group({
      vendorName: [""],
      city: [""],
      country: [""],
      stateProvince: [""],
      imaNumber: [""],
      commodityIds: [null],
      commodityCode: [""],
      commodityDescription: [""],
      isSmallDiversity: [false],
    });
  }

  ngOnInit(): void {
    super.ngOnInit();

    // Load countries
    this.countries$ = this.countriesAndStatesService.getAllCountries();

    // Subscribe to country changes to load states
    this.filtersForm.get("country").valueChanges.subscribe((countryId) => {
      this.onCountryChange(countryId);
    });

    this.updateListings();
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;

    this.paginator.page.subscribe((event: PageEvent) => {
      this.updateListings(event.pageIndex + 1, event.pageSize);
    });
  }

  onCountryChange(countryId: string): void {
    if (countryId) {
      this.states$ =
        this.countriesAndStatesService.getStateByCountryId(countryId);
    } else {
      this.states$ = null;
    }
  }

  handleSelectedCommodityNames(selectedCommodityNames: ICommodity[]) {
    this.selectedCommodityObjects = selectedCommodityNames;
    if (selectedCommodityNames.length != 0) {
      this.filtersForm.patchValue({
        commodityIds: selectedCommodityNames.map(
          (commodity) => commodity.commodityId
        ),
      });
    } else {
      this.filtersForm.patchValue({
        commodityIds: null,
      });
    }
  }

  announceSortChange(event: Sort) {
    this.dataSource.data = this.tableSortService.sortDataSource(
      this.originalDataSource.data,
      event
    );
  }

  updateListings(pageIndex: number = 1, pageSize: number = 10) {
    this.dataSourceLoading = true;

    const formValues = this.filtersForm.value;
    const params = {
      vendorName: formValues.vendorName,
      city: formValues.city,
      country: formValues.country,
      stateProvince: formValues.stateProvince,
      imaNumber: formValues.imaNumber,
      commodityDescription: formValues.commodityDescription,
      commodityIds: this.selectedCommodityObjects
        .map((c) => c.commodityId)
        .join(","),
      commodityCode: formValues.commodityCode,
      isSmallDiversity: formValues.isSmallDiversity,
      pageIndex: pageIndex,
      pageSize: pageSize,
    };

    this.service
      .searchVendorCommodity(
        params.vendorName,
        params.city,
        params.country,
        params.stateProvince,
        params.imaNumber,
        params.commodityIds,
        params.commodityCode,
        params.commodityDescription,
        params.isSmallDiversity,
        params.pageIndex,
        params.pageSize
      )
      .subscribe({
        next: (response) => {
          this.originalDataSource =
            new MatTableDataSource<ICommoditySearchData>(response.items);
          this.dataSource = new MatTableDataSource<ICommoditySearchData>(
            this.originalDataSource.data
          );

          setTimeout(() => {
            this.paginator.pageSize = pageSize;
            this.totalData = response.totalCount;
            this.paginator.pageIndex = pageIndex - 1;
          });
          this.dataSourceLoading = false;
        },
        error: (error) => {
          console.error(error);
          this.dataSourceLoading = false;
        },
      });
  }

  applyFilters() {
    // Reset paginator to first page
    if (this.paginator) {
      this.paginator.pageIndex = 0;
    }
    this.updateListings();
  }

  public clearFilters() {
    this.filtersForm.patchValue({
      vendorName: "",
      city: "",
      country: "",
      stateProvince: "",
      imaNumber: "",
      commodityCode: "",
      commodityDescription: "",
    });

    this.selectedCommodityObjects = [];
    this.clearCommodityCodesEvent.emit();

    // Reset paginator to first page
    if (this.paginator) {
      this.paginator.pageIndex = 0;
    }

    this.updateListings();
  }
}
