// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.red-box {  
    background-color: #ffe6e6;
    color: red;
    border: 2px solid red;  
    padding: 10px;  
    margin-top: 20px;  
  } `, "",{"version":3,"sources":["webpack://./projects/aqua3/src/app/home/home.component.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB,UAAU;IACV,qBAAqB;IACrB,aAAa;IACb,gBAAgB;EAClB","sourcesContent":[".red-box {  \n    background-color: #ffe6e6;\n    color: red;\n    border: 2px solid red;  \n    padding: 10px;  \n    margin-top: 20px;  \n  } "],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
