import { Component, OnInit } from '@angular/core';
import { DatePipe } from '@angular/common';
import { HttpParams } from '@angular/common/http';
import { ManufacturersNameService } from '../../../services/qa-reports/manufacturers-name.service';
import { Router } from '@angular/router';
 
@Component({
  selector: 'app-assignmentlog',
  templateUrl: './assignmentlog.component.html',
  styleUrls: ['./assignmentlog.component.css'],
  providers: [DatePipe]
})
export class AssignmentlogComponent implements OnInit{
  startDate: string;
  endDate: string;
  validationMessages: string[] = [];
  isLoading = false;
 
  constructor(private datePipe: DatePipe, private manufacturersNameService:ManufacturersNameService, private router:Router) {}
  ngOnInit(): void {
    const currentYear = new Date().getFullYear();
    this.startDate = this.datePipe.transform(new Date(currentYear, 0, 1), 'yyyy-MM-dd') || '';
    this.endDate = this.datePipe.transform(new Date(), 'yyyy-MM-dd') || '';
  }
 
  // Reset Start date
  resetStartDate() {
    this.startDate = '';
  }
  cancel() {
    this.router.navigate(['/reports-forms'], { queryParams: { tab: 'qaReports' } });
  }
 
  // Reset End date
  resetEndDate() {
    this.endDate = '';
  }
  isValidDate(dateString: string): boolean {
    const date = new Date(dateString);
    return date instanceof Date && !isNaN(date.getTime());
  }
  generateReport() {
    this.validationMessages = [];
    this.isLoading = true;
 
    if (!this.startDate) {
      this.validationMessages.push('Start date is required.');
      this.isLoading=false;
    }
    if (!this.endDate) {
      this.validationMessages.push('End date is required.');
      this.isLoading=false;
    }
    if (new Date(this.startDate) > new Date(this.endDate)) {
      this.validationMessages.push('Start date cannot be after end date.');
      this.isLoading=false;
    }
 
    if (this.validationMessages.length > 0) {
      return;
    }
    const formattedStartDate = this.datePipe.transform(this.startDate, 'yyyy-MM-dd');
    const formattedEndDate = this.datePipe.transform(this.endDate, 'yyyy-MM-dd');
 
    const params = new HttpParams()
      .set('StartDate', formattedStartDate ?? 0)
      .set('EndDate', formattedEndDate ?? 0);
 
    this.manufacturersNameService.getAssignmentLog(params).subscribe(response => {
      const blob = new Blob([response], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = `AQUA3_QualityAssuranceAssignmentLog_${new Date().toISOString().slice(0, 19).replace(/:/g, '-')}.xlsx`;
      a.click();
      window.URL.revokeObjectURL(url);
      this.isLoading = false;
    }, error => {
      console.error('Error downloading the Assignment Log report', error);
    });
  }
 
}
 