// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.date-form {
    width: inherit;
  }
  .button-container {
    display: flex;
    gap: 30px;
  }`, "",{"version":3,"sources":["webpack://./projects/aqua3/src/app/reports-forms/qa-reports/qabyinspector/qabyinspector.component.css"],"names":[],"mappings":"AAAA;IACI,cAAc;EAChB;EACA;IACE,aAAa;IACb,SAAS;EACX","sourcesContent":[".date-form {\n    width: inherit;\n  }\n  .button-container {\n    display: flex;\n    gap: 30px;\n  }"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
