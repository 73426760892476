import { Inject, Injectable } from '@angular/core';
import { NextPageService } from '@cvx/nextpage';
import { NextPageRoute } from '@cvx/nextpage/public_api';
import { RolePrivilegesDataService } from './administration/role-privileges.service';
import { IRolePrivilegesData } from '../interfaces/administration/rolePrivilegesData';
import { BehaviorSubject, map, Observable } from 'rxjs';
import { MSAL_INSTANCE } from '@azure/msal-angular';
import { IPublicClientApplication } from '@azure/msal-browser';
import { PrivilegesAccessType } from '../interfaces/common/privilegesAccessTypeData';

@Injectable({
    providedIn: 'root'
})
export class RoutesService {
    private allowedPages: { pageName: string, subPageName: string }[] = [];
    private filteredRoutes: NextPageRoute[] = [];

    filteredRoutesLoaded = new BehaviorSubject<boolean>(false);

    constructor(private rolePrivilegesService: RolePrivilegesDataService,
        private NP: NextPageService,
        @Inject(MSAL_INSTANCE) private msalInstance: IPublicClientApplication,
    ) { }

    setFilteredRoutes(routes: NextPageRoute[]) {
        this.filteredRoutes = routes;
    }

    getFilteredRoutes(): NextPageRoute[] {
        return this.filteredRoutes;
    }

    public filterRoutes(routes: NextPageRoute[]): Observable<NextPageRoute[]> {
        return this.loadAllowedPages().pipe(
            map(() => {
                return routes.filter(route => this.filterRoute(route));
            })
        );
    }

    public loadAllowedPages(): Observable<void> {
        return this.rolePrivilegesService.getAllRolePrivileges().pipe(map((data: IRolePrivilegesData[]) => {
            this.allowedPages = data
                .filter(privilege => privilege.accessType == PrivilegesAccessType.R || privilege.accessType == PrivilegesAccessType.RW)
                .map(privilege => ({
                    pageName: privilege.pageCategory,
                    subPageName: privilege.pageSubCategory
                }));
        }));
    }

    public filterRoutesForMenu(routes: NextPageRoute[]) {
        this.msalInstance.initialize()
            .then(() => {
                return this.filterRoutes(routes).subscribe(filteredRoutes => {
                    routes = filteredRoutes;
                    this.NP.setOption('navigationRoutes', routes);
                    this.NP.UI.setNavigation(routes);
                    this.NP.options.navigationRoutes = routes;
                    this.NP.UI.options.navigationRoutes = routes;
                    this.NP.buildMenuStructure();
                    this.filteredRoutesLoaded.next(true);
                });
            })
            .catch(error => {
                console.error('Error initializing MSAL instance', error);
            });
    }

    private filterRoute(route: NextPageRoute): boolean {
        if (route.data && route.data.pageName && route.data.subPageName) {
            const isAllowed = this.allowedPages.some(
                allowed => allowed.pageName == route.data.pageName && allowed.subPageName == route.data.subPageName
            );
            if (!isAllowed) {
                return false;
            }
        }

        if (route.children) {
            route.children = route.children.filter(child => this.filterRoute(child));
        }

        return true;
    }


}