import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { ILookupData } from "../interfaces/administration/lookupData";
import { BusOpcosDataService } from "../services/administration/bus-opcos-data-service";
import { ICapitalProjectData } from "../interfaces/administration/capitalProjectData";
import { PurchaserManagementService } from "../services/administration/purchaser-management.service";
import { CapitalProjectsDataService } from "../services/capital-projects.service";
import { BaseComponent } from "../shared/base/base.component";
import { UserPermissionsService } from "../services/common/user-permissions.service";

@Component({
  selector: "preview-project-component",
  templateUrl: "./preview-project.component.html",
  styles: [
    `
      .button-container {
        display: flex !important;
        justify-content: space-between !important;
      }
    `,
  ],
})
export class PreviewProjectComponent extends BaseComponent implements OnInit {
  public title = "Create new project";
  public projectData: IExtendedCapitalProjectData;
  public isLoading = false;
  public validationMessages = [];
  public opcoids: ILookupData[] = [];
  public businessUnits: ILookupData[] = [];
  public purchasers: ILookupData[] = []; // Add the purchasers array back
  public activeTab: string = "avl"; // Default to the first tab

  constructor(
    private service: BusOpcosDataService,
    private capitalProjectsDataService: CapitalProjectsDataService,
    private purchaserService: PurchaserManagementService,
    private router: Router,
    private fb: FormBuilder,
    userPermissionService: UserPermissionsService
  ) {
    super(userPermissionService);
    this.projectData = {
      projectId: 0,
      longName: "",
      shortName: "",
      opcoid: 0,
      sbuid: 0,
      description: "",
      location: "",
      epcid: 0,
      isProjectArchived: false,
      opcoName: "",
      busName: "",
      purchaserName: "",
    };
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.isLoading = true;
    let id = this.router.url.split("/")[3];

    this.capitalProjectsDataService
      .getCapitalProjectById(Number(id))
      .subscribe((data) => {
        this.projectData = data;
        this.getOpco();
        this.getBus();
        this.getPurchaser();
        this.setActiveTab();
        this.capitalProjectsDataService.setCurrentCapitalProject(
          this.projectData
        );
        this.isLoading = false;
      });
  }

  getOpco() {
    this.service
      .getOpcoById(this.projectData.opcoid)
      .subscribe((data: ILookupData) => {
        this.projectData.opcoName = data.name;
      });
  }

  getPurchaser() {
    this.purchaserService
      .getPurchaserById(this.projectData.epcid.toString())
      .subscribe((data: ILookupData) => {
        this.projectData.purchaserName = data.name;
      });
  }

  getBus() {
    this.service
      .getBUById(this.projectData.sbuid)
      .subscribe((data: ILookupData) => {
        this.projectData.busName = data.name;
      });
  }

  editProject() {
    this.capitalProjectsDataService.setBackRoute(
      "/approved-vendor-list/preview/" + this.projectData.projectId
    );
    this.router.navigate([
      "/approved-vendor-list/edit/" + this.projectData.projectId,
    ]);
  }

  cancelRequest() {
    this.router.navigate(["/approved-vendor-list"]);
  }

  setActiveTab(tabName: string = "avl"): void {
    this.activeTab = tabName;

    if (tabName === "avl")
      this.capitalProjectsDataService.loadAvlsForProject(this.projectData);
    else if (tabName === "projectMembers")
      this.capitalProjectsDataService.loadMembers(this.projectData.projectId);
    else if (tabName === "supplierAlerts")
      this.capitalProjectsDataService.loadAlertsForProject(this.projectData);
  }
}

export interface IExtendedCapitalProjectData extends ICapitalProjectData {
  opcoName?: string;
  busName?: string;
  purchaserName?: string;
}
