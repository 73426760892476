import { Component, OnInit } from '@angular/core';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-qabyinspector',
  templateUrl: './qabyinspector.component.html',
  styleUrls: ['./qabyinspector.component.css'],
  providers: [DatePipe]
})
export class QabyinspectorComponent implements OnInit {
  startDate: string; 
  endDate: string;

  constructor(private datePipe: DatePipe) {}

  ngOnInit(): void {
    const currentYear = new Date().getFullYear();
    this.startDate = this.datePipe.transform(new Date(currentYear, 0, 1), 'yyyy-MM-dd') || '';
    this.endDate = this.datePipe.transform(new Date(), 'yyyy-MM-dd') || '';
  }

  // Reset Start date
  resetStartDate() {
    this.startDate = '';
  }

  // Reset End date
  resetEndDate() {
    this.endDate ='';
  }
}
