import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { environment } from "projects/aqua3/src/environments/environment";
import { VendorCommodityRequest } from "../../interfaces/vendor-information/vendorCommodityRequest";
import { VendorCommodityData } from "../../interfaces/vendor-information/vendorCommodityData";
import { VendorCommoditySearchData } from "../../interfaces/vendor-information/viewVendorCommoditySearchData";
import { IViewVendorCommodityData } from "../../interfaces/vendor-information/viewVendorCommodityData";
import { IVendorData } from "../../interfaces/administration/vendorData";

const apiUrl = environment.apiUrl + "/vendorCommodity";

@Injectable({
  providedIn: "root",
})
export class VendorCommodityDataService {
  constructor(private http: HttpClient) {}

  public getManufacturerCommodityCountByCommodityId(
    manufacturerId: string,
    commodityId: string
  ): Observable<number> {
    return this.http.get<number>(
      `${apiUrl}/manufacturerId/${manufacturerId}/commodityId/${commodityId}`
    );
  }

  public getSupplierCommodityCountByCommodityId(
    supplierId: string,
    commodityId: string
  ): Observable<number> {
    return this.http.get<number>(
      `${apiUrl}/supplierId/${supplierId}/commodityId/${commodityId}`
    );
  }

  public insertVendorCommodityRequest(
    vendorCommodityRequest: VendorCommodityRequest
  ): Observable<VendorCommodityRequest[]> {
    return this.http.post<VendorCommodityRequest[]>(
      `${apiUrl}/insertRequest`,
      vendorCommodityRequest
    );
  }

  public insertVendorCommodity(
    vendorId: number,
    commodityId: number,
    vendorCommodity: VendorCommodityRequest
  ): Observable<VendorCommodityRequest> {
    return this.http.post<VendorCommodityRequest>(
      `${apiUrl}/insertVendorCommodity/${vendorId}/commodityId/${commodityId}`,
      vendorCommodity
    );
  }

  public updateVendorCommodity(
    vendorId: number,
    editingCommodityId: number,
    brandName: string,
    VendorCommodityRequestDto: VendorCommodityData
  ): Observable<VendorCommodityData> {
    let updateCommodityRequest = {
      BrandName: brandName,
      VendorCommodityRequestDto: VendorCommodityRequestDto,
    };
    return this.http.put<VendorCommodityData>(
      `${apiUrl}/updateVendorCommodity/${vendorId}/commodityId/${editingCommodityId}`,
      updateCommodityRequest
    );
  }

  public getVendorCommodityById(
    vendorCommodityId: number
  ): Observable<VendorCommodityData> {
    return this.http.get<VendorCommodityData>(
      `${apiUrl}/${vendorCommodityId}/getVendorCommodityById`
    );
  }

  public getCommoditiesByVendorId(
    vendorId: string
  ): Observable<VendorCommodityRequest[]> {
    return this.http.get<VendorCommodityRequest[]>(
      `${apiUrl}/vendorId/${vendorId}`
    );
  }

  public getCommoditiesForVendor(
    vendorId: number
  ): Observable<VendorCommoditySearchData[]> {
    return this.http.get<VendorCommoditySearchData[]>(
      `${apiUrl}/vendor-commodities/${vendorId}`
    );
  }

  private currentViewVendorCommodityData: BehaviorSubject<IViewVendorCommodityData> =
    new BehaviorSubject(null);
  currentViewVendorCommodityData$: Observable<IViewVendorCommodityData> =
    this.currentViewVendorCommodityData.asObservable();

  private currentVendorData: BehaviorSubject<IVendorData> = new BehaviorSubject(
    null
  );
  currentVendorData$: Observable<IVendorData> =
    this.currentVendorData.asObservable();

  public storeEditedViewVendorCommodityData(
    commodity: IViewVendorCommodityData,
    vendor: IVendorData
  ) {
    this.currentViewVendorCommodityData.next(commodity);
    this.currentVendorData.next(vendor);
  }
}
