import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
const apiUrl = environment.apiUrl;
@Injectable({
  providedIn: 'root'
})

export class ReportDataService {

  constructor(private http: HttpClient) { }
  public getCommodityDetails() {
    return  this.http.get(`${apiUrl}/report/commoditycodereport`, { responseType: 'blob' });
 }
 public getVendorInformationDetails() {
  return this.http.get(`${apiUrl}/report/vendorinformationdetailsreport`, { responseType: 'blob' });
}
  public getMgcandCC() {
    return this.http.get(`${apiUrl}/report/mgcandcommoditydetails`, { responseType: 'blob' });
  }
   }


