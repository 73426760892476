import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { VendorDataAsurenceService } from '../../services/procedures/vendorDataAssurence.service';
@Component({
  selector: 'app-procedures',
  templateUrl: './procedures.component.html',
})
export class ProceduresComponent implements OnInit{
  links: any[] = [];
  ngOnInit(): void {
    
  }
  constructor (private vendorDataService:VendorDataAsurenceService){}
  downloadVendorDataAssurance(): void {
    this.vendorDataService.getVendorDataAssuranceDetails().subscribe((data) => {
      const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = `110906 AQUA 3 Governance Process Rev 1.0.docx`;
      a.click();
      window.URL.revokeObjectURL(url);
    }, error => {
      console.error('Error downloading the Vendor Data Assurance report', error);
    });
  }
  
  downloadUserAccess(): void {
    this.vendorDataService.getUserAccessControlDetails().subscribe((data) => {
      const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = `AQUA3 User Access Control Process Rev0.docx`;
      a.click();
      window.URL.revokeObjectURL(url);
    }, error => {
      console.error('Error downloading the User Access Control report', error);
    });
  }
}
