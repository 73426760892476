import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ILookupData } from '../../../interfaces/administration/lookupData';
import { ICommodity } from '../../../interfaces/administration/commodity';
import { CountriesAndStatesService } from '../../../services/countries-states.service';
import { CommodityCodesService } from '../../../services/administration/commodity-codes.service';
import { ManufacturersNameService } from '../../../services/qa-reports/manufacturers-name.service';
import { Router } from '@angular/router';
import { HttpParams } from '@angular/common/http';
 
@Component({
  selector: 'app-inspectorsbycommodity',
  templateUrl: './inspectorsbycommodity.component.html',
  styleUrl: './inspectorsbycommodity.component.css'
})
export class InspectorsbycommodityComponent implements OnInit {
  manufacturerForm: FormGroup;
  statesData: ILookupData[] = [];
  commoditiesData: ICommodity[] = [];
  selectedCommodityObjects: ICommodity[] = [];
  validationMessages: string[] = [];
  isLoading=false;
 
  constructor(
    private fb: FormBuilder,
    private countriesAndStatesService: CountriesAndStatesService,
    private commodityCodesService: CommodityCodesService,
    private manufacturersNameService:ManufacturersNameService,
    private router: Router
  ) {
    this.manufacturerForm = this.fb.group({
      ManufacturerName: [''],
      ManufacturerCity: [''],
      state: ['stateDefault'],
      commodity: ['']
    });
  }
 
  ngOnInit(): void {
   
    this.loadStates();
    this.loadCommodities();
  }
 
  loadStates(): void {
    this.countriesAndStatesService.getAllStates().subscribe(
      (data: ILookupData[]) => {
        this.statesData = data.sort((a, b) => a.name.localeCompare(b.name));
      },
      (error) => {
        console.error('Error fetching states data', error);
      }
    );
  }
  cancel() {
    this.router.navigate(['/reports-forms'], { queryParams: { tab: 'qaReports' } });
  }
 
  loadCommodities(): void {
    this.commodityCodesService.getAllCommodities().subscribe(
      (data: ICommodity[]) => {
        this.commoditiesData = data;
      },
      (error) => {
        console.error('Error fetching commodities data', error);
      }
    );
  }
 
  handleSelectedCommodityNames(selectedCommodityNames: ICommodity[]) {
    this.selectedCommodityObjects = selectedCommodityNames;
    if (selectedCommodityNames.length !== 0) {
      this.manufacturerForm.patchValue({
        commodity: selectedCommodityNames.map(commodity => commodity.commodityId)
      });
    } else {
      this.manufacturerForm.patchValue({
        commodity: null
      });
    }
  }
 
  onSubmit(): void {
    this.isLoading=true;
    this.validationMessages = [];
    const formValues = this.manufacturerForm.value;
 
    if (!formValues.commodity) {
      this.validationMessages.push('Please give commodity. It cannot be empty.');
      this.isLoading = false;
      return; // Exit the function if validation fails
    }
    if (this.validationMessages.length > 0) {
      this.isLoading = false;
      return;
    }
    const params = new HttpParams()
      .set('commodityId', formValues.commodity)
      .set('mfrName', formValues.ManufacturerName)
      .set('mfrCity', formValues.ManufacturerCity)
      .set('mfrState', formValues.state)
 
    this.manufacturersNameService.getInspectorByCommodity(params)// change the method name
      .subscribe((data) => {
        const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = `AQUA3_InspectorsbycommodityReport_${new Date().toISOString().slice(0, 19).replace(/:/g, '-')}.xlsx`;
        a.click();
        window.URL.revokeObjectURL(url);
        this.isLoading=false;
      }, error => {
        console.error('Error downloading the report', error);
     });
   }}
 