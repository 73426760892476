import { Component, OnInit } from "@angular/core";
import { IAttachmentTypeData } from "../../interfaces/qa-management/attachmentTypeData";
import { UploadDocumentService } from "../../services/qa-management/upload-document.service";
import { SettingsService } from "../../services/settings.service";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { BaseComponent } from "../../shared/base/base.component";
import { UserPermissionsService } from "../../services/common/user-permissions.service";

@Component({
  selector: "app-upload-document",
  templateUrl: "./upload-documents.component.html",
  styles: [
    `
      .upload-document {
        border: 1px solid #ccc;
        padding: 20px;
        margin: 20px;
      }

      .form-group {
        margin-bottom: 15px;
      }

      label {
        display: block;
        margin-bottom: 5px;
      }

      select,
      input[type="file"],
      textarea {
        width: 100%;
        padding: 8px;
        margin-bottom: 10px;
      }

      button {
        margin-right: 10px;
        padding: 10px 20px;
        background-color: #4caf50;
        color: white;
        border: none;
        cursor: pointer;
      }

      button[disabled] {
        background-color: #ccc;
      }

      .document-links ul {
        list-style-type: none;
        padding: 0;
      }

      .document-links li {
        margin: 5px 0;
      }

      .button-container {
        display: flex !important;
        justify-content: space-between !important;
      }
    `,
  ],
})
export class UploadDocumentsComponent extends BaseComponent implements OnInit {
  referenceNumbers: string[] = [];
  public documents: IAttachmentTypeData[] = [];
  public sharepointUrl: string = "";
  public sharepointWorkspace: string = "";
  public formData = new FormData();
  public successMessage: string = "";
  uploadForm: FormGroup;
  file: File;

  constructor(
    private fb: FormBuilder,
    private service: UploadDocumentService,
    private settingService: SettingsService,
    userpermissionsService: UserPermissionsService
  ) {
    super(userpermissionsService);
    this.uploadForm = this.fb.group({
      attachmentType: ["", Validators.required],
      referenceNo: ["", Validators.required],
      notes: [""],
      file: [null, Validators.required],
    });
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.service.getAttachmentTypes().subscribe((data) => {
      this.documents = data;
    });
    this.settingService
      .getSettingsByKey("SharePointWorkSpace")
      .subscribe((data) => {
        this.sharepointWorkspace = data.settingValue;
      });
    this.settingService
      .getSettingsByKey("SharePointSiteURL")
      .subscribe((data) => {
        this.sharepointUrl = data.settingValue;
      });

    // Subscribe to changes in the attachmentType form control
    this.uploadForm.get("attachmentType").valueChanges.subscribe((value) => {
      this.loadReferenceNumbers(value);
    });
  }

  onFileChange(event: any) {
    const file = (event.target as HTMLInputElement).files;
    this.formData.append("file", file[0], file[0].name);
    this.uploadForm.patchValue({
      file: file[0],
    });
  }

  loadReferenceNumbers(attachmentType: string) {
    this.service.getReferenceNumbers(attachmentType).subscribe((data) => {
      this.referenceNumbers = data;
    });
  }

  onSubmit() {
    if (this.uploadForm.invalid) {
      return;
    }

    this.formData.append(
      "attachmentType",
      this.uploadForm.get("attachmentType").value
    );

    this.formData.append(
      "referenceNo",
      this.uploadForm.get("referenceNo").value
    );

    this.formData.append("notes", this.uploadForm.get("notes").value);

    this.service.uploadDocument(this.formData).subscribe((response) => {
      if (response) {
        this.successMessage = response;
        this.onClear();
      }
    });
  }

  onClear() {
    this.uploadForm.reset();
    this.file = null;
    const fileInput = document.getElementById("file-input") as HTMLInputElement;
    if (fileInput) {
      fileInput.value = "";
    }
  }
}
