// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
      .upload-document {
        border: 1px solid #ccc;
        padding: 20px;
        margin: 20px;
      }

      .form-group {
        margin-bottom: 15px;
      }

      label {
        display: block;
        margin-bottom: 5px;
      }

      select,
      input[type="file"],
      textarea {
        width: 100%;
        padding: 8px;
        margin-bottom: 10px;
      }

      button {
        margin-right: 10px;
        padding: 10px 20px;
        background-color: #4caf50;
        color: white;
        border: none;
        cursor: pointer;
      }

      button[disabled] {
        background-color: #ccc;
      }

      .document-links ul {
        list-style-type: none;
        padding: 0;
      }

      .document-links li {
        margin: 5px 0;
      }

      .button-container {
        display: flex !important;
        justify-content: space-between !important;
      }
    `, "",{"version":3,"sources":["webpack://./projects/aqua3/src/app/qa-management/upload-documents/upload-documents.component.ts"],"names":[],"mappings":";MACM;QACE,sBAAsB;QACtB,aAAa;QACb,YAAY;MACd;;MAEA;QACE,mBAAmB;MACrB;;MAEA;QACE,cAAc;QACd,kBAAkB;MACpB;;MAEA;;;QAGE,WAAW;QACX,YAAY;QACZ,mBAAmB;MACrB;;MAEA;QACE,kBAAkB;QAClB,kBAAkB;QAClB,yBAAyB;QACzB,YAAY;QACZ,YAAY;QACZ,eAAe;MACjB;;MAEA;QACE,sBAAsB;MACxB;;MAEA;QACE,qBAAqB;QACrB,UAAU;MACZ;;MAEA;QACE,aAAa;MACf;;MAEA;QACE,wBAAwB;QACxB,yCAAyC;MAC3C","sourcesContent":["\n      .upload-document {\n        border: 1px solid #ccc;\n        padding: 20px;\n        margin: 20px;\n      }\n\n      .form-group {\n        margin-bottom: 15px;\n      }\n\n      label {\n        display: block;\n        margin-bottom: 5px;\n      }\n\n      select,\n      input[type=\"file\"],\n      textarea {\n        width: 100%;\n        padding: 8px;\n        margin-bottom: 10px;\n      }\n\n      button {\n        margin-right: 10px;\n        padding: 10px 20px;\n        background-color: #4caf50;\n        color: white;\n        border: none;\n        cursor: pointer;\n      }\n\n      button[disabled] {\n        background-color: #ccc;\n      }\n\n      .document-links ul {\n        list-style-type: none;\n        padding: 0;\n      }\n\n      .document-links li {\n        margin: 5px 0;\n      }\n\n      .button-container {\n        display: flex !important;\n        justify-content: space-between !important;\n      }\n    "],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
