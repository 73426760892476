// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
      .button-container {
        display: flex !important;
        justify-content: space-between !important;
      }
      .button-group {
        display: flex;
        gap: 8px;
      }
    `, "",{"version":3,"sources":["webpack://./projects/aqua3/src/app/administration/vendors/commodities/vendor-edit-commodity.component.ts"],"names":[],"mappings":";MACM;QACE,wBAAwB;QACxB,yCAAyC;MAC3C;MACA;QACE,aAAa;QACb,QAAQ;MACV","sourcesContent":["\n      .button-container {\n        display: flex !important;\n        justify-content: space-between !important;\n      }\n      .button-group {\n        display: flex;\n        gap: 8px;\n      }\n    "],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
