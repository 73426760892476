// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.red-box {  
    background-color: #ffe6e6;
    color: red;
    border: 2px solid red;  
    padding: 5px;  
    margin-top: 20px;  
  } 

  .red-box p {
    font-size: 12px !important;
  }`, "",{"version":3,"sources":["webpack://./projects/aqua3/src/app/home/home.component.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB,UAAU;IACV,qBAAqB;IACrB,YAAY;IACZ,gBAAgB;EAClB;;EAEA;IACE,0BAA0B;EAC5B","sourcesContent":[".red-box {  \n    background-color: #ffe6e6;\n    color: red;\n    border: 2px solid red;  \n    padding: 5px;  \n    margin-top: 20px;  \n  } \n\n  .red-box p {\n    font-size: 12px !important;\n  }"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
