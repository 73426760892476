import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "projects/aqua3/src/environments/environment";
import { Observable } from "rxjs";
import { IInspectionReportData } from "../interfaces/common/inspectionReportData";

const apiUrl = environment.apiUrl;

@Injectable({
  providedIn: "root",
})
export class InspectionReportsDataService {
  constructor(private http: HttpClient) {}

  public addInspectionReport(
    inspectionReportData: IInspectionReportData
  ): Observable<IInspectionReportData> {
    return this.http.post<IInspectionReportData>(
      `${apiUrl}/inspectionReports`,
      inspectionReportData
    );
  }

  public updateInspectionReport(
    inspectionReportData: IInspectionReportData
  ): Observable<IInspectionReportData> {
    return this.http.put<IInspectionReportData>(
      `${apiUrl}/inspectionReports`,
      inspectionReportData
    );
  }

  public getInspectioReportById(
    inspectionReportId: string
  ): Observable<IInspectionReportData> {
    return this.http.get<IInspectionReportData>(
      `${apiUrl}/inspectionReports/${inspectionReportId}`
    );
  }
}
