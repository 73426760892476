import { Component } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { MatTableDataSource } from "@angular/material/table";
import { IVendorInformationData } from "../../../interfaces/vendor-information/vendorInformationData";
import { UserRequestsDataService } from "../../../services/administration/user-requests.service";
import { IUserData } from "../../../interfaces/administration/userData";
import { VendorInformationDataService } from "../../../services/vendor-information-data.service";
import { LoggedUserService } from "../../../services/common/logged-user.service";
import { ILoggedUserData } from "../../../interfaces/common/loggedUserData";
import { AuditObservationService } from "../../../services/qa-management/audit-observation.service";
import { IEvaluationStatus } from "../../../interfaces/qa-management/audit-observation/evaluationStatus";
import { Router } from "@angular/router";
import { PageViewMode } from "../../../interfaces/common/pageViewMode";
import { IAddAuditObservationEvaluation } from "../../../interfaces/qa-management/audit-observation/add-audit-observation-evaluation";
import { IAuditObservationCommodity } from "../../../interfaces/qa-management/audit-observation/auditObservationEvaluationDetails";
import { CommodityCodesService } from "../../../services/administration/commodity-codes.service";
import { ICommodityResult } from "../../../interfaces/qa-management/commodityResult";
import { ICommodityRating } from "../../../interfaces/qa-management/commodityRating";
import { IAuditObservationEvaluation } from "../../../interfaces/qa-management/audit-observation/audit-observation-evaluation";

@Component({
  selector: "add-manufacturer-audit-and-observation",
  templateUrl: "./add-mao.component.html",
  styleUrls: ["./add-mao.component.css",
    '../mao.component.css'
  ],
})
export class AddManufacturerAuditAndObservationComponent {
  title = "";
  detailsForm: FormGroup;

  PageViewMode = PageViewMode;
  addingDetailsMode: PageViewMode;
  previousMode: PageViewMode;

  userLoggedData: ILoggedUserData | null = null;
  leadsDropdownData: IUserData[] = [];
  manufacturerDropdownData: IVendorInformationData[] = [];
  auditObservationStatusesDropdownData: IEvaluationStatus[] =
    [];

  tableDataSource = new MatTableDataSource<IAuditObservationCommodity>();
  displayedColumns: string[] = ['actions', 'name', 'result', 'completionDate', 'rating', 'notes'];
  validationMessages = [];
  addAuditObservationEvaluationModel: IAddAuditObservationEvaluation;
  commodityResultsData: ICommodityResult[] = [];
  commodityRatingsData: ICommodityRating[] = [];

  constructor(private fb: FormBuilder,
    private userRequestsDataService: UserRequestsDataService,
    private vendorInformationDataService: VendorInformationDataService,
    private loggedUserService: LoggedUserService,
    private auditObservationService: AuditObservationService,
    private commodityCodesService: CommodityCodesService,
    private router: Router,
  ) {
    this.auditObservationService.MAOAddEditMode$.subscribe(addEditMode => {
      this.previousMode = addEditMode;
      this.addingDetailsMode = addEditMode;
    });
    if (this.addingDetailsMode == PageViewMode.Edit) {
      this.title = "Edit Manufacturer Audit And Observation";
      this.auditObservationService.MAOObject$.subscribe(obj => {
        this.addAuditObservationEvaluationModel = obj;
        this.detailsForm = this.fb.group({
          recordNo: [this.addAuditObservationEvaluationModel.evaluation.id],
          recordType: [this.addAuditObservationEvaluationModel.evaluation.recordType],
          lead: [this.addAuditObservationEvaluationModel.evaluation.responsibleCai],
          otherParticipants: [this.addAuditObservationEvaluationModel.evaluation.participants],
          manufacturerId: [this.addAuditObservationEvaluationModel.evaluation.manufacturerId],
          isRML: [false],
          fileAttachment: [''],
          auditDate: [this.addAuditObservationEvaluationModel.evaluation.recordDate],
          status: [this.addAuditObservationEvaluationModel.evaluation.statusId],
          notes: [this.addAuditObservationEvaluationModel.evaluation.notes],
          commodities: [this.addAuditObservationEvaluationModel.commodities],
        });
      });
    } else {
      this.title = "Add Manufacturer Audit And Observation";
      this.auditObservationService.setMAOAddMode(PageViewMode.Base);
      this.detailsForm = this.fb.group({
        recordNo: [''],
        recordType: ['Audit'],
        lead: [''],
        otherParticipants: [''],
        manufacturerId: [''],
        isRML: [false],
        fileAttachment: [''],
        auditDate: [''],
        status: [1],
        notes: [''],
        commodities: [null],
      });
    }

    this.detailsForm.valueChanges.subscribe(value => {
      this.tableDataSource.data = value.commodities;
    });
  }

  ngOnInit(): void {
    this.getActualLoggedInUser();
    this.getLeads();
    this.getEvaluationStatuses();
    this.getManufacturers();
    this.getCommodityResults();
    this.getCommodityRatings();
  }

  getCommodityResults(): void {
    this.commodityCodesService
      .getAllCommodityResults()
      .subscribe((response) => {
        this.commodityResultsData = response;
      });
  }

  getCommodityRatings(): void {
    this.commodityCodesService
      .getAllCommodityRatings()
      .subscribe((response) => {
        this.commodityRatingsData = response;
      });
  }

  getResultDescription(id: number): string {
    return this.commodityResultsData.find(x => x.id == id)?.description;
  }

  getRatingDescription(id: number): string {
    return this.commodityRatingsData.find(x => x.id == id)?.description;
  }

  getActualLoggedInUser(): void {
    this.loggedUserService
      .getLoggedUserData()
      .subscribe((loggedUser: ILoggedUserData | null) => {
        if (loggedUser) {
          this.userLoggedData = loggedUser;
        } else
          console.error("Cannot fetch logged user data in add-agency form.");
      });

  }

  onSubmit(): void {
    //TODO FILE UPLOAD
    this.validationMessages = [];

    if (!this.detailsForm.get('lead').value) {
      this.validationMessages.push('Please select a valid Lead Auditor');
    }

    if (!this.detailsForm.get('manufacturerId').value) {
      this.validationMessages.push('Please select a valid Manufacturer');
    }

    const status = this.detailsForm.get('status').value;
    const fileAttachment = this.detailsForm.get('fileAttachment').value;
    const isNewRecord = this.addingDetailsMode == PageViewMode.Base;
    const isEditRecord = this.addingDetailsMode == PageViewMode.Edit;

    if ((isNewRecord || isEditRecord) && status === 3 && !fileAttachment) {
      this.validationMessages.push('Please upload at least one Attachment if status is closed');
    }

    const auditDate = this.detailsForm.get('auditDate').value;
    if (!auditDate) {
      this.validationMessages.push('Please select Audit Date');
    } else {
      const auditDateObj = new Date(auditDate);
      if (auditDateObj > new Date()) {
        this.validationMessages.push('Please select a valid Audit Date. Audit Date cannot be a future date');
      }
    }

    if (!status) {
      this.validationMessages.push('Please select Status');
    }

    if (this.validationMessages.length == 0 && this.detailsForm.valid) {
      let auditObservationEvaluation: IAuditObservationEvaluation = {
        recordType: this.detailsForm.get('recordType').value,
        id: this.detailsForm.get('recordNo').value ?? 0,
        responsibleCai: null,
        participants: this.detailsForm.get('otherParticipants').value ?? null,
        manufacturerId: null,
        fileUrl: null,
        fileUrllink: null,
        recordDate: null,
        notes: this.detailsForm.get('notes').value,
        statusId: null,
        agencyId: null,
        inspectorId: null,
        createdBy: '',
        updatedBy: '',
      };

      const leadValue = this.detailsForm.get('lead').value;
      if (leadValue > 0) {
        var leadCai = this.leadsDropdownData.find((x) => x.userId === leadValue)?.cai;
        auditObservationEvaluation.responsibleCai = leadCai;
      }

      const manufacturerIdValue = this.detailsForm.get('manufacturerId').value;
      if (manufacturerIdValue > 0) {
        auditObservationEvaluation.manufacturerId = manufacturerIdValue;
      }

      const auditDateValue = this.detailsForm.get('auditDate').value;
      if (auditDateValue) {
        auditObservationEvaluation.recordDate = new Date(auditDateValue);
      }

      const statusValue = this.detailsForm.get('status').value;
      if (statusValue > 0) {
        auditObservationEvaluation.statusId = statusValue;
      }

      try {
        if (isNewRecord) {
          auditObservationEvaluation.createdBy = this.userLoggedData.cai;
        }
        auditObservationEvaluation.updatedBy = this.userLoggedData.cai;

        var commodityList: IAuditObservationCommodity[] = [];

        this.tableDataSource.data.forEach(item => {
          commodityList.push({
            id: item.id,
            auditObservationEvaluationId: auditObservationEvaluation.id,
            commodityId: item.commodityId,
            resultId: item.resultId,
            ratingId: item.ratingId,
            completionDate: new Date(item.completionDate),
            notes: item.notes
          });
        });

        this.addAuditObservationEvaluationModel = {
          evaluation: auditObservationEvaluation,
          commodities: commodityList,
        }

        if (this.addingDetailsMode == PageViewMode.Edit) {
          this.auditObservationService.updateAuditObservation(this.addAuditObservationEvaluationModel)
            .subscribe(auditObservationId => {
              this.goToAddAuditObservationMainPage();
            });
        } else {
          this.auditObservationService.addAuditObservation(this.addAuditObservationEvaluationModel)
            .subscribe(auditObservationId => {
              this.goToAddAuditObservationMainPage();
            });
        }
        this.goToAddAuditObservationMainPage();
      } catch (ex) {
        this.validationMessages.push(`There was a problem saving the requested audit (${ex.message}).`);
      }
    }
  }

  goToAddAuditObservationMainPage() {
    this.auditObservationService.setMAOAddMode(PageViewMode.Base);
    this.router.navigate(["/qa-management/manufacturer-audit-observation"]);
  }

  onCancel(): void {
    this.detailsForm.reset();
    this.goToAddAuditObservationMainPage();
  }

  getRatingClass(ratingId: number): string {
    var ratingDesc = this.commodityResultsData.find(x => x.id == ratingId)?.description;
    return this.auditObservationService.getRatingClass(ratingDesc);
  }

  getLeads(): void {
    this.userRequestsDataService.getAllActiveUsers().subscribe((response) => {
      this.leadsDropdownData = response;
      this.detailsForm.patchValue({
        lead: this.leadsDropdownData.find((x) => x.userId === this.userLoggedData?.userId)?.userId
      });
    });
  }

  getManufacturers(): void {
    this.vendorInformationDataService
      .getAllShortInfoManufacturerVendors()
      .subscribe(
        (response) => {
          this.vendorInformationDataService.changeVendorManufacturerData(
            response
          );
          this.manufacturerDropdownData = response;
        },
        (error) => {
          console.error(error);
        }
      );
  }

  getEvaluationStatuses(): void {
    this.auditObservationService
      .getAllEvaluationStatus()
      .subscribe((response) => {
        this.auditObservationStatusesDropdownData = response;
      });
  }

  onSaveCommodityDetails(newCommodities: IAuditObservationCommodity[]) {
    let currentCommodities = this.detailsForm.get('commodities').value;
    if (!Array.isArray(currentCommodities)) {
      currentCommodities = [];
    }

    newCommodities.forEach(newCommodity => {
      const index = currentCommodities.findIndex(commodityForAuditObs => commodityForAuditObs.commodityId == newCommodity.commodityId);

      if (index != -1) {
        currentCommodities[index] = newCommodity;
      } else {
        currentCommodities.push(newCommodity);
      }
    });

    this.detailsForm.patchValue({
      commodities: currentCommodities
    });

    this.addingDetailsMode = this.previousMode;
  }

  onAddCommodityIntoAuditObservation() {
    this.addingDetailsMode = PageViewMode.Add;
  }

  editingCommodityElement: IAuditObservationCommodity = null;
  onEditCommodity(commodityElement: IAuditObservationCommodity) {
    this.editingCommodityElement = commodityElement;
    this.addingDetailsMode = PageViewMode.Add;
  }

  onDeleteCommodity(commodityElement: IAuditObservationCommodity) {
    let currentCommodities = this.detailsForm.get('commodities').value;
    if (Array.isArray(currentCommodities)) {
      this.detailsForm.patchValue({
        commodities: currentCommodities.filter((commodityForAuditObs: IAuditObservationCommodity) => commodityForAuditObs.commodityId != commodityElement.commodityId)
      });
    }
  }
}
