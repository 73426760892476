import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { environment } from "projects/aqua3/src/environments/environment";
import { BaseMsaData } from "../../interfaces/administration/msa/baseMsaData";
import { BudgetScopesData } from "../../interfaces/administration/msa/budgetScopesData";
import { MSADetailsData } from "../../interfaces/administration/msa/msaDetailsData";
import { MSAHistoryData } from "../../interfaces/administration/msa/msaHistoryData";
const apiUrl = environment.apiUrl;

@Injectable({
  providedIn: "root",
})
export class MsaDataService {
  constructor(private http: HttpClient) {}

  public getMsaData(
    year: string,
    agencyId: string,
    scopeId: string,
    orderBy: string
  ): Observable<MSADetailsData[]> {
    const params = {
      year: year,
      agencyId: agencyId,
      scopeId: scopeId,
      orderBy: orderBy,
    };
    return this.http.get<MSADetailsData[]>(`${apiUrl}/msa`, { params });
  }

  public getAllScopes(): Observable<BudgetScopesData[]> {
    return this.http.get<BudgetScopesData[]>(`${apiUrl}/msa/budget-scopes`);
  }

  public saveMsaData(newEntry: MSADetailsData) {
    return this.http.post(
      `${apiUrl}/msa?budgetYear=${newEntry.budgetYear}`,
      newEntry
    );
  }

  public getMsaHistoryData(budgetYear: string): Observable<MSAHistoryData[]> {
    return this.http.get<MSAHistoryData[]>(
      `${apiUrl}/msa/history/${budgetYear}`
    );
  }

  public updateMsaData(entry: MSADetailsData): Observable<MSADetailsData> {
    return this.http.put<MSADetailsData>(`${apiUrl}/msa`, entry);
  }

  public generateReport(): Observable<Blob> {
    return this.http.get(`${apiUrl}/msa/report`, { responseType: "blob" });
  }
}
