import { Component, OnInit } from '@angular/core';
import { DatePipe } from '@angular/common';
import { HttpParams } from '@angular/common/http';
import { ManufacturersNameService } from '../../../services/qa-reports/manufacturers-name.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-qabyqae',
  templateUrl: './qabyqae.component.html',
  styleUrls: ['./qabyqae.component.css'],
  providers: [DatePipe]
})
export class QabyqaeComponent implements OnInit {
  startDate: string; 
  endDate: string;
  validationMessages: string[]=[];

  constructor(private datePipe: DatePipe, private manufacturersNameService:ManufacturersNameService,
    private router:Router
  ) {}

  ngOnInit(): void {
    const currentYear = new Date().getFullYear();
    this.startDate = this.datePipe.transform(new Date(currentYear, 0, 1), 'yyyy-MM-dd') || '';
    this.endDate = this.datePipe.transform(new Date(), 'yyyy-MM-dd') || '';
  }

   resetStartDate() {
    this.startDate = '';
  }

  resetEndDate() {
    this.endDate ='';
  }
  cancel() {
    this.router.navigate(['/reports-forms'], { queryParams: { tab: 'qaReports' } });
  }
    
  generateReport() {
    this.validationMessages = [];

    if (!this.startDate) {
      this.validationMessages.push('Start date is required.');
    }
    if (!this.endDate) {
      this.validationMessages.push('End date is required.');
    }
    if (new Date(this.startDate) > new Date(this.endDate)) {
      this.validationMessages.push('Start date cannot be after end date.');
    }

    if (this.validationMessages.length > 0) {
        return;
    }
    const formattedStartDate = this.datePipe.transform(this.startDate, 'yyyy-MM-dd');
    const formattedEndDate = this.datePipe.transform(this.endDate, 'yyyy-MM-dd');
  
    const params = new HttpParams()
      .set('StartDate', formattedStartDate || '')
      .set('EndDate', formattedEndDate || '');

    this.manufacturersNameService.CreateQAActivityReportByQAE(params).subscribe(response => {
      const blob = new Blob([response], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = `AQUA3_QAActivityReportByQAE_${new Date().toISOString().slice(0, 19).replace(/:/g, '-')}.xlsx`;
      a.click();
      window.URL.revokeObjectURL(url);
    }, error => {
      console.error('Error downloading the report', error);
    });
  }
}
