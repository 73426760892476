import { Injectable } from "@angular/core";
import { TableSortService } from "../../table-sort.service";

@Injectable({
  providedIn: "root",
})
export class RevisionsTableSortService extends TableSortService {
  getCombinedValue(obj: any, fields: string[]): string {
    let combinedValue = "";

    for (const field of fields) {
      if (obj[field]) {
        combinedValue += obj[field];
      } else if (obj.revisions && obj.revisions[field]) {
        combinedValue += obj.revisions[field];
      }
    }

    return combinedValue;
  }
}