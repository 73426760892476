import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from "@angular/core";
import { WorkRequestsDataService } from "projects/aqua3/src/app/services/qa-management/work-requests/work-requests.service";
import { Router } from "@angular/router";
import { PerformanceTrackingService } from "../../../services/vendor-information/performance-tracking.service";
import { ICapitalProjectData } from "../../../interfaces/administration/capitalProjectData";
import { IViewPerformanceRatingDataNew } from "../../../interfaces/vendor-information/viewPerformanceRatingDataNew";
import { LoggedUserService } from "../../../services/common/logged-user.service";
import { ILoggedUserData } from "../../../interfaces/common/loggedUserData";
import { BaseComponent } from "../../../shared/base/base.component";
import { UserPermissionsService } from "../../../services/common/user-permissions.service";

@Component({
  selector: "mfr-ratings-component",
  templateUrl: "./mfr-ratings-component.html",
  styles: [
    `
      .button-container {
        display: flex !important;
        justify-content: space-between !important;
      }

      .unchecked {
        color: red;
      }

      .mt-1 {
        margin-top: 1rem !important;
      }
    `,
  ],
})
export class MFRRatingsComponent
  extends BaseComponent
  implements OnInit, OnChanges
{
  @Input() workRequestId: number;
  title = "MFR Ratings";
  public project: ICapitalProjectData;
  public performanceRatingDataNew: IViewPerformanceRatingDataNew;
  public isLoading: boolean = false;
  public activeTab: string = "stats"; // Default to the first tab
  validationMessages = [];
  sptExists: boolean = false;
  mfrRatingsVisible: boolean = false;
  sptApproved: string = "";
  loggedUserData: ILoggedUserData;

  displayedColumns: string[];

  dataSourceLoading: boolean = true;

  constructor(
    private performanceService: PerformanceTrackingService,
    private workRequestsService: WorkRequestsDataService,
    private loggedUserService: LoggedUserService,
    private router: Router,
    userPermissionsService: UserPermissionsService
  ) {
    super(userPermissionsService);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.loggedUserService
      .getLoggedUserData()
      .subscribe((loggedUser: ILoggedUserData | null) => {
        if (loggedUser) this.loggedUserData = loggedUser;
      });

    this.loadSpt();
  }

  ngOnChanges(changes: SimpleChanges): void {
    // React to changes in the input property
    if (changes.workRequestId && !changes.workRequestId.isFirstChange()) {
      this.loadSpt();
    }
  }

  loadSpt() {
    if (this.workRequestId !== null && this.workRequestId !== undefined) {
      this.workRequestsService
        .getSPTForWorkRequest(this.workRequestId)
        .subscribe((data) => {
          if (data !== null) {
            this.performanceRatingDataNew = data;
            this.sptExists = true;
            this.mfrRatingsVisible = true;
            if (this.performanceRatingDataNew.isApproved) {
              this.sptApproved =
                "Approved by '" +
                this.performanceRatingDataNew.lastName +
                " " +
                this.performanceRatingDataNew.firstName +
                "' " +
                this.performanceRatingDataNew.approvedDate;
            } else
              this.sptApproved = "This performance tracking is not approved.";
          } else {
            this.sptExists = false;
            this.mfrRatingsVisible = false;
            this.sptApproved =
              "There are currently no SPT records associated with this work request. Click the 'Create New SPT' button below to start rating.";
          }
        });
    }
  }

  viewEditSPTDetails() {
    let messagesValidationsList: string[] = [];

    if (this.performanceRatingDataNew?.performanceTrackingId == null) {
      messagesValidationsList.push("Performance Tracking ID was not found.");
    }

    this.validationMessages = messagesValidationsList;
    if (this.validationMessages.length === 0) {
      this.router.navigate(
        [
          "vendor-information/performance-tracking/preview-spt/",
          this.performanceRatingDataNew.performanceTrackingId,
        ],
        {
          queryParams: {
            source:
              "qa-management/work-requests/edit-work-request/" +
              this.workRequestId,
          },
        }
      );
    }
  }

  createNewSPT(): void {
    this.validationMessages = [];

    if (
      this.workRequestId !== null &&
      this.workRequestId !== undefined &&
      this.loggedUserData
    ) {
      this.workRequestsService
        .createNewSptForWorkRequest(
          this.workRequestId,
          this.loggedUserData.userId
        )
        .subscribe(
          (perfTracking) => {
            if (perfTracking != null) {
              this.router.navigate([
                [
                  "vendor-information/performance-tracking/preview-spt/",
                  perfTracking.performanceTrackingId,
                ],
                {
                  queryParams: {
                    source:
                      "qa-management/work-requests/edit-work-request/" +
                      this.workRequestId,
                  },
                },
              ]);
            } else
              this.validationMessages.push(
                "There was a problem loading the newly created SPT record."
              );
          },
          (error) => {
            this.validationMessages.push(
              "There was a problem loading the newly created SPT record."
            );

            this.router.navigate(["vendor-information/performance-tracking"]);
          }
        );
    }
  }

  setActiveMFRTab(tabName: string = "stats"): void {
    this.activeTab = tabName;
    if (
      tabName === "stats" &&
      this.performanceRatingDataNew?.performanceTrackingId != null
    )
      this.performanceService.loadStatsDataTab(
        this.performanceRatingDataNew?.performanceTrackingId
      );
    else if (
      tabName === "engineering" &&
      this.performanceRatingDataNew?.performanceTrackingId != null
    )
      this.performanceService.loadRatingDataTab(
        this.performanceRatingDataNew.performanceTrackingId,
        1
      );
    else if (
      tabName === "procurement" &&
      this.performanceRatingDataNew?.performanceTrackingId != null
    )
      this.performanceService.loadRatingDataTab(
        this.performanceRatingDataNew.performanceTrackingId,
        6
      );
    else if (
      tabName === "construction" &&
      this.performanceRatingDataNew?.performanceTrackingId != null
    )
      this.performanceService.loadRatingDataTab(
        this.performanceRatingDataNew.performanceTrackingId,
        0
      );
    else if (
      tabName === "manufacturing" &&
      this.performanceRatingDataNew?.performanceTrackingId != null
    )
      this.performanceService.loadRatingDataTab(
        this.performanceRatingDataNew.performanceTrackingId,
        2
      );
  }
}
