import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, map } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class EmailTemplateService {
  constructor(private http: HttpClient) {}

  private readTemplate(templatePath: string): Observable<string> {
    return this.http.get(templatePath, { responseType: "text" });
  }

  private replaceVariables(
    template: string,
    variables: { [key: string]: any }
  ): string {
    let result = template;
    for (const key in variables) {
      const value = variables[key];
      result = result.replace(new RegExp(`{{${key}}}`, "g"), value);
    }
    return result;
  }

  public generateEmailContent(
    templateName: string,
    variables: { [key: string]: any }
  ): Observable<string> {
    const templatePath = `assets/administration/email-templates/${templateName}.template.html`;
    return this.readTemplate(templatePath).pipe(
      map((template) => this.replaceVariables(template, variables))
    );
  }
}
