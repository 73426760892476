import { ChangeDetectorRef, Component, OnInit, ViewChild } from "@angular/core";
import { IVendorData } from "../../interfaces/administration/vendorData";
import { MatSort } from "@angular/material/sort";
import { MatPaginator } from "@angular/material/paginator";
import { MatTableDataSource } from "@angular/material/table";
import { VendorDataService } from "../../services/vendor-data.service";
import { Router } from "@angular/router";
import { BaseComponent } from "../../shared/base/base.component";
import { UserPermissionsService } from "../../services/common/user-permissions.service";
import { FormBuilder, FormGroup } from "@angular/forms";

@Component({
  selector: "app-vendors",
  templateUrl: "./vendors.component.html",
})
export class VendorsComponent extends BaseComponent implements OnInit {
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  public vendorData: MatTableDataSource<IVendorData>;
  public originalVendorData: MatTableDataSource<IVendorData>;
  public displayedColumns: string[] = [
    "vendorName",
    "streetAddress",
    "countryName",
    "stateName",
    "city",
    "postalCode",
    "actions",
  ];
  public isLoading = true;
  filterForm: FormGroup;

  constructor(
    private service: VendorDataService,
    private router: Router,
    private fb: FormBuilder,
    private changeDetectorRefs: ChangeDetectorRef,
    userPermissionService: UserPermissionsService
  ) {
    super(userPermissionService);
    this.filterForm = this.fb.group({
      vendorName: [""],
      country: [""],
      state: [""],
      county: [""],
      postalCode: [""],
      city: [""],
    });
  }

  ngOnInit() {
    super.ngOnInit();
    this.loadVendors();
  }

  ngAfterViewInit() {}

  onFilter(): void {
    const { vendorName, country, state, county, postalCode, city } =
      this.filterForm.value;
    this.vendorData.data = this.originalVendorData.data.filter((item) => {
      return (
        (vendorName
          ? item.vendorName.toLowerCase().includes(vendorName.toLowerCase())
          : true) &&
        (country
          ? item.countryName.toLowerCase().includes(country.toLowerCase())
          : true) &&
        (state
          ? item.stateName.toLowerCase().includes(state.toLowerCase())
          : true) &&
        (county
          ? item.countyEquivlient.toLowerCase().includes(county.toLowerCase())
          : true) &&
        (postalCode
          ? item.postalCode.toLowerCase().includes(postalCode.toLowerCase())
          : true) &&
        (city ? item.city.toLowerCase().includes(city.toLowerCase()) : true)
      );
    });
  }

  onClearFilters(): void {
    this.filterForm.reset();
    this.loadVendors();
  }

  loadVendors() {
    this.isLoading = true;
    this.service.getVendors().subscribe((data) => {
      this.vendorData = new MatTableDataSource(data);
      this.originalVendorData = new MatTableDataSource(data);
      this.vendorData.sort = this.sort;
      this.vendorData.paginator = this.paginator;
      this.paginator.pageSize = 10;
      this.isLoading = false;
      this.changeDetectorRefs.detectChanges();
      this.onFilter();
    });
  }

  editVendor(vendor: IVendorData) {
    this.router.navigate(["/administration/vendors/preview/", vendor.vendorId]);
  }

  moveToCreateVendor() {
    this.router.navigate(["/administration/vendors/create"]);
  }
}
