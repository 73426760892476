// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.small-input {
  width: 60px;
}

.table-container {
  max-height: 400px;
  overflow-y: auto;
}

.button-container {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.done-button {
  margin-left: auto;
}`, "",{"version":3,"sources":["webpack://./projects/aqua3/src/app/qa-management/work-requests/edit-work-request-form.component.css"],"names":[],"mappings":"AAAA;EACE,WAAW;AACb;;AAEA;EACE,iBAAiB;EACjB,gBAAgB;AAClB;;AAEA;EACE,aAAa;EACb,eAAe;EACf,SAAS;AACX;;AAEA;EACE,iBAAiB;AACnB","sourcesContent":[".small-input {\n  width: 60px;\n}\n\n.table-container {\n  max-height: 400px;\n  overflow-y: auto;\n}\n\n.button-container {\n  display: flex;\n  flex-wrap: wrap;\n  gap: 10px;\n}\n\n.done-button {\n  margin-left: auto;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
