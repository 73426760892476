import {
  HttpClient,
  HttpErrorResponse,
  HttpParams,
} from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "projects/aqua3/src/environments/environment";
import {
  catchError,
  forkJoin,
  map,
  Observable,
  of,
  Subject,
  throwError,
} from "rxjs";
import { IViewPerformanceTrackingData } from "../../interfaces/vendor-information/viewPerformanceTrackingData";
import { FormGroup } from "@angular/forms";
import { IPerformanceTrackingData } from "../../interfaces/vendor-information/performanceTrackingData";
import { ViewPerformanceRatingData } from "../../interfaces/vendor-information/viewPerformanceRatingData";
import { IPerformanceRatingData } from "../../interfaces/vendor-information/performanceRatingData";
import { Workbook } from "exceljs";
import { PaginatedResponse } from "../qa-management/work-requests/work-requests.service";

const apiUrl = environment.apiUrl;

@Injectable({
  providedIn: "root",
})
export class PerformanceTrackingService {
  private performanceTrackingId = new Subject<number>();
  performanceTrackingId$: Observable<number> =
    this.performanceTrackingId.asObservable();

  private loadEngineering = new Subject<ViewPerformanceRatingData[]>();
  loadEngineering$: Observable<ViewPerformanceRatingData[]> =
    this.loadEngineering.asObservable();

  private loadProcurement = new Subject<ViewPerformanceRatingData[]>();
  loadProcurement$: Observable<ViewPerformanceRatingData[]> =
    this.loadProcurement.asObservable();

  private loadConstruction = new Subject<ViewPerformanceRatingData[]>();
  loadConstruction$: Observable<ViewPerformanceRatingData[]> =
    this.loadConstruction.asObservable();

  private loadManufacturing = new Subject<ViewPerformanceRatingData[]>();
  loadManufacturing$: Observable<ViewPerformanceRatingData[]> =
    this.loadManufacturing.asObservable();

  private loadStatsData = new Subject<ViewPerformanceRatingData[]>();
  loadStatsData$: Observable<ViewPerformanceRatingData[]> =
    this.loadStatsData.asObservable();

  constructor(private http: HttpClient) {}

  public getPerformanceTrackingData(
    ProjectId: string,
    ProjectLocation: string,
    EPCId: number,
    Vendor: string,
    CommodityDescription: string,
    PONumber: string,
    CommodityIds?: string,
    FromDate?: string,
    ToDate?: string,
    PageIndex: number = 1,
    PageSize: number = 10
  ): Observable<PaginatedResponse<IViewPerformanceTrackingData>> {
    let params = new HttpParams();
    params = params.append("ProjectId", ProjectId.toString());
    params = params.append("ProjectLocation", ProjectLocation);
    params = params.append("EPCId", EPCId.toString());
    params = params.append("Vendor", Vendor);
    if (CommodityIds !== undefined)
      params = params.append("CommodityIds", CommodityIds);
    params = params.append("CommodityDescription", CommodityDescription);
    params = params.append("PONumber", PONumber);
    if (FromDate !== undefined) params = params.append("FromDate", FromDate);
    if (ToDate !== undefined) params = params.append("ToDate", ToDate);
    params = params.append("PageIndex", PageIndex.toString());
    params = params.append("PageSize", PageSize.toString());
    return this.http.get<PaginatedResponse<IViewPerformanceTrackingData>>(
      `${apiUrl}/performance-tracking`,
      { params }
    );
  }

  public createPerformanceTracking(
    perforamcneTracking: IPerformanceTrackingData
  ): Observable<IPerformanceTrackingData> {
    return this.http.post<IPerformanceTrackingData>(
      `${apiUrl}/performance-tracking`,
      perforamcneTracking
    );
  }

  public updatePerformanceTracking(
    performanceTracking: IPerformanceTrackingData
  ): Observable<IPerformanceTrackingData> {
    return this.http.put<IPerformanceTrackingData>(
      `${apiUrl}/performance-tracking`,
      performanceTracking
    );
  }

  public getPerformanceTrackingDataById(
    sptId: number
  ): Observable<IPerformanceTrackingData> {
    return this.http.get<IPerformanceTrackingData>(
      `${apiUrl}/performance-tracking/${sptId}`
    );
  }

  public getPerformanceRatingData(
    sptId: number,
    type: string
  ): Observable<ViewPerformanceRatingData[]> {
    return this.http.get<ViewPerformanceRatingData[]>(
      `${apiUrl}/performance-tracking/${sptId}/get-ratings/${type}/0`
    );
  }

  public getAllPerformanceRatingData(
    sptId: number
  ): Observable<ViewPerformanceRatingData[]> {
    return this.http.get<ViewPerformanceRatingData[]>(
      `${apiUrl}/performance-tracking/${sptId}/get-ratings`
    );
  }

  public createRating(
    sptId: number,
    data: IPerformanceRatingData
  ): Observable<IPerformanceRatingData> {
    return this.http
      .post<IPerformanceRatingData>(
        `${apiUrl}/performance-tracking/${sptId}/save-rating`,
        data
      )
      .pipe(
        catchError((error: HttpErrorResponse) => {
          console.error("Error creating rating:", error);
          return throwError(() => new Error("Error creating rating"));
        })
      );
  }

  public updateRating(
    sptId: number,
    data: IPerformanceRatingData
  ): Observable<IPerformanceRatingData> {
    return this.http
      .put<IPerformanceRatingData>(
        `${apiUrl}/performance-tracking/${sptId}/update-rating`,
        data
      )
      .pipe(
        catchError((error: HttpErrorResponse) => {
          console.error("Error updating rating:", error);
          return throwError(() => new Error("Error updating rating"));
        })
      );
  }

  getRatingNameByKpiId(kpiId: number): string {
    const kpiMap: { [key: number]: string } = {
      1: "Timeliness of Initial Vendor Data",
      2: "Timeliness of Revisions to Vendor Data",
      3: "Incorporation of Comments",
      4: "Overall Engineering Accuracy",
      5: "Level of Chevron/Eng Contractor Support Required",
      6: "Adherence to Commercial Requirements",
      7: "Sub-Supplier Management",
      8: "Progress Reporting",
      9: "Delivery Performance",
      10: "Logistics Performance",
      11: "Supplier & Subcontractor QA",
      12: "Adherence to P.O & Specifications",
      13: "Adherence to Drawings & Tolerances",
      14: "Workmanship (Welding etc...)",
      15: "Painting or Coating",
      16: "Acceptance Testing & Inspection",
      17: "Work Request Planning and Scheduling",
      18: "Timeliness of Drawings",
      19: "Expediting Materials & Components",
      20: "Maintaining WR Schedule",
      21: "Willingness to Add Labor or O/T",
      22: "Meeting Required Delivery",
      23: "Access to Documents and Materials",
      24: "Advises of Problems Promptly",
      25: "Provides Schedules and Status",
      26: "Resolves Problems",
      27: "Enforces Holdpoints",
      28: "Other",
      29: "Ability to close out issues and support installation and commissioning activities",
      30: "Quality and timeliness of equipment/package final documentation",
      31: "Ability to coordinate and address in a timely manner required regulatory reviews and inspections",
      32: "Quality and availability of support personnel",
      33: "Ability to agree to CVX service contracts",
    };

    return kpiMap[kpiId] || "Unknown KPI";
  }

  verifyRatingsAndComments(
    ratingValue: number,
    commentsValue: string,
    kpiId: number
  ): string[] {
    const validationIssues: string[] = [];

    if (ratingValue) {
      // Check to make sure we have a digit
      if (!/^\d$/.test(ratingValue.toString())) {
        validationIssues.push(
          `The rating for KPI ${this.getRatingNameByKpiId(
            kpiId
          )} must be a digit from 0 to 5. 0 is equal to no rating`
        );
        return validationIssues;
      }

      // Check to see if the digit is 0 through 5
      if (ratingValue < 0 || ratingValue > 5) {
        validationIssues.push(
          `The rating you selected '${ratingValue}' for KPI ${this.getRatingNameByKpiId(
            kpiId
          )} is invalid. The rating must be a digit from 0 to 5. 0 is equal to no rating`
        );
        return validationIssues;
      }

      // Check to see if the rating is either a 1 or a 5 and requires a comment
      if ((ratingValue == 1 || ratingValue == 5) && !commentsValue) {
        validationIssues.push(
          `The rating you selected '${ratingValue}' for KPI ${this.getRatingNameByKpiId(
            kpiId
          )} requires a comment. A rating of 1 or 5 requires a comment.`
        );
        return validationIssues;
      }
    }

    return validationIssues;
  }

  public validateKpiForm(
    formValues: any,
    kpiIds: number[],
    getFormControlName: (kpiId: number, isComment?: boolean) => string
  ): string[] {
    let validationIssues: string[] = [];

    kpiIds.forEach((kpiId) => {
      const ratingValue = formValues[getFormControlName(kpiId)];
      const commentsValue = formValues[getFormControlName(kpiId, true)];
      let issues = this.verifyRatingsAndComments(
        ratingValue,
        commentsValue,
        kpiId
      );

      if (issues.length > 0) {
        validationIssues.push(...issues);
      }
    });

    return validationIssues;
  }

  public submitKpiForm(
    formValues: any,
    kpiIds: number[],
    procurementRating: ViewPerformanceRatingData[],
    performanceTrackingId: number,
    getFormControlName: (kpiId: number, isComment?: boolean) => string
  ): Observable<boolean> {
    const requests: Observable<any>[] = [];

    kpiIds.forEach((kpiId) => {
      const ratingValue = formValues[getFormControlName(kpiId)];
      const commentsValue = formValues[getFormControlName(kpiId, true)];
      const existingRating = procurementRating.find(
        (x) => x.keyPerformanceIndicatorId === kpiId
      );

      if (existingRating) {
        // Update existing rating
        const updatedRating: IPerformanceRatingData = {
          ...existingRating,
          rating: ratingValue,
          comments: commentsValue,
          ratedDate: new Date().toISOString(),
        };
        requests.push(this.updateRating(performanceTrackingId, updatedRating));
      } else {
        // Create new rating
        const ratingData: IPerformanceRatingData = {
          keyPerformanceIndicatorId: kpiId,
          rating: ratingValue,
          comments: commentsValue,
          performanceTrackingId: performanceTrackingId,
          performanceRatingId: 0,
          ratedByUserId: 0,
          ratedDate: new Date().toISOString(),
        };
        requests.push(this.createRating(performanceTrackingId, ratingData));
      }
    });

    return forkJoin(requests).pipe(
      map(() => true),
      catchError(() => of(false))
    );
  }

  private ratingNames: string[] = [
    "Construction",
    "Engineering",
    "Manufacturing",
    "Quality",
    "Schedule",
    "Cooperation",
    "Procurement",
    "Inspector",
  ];

  private getRatingNameById(id: number): string | undefined {
    if (id >= 0 && id < this.ratingNames.length) {
      return this.ratingNames[id];
    }
    return undefined; // or throw an error if you prefer
  }

  public loadRatingDataTab(sptId: number, type: number) {
    this.getPerformanceRatingData(
      sptId,
      this.getRatingNameById(type)
    ).subscribe((data: ViewPerformanceRatingData[]) => {
      if (data) {
        this.performanceTrackingId.next(sptId);
        if (this.getRatingNameById(type) === "Engineering") {
          this.loadEngineering.next(data);
        }
        if (this.getRatingNameById(type) === "Procurement") {
          this.loadProcurement.next(data);
        }
        if (this.getRatingNameById(type) === "Construction") {
          this.loadConstruction.next(data);
        }
        if (this.getRatingNameById(type) === "Manufacturing") {
          this.loadManufacturing.next(data);
        }
      }
    });
  }

  public loadStatsDataTab(sptId: number) {
    this.getAllPerformanceRatingData(sptId).subscribe(
      (data: ViewPerformanceRatingData[]) => {
        this.loadStatsData.next(data);
      }
    );
  }

  public setRatingApproval(
    performanceTrackingId: number,
    userId: string,
    comments: string,
    isApproved: boolean
  ): Observable<void> {
    let params = new HttpParams()
      .set("performanceTrackingId", performanceTrackingId.toString())
      .set("userId", userId)
      .set("comments", comments)
      .set("isApproved", isApproved.toString());

    return this.http.post<void>(
      `${apiUrl}/performance-tracking/rating-approval`,
      null,
      { params }
    );
  }

  public validatePerformanceForm(form: FormGroup) {
    let poNumber: string = form.get("poNumber").value;
    let poRequiredDate: string = form.get("poRequiredDate").value;
    let vendor: string = form.get("vendor").value;
    let commodity: string = form.get("commodity").value;

    let messagesValidationsList: string[] = [];

    if (poNumber === "")
      messagesValidationsList.push(
        "A P.O. number associated with the purchase of the selected commodity from the selected vendor is required to enter performance tracking."
      );

    if (poRequiredDate === "")
      messagesValidationsList.push(
        "A P.O. required date associated with the purchase of the selected commodity from the selected vendor is required to enter performance tracking."
      );

    if (vendor === "")
      messagesValidationsList.push(
        "A vendor is required for performance tracking."
      );

    if (commodity === "")
      messagesValidationsList.push(
        "A commodity is required for performance tracking."
      );

    return messagesValidationsList;
  }

  public async processFile(file: File, sptid: number): Promise<string[]> {
    const errors: string[] = [];

    try {
      const arrayBuffer = await file.arrayBuffer();
      const workbook = new Workbook();
      await workbook.xlsx.load(arrayBuffer);

      const worksheet = workbook.getWorksheet("Form");

      if (!worksheet) {
        errors.push(
          "Could not find a valid worksheet within the uploaded file."
        );
        return errors;
      }

      const jsonData: any[][] = [];

      worksheet.eachRow({ includeEmpty: true }, (row, rowNumber) => {
        const rowData: any[] = [];
        row.eachCell({ includeEmpty: true }, (cell, colNumber) => {
          rowData.push(cell.value);
        });
        jsonData.push(rowData);
      });

      this.processWorksheetData(jsonData, sptid, errors);
    } catch (error: any) {
      errors.push("Error reading file: " + (error.message || error));
    }

    return errors;
  }
  private processWorksheetData(
    data: any[][],
    sptid: number,
    errors: string[]
  ): void {
    // Added errors parameter
    const worksheetIdentifier = data[1][4]; // Assuming "E2" is in the second row, fifth column
    const worksheetVersion = data[2][4]; // Assuming "E3" is in the third row, fifth column

    if (!worksheetIdentifier || !worksheetVersion) {
      errors.push("Invalid worksheet version or identifier.");
      return;
    }

    if (
      worksheetVersion.startsWith("Rev.") &&
      worksheetVersion.endsWith("3") &&
      worksheetIdentifier === "QMS-2330-PUR-004-01-FRM"
    ) {
      const performanceData = this.extractPerformanceData(data);
      this.createPerformanceRatingData(performanceData, sptid, errors); // Pass errors array to createPerformanceRatingData
    } else {
      errors.push("Unsupported worksheet version.");
    }
  }

  private createPerformanceRatingData(
    performanceData: any,
    sptid: number,
    errors: string[]
  ): void {
    // Added errors parameter
    const ratings: IPerformanceRatingData[] = [];

    for (const section in performanceData) {
      if (performanceData.hasOwnProperty(section)) {
        performanceData[section].forEach((item: any) => {
          const ratingData: IPerformanceRatingData = {
            performanceRatingId: 0, // Assuming 0 for new records; adjust as needed
            performanceTrackingId: sptid,
            keyPerformanceIndicatorId: item.kpi, // Assuming item.kpi holds the KPI ID
            rating: item.rating,
            comments: item.comments,
            ratedByUserId: 1, // Replace with actual user ID
            ratedDate: new Date().toISOString(),
            performanceRatingApprovalId: 0, // Assuming 0 for new records; adjust as needed
          };
          ratings.push(ratingData);
        });
      }
    }

    ratings.forEach((rating) => {
      this.updateRating(sptid, rating).subscribe((response) => {});
    });
  }

  private extractPerformanceData(data: any[][]): { [key: string]: any[] } {
    const performanceData: { [key: string]: any[] } = {};

    performanceData["engineering"] = this.extractSectionData(data, 14, 18);
    performanceData["procurement"] = this.extractSectionData(data, 21, 25);
    performanceData["manufacturing quality"] = this.extractSectionData(
      data,
      29,
      34
    );
    performanceData["manufacturing schedule"] = this.extractSectionData(
      data,
      37,
      42
    );
    performanceData["manufacturing cooperation"] = this.extractSectionData(
      data,
      45,
      50
    );
    performanceData["construction"] = this.extractSectionData(data, 53, 57);

    return performanceData;
  }

  private extractSectionData(
    data: any[][],
    startRow: number,
    endRow: number
  ): any[] {
    const sectionData: any[] = [];

    for (let row = startRow; row <= endRow; row++) {
      const kpi = data[row][2]; // Assuming KPI ID is in column C
      const rating = data[row][3]; // Assuming rating is in column D
      const comments = data[row][4]; // Assuming comments are in column E

      if (kpi && rating) {
        sectionData.push({
          kpi,
          rating,
          comments,
        });
      }
    }

    return sectionData;
  }
}
