import { Component, OnInit, Output, EventEmitter } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { VendorCommodityDataService } from "../../../services/vendor-information/vendor-commodity.service";
import { VendorDataService } from "../../../services/vendor-data.service";
import { IVendorData } from "../../../interfaces/administration/vendorData";
import { VendorCommodityData } from "../../../interfaces/vendor-information/vendorCommodityData";
import { IVendorCommodityData } from "../../../interfaces/administration/vendorCommodityData";
import { CommodityCodesService } from "../../../services/administration/commodity-codes.service";
import { IViewVendorCommodityData } from "../../../interfaces/vendor-information/viewVendorCommodityData";
import { ICommodityResult } from "../../../interfaces/qa-management/commodityResult";
import { tap, forkJoin } from "rxjs";

@Component({
  // Updated selector
  selector: "app-vendor-edit-commodity",
  templateUrl: "./vendor-edit-commodity.component.html",
  styles: [
    `
      .button-container {
        display: flex !important;
        justify-content: space-between !important;
      }
      .button-group {
        display: flex;
        gap: 8px;
      }
    `,
  ],
})
// Updated class name
export class VendorEditCommodityComponent implements OnInit {
  @Output() formCancelled = new EventEmitter<void>();
  commodityForm: FormGroup;
  validationMessages: string[] = [];
  vendorInfo: IVendorData | null = null;
  vendorCommodity: IViewVendorCommodityData | null = null;
  isLoading: boolean = true;
  vendor: IVendorData;
  editedCommodityCode: ICommodityResult;
  vendorCommodityId: number;
  vendorId: number;
  suppliers: IVendorData[] = [];
  manufacturers: IVendorData[] = [];

  constructor(
    private fb: FormBuilder,
    private commodityCodesService: CommodityCodesService,
    private vendorCommodityService: VendorCommodityDataService,
    private vendorDataService: VendorDataService
  ) {
    this.commodityForm = this.fb.group({
      commodityCodes: [""],
      commodityBrand: [""],
      supplier: [0],
      supplierRating: [""],
      supplierRatingComment: [""],
      imaNumber: [""],
      supplierSD: [false],
      manufacturer: [0],
      manufacturerRating: [""],
      manufacturerRatingComment: [""],
      manufacturerIMANumber: [""],
      manufacturerSD: [false],
    });
  }

  ngOnInit(): void {
    this.loadCommodity();
    this.loadVendor();
  }

  loadVendor() {
    this.vendorCommodityService.currentVendorData$.subscribe((data) => {
      this.vendor = data;
      this.vendorId = data.vendorId;
    });
  }

  loadCommodity(): void {
    this.isLoading = true;
    this.vendorCommodityService.currentViewVendorCommodityData$.subscribe(
      (data) => {
        this.vendorCommodity = data;
        this.vendorCommodityId = data.vendorCommodityId;

        this.loadManufacturersAndSuppliers(
          data.supplierVendorId,
          data.manufacturerVendorId
        );

        const vendorCommodity$ = this.vendorCommodityService
          .getVendorCommodityById(this.vendorCommodity.vendorCommodityId)
          .pipe(tap((result) => this.setFormValues(result)));

        const commodityInfo$ = this.commodityCodesService
          .getCommodityInformation(data.commodityId)
          .pipe(
            tap((commodityData) => (this.editedCommodityCode = commodityData))
          );

        forkJoin([vendorCommodity$, commodityInfo$]).subscribe(() => {
          this.isLoading = false;
        });
      }
    );
  }

  loadManufacturersAndSuppliers(
    selectedSupplier: number = 0,
    selectedManufacturer: number = 0
  ) {
    this.vendorDataService.currentVendorsList$.subscribe((data) => {
      if (data && data.length > 0) {
        this.setupLists(data, selectedSupplier, selectedManufacturer);
      } else {
        this.vendorDataService.getVendors().subscribe((results) => {
          if (results) {
            this.setupLists(data, selectedSupplier, selectedManufacturer);
          }
        });
      }
    });
  }

  setupLists(
    data: IVendorData[],
    selectedSupplier: number = 0,
    selectedManufacturer: number = 0
  ) {
    if (data) {
      this.suppliers = data
        .filter((vendor) => vendor.isSupplier === true)
        .sort((a, b) => a.vendorName.localeCompare(b.vendorName));
      this.manufacturers = data
        .filter((vendor) => vendor.isManufacturer === true)
        .sort((a, b) => a.vendorName.localeCompare(b.vendorName));
    }

    this.commodityForm.controls["supplier"].setValue(selectedSupplier);
    this.commodityForm.controls["manufacturer"].setValue(selectedManufacturer);
  }

  setFormValues(data: VendorCommodityData): void {
    this.commodityForm.patchValue({
      commodityCodes: this.vendorCommodity.commodityCodeTrimmed,
      commodityBrand: this.vendorCommodity.brandName,
      supplier: this.vendorCommodity.supplierVendorId,
      supplierRating: this.vendorCommodity.supplierRating,
      supplierRatingComment: this.vendorCommodity.supplierRatingComments,
      imaNumber: this.vendorCommodity.supplierImanumber,
      supplierSD: data.supplierSD,
      manufacturer: this.vendorCommodity.manufacturerVendorId,
      manufacturerRating: this.vendorCommodity.manufacturerRating,
      manufacturerRatingComment:
        this.vendorCommodity.manufacturerRatingComments,
      manufacturerIMANumber: this.vendorCommodity.manufacturerImanumber,
      manufacturerSD: data.manufacturerSD,
    });
  }

  onSubmit(): void {
    this.validationMessages = [];
    let supplierId = this.commodityForm.controls["supplier"].value;
    let manufacturerId = this.commodityForm.controls["manufacturer"].value;

    if (supplierId < 1 && manufacturerId < 1) {
      this.validationMessages.push("Supplier or Manufacturer are required.");
    }

    this.isLoading = true;

    let commodityData = this.createCommodityData(supplierId, manufacturerId);
    this.updateCommodity(
      this.vendorCommodityId,
      this.commodityForm.controls["commodityBrand"].value,
      commodityData
    );
  }

  createCommodityData(
    supplierId: number,
    manufacturerId: number
  ): IVendorCommodityData {
    return {
      vendorCommodityId: this.vendorCommodityId,
      supplierId: supplierId,
      approvedByUserId: 0, // Set appropriate value
      approvedDate: undefined, // Set appropriate value
      rejectedByUserId: 0, // Set appropriate value
      rejectedDate: undefined, // Set appropriate value
      supplierCapabilityRating:
        this.commodityForm.controls["supplierRating"].value || 0,
      supplierCapabilityComments:
        this.commodityForm.controls["supplierRatingComment"].value,
      supplierIMANumber: this.commodityForm.controls["imaNumber"].value,
      supplierSD: this.commodityForm.controls["supplierSD"].value,
      brandId: 0, // Set appropriate value
      manufacturerId: manufacturerId,
      manufacturerCapabilityRating:
        this.commodityForm.controls["manufacturerRating"].value || 0,
      manufacturerCapabilityComments:
        this.commodityForm.controls["manufacturerRatingComment"].value,
      manufacturerIMANumber:
        this.commodityForm.controls["manufacturerIMANumber"].value,
      manufacturerSD: this.commodityForm.controls["manufacturerSD"].value,
      isApproved: true,
      isActive: true,
    };
  }

  updateCommodity(
    commodityId: number,
    brandName: string,
    commodityRequest: IVendorCommodityData
  ) {
    this.vendorCommodityService
      .updateVendorCommodity(
        this.vendorId,
        commodityId,
        brandName,
        commodityRequest
      )
      .subscribe(() => {
        this.cancelForm();
      });
  }

  cancelForm(): void {
    this.formCancelled.emit();
  }
}
