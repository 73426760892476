import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'projects/aqua3/src/environments/environment';
const apiUrl = environment.apiUrl;
@Injectable({
  providedIn: 'root'
})
export class VendorDataAsurenceService {

  constructor(private http: HttpClient) { }
  public getVendorDataAssuranceDetails() {
    return  this.http.get(`${apiUrl}/procedures/vendorDataAssurance`, { responseType: 'blob' });
 }
 public getUserAccessControlDetails() {
  return  this.http.get(`${apiUrl}/procedures/UserAccessControlProcess`, { responseType: 'blob' });
}

}
