import { Component, Input, OnInit, SimpleChanges } from "@angular/core";
import { AuditObservationService } from "../../../services/qa-management/audit-observation.service";
import { PageViewMode } from "../../../interfaces/common/pageViewMode";
import { IAuditObservationEvaluationDetails } from "../../../interfaces/qa-management/audit-observation/auditObservationEvaluationDetails";
import { FormArray, FormBuilder, FormGroup } from "@angular/forms";
import { Router } from "@angular/router";

@Component({
    selector: 'manufacturer-audit-and-observation-details',
    templateUrl: './mao-details.component.html',
    styleUrls: ['./mao-details.component.css',
        '../mao.component.css']
})
export class ManufacturerAuditAndObservationDetailsComponent implements OnInit {
    @Input() recordNo: number;
    @Input() isEditingEnabled: boolean;
    auditObservationDetails: IAuditObservationEvaluationDetails;
    editMode = false;
    detailsForm: FormGroup;
    tableDataSource: FormArray;

    displayedColumns: string[] = ['name', 'result', 'completionDate', 'rating', 'notes'];

    constructor(
        private auditObservationService: AuditObservationService,
        private fb: FormBuilder,
        private router: Router,
    ) { }

    ngOnInit(): void {
        this.detailsForm = this.fb.group({
            recordNo: [''],
            recordType: [''],
            lead: [''],
            otherParticipants: [''],
            manufacturer: [''],
            isRML: [''],
            fileAttachmentLink: [''],
            auditDate: [''],
            status: [''],
            notes: [''],
            commodities: this.fb.array([]),
        });

        this.tableDataSource = this.detailsForm.get('commodities') as FormArray;
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.recordNo) {
            this.auditObservationService.getDetailsById(this.recordNo).subscribe((data) => {
                this.auditObservationDetails = data;
                this.populateForm(data);
            });
        }
    }

    populateForm(details: IAuditObservationEvaluationDetails): void {
        this.detailsForm.patchValue({
            recordNo: details.id,
            recordType: details.recordType,
            lead: details.fullName,
            otherParticipants: details.participants,
            manufacturer: details.manufacturerName,
            isRML: details.isRML,
            fileAttachmentLink: details.fileURLLink,
            auditDate: details.recordDate,
            status: details.status,
            notes: details.notes
        });

        this.tableDataSource.clear();
        details.commodities.forEach(commodity => {
            this.tableDataSource.push(this.fb.group({
                name: [commodity.commodityName],
                result: [commodity.resultDesc],
                completionDate: [commodity.completionDate],
                rating: [commodity.ratingDesc],
                notes: [commodity.notes]
            }));
        });
    }

    getRatingClass(rating: string): string {
        return this.auditObservationService.getRatingClass(rating);
    }

    backToSearch(): void {
        this.auditObservationService.setMAOMode(PageViewMode.Base);
    }

    editMAO(): void {
        this.auditObservationService.setMAOAddMode(PageViewMode.Edit);
        this.auditObservationService.setMAOObject({
            evaluation: {
                id: this.auditObservationDetails.id,
                recordType: this.auditObservationDetails.recordType,
                responsibleCai: this.auditObservationDetails.responsibleCAI,
                participants: this.auditObservationDetails.participants,
                manufacturerId: this.auditObservationDetails.manufacturerId,
                agencyId: this.auditObservationDetails.agencyId,
                recordDate: this.auditObservationDetails.recordDate,
                notes: this.auditObservationDetails.notes,
                statusId: this.auditObservationDetails.statusId
            },
            commodities: this.auditObservationDetails.commodities.map(commodity => {
                return {
                    id: commodity.id,
                    commodityName: commodity.commodityName,
                    resultId: commodity.resultId,
                    completionDate: commodity.completionDate,
                    ratingId: commodity.ratingId,
                    notes: commodity.notes,
                    commodityId: commodity.commodityId
                };
            })
        });
        this.router.navigate(["/qa-management/manufacturer-audit-observation/addAuditObservation"]);
    }
}  