import { Component, OnInit } from "@angular/core";
import {
  NextPageService,
  NavigationLocation,
  PageLayout,
  SearchType,
  SliderType,
} from "@cvx/nextpage";
import { MsalService } from "@azure/msal-angular";
import { UserRequestsDataService } from "./services/administration/user-requests.service";
import { IUserData } from "./interfaces/administration/userData";
import { ILoggedUserData } from "./interfaces/common/loggedUserData";
import { Observable } from "rxjs/internal/Observable";
import { map } from "rxjs/internal/operators/map";
import { LoggedUserService } from "./services/common/logged-user.service";
import { RoutesService } from "./services/routes.service";

@Component({
  selector: "app-root",
  templateUrl: "app.component.html",
})
export class AppComponent implements OnInit {
  NavigationLocation = NavigationLocation;
  PageLayout = PageLayout;
  SearchType = SearchType;
  SliderType = SliderType;
  loggedUsername: string = "";
  filteredRoutesLoaded: boolean = false;

  constructor(
    private NP: NextPageService,
    private msalService: MsalService,
    private loggedUserService: LoggedUserService,
    private userRequestsDataService: UserRequestsDataService,
    private routesService: RoutesService,
  ) {
    this.NP.options.sitename = "Automated Quality Assurance - 3";
    this.setLoggedUserInfo();
  }

  ngOnInit() {
    this.routesService.filteredRoutesLoaded.subscribe(loaded => {
      if (loaded) {
        this.filteredRoutesLoaded = loaded;
      }
    });
  }

  setLoggedUserInfo() {
    if (this.msalService.instance.getAllAccounts().length > 0) {
      this.msalService.instance.setActiveAccount(
        this.msalService.instance.getAllAccounts()[0]
      );
      let accountInfo = this.msalService.instance.getActiveAccount();
      this.loggedUsername = accountInfo.username;
      this.getLoggedUserInfo().subscribe((loggedUserData) => {
        if (loggedUserData !== null) {
          this.loggedUserService.setLoggedUserData(loggedUserData);
          console.log(loggedUserData.cai);
        }
      });
    } else {
      this.msalService.loginRedirect();
    }
  }

  getLoggedUserInfo(): Observable<ILoggedUserData> {
    return this.userRequestsDataService
      .getUserByEmail(this.loggedUsername)
      .pipe(
        map((data) => {
          const userData: IUserData = data;
          this.userRequestsDataService.setActualLoggedUserData(userData);
          const loggedUserData: ILoggedUserData = {
            userId: userData.userId,
            cai: userData.cai,
            email: userData.email,
            isSystemAdministrator: userData.isSystemAdministrator,
            isQualityAssuranceAndControlUser: userData.isQualityAssuranceAndControlUser,
            isProjectTeamMember: userData.isProjectTeamMember,
            isQualityAssuranceAdministrator: userData.isQualityAssuranceAdministrator,
          };
          return loggedUserData;
        })
      );
  }
}
