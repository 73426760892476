import { HttpClient } from "@angular/common/http";
import { Component, OnInit, ViewChild } from "@angular/core";
import { FormGroup, FormBuilder } from "@angular/forms";
import { MatPaginator } from "@angular/material/paginator";
import { MatTableDataSource } from "@angular/material/table";
import { IViewPerformanceTrackingData } from "../../interfaces/vendor-information/viewPerformanceTrackingData";
import { PerformanceTrackingService } from "../../services/vendor-information/performance-tracking.service";
import { Router } from "@angular/router";
import { BaseComponent } from "../../shared/base/base.component";
import { UserPermissionsService } from "../../services/common/user-permissions.service";
import { CapitalProjectsDataService } from "../../services/capital-projects.service";
import { ICapitalProjectData } from "../../interfaces/administration/capitalProjectData";
import { ILookupData } from "../../interfaces/administration/lookupData";
import { PurchaserManagementService } from "../../services/administration/purchaser-management.service";
import { ManufacturersNameService } from "../../services/qa-reports/manufacturers-name.service";
import { PerformanceTrackingSearchFilter } from "../../interfaces/administration/PerformanceTrackingSearchFilter";
import { ICommodity } from "../../interfaces/administration/commodity";
import { CommodityCodesService } from "../../services/administration/commodity-codes.service";

@Component({
  selector: "app-performance-tracking",
  templateUrl: "./performance-tracking.component.html",
  styles: [
    `
      .button-container {
        display: flex !important;
        justify-content: space-between !important;
      }

      .unchecked {
        color: red;
      }

      .mt-1 {
        margin-top: 1rem !important;
      }
    `,
  ],
})
export class PerformanceTrackingComponent
  extends BaseComponent
  implements OnInit
{
  title = "Performance Tracking";
  public dataSource: MatTableDataSource<IViewPerformanceTrackingData>;
  public originalData: MatTableDataSource<IViewPerformanceTrackingData>;
  public searchForm: FormGroup;
  commodityCodes: number[] = [];
  selectedCommodityObjects: ICommodity[] = [];
  commoditiesData: ICommodity[] = [];

  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;

  public displayedColumns: string[] = [
    "actions",
    "projectLongName",
    "commodityCodeTrimmed",
    "commodityDescriptionPath",
    "poNumber",
    "eng",
    "pro",
    "mfg",
    "con",
  ];

  dataSourceLoading: boolean = true;
  public capitalProjectsData: ICapitalProjectData[] = [];
  public purchasers: ILookupData[] = [];
  public projectLocations: string[] = [];

  get isFilterSelected(): boolean {
    const filters = this.searchForm.value;
    return Object.values(filters).some((value) => value && value !== "");
  }

  constructor(
    private fb: FormBuilder,
    private http: HttpClient,
    private router: Router,
    private service: PerformanceTrackingService,
    userPermissionsService: UserPermissionsService,
    private capitalProjectsService: CapitalProjectsDataService,
    private purchaserService: PurchaserManagementService,
    private manufacturerService: ManufacturersNameService,
    private commodityCodesService: CommodityCodesService
  ) {
    super(userPermissionsService);
    this.searchForm = this.fb.group({
      recordDateFrom: [""],
      recordDateTo: [""],
      projectName: [""],
      projectLocation: [""],
      purchaser: [""],
      poNumber: [""],
      vendor: [""],
      commodityDescription: [""],
      commodityCodes: [[] as number[]],
    });
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.updateListings();
  }

  updateListings() {
    this.dataSourceLoading = true;
    this.loadCapitalProjects();
    this.getPurchasers();
    this.loadProjctsLocation();
    this.service.getPerformanceTrackingData().subscribe({
      next: (data) => {
        this.dataSourceLoading = true;

        this.dataSource = new MatTableDataSource<IViewPerformanceTrackingData>(
          data
        );

        this.originalData =
          new MatTableDataSource<IViewPerformanceTrackingData>(data);

        this.applyFilters();

        this.dataSourceLoading = false;
        // Delay the initialization of the paginator until the data is assigned
        setTimeout(() => {
          this.dataSource.paginator = this.paginator;
          this.paginator.pageSize = 10;
        });
      },
      error: (err) => {
        console.error(err);
      },
    });
  }

  handleSelectedCommodityNames(selectedCommodityNames: ICommodity[]): void {
    this.selectedCommodityObjects = selectedCommodityNames;
    if (selectedCommodityNames.length !== 0) {
      this.searchForm
        .get("commodityCodes")
        .setValue(
          selectedCommodityNames.map((commodity) => commodity.commodityId)
        );
    } else {
      this.searchForm.get("commodityCodes").setValue([]);
    }
  }

  loadCommodities(): void {
    this.commodityCodesService
      .getAllCommodities()
      .subscribe((data: ICommodity[]) => {
        this.commoditiesData = data;
      });
  }

  onSearch() {
    this.applyFilters();
  }

  loadProjctsLocation() {
    this.capitalProjectsService.getProjectsLocations().subscribe((data) => {
      this.projectLocations = data;
    });
  }

  loadCapitalProjects() {
    this.capitalProjectsService.getAllProjects().subscribe((data) => {
      this.capitalProjectsData = data;
    });
  }

  getPurchasers() {
    this.purchaserService.getPurchasers().subscribe((data: ILookupData[]) => {
      this.purchasers = data;
    });
  }

  applyFilters() {
    const filters = this.searchForm.value;

    let filteredData = this.originalData.data;

    // Date range filter
    if (filters.recordDateFrom || filters.recordDateTo) {
      filteredData = filteredData.filter((item) => {
        const itemDate = new Date(item.createdDate);
        const fromDate = filters.recordDateFrom
          ? new Date(filters.recordDateFrom)
          : null;
        const toDate = filters.recordDateTo
          ? new Date(filters.recordDateTo)
          : null;

        if (fromDate && toDate) {
          return itemDate >= fromDate && itemDate <= toDate;
        } else if (fromDate) {
          return itemDate >= fromDate;
        } else if (toDate) {
          return itemDate <= toDate;
        } else {
          return true;
        }
      });
    }

    // Select filters
    if (filters.projectName) {
      filteredData = filteredData.filter(
        (item) => item.projectId === Number(filters.projectName)
      );
    }

    if (filters.projectLocation) {
      filteredData = filteredData.filter(
        (item) => item.projectLocation === filters.projectLocation
      );
    }

    if (filters.purchaser) {
      filteredData = filteredData.filter(
        (item) => item.projectSbuid === filters.purchaser
      );
    }

    // String filters
    if (filters.poNumber) {
      filteredData = filteredData.filter((item) =>
        item.ponumber?.toLowerCase().includes(filters.poNumber.toLowerCase())
      );
    }

    if (filters.vendor) {
      filteredData = filteredData.filter((item) =>
        item.vendorName?.toLowerCase().includes(filters.vendor.toLowerCase())
      );
    }

    if (filters.commodityDescription) {
      filteredData = filteredData.filter((item) =>
        item.commodityDescriptionPath
          ?.toLowerCase()
          .includes(filters.commodityDescription.toLowerCase())
      );
    }

    // Uncomment and adjust if needed
    if (filters.commodityCodes && filters.commodityCodes.length > 0) {
      filteredData = filteredData.filter(
        (item) => item.commodityId == filters.commodityCodes
      );
    }

    this.dataSource.data = filteredData;
    this.dataSource.paginator = this.paginator;
  }

  clearFilters() {
    this.searchForm.reset();
    this.updateListings();
  }

  select(data: IViewPerformanceTrackingData) {
    this.router.navigate([
      "vendor-information/performance-tracking/preview-spt/",
      data.performanceTrackingId,
    ]);
  }

  exportData() {
    const filters = this.searchForm.value;

    const filter: PerformanceTrackingSearchFilter = {
      projectId: filters.projectName ? Number(filters.projectName) : 0,
      projectLocation: filters.projectLocation || "",
      epcId: filters.purchaser ? Number(filters.purchaser) : 0,
      vendor: filters.vendor || "",
      commodityId:
        filters.commodityCodes.length > 0 ? filters.commodityCodes : [0],
      commodityDescription: filters.commodityDescription || "",
      poNumber: filters.poNumber || "",
      fromDate: filters.recordDateFrom
        ? new Date(filters.recordDateFrom)
        : undefined,
      toDate: filters.recordDateTo ? new Date(filters.recordDateTo) : undefined,
    };

    this.manufacturerService.getsptreport(filter).subscribe((data) => {
      const blob = new Blob([data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = `AQUA3_SupplierPerformaceTrackingReport_${new Date()
        .toISOString()
        .slice(0, 19)
        .replace(/:/g, "-")}.xlsx`;
      a.click();
      window.URL.revokeObjectURL(url);
    });
  }
}
