import { OnInit, Component } from "@angular/core";

@Component({
  selector: "no-access",
  templateUrl: "./no-access.component.html",
})
export class NoAccessComponent implements OnInit {
  constructor() { }

  ngOnInit() { }
}
