import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { NextPageRoute } from "@cvx/nextpage/public_api";
import { HomeComponent } from "./home/home.component";
import { MsalGuard, MsalRedirectComponent } from "@azure/msal-angular";
import { FAQComponent } from "./help/faq/faq.component";
import { ContactComponent } from "./help/contact/contact.component";
import { HelpComponent } from "./help/help/help.component";
import { AdministrationComponent } from "./administration/administration.component";
import { AlertsComponent } from "./administration/alerts/alerts.component";
import { ReportsFormsComponent } from "./reports-forms/reports-forms.component";
import { ApprovedVendorListComponent } from "./approved-vendor-list/approved-vendor-list.component";
import { UsersAndRolesComponent } from "./administration/users-roles/users-roles.component";
import { CountriesAndStatesComponent } from "./administration/countries-states/countries-states.component";
import { VendorsComponent } from "./administration/vendors/vendors.component";
import { CreateVendorComponent } from "./administration/vendors/create-vendor.component";
import { BUsAndOpcosComponent } from "./administration/bus-opcos/bus-opcos.component";
import { EditOpcoDataFormComponent } from "./administration/bus-opcos/forms/edit-opco-data.form.component";
import { EditVendorComponent } from "./administration/vendors/edit-vendor.component";
import { PreviewVendorComponent } from "./administration/vendors/preview-vendor.component";
import { EditBUDataFormComponent } from "./administration/bus-opcos/forms/edit-bu-data-form.component";
import { AddOpcoDataFormComponent } from "./administration/bus-opcos/forms/add-opco-data-form.component";
import { CreateCountryComponent } from "./administration/countries-states/create-country/create-country.component";
import { EditCountryComponent } from "./administration/countries-states/edit-country/edit-country.component";
import { PurchaserManagementComponent } from "./administration/purchaser-management/purchaser-management.component";
import { CreatePurchaserManagementComponent } from "./administration/purchaser-management/create-purchaser-management.component";
import { EditPurchaserManagementComponent } from "./administration/purchaser-management/edit-purchaser-management.component";
import { AddBUDataFormComponent } from "./administration/bus-opcos/forms/add-bu-data-form.component";
import { CreateStateComponent } from "./administration/countries-states/create-state/create-state.component";
import { EditStateComponent } from "./administration/countries-states/edit-state/edit-state.component";
import { EditUserRequestsFormComponent } from "./administration/administration-home-subcomponents/user-requests/edit-user-request-form.component";
import { CustomErrorComponent } from "./shared/error-page/custom-error-page.component";
import { PerformActionCommodityRequestFormComponent } from "./administration/administration-home-subcomponents/commodity-requests/edit-commodity-request/perform-action-commodity-request-form.component";
import { CommodityCodesComponent } from "./administration/commodity-codes/commodity-codes.component";
import { ProjectAndAvlComponent } from "./administration/project-and-avl/project-and-avl.component";
import { CreateProjectComponent } from "./approved-vendor-list/create-project.component";
import { AgenciesAndInspectorsComponent } from "./qa-management/agencies-and-inspectors/agencies-and-inspectors-component";
import { EditUserComponent } from "./administration/users-roles/edit-user/edit-user.component";
import { AddUserComponent } from "./administration/users-roles/add-user/add-user.component";
import { EditProjectComponent } from "./approved-vendor-list/edit-project.component";
import { PreviewProjectComponent } from "./approved-vendor-list/preview-project.component";
import { AddCommodityToVendorComponent } from "./vendor-information/add-commodity-to-vendor/add-commodity-to-vendor.component";
import { BuyersEngineersQaesComponent } from "./qa-management/buyers-engineers-qaes/buyers-engineers-qaes-component";
import { BuyersEngineersQaesCreateComponent } from "./qa-management/buyers-engineers-qaes/buyers-engineers-qaes-create-component";
import { BuyersEngineersQaesEditComponent } from "./qa-management/buyers-engineers-qaes/buyers-engineers-qaes-edit-component";
import { EditInspectorFormComponent } from "./qa-management/agencies-and-inspectors/inspectors/edit-inspector-form.component";
import { AddVendorComponent } from "./vendor-information/add-vendor/add-vendor.component";
import { CreateNewVendorComponent } from "./vendor-information/add-vendor/create-new-vendor.component";
import { VendorSearchComponent } from "./vendor-information/vendor-search/vendor-search.component";
import { AddInspectorFormComponent } from "./qa-management/agencies-and-inspectors/inspectors/add-inspector-form.component";
import { EditAgencyFormComponent } from "./qa-management/agencies-and-inspectors/agencies/edit-agency-form.component";
import { AddAgencyFormComponent } from "./qa-management/agencies-and-inspectors/agencies/add-agency-form.component";
import { VendorDetailsComponent } from "./vendor-information/vendor-search/vendor-details.component";
import { WorkRequestsComponent } from "./qa-management/work-requests/work-requests-component";
import { PerformanceTrackingComponent } from "./vendor-information/performance-tracking/performance-tracking.component";
import { ManufacturerAuditAndObservationComponent } from "./qa-management/manufacturer-audit-and-observation/mao.component";
import { CreatePerformanceTrackingComponent } from "./vendor-information/performance-tracking/create-performance-tracking.component";
import { AddWorkRequestFormComponent } from "./qa-management/work-requests/add-work-request-form.component";
import { EditPerformanceTrackingComponent } from "./vendor-information/performance-tracking/edit-performance-tracking.component";
import { PreviewPerformanceTrackingComponent } from "./vendor-information/performance-tracking/preview-performance-tracking.component";
import { EditWorkRequestFormComponent } from "./qa-management/work-requests/edit-work-request-form.component";
import { SptReportsComponent } from "./reports-forms/reports/spt-reports/spt-reports.component";
import { AddManufacturerAuditAndObservationComponent } from "./qa-management/manufacturer-audit-and-observation/add-mao/add-mao.component";
import { AddCommodityMAOComponent } from "./qa-management/manufacturer-audit-and-observation/add-mao/add-commodity-mao/add-commodity-mao.component";
import { MSAComponent } from "./administration/msa/msa.component";
import { InspectorEvaluationComponent } from "./qa-management/inspector-evaluation/inspector-evaluation.component";
import { AddCommodityIEComponent } from "./qa-management/inspector-evaluation/add-ie/add-commodity-ie/add-commodity-ie.component";
import { AddInspectorEvaluationComponent } from "./qa-management/inspector-evaluation/add-ie/add-ie.component";
import { UploadDocumentsComponent } from "./qa-management/upload-documents/upload-documents.component";
import { UploadInspectionReportComponenet } from "./qa-management/upload-inspection-report/upload-inspection-report.component";
import { AddInspectionReportFormComponent } from "./qa-management/work-requests/inspection-reports/add-inspection-report-form.component";
import { AddRFQFormComponent } from "./qa-management/work-requests/rfq/add-rfq-form.component";
import { EditRFQFormComponent } from "./qa-management/work-requests/rfq/edit-rfq-form.component";
import { EditInspectionReportFormComponent } from "./qa-management/work-requests/inspection-reports/edit-inspection-report-form.component";
import { UploadPerformanceTrackingComponent } from "./vendor-information/performance-tracking/upload-performance-tracking.component";
import { AddAlertComponent } from "./administration/alerts/add-alert.component";
import { UpdateAlertComponent } from "./administration/alerts/update-alert.component";
import { CopyWorkRequestFormComponent } from "./qa-management/work-requests/copy-work-request-form.component";

import { ManufacturebynameComponent } from "./reports-forms/qa-reports/manufacturebyname/manufacturebyname.component";
import { ManufacturebycommodityComponent } from "./reports-forms/qa-reports/manufacturebycommodity/manufacturebycommodity.component";
import { InspectorsbycommodityComponent } from "./reports-forms/qa-reports/inspectorsbycommodity/inspectorsbycommodity.component";
import { QaReportsComponent } from "./reports-forms/qa-reports/qa-reports.component";
import { AssignmentlogComponent } from "./reports-forms/qa-reports/assignmentlog/assignmentlog.component";
import { ClosedjoblistingComponent } from "./reports-forms/qa-reports/closedjoblisting/closedjoblisting.component";
import { QabydateComponent } from "./reports-forms/qa-reports/qabydate/qabydate.component";
import { QabypoComponent } from "./reports-forms/qa-reports/qabypo/qabypo.component";
import { QabyqaeComponent } from "./reports-forms/qa-reports/qabyqae/qabyqae.component";
import { QabyclientComponent } from "./reports-forms/qa-reports/qabyclient/qabyclient.component";
import { QabyinspectorComponent } from "./reports-forms/qa-reports/qabyinspector/qabyinspector.component";
import { AgencyratingsComponent } from "./reports-forms/qa-reports/agencyratings/agencyratings.component";
import { CloseiContractNoticeFormComponent } from "./qa-management/work-requests/emails/close-icontract-notice-form.component";
import { AddActivityFormComponent } from "./qa-management/work-requests/activity/add-activity-form.component";
import { EmailIContractToAgencyComponent } from "./qa-management/work-requests/emails/email-icontract-agency.component";
import { RoleGuard } from "./role-guard";
import { PageConfig } from "./page-config";
import { RoutesService } from "./services/routes.service";
import { NoAccessComponent } from "./shared/no-access/no-access.component";

let routes: NextPageRoute[] = [
  {
    path: "home",
    title: "Home",
    redirectTo: "/",
    pathMatch: "full",
  },
  {
    // Needed for handling redirect after login
    path: "auth",
    component: MsalRedirectComponent,
  },
  {
    path: "qa-management",
    title: "QA Management",
    children: [
      {
        path: "",
        title: "QA Management",
        redirectTo: "work-requests",
        pathMatch: "full",
      },
      {
        path: "work-requests",
        title: "Work Requests",
        children: [
          {
            path: "",
            component: WorkRequestsComponent,
            canActivate: [MsalGuard, RoleGuard],
            data: PageConfig.QAWorkRequests.data,
          },
          {
            path: "add-work-request",
            component: AddWorkRequestFormComponent,
            canActivate: [MsalGuard, RoleGuard],
            data: PageConfig.QANewWorkRequest.data,
          },
          {
            path: "edit-work-request/:id",
            component: EditWorkRequestFormComponent,
            canActivate: [MsalGuard, RoleGuard],
            data: PageConfig.QAViewWorkRequest.data,
          },
          {
            path: "add-inspection-report/:id",
            component: AddInspectionReportFormComponent,
            canActivate: [MsalGuard, RoleGuard],
            data: PageConfig.QAViewWorkRequest.data,
          },
          {
            path: "edit-inspection-report/:irId",
            component: EditInspectionReportFormComponent,
            canActivate: [MsalGuard, RoleGuard],
            data: PageConfig.QAViewWorkRequest.data,
          },
          {
            path: "add-rfq/:id",
            component: AddRFQFormComponent,
            canActivate: [MsalGuard, RoleGuard],
            data: PageConfig.QAViewWorkRequest.data,
          },
          {
            path: "edit-rfq/:rfqId",
            component: EditRFQFormComponent,
            canActivate: [MsalGuard, RoleGuard],
            data: PageConfig.QAViewWorkRequest.data,
          },
          {
            path: "copy-work-request",
            component: CopyWorkRequestFormComponent,
            canActivate: [MsalGuard, RoleGuard],
            data: PageConfig.QAViewWorkRequest.data,
          },
          {
            path: "close-icontract-notice",
            component: CloseiContractNoticeFormComponent,
            canActivate: [MsalGuard, RoleGuard],
            data: PageConfig.QAViewWorkRequest.data,
          },
          {
            path: "email-icontract-agency",
            component: EmailIContractToAgencyComponent,
            canActivate: [MsalGuard, RoleGuard],
            data: PageConfig.QAViewWorkRequest.data,
          },
          {
            path: "add-activity",
            component: AddActivityFormComponent,
            canActivate: [MsalGuard, RoleGuard],
            data: PageConfig.QAViewWorkRequest.data,
          },
        ],
      },
      {
        path: "agencies-and-inspectors",
        title: "Agencies and Inspectors",
        children: [
          {
            path: "",
            component: AgenciesAndInspectorsComponent,
            canActivate: [MsalGuard, RoleGuard],
            data: PageConfig.QAAgenciesAndInspectors.data,
          },
          {
            path: "edit-inspector/:id",
            component: EditInspectorFormComponent,
            canActivate: [MsalGuard, RoleGuard],
            data: PageConfig.QAAgenciesAndInspectors.data,
          },
          {
            path: "add-inspector",
            component: AddInspectorFormComponent,
            canActivate: [MsalGuard, RoleGuard],
            data: PageConfig.QAAgenciesAndInspectors.data,
          },
          {
            path: "edit-agency/:id",
            component: EditAgencyFormComponent,
            canActivate: [MsalGuard, RoleGuard],
            data: PageConfig.QAAgenciesAndInspectors.data,
          },
          {
            path: "add-agency",
            component: AddAgencyFormComponent,
            canActivate: [MsalGuard, RoleGuard],
            data: PageConfig.QAAgenciesAndInspectors.data,
          },
        ],
      },
      {
        path: "buyers-engineers-qaes",
        title: "Buyers, Engineers, QAEs",
        children: [
          {
            path: "",
            component: BuyersEngineersQaesComponent,
            canActivate: [MsalGuard, RoleGuard],
            data: PageConfig.QABuyersQAEsInpsectors.data,
          },
          {
            path: "create",
            component: BuyersEngineersQaesCreateComponent,
            canActivate: [MsalGuard, RoleGuard],
            data: PageConfig.QABuyersQAEsInpsectors.data,
          },
          {
            path: "edit",
            component: BuyersEngineersQaesEditComponent,
            canActivate: [MsalGuard, RoleGuard],
            data: PageConfig.QABuyersQAEsInpsectors.data,
          },
        ],
      },
      {
        path: "manufacturer-audit-observation",
        title: "Manufacturer Audit and Observation",
        canActivate: [MsalGuard, RoleGuard],
        data: PageConfig.QAManufacturer.data,
        children: [
          {
            path: "",
            component: ManufacturerAuditAndObservationComponent,
            canActivate: [MsalGuard, RoleGuard],
            data: PageConfig.QAManufacturer.data,
          },
          {
            path: "addAuditObservation",
            component: AddManufacturerAuditAndObservationComponent,
            canActivate: [MsalGuard, RoleGuard],
            data: PageConfig.QAManufacturer.data,
          },
          {
            path: "addAuditObservation/addCommodity",
            component: AddCommodityMAOComponent,
            canActivate: [MsalGuard, RoleGuard],
            data: PageConfig.QAManufacturer.data,
          },
        ],
      },
      {
        path: "inspector-evaluation",
        title: "Inspector Evaluation",
        children: [
          {
            path: "",
            component: InspectorEvaluationComponent,
            canActivate: [MsalGuard],
          },
          {
            path: "add-inspector-evaluation",
            component: AddInspectorEvaluationComponent,
            canActivate: [MsalGuard],
          },
          {
            path: "add-inspector-evaluation/addCommodity",
            component: AddCommodityIEComponent,
            canActivate: [MsalGuard],
          },
        ],
      },
      {
        path: "upload-inspection-report",
        title: "Upload Inspection Report",
        component: UploadInspectionReportComponenet,
        canActivate: [MsalGuard, RoleGuard],
        data: PageConfig.QAWorkRequests.data,
      },
      {
        path: "upload-documents",
        title: "Upload Documents",
        component: UploadDocumentsComponent,
        canActivate: [MsalGuard, RoleGuard],
        data: PageConfig.QAWorkRequests.data,
      },
    ],
  },
  {
    path: "approved-vendor-list",
    title: "Approved Vendor List (AVL)",
    data: PageConfig.AVL.data,
    children: [
      {
        path: "",
        component: ApprovedVendorListComponent,
        canActivate: [MsalGuard, RoleGuard],
        data: PageConfig.AVL.data,
      },
      {
        path: "create",
        component: CreateProjectComponent,
        canActivate: [MsalGuard, RoleGuard],
        data: PageConfig.AVL.data,
      },
      {
        path: "edit/:projectId",
        component: EditProjectComponent,
        canActivate: [MsalGuard, RoleGuard],
        data: PageConfig.AVL.data,
      },
      {
        path: "preview/:projectId",
        component: PreviewProjectComponent,
        canActivate: [MsalGuard, RoleGuard],
        data: PageConfig.AVL.data,
      },
    ],
  },
  {
    path: "vendor-information",
    title: "Vendor Information",
    canActivate: [MsalGuard],
    children: [
      {
        path: "",
        title: "Vendor Search",
        redirectTo: "vendor-search",
        pathMatch: "full",
      },
      {
        path: "performance-tracking",
        title: "Performance Tracking",
        children: [
          {
            path: "",
            component: PerformanceTrackingComponent,
            canActivate: [MsalGuard, RoleGuard],
            data: PageConfig.VIPerformanceTracking.data,
          },
          {
            path: "create-spt/:projectId",
            component: CreatePerformanceTrackingComponent,
            canActivate: [MsalGuard, RoleGuard],
            data: PageConfig.VIPerformanceTracking.data,
          },
          {
            path: "edit-spt/:sptId",
            component: EditPerformanceTrackingComponent,
            canActivate: [MsalGuard, RoleGuard],
            data: PageConfig.VIPerformanceTracking.data,
          },
          {
            path: "preview-spt/:sptId",
            component: PreviewPerformanceTrackingComponent,
            canActivate: [MsalGuard, RoleGuard],
            data: PageConfig.VIPerformanceTracking.data,
          },
          {
            path: "upload-spt/:sptId",
            component: UploadPerformanceTrackingComponent,
            canActivate: [MsalGuard, RoleGuard],
            data: PageConfig.VIPerformanceTracking.data,
          },
        ],
      },
      {
        path: "vendor-search",
        title: "Vendor Search",
        component: VendorSearchComponent,
        canActivate: [MsalGuard, RoleGuard],
        data: PageConfig.VIVendorSearch.data,
      },
      {
        path: "add-vendor",
        title: "Add Vendor",
        component: AddVendorComponent,
        canActivate: [MsalGuard, RoleGuard],
        data: PageConfig.VIAddVendor.data,
      },
      {
        path: "vendor-details/:vendorId",
        component: VendorDetailsComponent,
        canActivate: [MsalGuard],
      },
      {
        path: "add-commodity-to-vendor",
        title: "Add Commodity to Vendor",
        component: AddCommodityToVendorComponent,
        canActivate: [MsalGuard, RoleGuard],
        data: PageConfig.Requests.data,
      },
      {
        path: "create-vendor",
        component: CreateNewVendorComponent,
        canActivate: [MsalGuard],
      },
    ],
  },
  {
    path: "reports-forms",
    title: "Reports & Forms",
    children: [
      {
        path: "",
        component: ReportsFormsComponent,
        canActivate: [MsalGuard],
      },
      {
        path: "qa-reports",
        title: "",
        children: [
          {
            path: "manufacturebyname",
            component: ManufacturebynameComponent,
            canActivate: [MsalGuard],
          },
          {
            path: "inspectorsbycommodity",
            component: InspectorsbycommodityComponent,
            canActivate: [MsalGuard],
          },
          {
            path: "assignmentlog",
            component: AssignmentlogComponent,
            canActivate: [MsalGuard],
          },
          {
            path: "manufacturebycommodity",
            component: ManufacturebycommodityComponent,
            canActivate: [MsalGuard],
          },
          {
            path: "closedjoblisting",
            component: ClosedjoblistingComponent,
            canActivate: [MsalGuard],
          },
          {
            path: "qabydate",
            component: QabydateComponent,
            canActivate: [MsalGuard],
          },
          {
            path: "qabypo",
            component: QabypoComponent,
            canActivate: [MsalGuard],
          },
          {
            path: "qabyqae",
            component: QabyqaeComponent,
            canActivate: [MsalGuard],
          },
          {
            path: "qabyclient",
            component: QabyclientComponent,
            canActivate: [MsalGuard],
          },
          {
            path: "qabyinspector",
            component: QabyinspectorComponent,
            canActivate: [MsalGuard],
          },
          {
            path: "agencyratings",
            component: AgencyratingsComponent,
            canActivate: [MsalGuard],
          },
        ],
      },
      {
        path: "reports/spt-reports",
        component: SptReportsComponent,
        canActivate: [MsalGuard],
      },
    ],
  },

  {
    path: "administration",
    title: "Administration",
    data: PageConfig.Administration.data,
    children: [
      {
        path: "",
        redirectTo: "adminHome",
        pathMatch: "full",
        data: PageConfig.Administration.data,
      },
      {
        path: "adminHome",
        title: "Administration Home",
        children: [
          {
            path: "",
            component: AdministrationComponent,
            canActivate: [MsalGuard, RoleGuard],
            data: PageConfig.Administration.data,
          },
          {
            path: "user-requests/edit/:id",
            component: EditUserRequestsFormComponent,
            canActivate: [MsalGuard, RoleGuard],
            data: PageConfig.Administration.data,
          },
          {
            path: "commodity-requests/performAction",
            component: PerformActionCommodityRequestFormComponent,
            canActivate: [MsalGuard, RoleGuard],
            data: PageConfig.Administration.data,
          },
        ],
      },
      {
        path: "alerts",
        title: "Alerts",
        data: PageConfig.Administration.data,
        children: [
          {
            path: "",
            component: AlertsComponent,
            canActivate: [MsalGuard, RoleGuard],
            data: PageConfig.Administration.data,
          },
          {
            path: "add",
            component: AddAlertComponent,
            canActivate: [MsalGuard, RoleGuard],
            data: PageConfig.Administration.data,
          },
          {
            path: "update/:id",
            component: UpdateAlertComponent,
            canActivate: [MsalGuard, RoleGuard],
            data: PageConfig.Administration.data,
          },
        ],
      },
      {
        path: "projects-approved-vendor-lists",
        title: "Projects & Approved Vendor Lists",
        component: ProjectAndAvlComponent,
        canActivate: [MsalGuard, RoleGuard],
        data: PageConfig.Administration.data,
      },
      {
        path: "business-unit-opcos",
        title: "Business Unit & OPCOs",
        data: PageConfig.Administration.data,
        children: [
          {
            path: "",
            component: BUsAndOpcosComponent,
            canActivate: [MsalGuard, RoleGuard],
            data: PageConfig.Administration.data,
          },
          {
            path: "edit-opco-data-form",
            component: EditOpcoDataFormComponent,
            canActivate: [MsalGuard, RoleGuard],
            data: PageConfig.Administration.data,
          },
          {
            path: "edit-bu-data-form",
            component: EditBUDataFormComponent,
            canActivate: [MsalGuard, RoleGuard],
            data: PageConfig.Administration.data,
          },
          {
            path: "add-opco-data-form",
            component: AddOpcoDataFormComponent,
            canActivate: [MsalGuard, RoleGuard],
            data: PageConfig.Administration.data,
          },
          {
            path: "add-bu-data-form",
            component: AddBUDataFormComponent,
            canActivate: [MsalGuard, RoleGuard],
            data: PageConfig.Administration.data,
          },
        ],
      },
      {
        path: "countries-states",
        title: "Countries & States",
        data: PageConfig.Administration.data,
        children: [
          {
            path: "",
            component: CountriesAndStatesComponent,
            canActivate: [MsalGuard, RoleGuard],
            data: PageConfig.Administration.data,
          },
          {
            path: "create-country",
            component: CreateCountryComponent,
            canActivate: [MsalGuard, RoleGuard],
            data: PageConfig.Administration.data,
          },
          {
            path: "edit-country",
            component: EditCountryComponent,
            canActivate: [MsalGuard, RoleGuard],
            data: PageConfig.Administration.data,
          },
          {
            path: "create-state",
            component: CreateStateComponent,
            canActivate: [MsalGuard, RoleGuard],
            data: PageConfig.Administration.data,
          },
          {
            path: "edit-state",
            component: EditStateComponent,
            canActivate: [MsalGuard, RoleGuard],
            data: PageConfig.Administration.data,
          },
        ],
      },
      {
        path: "commodity-codes",
        title: "Commodity Codes",
        component: CommodityCodesComponent,
        canActivate: [MsalGuard, RoleGuard],
        data: PageConfig.Administration.data,
      },
      {
        path: "user-roles",
        title: "User and Roles",
        children: [
          {
            path: "",
            component: UsersAndRolesComponent,
            canActivate: [MsalGuard, RoleGuard],
            data: PageConfig.Administration.data,
          },
          {
            path: "edit-user",
            component: EditUserComponent,
            canActivate: [MsalGuard, RoleGuard],
            data: PageConfig.Administration.data,
          },
          {
            path: "add-user",
            component: AddUserComponent,
            canActivate: [MsalGuard, RoleGuard],
            data: PageConfig.Administration.data,
          },
        ],
      },
      {
        path: "purchaser-management",
        title: "Purchaser Management",
        children: [
          {
            path: "",
            component: PurchaserManagementComponent,
            canActivate: [MsalGuard, RoleGuard],
            data: PageConfig.Administration.data,
          },
          {
            path: "create",
            component: CreatePurchaserManagementComponent,
            canActivate: [MsalGuard, RoleGuard],
            data: PageConfig.Administration.data,
          },
          {
            path: "edit/:id",
            component: EditPurchaserManagementComponent,
            canActivate: [MsalGuard, RoleGuard],
            data: PageConfig.Administration.data,
          },
        ],
      },
      {
        path: "vendors",
        title: "Vendors",
        children: [
          {
            path: "",
            component: VendorsComponent,
            canActivate: [MsalGuard, RoleGuard],
            data: PageConfig.Administration.data,
          },
          {
            path: "preview",
            component: PreviewVendorComponent,
            canActivate: [MsalGuard, RoleGuard],
            data: PageConfig.Administration.data,
          },
          {
            path: "create",
            component: CreateVendorComponent,
            canActivate: [MsalGuard, RoleGuard],
            data: PageConfig.Administration.data,
          },
          {
            path: "preview/:id",
            component: PreviewVendorComponent,
            canActivate: [MsalGuard, RoleGuard],
            data: PageConfig.Administration.data,
          },
          {
            path: "edit/:id",
            component: EditVendorComponent,
            canActivate: [MsalGuard, RoleGuard],
            data: PageConfig.Administration.data,
          },
        ],
      },
      {
        path: "msa",
        title: "MSA",
        component: MSAComponent,
        canActivate: [MsalGuard, RoleGuard],
        data: PageConfig.Administration.data,
      },
    ],
  },
  {
    path: "help",
    title: "Help",
    canActivate: [MsalGuard],
    children: [
      {
        path: "help",
        title: "Help",
        component: HelpComponent,
        canActivate: [MsalGuard, RoleGuard],
        data: PageConfig.Help.data,
      },
      {
        path: "contact-us",
        title: "Contact",
        component: ContactComponent,
        canActivate: [MsalGuard],
      },
      {
        path: "faq",
        title: "FAQ",
        component: FAQComponent,
        canActivate: [MsalGuard, RoleGuard],
        data: PageConfig.HelpFAQ.data,
      },
    ],
  },
  {
    path: "",
    title: "Main",
    children: [
      {
        path: "",
        component: HomeComponent,
        canActivate: [MsalGuard],
      },
    ],
  },
  { path: "error", component: CustomErrorComponent },
  { path: "no-access", component: NoAccessComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {
  constructor(private routesService: RoutesService) {
    this.routesService.filterRoutesForMenu(routes);
  }
}
