// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mat-table {
  width: 100%;
  td,
  th {
    padding: 0px 15px !important;
  }
}

th.mat-header-cell,
td.mat-cell {
  padding: 8px 16px;
}

.manufacturer-aud-obs-table {
  margin: 30px 0px;
}

.clickable {
  cursor: pointer;
  color: blue;
  text-decoration: underline;
}

.manufacturer-aud-obs-table {
  width: 100%;
}

.manufacturer-aud-obs-table > div {
  width: 100%;
  overflow-x: auto;
}
`, "",{"version":3,"sources":["webpack://./projects/aqua3/src/app/qa-management/manufacturer-audit-and-observation/mao-table-result/mao-table-result.component.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX;;IAEE,4BAA4B;EAC9B;AACF;;AAEA;;EAEE,iBAAiB;AACnB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,eAAe;EACf,WAAW;EACX,0BAA0B;AAC5B;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,WAAW;EACX,gBAAgB;AAClB","sourcesContent":[".mat-table {\n  width: 100%;\n  td,\n  th {\n    padding: 0px 15px !important;\n  }\n}\n\nth.mat-header-cell,\ntd.mat-cell {\n  padding: 8px 16px;\n}\n\n.manufacturer-aud-obs-table {\n  margin: 30px 0px;\n}\n\n.clickable {\n  cursor: pointer;\n  color: blue;\n  text-decoration: underline;\n}\n\n.manufacturer-aud-obs-table {\n  width: 100%;\n}\n\n.manufacturer-aud-obs-table > div {\n  width: 100%;\n  overflow-x: auto;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
