import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
const apiUrl = environment.apiUrl;
@Injectable({
  providedIn: 'root'
})
export class FormsService {

  constructor(private http: HttpClient) { }
  public getSupplierPerformanceRatingForm() {
    return this.http.get(`${apiUrl}/forms/supplierperformanceratingform`, { responseType: 'blob' });
  }
}
