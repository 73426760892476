import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ILookupData } from '../../../interfaces/administration/lookupData';
import { ICommodity } from '../../../interfaces/administration/commodity';
import { CountriesAndStatesService } from '../../../services/countries-states.service';
import { CommodityCodesService } from '../../../services/administration/commodity-codes.service';

@Component({
  selector: 'app-inspectorsbycommodity',
  templateUrl: './inspectorsbycommodity.component.html',
  styleUrl: './inspectorsbycommodity.component.css'
})
export class InspectorsbycommodityComponent implements OnInit {
  manufacturerForm: FormGroup;
  statesData: ILookupData[] = [];
  commoditiesData: ICommodity[] = [];
  selectedCommodityObjects: ICommodity[] = [];
 
  constructor(
    private fb: FormBuilder,
    private countriesAndStatesService: CountriesAndStatesService,
    private commodityCodesService: CommodityCodesService
  ) {
    this.manufacturerForm = this.fb.group({
      ManufacturerName: [''],
      ManufacturerCity: [''],
      state: ['stateDefault'],
      commodity: ['']
    });
  }
 
  ngOnInit(): void {
    this.loadStates();
    this.loadCommodities();
  }
 
  loadStates(): void {
    this.countriesAndStatesService.getAllStates().subscribe(
      (data: ILookupData[]) => {
        this.statesData = data.sort((a, b) => a.name.localeCompare(b.name));
      },
      (error) => {
        console.error('Error fetching states data', error);
      }
    );
  }
  loadCommodities(): void {
    this.commodityCodesService.getAllCommodities().subscribe(
      (data: ICommodity[]) => {
        this.commoditiesData = data;
      },
      (error) => {
        console.error('Error fetching commodities data', error);
      }
    );
  }
 
  handleSelectedCommodityNames(selectedCommodityNames: ICommodity[]) {
    this.selectedCommodityObjects = selectedCommodityNames;
    if (selectedCommodityNames.length !== 0) {
      this.manufacturerForm.patchValue({
        commodity: selectedCommodityNames.map(commodity => commodity.commodityId)
      });
    } else {
      this.manufacturerForm.patchValue({
        commodity: null
      });
    }
  }
 
  onSubmit(): void {
    console.log(this.manufacturerForm.value);
    // Add your form submission logic here
  }
}
