
import { Component, OnInit } from '@angular/core';
import { ReportDataService } from '../../services/report-data.service';

@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.css'],
})
export class ReportsComponent implements OnInit {
  isLoading: boolean = false;

  constructor(private reportService: ReportDataService) {}

  ngOnInit(): void {}

  downloadReport(): void {
    this.isLoading=true;
    this.reportService.getCommodityDetails().subscribe((data) => {
      const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = `AQUA3_CommodityCodeReport_${new Date().toISOString().slice(0, 19).replace(/:/g, '-')}.xlsx`;
      a.click();
      window.URL.revokeObjectURL(url);
      this.isLoading=false;
    }, error => {
      console.error('Error downloading the report', error);
    });
  }

  downloadReportvendor(): void {
    this.isLoading=true;
    this.reportService.getVendorInformationDetails().subscribe((data) => {
      const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = `AQUA3_VendorInformationReport_${new Date().toISOString().slice(0, 19).replace(/:/g, '-')}.xlsx`;
      a.click();
      window.URL.revokeObjectURL(url);
      this.isLoading=false;
    }, error => {
      console.error('Error downloading the report', error);
    });
  }
  downloadMGC():void{
    this.reportService.getMgcandCC().subscribe((data) => {
      const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = `20110204 AQUA3-CCStructure for MGC Analysis.xlsx`;
      a.click();
      window.URL.revokeObjectURL(url);
    }, error => {
      console.error('Error downloading the report', error);
    });
  }
 
}