import { Component, OnInit } from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { AgenciesAndInspectorsDataService } from "../../../services/qa-management/agencies-inspectors.service";
import { InspectionReportsDataService } from "../../../services/inspection-reports.service";
import { IInspectionReportData } from "../../../interfaces/common/inspectionReportData";
import { LoggedUserService } from "../../../services/common/logged-user.service";
import { ILoggedUserData } from "../../../interfaces/common/loggedUserData";
import { CurrenciesDataService } from "../../../services/qa-management/currencies.service";
import { BaseComponent } from "../../../shared/base/base.component";
import { UserPermissionsService } from "../../../services/common/user-permissions.service";

@Component({
  selector: "edit-inspection-report-form.component",
  templateUrl: "./edit-inspection-report-form.component.html",
})
export class EditInspectionReportFormComponent
  extends BaseComponent
  implements OnInit
{
  irData: IInspectionReportData;
  inspectionRate: number;
  travelRate: number;
  reportRate: number;
  mileageRate: number;
  overtimeRate: number;
  currencyCode: string;
  currencySymbol: string;

  editForm: FormGroup;
  validationMessages = [];
  inspectorFullName: string = "";
  agencyName: string = "";
  calculatedCost: number = 0;

  constructor(
    private agenciesAndInspectorsDataService: AgenciesAndInspectorsDataService,
    private currenciesDataService: CurrenciesDataService,
    private fb: FormBuilder,
    private inspectionReportsService: InspectionReportsDataService,
    private loggedUserService: LoggedUserService,
    private router: Router,
    private route: ActivatedRoute,
    userPermissionsService: UserPermissionsService
  ) {
    super(userPermissionsService);
  }

  ngOnInit(): void {
    super.ngOnInit();
    const irId = this.route.snapshot.paramMap.get("irId");

    this.route.queryParams.subscribe((params) => {
      this.inspectionRate = +params["inspectionRate"] || 0;
      this.travelRate = +params["travelRate"] || 0;
      this.reportRate = +params["reportRate"] || 0;
      this.mileageRate = +params["mileageRate"] || 0;
      this.overtimeRate = +params["overtimeRate"] || 0;
      this.currencyCode = params["currency"] || "";
    });

    this.editForm = this.fb.group({
      dateOfReportOrInvoice: [null, Validators.required],
      reportOrInvoiceNumber: ["", Validators.required],
      agencyJobNumber: ["", Validators.required],
      inspectionHours: [null],
      travelHours: [null],
      coordinationHours: [null],
      overtimeHours: [null],
      milesKilometers: [null],
      additionalCost: [null],
      fileAttachment: [""],
      notes: [""],
    });

    this.currenciesDataService
      .getCurrencySymbolByCode(this.currencyCode)
      .subscribe((symbol) => {
        this.currencySymbol = symbol;
      });

    if (irId) {
      this.inspectionReportsService
        .getInspectioReportById(irId)
        .subscribe((data) => {
          if (data) {
            this.irData = data;

            this.editForm.patchValue({
              dateOfReportOrInvoice: new Date(this.irData.reportInvoiceDate),
              reportOrInvoiceNumber: this.irData.reportInvoiceNumber,
              agencyJobNumber: this.irData.agencyJobNo,
              inspectionHours: this.irData.inspectionHours,
              travelHours: this.irData.travelHours,
              coordinationHours: this.irData.coordinationHours,
              overtimeHours: this.irData.overtimeHours,
              milesKilometers: this.irData.milesKilometers,
              additionalCost: this.irData.additionalCost,
              notes: this.irData.notes,
            });

            if (this.irData.inspectorId != null)
              this.agenciesAndInspectorsDataService
                .getInspectorById(this.irData.inspectorId.toString())
                .subscribe((data) => {
                  if (data) {
                    this.inspectorFullName = `${data?.lastName}, ${data?.firstName}`;
                  }
                });

            if (this.irData.agencyId != null)
              this.agenciesAndInspectorsDataService
                .getAgencyById(this.irData.agencyId.toString())
                .subscribe((data) => {
                  if (data) {
                    this.agencyName = data?.name;
                  }
                });
          }
        });
    }
  }

  setCalculatedCost(): void {
    const inspectionHours = this.editForm.get("inspectionHours").value || 0;
    const travelHours = this.editForm.get("travelHours").value || 0;
    const coordinationHours = this.editForm.get("coordinationHours").value || 0;
    const overtimeHours = this.editForm.get("overtimeHours").value || 0;
    const milesKilometers = this.editForm.get("milesKilometers").value || 0;
    const additionalCost = this.editForm.get("additionalCost").value || 0;

    this.calculatedCost =
      inspectionHours * this.inspectionRate +
      travelHours * this.travelRate +
      coordinationHours * this.reportRate +
      overtimeHours * this.overtimeRate +
      milesKilometers * this.mileageRate +
      additionalCost;
  }

  submitForm(): void {
    let messagesValidationsList: string[] = [];

    let formDateOfReportOrInvoice: string =
      this.editForm.get("dateOfReportOrInvoice").value == ""
        ? null
        : this.editForm.get("dateOfReportOrInvoice").value;
    let formReportOrInvoiceNumber: string = this.editForm.get(
      "reportOrInvoiceNumber"
    ).value;
    let formAgencyJobNumber: string =
      this.editForm.get("agencyJobNumber").value;
    let formInspectionHours: number =
      this.editForm.get("inspectionHours").value;
    let formTravelHours: number = this.editForm.get("travelHours").value;
    let formCoordinationHours: number =
      this.editForm.get("coordinationHours").value;
    let formOvertimeHours: number = this.editForm.get("overtimeHours").value;
    let formMilesKilometers: number =
      this.editForm.get("milesKilometers").value;
    let formAdditionalCost: number = this.editForm.get("additionalCost").value;
    let formNotes: string = this.editForm.get("notes").value;

    let fileAttachment = this.editForm.get("fileAttachment").value;

    // Validate form controls
    if (formDateOfReportOrInvoice == null) {
      messagesValidationsList.push("Date of Report or Invoice is Required.");
    }
    if (formReportOrInvoiceNumber == "") {
      messagesValidationsList.push("Report or Invoice Number is Required.");
    }
    if (formAgencyJobNumber == "") {
      messagesValidationsList.push("Agency JobNo is Required.");
    }

    this.validationMessages = messagesValidationsList;

    if (this.editForm.valid && this.validationMessages.length === 0) {
      this.irData.reportInvoiceDate = formDateOfReportOrInvoice;
      this.irData.reportInvoiceNumber = formReportOrInvoiceNumber;
      this.irData.agencyJobNo = formAgencyJobNumber;
      this.irData.inspectionHours = formInspectionHours;
      this.irData.travelHours = formTravelHours;
      this.irData.coordinationHours = formCoordinationHours;
      this.irData.overtimeHours = formOvertimeHours;
      this.irData.additionalCost = formAdditionalCost;
      this.irData.milesKilometers = formMilesKilometers;
      this.irData.calculatedCost = this.calculatedCost;
      this.irData.notes = formNotes;
      this.irData.updatedOn = new Date();

      this.loggedUserService
        .getLoggedUserData()
        .subscribe((loggedUser: ILoggedUserData | null) => {
          if (loggedUser) {
            this.irData.updatedBy = loggedUser.cai;
            this.inspectionReportsService
              .updateInspectionReport(this.irData)
              .subscribe({
                next: () => {
                  this.router.navigate([
                    `/qa-management/work-requests/edit-work-request/${this.irData.workRequestId}`,
                  ]);
                },
                error: (error) => {
                  console.error(
                    "POST /workRequests/edit-inspection-report" + error
                  );
                },
              });
          }
        });
    }
  }

  cancelChanges(): void {
    this.router.navigate([
      `/qa-management/work-requests/edit-work-request/${this.irData.workRequestId}`,
    ]);
  }
}
