// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `  .spinner-container {
    display: flex;
    justify-content: center;
    align-items: center;
   padding-left: 400px; 
}
`, "",{"version":3,"sources":["webpack://./projects/aqua3/src/app/reports-forms/reports/reports.component.css"],"names":[],"mappings":"EAAE;IACE,aAAa;IACb,uBAAuB;IACvB,mBAAmB;GACpB,mBAAmB;AACtB","sourcesContent":["  .spinner-container {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n   padding-left: 400px; \n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
