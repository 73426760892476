import { Component, OnInit } from "@angular/core";
import { FormGroup, FormBuilder } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { LoggedUserService } from "../../../services/common/logged-user.service";
import { ILoggedUserData } from "../../../interfaces/common/loggedUserData";
import { UserRequestsDataService } from "../../../services/administration/user-requests.service";
import { IAgencyData } from "../../../interfaces/qa-management/agencyData";
import { IAq2QaqcbuyerEngineerQae } from "../../../interfaces/qa-management/aq2QaqcbuyerEngineerQae";
import { WorkRequestsDataService } from "../../../services/qa-management/work-requests/work-requests.service";
import { IWorkRequestActivityData } from "../../../interfaces/qa-management/work-requests/workRequestActivityData";
import { BaseComponent } from "../../../shared/base/base.component";
import { UserPermissionsService } from "../../../services/common/user-permissions.service";

@Component({
  selector: "add-activity-component",
  templateUrl: "./add-activity-form.component.html",
  styleUrls: ["./add-activity-form.component.css"],
})
export class AddActivityFormComponent extends BaseComponent implements OnInit {
  agencyName: string = "";
  workRequestId: number;
  userFullName: string = "";
  loggedUserData: ILoggedUserData;
  public agencyData: IAgencyData;
  public qaeData: IAq2QaqcbuyerEngineerQae;

  addForm: FormGroup;
  validationMessages = [];

  constructor(
    private fb: FormBuilder,
    private loggedUserService: LoggedUserService,
    private userRequestsService: UserRequestsDataService,
    private workRequestsService: WorkRequestsDataService,
    private router: Router,
    private route: ActivatedRoute,
    userPermissionsService: UserPermissionsService
  ) {
    super(userPermissionsService);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.route.queryParams.subscribe((params) => {
      this.workRequestId = +params["workRequestId"];
      this.agencyName = params["agencyName"];
    });

    this.loggedUserService
      .getLoggedUserData()
      .subscribe((loggedUser: ILoggedUserData | null) => {
        if (loggedUser) {
          this.loggedUserData = loggedUser;
          this.userRequestsService
            .getUserFullNameById(loggedUser.userId)
            .subscribe((fullName) => {
              this.userFullName = fullName;
            });
        }
      });

    this.addForm = this.fb.group({
      acceptanceDate: [null],
      receivedDate: [null],
      notes: [""],
    });
  }

  submitForm(): void {
    let messagesValidationsList: string[] = [];
    let formNotes: string = this.addForm.get("notes").value;
    let formAcceptanceDate: Date =
      this.addForm.get("acceptanceDate").value == ""
        ? null
        : this.addForm.get("acceptanceDate").value;
    let formReceivedDate: Date =
      this.addForm.get("receivedDate").value == ""
        ? null
        : this.addForm.get("receivedDate").value;

    if (formAcceptanceDate == null || formAcceptanceDate.toString() == "") {
      messagesValidationsList.push("Acceptance Date is Required.");
    }

    if (formReceivedDate == null || formReceivedDate.toString() == "") {
      messagesValidationsList.push("Received Date is Required.");
    }

    this.validationMessages = messagesValidationsList;
    if (this.addForm.valid && this.validationMessages.length === 0) {
      let formNewAcceptanceDate: Date = new Date(formAcceptanceDate);
      let formNewReceivedDate: Date = new Date(formReceivedDate);

      let activityDate: IWorkRequestActivityData = {
        typeId: this.workRequestId,
        actionDate: new Date(),
        actionBy: this.loggedUserData?.cai,
        acceptanceDate: formNewAcceptanceDate,
        receivedDate: formNewReceivedDate,
        acceptedBy: this.agencyName,
        status: "Email Accepted By Agency",
        notes: formNotes,
      };

      this.workRequestsService.addWorkRequestActivity(activityDate).subscribe({
        next: () => {
          this.router.navigate([
            `/qa-management/work-requests/edit-work-request/${this.workRequestId}`,
          ]);
        },
        error: (error) => {
          console.error("POST /workRequests/add-activity" + error);
        },
      });
    }
  }

  cancelChanges(): void {
    this.router.navigate([
      `/qa-management/work-requests/edit-work-request/${this.workRequestId}`,
    ]);
  }
}
