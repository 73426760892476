import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { CountriesAndStatesService } from '../../../services/countries-states.service';
import { CommodityCodesService } from "../../../services/administration/commodity-codes.service";
import { ManufacturersNameService } from "../../../services/qa-reports/manufacturers-name.service";
import { ILookupData } from "../../../interfaces/administration/lookupData";
import { ICommodity } from "../../../interfaces/administration/commodity";
import { HttpParams } from "@angular/common/http";
import { Router } from "@angular/router";
import { DatePipe } from "@angular/common";
 
@Component({
  selector: 'app-manufacturebyname',
  templateUrl: './manufacturebyname.component.html',
  styleUrl: './manufacturebyname.component.css',
  providers: [DatePipe]
})
export class ManufacturebynameComponent implements OnInit {
  manufacturerForm: FormGroup;
  statesData: ILookupData[] = [];
  startDate: string;
  endDate: string;
  commoditiesData: ICommodity[] = [];
  selectedCommodityObjects: ICommodity[] = [];
  validationMessages: string[] = [];
  isLoading = false;
 
  constructor(
    private fb: FormBuilder,
    private countriesAndStatesService: CountriesAndStatesService,
    private commodityCodesService: CommodityCodesService,
    private manufacturersNameService: ManufacturersNameService,
    private router: Router,
    private datePipe: DatePipe
  ) {
    this.manufacturerForm = this.fb.group({
      ManufacturerName: [''],
      ManufacturerCity: [''],
      state: ['stateDefault'],
      commodity: ['']
    });
  }
 
  ngOnInit(): void {
    const currentYear = new Date().getFullYear();
    this.startDate = this.datePipe.transform(new Date(currentYear, 0, 1), 'yyyy-MM-dd') || '';
    this.endDate = this.datePipe.transform(new Date(), 'yyyy-MM-dd') || '';
    this.loadStates();
    this.loadCommodities();
   // this.generate(); // Auto-submit on initialization
  }
 
  loadStates(): void {
    this.countriesAndStatesService.getAllStates().subscribe(
      (data: ILookupData[]) => {
        this.statesData = data.sort((a, b) => a.name.localeCompare(b.name));
      },
      (error) => {
        console.error('Error fetching states data', error);
      }
    );
  }
 
  cancel() {
    this.router.navigate(['/reports-forms'], { queryParams: { tab: 'qaReports' } });
  }
 
  loadCommodities(): void {
    this.commodityCodesService.getAllCommodities().subscribe(
      (data: ICommodity[]) => {
        this.commoditiesData = data;
      },
      (error) => {
        console.error('Error fetching commodities data', error);
      }
    );
  }
 
  handleSelectedCommodityNames(selectedCommodityNames: ICommodity[]) {
    this.selectedCommodityObjects = selectedCommodityNames;
    if (selectedCommodityNames.length !== 0) {
      this.manufacturerForm.patchValue({
        commodity: selectedCommodityNames.map(commodity => commodity.commodityId)
      });
    } else {
      this.manufacturerForm.patchValue({
        commodity: null
      });
    }
  }
 
  generate(): void {
    this.isLoading = true;
    const formValues = this.manufacturerForm.value;
 
    const params = new HttpParams()
      .set('commodityId', formValues.commodity)
      .set('mfrName', formValues.ManufacturerName )
      .set('mfrCity', formValues.ManufacturerCity )
      .set('mfrState', formValues.state );
 
    this.manufacturersNameService.getManufacturersbyNameDetails(params)
      .subscribe((data) => {
        const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = `AQUA3_ManufacturersReport_${new Date().toISOString().slice(0, 19).replace(/:/g, '-')}.xlsx`;
        a.click();
        window.URL.revokeObjectURL(url);
        this.isLoading = false;
      }, error => {
        console.error('Error downloading the report', error);
        this.isLoading = false;
      });
  }
}
 
 