export const PageConfig = {
    Home: { title: "Home", data: { pageName: 'Home', subPageName: 'N/A' } },
    QAManufacturer: { title: "Manufacturer Audit and Observation", data: { pageName: 'QA Management', subPageName: 'Manufacturers' } },
    Administration: { title: "Administration", data: { pageName: 'Administration', subPageName: 'N/A' } },
    QAWorkRequests: { title: "QA Management - Work Requests", data: { pageName: 'QA Management', subPageName: 'Work Requests' } },
    QACapitalProjects: { title: "QA Management - Capital Projects", data: { pageName: 'QA Management', subPageName: 'Capital Projects' } },
    QAPlantReviews: { title: "QA Management - Plant Reviews", data: { pageName: 'QA Management', subPageName: 'Plant Reviews' } },
    QAAgenciesAndInspectors: { title: "QA Management - Agencies and Inspectors", data: { pageName: 'QA Management', subPageName: 'Agencies and Inspectors' } },
    QABuyersQAEsInpsectors: { title: "QA Management - Buyers, QAEs, Inpsectors", data: { pageName: 'QA Management', subPageName: 'Buyers, QAEs, Inpsectors' } },
    AVL: { title: "AVL", data: { pageName: 'AVL', subPageName: 'N/A' } },
    VIPerformanceTracking: { title: "Vendor Information - Performance Tracking", data: { pageName: 'Vendor Information', subPageName: 'Performance Tracking' } },
    VIVendorSearch: { title: "Vendor Information - Vendor Search", data: { pageName: 'Vendor Information', subPageName: 'Vendor Search' } },
    VIAddVendor: { title: "Vendor Information - Add Vendor", data: { pageName: 'Vendor Information', subPageName: 'Add Vendor' } },
    Requests: { title: "Requests", data: { pageName: 'Requests', subPageName: 'Add Commodity To Vendor' } },
    ReportsFormsReports: { title: "Reports & Forms", data: { pageName: 'Reports & Forms', subPageName: 'Reports' } },
    Help: { title: "Help", data: { pageName: 'Help', subPageName: 'Help' } },
    HelpFAQ: { title: "Help - FAQ", data: { pageName: 'Help', subPageName: 'FAQ' } },
    QANewWorkRequest: { title: "QA Management - New Work Request", data: { pageName: 'QA Management', subPageName: 'New Work Request' } },
    QAViewWorkRequest: { title: "QA Management - View Work Request", data: { pageName: 'QA Management', subPageName: 'View Work Request' } },
};