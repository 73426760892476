import { ChangeDetectorRef, Component, OnInit, ViewChild } from "@angular/core";
import { IVendorData } from "../../interfaces/administration/vendorData";
import { MatSort } from "@angular/material/sort";
import { MatPaginator } from "@angular/material/paginator";
import { MatTableDataSource } from "@angular/material/table";
import { VendorDataService } from "../../services/vendor-data.service";
import { Router } from "@angular/router";
import { BaseComponent } from "../../shared/base/base.component";
import { UserPermissionsService } from "../../services/common/user-permissions.service";

@Component({
  selector: "app-vendors",
  templateUrl: "./vendors.component.html",
})
export class VendorsComponent extends BaseComponent implements OnInit {
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  public vendorData: MatTableDataSource<IVendorData>;
  public displayedColumns: string[] = [
    "vendorName",
    "streetAddress",
    "countryName",
    "stateName",
    "city",
    "postalCode",
    "actions",
  ];
  public isLoading = true;

  constructor(
    private service: VendorDataService,
    private router: Router,
    private changeDetectorRefs: ChangeDetectorRef,
    userPermissionService: UserPermissionsService
  ) {
    super(userPermissionService);
  }

  ngOnInit() {
    super.ngOnInit();
    this.loadVendors();
    this.paginator.pageSize = 10;
    this.sort.active = "vendorName";
    this.sort.direction = "asc";
    this.sort.sortChange.emit({
      active: this.sort.active,
      direction: this.sort.direction,
    });
  }

  ngAfterViewInit() {}

  loadVendors() {
    this.isLoading = true;
    this.service.getVendors().subscribe((data) => {
      this.vendorData = new MatTableDataSource(data);
      this.vendorData.sort = this.sort;
      this.vendorData.paginator = this.paginator;
      this.paginator.pageSize = 10;
      this.isLoading = false;
      this.changeDetectorRefs.detectChanges();
    });
  }

  editVendor(vendor: IVendorData) {
    this.router.navigate(["/administration/vendors/preview/", vendor.vendorId]);
  }
}
