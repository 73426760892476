import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, take } from "rxjs";
import { IVendorData } from "../interfaces/administration/vendorData";
import { HttpClient, HttpParams } from "@angular/common/http";
import { environment } from "../../environments/environment";
import { FormGroup } from "@angular/forms";
import { ICommoditySearchData } from "../interfaces/vendor-information/ICommoditySearchData";
import { IVendorCommentData } from "../interfaces/vendor-information/vendorCommentData";
import { IViewVendorCommodityData } from "../interfaces/vendor-information/viewVendorCommodityData";
import { PaginatedResponse } from "./qa-management/work-requests/work-requests.service";
import { viewVendorHistoryData } from "../interfaces/vendor-information/viewVendorHistory";

const apiUrl = environment.apiUrl;

@Injectable({
  providedIn: "root",
})
export class VendorDataService {
  private vendorDataSource = new BehaviorSubject<IVendorData | null>(null);
  currentVendorData = this.vendorDataSource.asObservable();

  private vendorsListSubject = new BehaviorSubject<IVendorData[] | null>(null);
  currentVendorsList$: Observable<IVendorData[]> =
    this.vendorsListSubject.asObservable();

  constructor(private http: HttpClient) {}

  changeVendorData(vendorData: IVendorData) {
    this.vendorDataSource.next(vendorData);
  }

  setVendors(vendors: IVendorData[]) {
    this.vendorsListSubject.next(vendors);
  }

  public getVendorData(): Observable<IVendorData> {
    return this.vendorDataSource.asObservable();
  }

  public getVendors(): Observable<IVendorData[]> {
    return this.http.get<IVendorData[]>(`${apiUrl}/vendors?pagesize=10000`);
  }

  public getVendorById(id: string): Observable<IVendorData> {
    return this.http.get<IVendorData>(`${apiUrl}/vendors/${id}`);
  }

  public getVendorCommentData(id: string): Observable<IVendorCommentData> {
    return this.http.get<IVendorCommentData>(
      `${apiUrl}/vendors/${id}/comments`
    );
  }

  public getVendorCommodityData(
    id: string
  ): Observable<IViewVendorCommodityData[]> {
    return this.http.get<IViewVendorCommodityData[]>(
      `${apiUrl}/vendors/${id}/commodities`
    );
  }

  public createUpdateVendorComment(
    vendorCommentForm: FormGroup,
    vendorId: string,
    updatedBy: string
  ): Observable<IVendorCommentData> {
    const commentData: IVendorCommentData = {
      vendorId: Number(vendorId),
      plantReviewRevisions: vendorCommentForm.get("plantReviewRevisions").value,
      recommendations: vendorCommentForm.get("recommendations").value,
      observedQuality: vendorCommentForm.get("observedQuality").value,
      qualityControl: vendorCommentForm.get("qualityControl").value,
      updatedBy: updatedBy,
    };

    return this.http.post<IVendorCommentData>(
      `${apiUrl}/vendors/comments`,
      commentData
    );
  }

  public saveVendor(vendorData: IVendorData): Observable<any> {
    return this.http.post<IVendorData>(`${apiUrl}/vendors`, vendorData);
  }

  public updateVendor(
    id: string,
    vendorData: IVendorData
  ): Observable<IVendorData> {
    return this.http.put<IVendorData>(`${apiUrl}/vendors/${id}`, vendorData);
  }

  public activateDeactivateVendor(
    vendroId: string,
    comments: string
  ): Observable<any> {
    return this.http.put(`${apiUrl}/vendors/${vendroId}/activate-deactivate`, {
      comments: comments,
    });
  }

  public searchVendors(vendorName: string): Observable<IVendorData[]> {
    return this.http
      .get<IVendorData[]>(`${apiUrl}/vendors/match/${vendorName}`)
      .pipe(take(1));
  }

  public getAdminVendorCommodityData(
    vendorId: string
  ): Observable<IViewVendorCommodityData[]> {
    return this.http.get<IViewVendorCommodityData[]>(
      `${apiUrl}/vendorCommodity/${vendorId}/commodities`
    );
  }

  public setCommodityActiveFlag(
    vendorCommodityId: number,
    isActive: boolean,
    comments: string
  ): Observable<any> {
    const body = { comments: comments, isActive: isActive };
    return this.http.put<any>(
      `${apiUrl}/vendorCommodity/${vendorCommodityId}/SetCommodityActiveFlag`,
      body
    );
  }

  public searchVendorsAsync(
    vendorName?: string,
    streetAddress?: string,
    url?: string
  ): Observable<IVendorData[]> {
    let params = new HttpParams();
    if (vendorName !== undefined)
      params = params.append("vendorName", vendorName);
    if (streetAddress !== undefined)
      params = params.append("streetAddress", streetAddress);
    if (url !== undefined) params = params.append("url", url);

    return this.http.get<IVendorData[]>(`${apiUrl}/vendors/search`, { params });
  }

  public searchVendorCommodity(
    vendorName?: string,
    city?: string,
    country?: string,
    stateProvince?: string,
    imaNumber?: string,
    commodityIds?: string,
    commodityCode?: string,
    commodityDescription?: string,
    isSmallDiversity?: boolean,
    pageIndex: number = 1,
    pageSize: number = 10
  ): Observable<PaginatedResponse<ICommoditySearchData>> {
    let params = new HttpParams();
    if (vendorName !== undefined)
      params = params.append("VendorName", vendorName);
    if (city !== undefined) params = params.append("City", city);
    if (country !== undefined) params = params.append("Country", country);
    if (stateProvince !== undefined)
      params = params.append("StateProvince", stateProvince);
    if (imaNumber !== undefined) params = params.append("ImaNumber", imaNumber);
    if (commodityIds !== undefined)
      params = params.append("CommodityIds", commodityIds);
    if (commodityCode !== undefined)
      params = params.append("CommodityCode", commodityCode);
    if (commodityDescription !== undefined)
      params = params.append("CommodityDescription", commodityDescription);
    if (isSmallDiversity !== undefined)
      params = params.append(
        "IsSmallDiversity",
        isSmallDiversity === true ? "1" : "0"
      );
    params = params.append("PageIndex", pageIndex.toString());
    params = params.append("PageSize", pageSize.toString());

    return this.http.get<PaginatedResponse<ICommoditySearchData>>(
      `${apiUrl}/vendors/commodity-search`,
      {
        params,
      }
    );
  }

  public loadVendorHistory(
    vendorId: number
  ): Observable<viewVendorHistoryData[]> {
    return this.http.get<viewVendorHistoryData[]>(
      `${apiUrl}/vendors/${vendorId.toString()}/history`
    );
  }

  public validateForm(form: FormGroup): string[] {
    let messagesValidationsList: string[] = [];

    if (form.get("vendorName").value === "") {
      messagesValidationsList.push("Vendor Name is required.");
    }

    if (form.get("city").value === "") {
      messagesValidationsList.push("City is required.");
    }

    if (
      form.get("countryId").value === "" ||
      form.get("countryId").value === "0"
    ) {
      messagesValidationsList.push("Country is required.");
    }

    if (form.get("streetAddress").value === "") {
      messagesValidationsList.push("Street Address is required.");
    }

    if (
      form.get("acknowledgeScreening") != null &&
      form.get("acknowledgeScreening").value !== true
    ) {
      messagesValidationsList.push("Vendor Screened is required.");
    }

    return messagesValidationsList;
  }
}
