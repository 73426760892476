import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewChild,
} from "@angular/core";
import { MatTableDataSource } from "@angular/material/table";
import { MatPaginator } from "@angular/material/paginator";
import { Sort } from "@angular/material/sort";
import { WorkRequestsDataService } from "projects/aqua3/src/app/services/qa-management/work-requests/work-requests.service";
import { IWorkRequestRevisionData } from "projects/aqua3/src/app/interfaces/qa-management/work-requests/workRequestRevisionData";
import { RevisionsTableSortService } from "projects/aqua3/src/app/services/qa-management/work-requests/revisions-table.sort.service";

@Component({
  selector: "revision-history-component",
  templateUrl: "./revision-history-component.html",
})
export class RevisionHistoryComponent implements OnInit, OnChanges {
  @Input() workRequestId: number;
  title = "Revision History";
  dataSource: MatTableDataSource<IWorkRequestRevisionData>;
  originalDataSource: MatTableDataSource<IWorkRequestRevisionData>;
  tableVisible: boolean = false;

  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;

  displayedColumns: string[];

  dataSourceLoading: boolean = true;

  constructor(
    private tableSortService: RevisionsTableSortService,
    private workRequestsService: WorkRequestsDataService,
  ) {}

  ngOnInit(): void {
    this.loadWorkRequestRevisionDetails();
  }

  ngOnChanges(changes: SimpleChanges): void {
    // React to changes in the input property
    if (changes.workRequestId && !changes.workRequestId.isFirstChange()) {
      this.loadWorkRequestRevisionDetails();
    }
  }

  loadWorkRequestRevisionDetails(): void {
    if (this.workRequestId !== null && this.workRequestId !== undefined) {
      this.workRequestsService
        .getWorkRequestRevisionDetails(this.workRequestId)
        .subscribe({
          next: (data) => {
            this.dataSourceLoading = true;

            if (data.length > 0) {
              const firstRow = data[0];
              const dynamicColumns = Object.keys(firstRow.revisions);
              this.displayedColumns = ["typeOfChange", ...dynamicColumns];
              this.tableVisible = true;
            }

            this.originalDataSource =
              new MatTableDataSource<IWorkRequestRevisionData>(data);
            this.dataSource = new MatTableDataSource<IWorkRequestRevisionData>(
              this.originalDataSource.data
            );

            this.dataSourceLoading = false;
            // Delay the initialization of the paginator until the data is assigned
            setTimeout(() => {
              this.dataSource.paginator = this.paginator;
              this.paginator.pageSize = 10;
            });
          },
          error: (error) => {
            console.error(error);
          },
        });
    }
  }

  announceSortChange(event: Sort) {
    this.dataSource.data = this.tableSortService.sortDataSource(
      this.originalDataSource.data,
      event
    );
  }
}
