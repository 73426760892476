import { Component, OnInit } from "@angular/core";
import {
  BehaviorSubject,
  debounceTime,
  distinctUntilChanged,
  filter,
  Observable,
  switchMap,
} from "rxjs";
import { IVendorData } from "../../interfaces/administration/vendorData";
import { VendorDataService } from "../../services/vendor-data.service";
import { ActivatedRoute, Router } from "@angular/router";
import { BaseComponent } from "../../shared/base/base.component";
import { UserPermissionsService } from "../../services/common/user-permissions.service";

@Component({
  selector: "add-vendor-component",
  templateUrl: "./add-vendor.component.html",
})
export class AddVendorComponent extends BaseComponent implements OnInit {
  vendorSearchResults$: Observable<IVendorData[]>;
  vendorSaved: boolean = false;
  private searchTerms = new BehaviorSubject<string>("");
  displayedColumns: string[] = [
    "vendorName",
    "streetAddress",
    "county",
    "city",
    "state",
    "postalCode",
    "country",
  ];

  constructor(
    private vendorService: VendorDataService,
    private router: Router,
    private route: ActivatedRoute,
    userPermissionService: UserPermissionsService
  ) {
    super(userPermissionService);
    this.vendorSearchResults$ = this.searchTerms.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      filter((term) => term.trim().length > 0), // Only proceed if the term is not empty
      switchMap((term: string) => this.vendorService.searchVendors(term))
    );
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.route.queryParams.subscribe((params) => {
      this.vendorSaved = params["saved"] === "true";
    });
  }

  search(term: string): void {
    this.searchTerms.next(term);
  }

  createNewVendor(): void {
    const vendorName = this.searchTerms.value;
    if (vendorName.trim().length > 0) {
      this.router.navigate(["/vendor-information/create-vendor"], {
        queryParams: { name: vendorName },
      });
    } else {
      this.router.navigate(["/vendor-information/create-vendor"]);
    }
  }
}
