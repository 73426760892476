import { Component } from "@angular/core";

@Component({
  selector: "app-email-icontract",
  templateUrl: "./email-icontract.component.html",
})
export class EmailIcontractComponent {
  notes: string = "";

  sendEmail() {
    // Logic to send the email
    console.log("Email sent with notes:", this.notes);
  }

  cancel() {
    // Logic to handle the cancel action
    this.notes = "";
    console.log("Action cancelled");
  }
}
