import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
  ViewChild,
} from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { PerformanceTrackingService } from "../../../services/vendor-information/performance-tracking.service";
import { BaseComponent } from "../../../shared/base/base.component";
import { UserPermissionsService } from "../../../services/common/user-permissions.service";
import { VendorDataService } from "../../../services/vendor-data.service";
import { MatTableDataSource } from "@angular/material/table";
import { IViewVendorCommodityData } from "../../../interfaces/vendor-information/viewVendorCommodityData";
import { MatPaginator } from "@angular/material/paginator";
import { CommodityCodesService } from "../../../services/administration/commodity-codes.service";
import { ICommodity } from "../../../interfaces/administration/commodity";
import { IVendorData } from "../../../interfaces/administration/vendorData";
import { MatDialog } from "@angular/material/dialog";
import { CommentBoxDialogComponent } from "../comment-box-dialog.component";
import { VendorCommodityDataService } from "../../../services/vendor-information/vendor-commodity.service";
import { VendorCommodityData } from "../../../interfaces/vendor-information/vendorCommodityData";
import { VendorCommodityRequest } from "../../../interfaces/vendor-information/vendorCommodityRequest";
import { throws } from "assert";
import { IVendorCommodityData } from "../../../interfaces/administration/vendorCommodityData";
import { ICommodityResult } from "../../../interfaces/qa-management/commodityResult";

@Component({
  selector: "commodities-tab-component",
  templateUrl: "./commodities-tab.component.html",
  styles: [
    `
      .button-container {
        display: flex !important;
        justify-content: space-between !important;
      }

      .button-group {
        display: flex;
        gap: 8px; /* Adjust the gap as needed */
      }
    `,
  ],
})
export class CommoditiesTabComponent extends BaseComponent implements OnInit {
  @Input() vendorId: number;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  showForm = false;
  commodityForm: FormGroup;

  editedCommodityCode: ICommodityResult;
  showCommentBox: boolean = false;
  commentBoxTitle: string = "";
  comment: string = "";
  currentAction: string = "";
  currentCommodityId: number | null = null;
  validationMessages: string[] = [];
  editingCommodityId: number | null = null;

  constructor(
    private fb: FormBuilder,
    private performanceService: PerformanceTrackingService,
    userPermissionsService: UserPermissionsService,
    private vendorDataService: VendorDataService,
    private vendorCommodityService: VendorCommodityDataService,
    private commodityCodesService: CommodityCodesService,
    private dialog: MatDialog
  ) {
    super(userPermissionsService);
  }

  vendor: IVendorData;

  public dataSourceLoading = true;
  displayedColumns: string[] = [
    "actions",
    "commodityCode",
    "commodityCodeDescription",
    "supplierVendorName",
    "supplierRating",
    "manufacturerVendorName",
    "manufacturerRating",
    "brandName",
  ];
  public dataSource = new MatTableDataSource<IViewVendorCommodityData>();

  showCreateFormBoolean = false;
  showEditForm = false;

  ngOnInit(): void {
    super.ngOnInit();
    this.setVendorsList();
    this.loadData();
  }

  setVendorsList() {
    this.vendorDataService.getVendors().subscribe((data) => {
      if (data) {
        this.vendorDataService.setVendors(data);
      }
    });
  }

  loadData() {
    this.vendorDataService
      .getVendorById(this.vendorId.toString())
      .subscribe((vendor) => {
        if (vendor) {
          this.vendor = vendor;

          this.vendorDataService
            .getAdminVendorCommodityData(this.vendorId.toString())
            .subscribe((data) => {
              this.dataSource.data = data;
              setTimeout(() => {
                this.dataSource.paginator = this.paginator;
                this.paginator.pageSize = 10;
              });
              this.dataSourceLoading = false;
            });
        }
      });
  }

  closeCommentBox(): void {
    this.showCommentBox = false;
    this.comment = "";
    this.currentAction = "";
    this.currentCommodityId = null;
  }

  setCommodityFlag(
    commodityId: number,
    action: string,
    comments: string
  ): void {
    this.vendorDataService
      .setCommodityActiveFlag(
        commodityId,
        action === "activate" ? true : false,
        comments
      )
      .subscribe(() => {
        this.dataSourceLoading = true;
        this.loadData();
      });
  }

  openCommentBox(
    action: string,
    vendorCommodity: IViewVendorCommodityData
  ): void {
    const dialogRef = this.dialog.open(CommentBoxDialogComponent, {
      width: "400px",
      data: {
        title:
          action === "activate" ? "Activate Commodity" : "Deactivate Commodity",
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.setCommodityFlag(
          vendorCommodity.vendorCommodityId,
          action,
          result
        );
      } else {
      }
    });
  }

  showCreateForm() {
    this.showCreateFormBoolean = true;
    this.showEditForm = false;
  }

  editCommodity(commodity: IViewVendorCommodityData) {
    this.showEditForm = true;
    this.showCreateFormBoolean = false;
    this.vendorCommodityService.storeEditedViewVendorCommodityData(
      commodity,
      this.vendor
    );
  }

  cancelForm() {
    this.dataSourceLoading = true;
    this.showForm = false;
    this.showEditForm = false;
    this.showCreateFormBoolean = false;
    this.loadData();
  }

  refreshCommodities(): void {
    this.loadData();
  }
}
