import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormsService } from '../../services/forms.service';

@Component({
  selector: 'app-forms',
  templateUrl: './forms.component.html',
})
export class FormsComponent implements OnInit {
  links: any[] = [];
  ngOnInit(): void {
  }
  constructor (private formsService: FormsService){}
  downloadSPRF(): void {
    this.formsService.getSupplierPerformanceRatingForm().subscribe((data) => {
      const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = `AQUA-2 Supplier Performance Rating Worksheet Rev 3.xlsx`;
      a.click();
      window.URL.revokeObjectURL(url);
    }, error => {
      console.error('Error downloading the SupplierPerformanceRating report', error);
    });
  }

}
