import { Component, Input, OnInit } from "@angular/core";
import { FormBuilder } from "@angular/forms";
import { PerformanceTrackingService } from "../../../services/vendor-information/performance-tracking.service";
import { BaseComponent } from "../../../shared/base/base.component";
import { UserPermissionsService } from "../../../services/common/user-permissions.service";
import { VendorDataService } from "../../../services/vendor-data.service";
import { viewVendorHistoryData } from "../../../interfaces/vendor-information/viewVendorHistory";

@Component({
  selector: "history-tab-component",
  templateUrl: "./history-tab.component.html",
})
export class HistoryTabComponent extends BaseComponent implements OnInit {
  @Input() vendorId: number;
  vendorHistoryData: viewVendorHistoryData[] = [];
  public dataSourceLoading = true;

  constructor(
    private fb: FormBuilder,
    private performanceService: PerformanceTrackingService,
    userPermissionsService: UserPermissionsService,
    private vendorDataService: VendorDataService
  ) {
    super(userPermissionsService);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.vendorDataService
      .loadVendorHistory(this.vendorId)
      .subscribe((data) => {
        this.vendorHistoryData = data;
        this.dataSourceLoading = false;
      });
  }
}
