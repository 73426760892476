import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { PerformanceTrackingSearchFilter } from '../../interfaces/administration/PerformanceTrackingSearchFilter';

const apiUrl=environment.apiUrl;
@Injectable({
  providedIn: 'root'
})
export class ManufacturersNameService {

  constructor(private http: HttpClient) { }
  public getManufacturersbyNameDetails(params: HttpParams): Observable<any> {
    return this.http.get(`${apiUrl}/Qareport/ManufacturersByNameReport`, { params, responseType: 'blob' });
  }
  public getAssignmentLog(params: HttpParams): Observable<any> {
    return this.http.get(`${apiUrl}/Qareport/AssignmentLog`,{ params, responseType: 'blob' });
  }
  public getClosedJob(params: HttpParams): Observable<any> {
    return this.http.get(`${apiUrl}/Qareport/ClosedJob`,{ params, responseType: 'blob' });
  }
  public inspecterByClientDetails(params: HttpParams): Observable<any> {
    return this.http.get(`${apiUrl}/report/QAActivityReportByClients`,{ params, responseType: 'blob' });
  }
  public CreateQAActivityByDateReport(params: HttpParams): Observable<any> {
    return this.http.get(`${apiUrl}/report/QAActivityByDateReport`,{ params, responseType: 'blob' });
  }
  public CreateQAActivityByPOReport(params: HttpParams): Observable<any> {
    return this.http.get(`${apiUrl}/report/QAActivityByPOReport`,{ params, responseType: 'blob' });
  }
  public CreateQAActivityReportByQAE(params: HttpParams): Observable<any> {
    return this.http.get(`${apiUrl}/report/QAActivityReportByQAE`,{ params, responseType: 'blob' });
  }
  public qaActivityReportByInspector(params: HttpParams): Observable<any> {
    return this.http.get(`${apiUrl}/report/QAActivityByInspector`,{ params, responseType: 'blob' });
  }
  public agencyOrInspector(params: HttpParams): Observable<any> {
    return this.http.get(`${apiUrl}/report/AgencyOrInspectorReport`,{ params, responseType: 'blob' });
  }
 
  public getInspectorByCommodity(params: HttpParams): Observable<any> {
    return this.http.get(`${apiUrl}/qareport/InspectorByCommodity`, { params, responseType: 'blob' });
  }
  public getManufacturerByCommodity(params: HttpParams): Observable<any> {
    return this.http.get(`${apiUrl}/qareport/ManufacturersByCommodityReport`, { params, responseType: 'blob' });
  }
  public getsptreport(filter : PerformanceTrackingSearchFilter): Observable<any> {
    return this.http.post(`${apiUrl}/report/SPTReport`, filter, { responseType: 'blob' as 'json'});
  }
}
