import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "projects/aqua3/src/environments/environment";
import { Observable } from "rxjs";
import { ICurrencyData } from "../../interfaces/qa-management/currencyData";
import { IAttachmentTypeData } from "../../interfaces/qa-management/attachmentTypeData";
const apiUrl = environment.apiUrl;

@Injectable({
  providedIn: "root",
})
export class UploadDocumentService {
  constructor(private http: HttpClient) {}

  public getAttachmentTypes(): Observable<IAttachmentTypeData[]> {
    return this.http.get<IAttachmentTypeData[]>(
      `${apiUrl}/documents/attachment-types`
    );
  }

  public getReferenceNumbers(attachmentType: string): Observable<string[]> {
    return this.http.get<string[]>(
      `${apiUrl}/documents/attachment-types/${attachmentType}`
    );
  }

  public uploadDocument(formData: FormData): Observable<any> {
    return this.http.post(`${apiUrl}/documents/upload`, formData);
  }

  public getIContract(): Observable<Blob> {
    return this.http.get(`${apiUrl}/documents/get-icontract`, {
      responseType: "blob",
    });
  }

  public uploadIContract(formData: FormData): Observable<any> {
    return this.http.post(`${apiUrl}/documents/upload-icontract`, formData);
  }
}
