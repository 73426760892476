import { Component, OnInit } from "@angular/core";
import { FormGroup, FormBuilder, Validators, FormArray } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { WorkRequestsDataService } from "../../../services/qa-management/work-requests/work-requests.service";
import { LoggedUserService } from "../../../services/common/logged-user.service";
import { ILoggedUserData } from "../../../interfaces/common/loggedUserData";
import { IWorkRequestRFQData } from "../../../interfaces/qa-management/work-requests/workRequestRFQData";
import { IUserData } from "../../../interfaces/administration/userData";
import { UserRequestsDataService } from "../../../services/administration/user-requests.service";
import { AgenciesAndInspectorsDataService } from "../../../services/qa-management/agencies-inspectors.service";
import { IAgencyData } from "../../../interfaces/qa-management/agencyData";
import { BaseComponent } from "../../../shared/base/base.component";
import { UserPermissionsService } from "../../../services/common/user-permissions.service";

@Component({
  selector: "add-rfq-form.component",
  templateUrl: "./add-rfq-form.component.html",
  styleUrls: ["./add-rfq-form.component.css"],
})
export class AddRFQFormComponent extends BaseComponent implements OnInit {
  workRequestId: number;
  currentDate: Date;
  userFullName: string = "";
  loggedUserData: ILoggedUserData;
  public agenciesData: IAgencyData[] = [];
  checkedAgencies: { [key: number]: boolean } = {};

  addForm: FormGroup;
  validationMessages = [];

  constructor(
    private workRequestsService: WorkRequestsDataService,
    private fb: FormBuilder,
    private loggedUserService: LoggedUserService,
    private userRequestsService: UserRequestsDataService,
    private agenciesAndInspectorsDataService: AgenciesAndInspectorsDataService,
    private router: Router,
    private route: ActivatedRoute,
    userPermissionsService: UserPermissionsService
  ) {
    super(userPermissionsService);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.loadAgencies();
    const id = this.route.snapshot.paramMap.get("id");
    if (id) {
      this.workRequestId = Number(id);
    }
    this.currentDate = new Date();
    this.loggedUserService
      .getLoggedUserData()
      .subscribe((loggedUser: ILoggedUserData | null) => {
        if (loggedUser) {
          this.loggedUserData = loggedUser;
          this.userRequestsService
            .getUserFullNameById(loggedUser.userId)
            .subscribe((fullName) => {
              this.userFullName = fullName;
            });
        }
      });

    this.addForm = this.fb.group({
      notes: [""],
    });
  }

  onCheckboxChange(agencyId: number, isChecked: boolean) {
    if (isChecked) {
      this.checkedAgencies[agencyId] = true;
    } else {
      delete this.checkedAgencies[agencyId];
    }
  }

  getCheckedAgencies(): IAgencyData[] {
    return this.agenciesData.filter(
      (agency) => this.checkedAgencies[agency.agencyId!]
    );
  }

  loadAgencies() {
    this.agenciesAndInspectorsDataService.getAllAgencies().subscribe((data) => {
      this.agenciesData = data;
    });
  }

  submitForm(): void {
    let messagesValidationsList: string[] = [];
    let formNotes: string = this.addForm.get("notes").value;
    const selectedAgencyIds: number[] = Object.keys(this.checkedAgencies)
      .filter((key) => this.checkedAgencies[+key])
      .map((key) => +key);

    // Validate form controls
    if (selectedAgencyIds.length === 0) {
      messagesValidationsList.push("Please select at least one Agency.");
    }

    this.validationMessages = messagesValidationsList;
    if (this.addForm.valid && this.validationMessages.length === 0) {
      const rfq: IWorkRequestRFQData = {
        workRequestId: this.workRequestId,
        notes: formNotes,
        createdOn: new Date(),
        updatedOn: new Date(),
        agencyIds: selectedAgencyIds,
      };

      rfq.createdBy = this.loggedUserData.cai;
      rfq.updatedBy = this.loggedUserData.cai;
      this.workRequestsService.addWorkRequestRFQ(rfq).subscribe({
        next: () => {
          this.router.navigate([
            `/qa-management/work-requests/edit-work-request/${this.workRequestId}`,
          ]);
        },
        error: (error) => {
          console.error("POST /workRequests/add-rfq" + error);
        },
      });
    }
  }

  cancelChanges(): void {
    this.router.navigate([
      `/qa-management/work-requests/edit-work-request/${this.workRequestId}`,
    ]);
  }
}
