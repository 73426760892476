// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.small-input {
  width: 60px;
}

.table-container {
  max-height: 400px;
  overflow-y: auto;
  overflow-x: auto;
}

.mandatory {
  color: red;
}

.is-invalid {
  border: 1px solid red;
}

.table-bordered {
  border: 1px solid #ced4da;
  border-collapse: collapse;
}

.table-bordered th,
.table-bordered td {
  border: 1px solid #ced4da;
}

.vertical-align-middle {
  vertical-align: middle;
}

:host ::ng-deep .mat-mdc-form-field-subscript-wrapper {
  height: 0;
}
`, "",{"version":3,"sources":["webpack://./projects/aqua3/src/app/qa-management/work-requests/add-work-request-form.component.css"],"names":[],"mappings":"AAAA;EACE,WAAW;AACb;;AAEA;EACE,iBAAiB;EACjB,gBAAgB;EAChB,gBAAgB;AAClB;;AAEA;EACE,UAAU;AACZ;;AAEA;EACE,qBAAqB;AACvB;;AAEA;EACE,yBAAyB;EACzB,yBAAyB;AAC3B;;AAEA;;EAEE,yBAAyB;AAC3B;;AAEA;EACE,sBAAsB;AACxB;;AAEA;EACE,SAAS;AACX","sourcesContent":[".small-input {\n  width: 60px;\n}\n\n.table-container {\n  max-height: 400px;\n  overflow-y: auto;\n  overflow-x: auto;\n}\n\n.mandatory {\n  color: red;\n}\n\n.is-invalid {\n  border: 1px solid red;\n}\n\n.table-bordered {\n  border: 1px solid #ced4da;\n  border-collapse: collapse;\n}\n\n.table-bordered th,\n.table-bordered td {\n  border: 1px solid #ced4da;\n}\n\n.vertical-align-middle {\n  vertical-align: middle;\n}\n\n:host ::ng-deep .mat-mdc-form-field-subscript-wrapper {\n  height: 0;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
