import { Component, OnInit } from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { AgenciesAndInspectorsDataService } from "../../../services/qa-management/agencies-inspectors.service";
import { IInspectorData } from "../../../interfaces/qa-management/inspectorData";
import { ILookupData } from "../../../interfaces/administration/lookupData";
import { IAgencyData } from "../../../interfaces/qa-management/agencyData";
import { CountriesAndStatesService } from "../../../services/countries-states.service";
import { LoggedUserService } from "../../../services/common/logged-user.service";
import { ILoggedUserData } from "../../../interfaces/common/loggedUserData";
import { CurrenciesDataService } from "../../../services/qa-management/currencies.service";
import { ICurrencyData } from "../../../interfaces/qa-management/currencyData";
import { UserPermissionsService } from "../../../services/common/user-permissions.service";
import { BaseComponent } from "../../../shared/base/base.component";

@Component({
  selector: "add-inspector-form.component",
  templateUrl: "./add-inspector-form.component.html",
})
export class AddInspectorFormComponent extends BaseComponent implements OnInit {
  addForm: FormGroup;
  validationMessages = [];
  inspectorData: IInspectorData;
  agencyName: string = "";
  countryName: string = "";
  stateName: string = "";
  lastUpdatedBy: string = "";
  public countriesData: ILookupData[] = [];
  public statesData: ILookupData[] = [];
  public agenciesData: IAgencyData[] = [];
  currenciesData: ICurrencyData[] = [];

  sourcePage: string;

  constructor(
    private fb: FormBuilder,
    private loggedUserService: LoggedUserService,
    private agenciesAndInspectorsDataService: AgenciesAndInspectorsDataService,
    private currencyDataService: CurrenciesDataService,
    private countriesAndStatesService: CountriesAndStatesService,
    private router: Router,
    private route: ActivatedRoute,
    userPermissionsService: UserPermissionsService
  ) {
    super(userPermissionsService);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.route.queryParams.subscribe((params) => {
      this.sourcePage = params["source"];
    });

    this.loadCountries();
    this.loadAgencies();
    this.loadCurrencies();

    this.addForm = this.fb.group({
      lastName: ["", Validators.required],
      firstName: ["", Validators.required],
      middleInitial: [""],
      agency: ["agencyDefault", Validators.required],
      address: [""],
      country: ["countryDefault", Validators.required],
      city: [""],
      state: ["stateDefault", Validators.required],
      zip: [""],
      phone: [""],
      fax: [""],
      cellPhone: [""],
      pager: [""],
      email: [""],
      currency: ["currencyDefault", Validators.required],
      inspectionRate: [null],
      travelRate: [null],
      reportRate: [null],
      mileageRate: [null],
      overtimeRate: [null],
      recommended: [false],
      active: [false],
    });
  }

  loadCountries() {
    this.countriesAndStatesService.getAllCountries().subscribe((data) => {
      this.countriesData = data;

      this.loadStates("countryDefault");
    });
  }

  loadStates(selectedCountryName: string) {
    let countryId: string;

    if (selectedCountryName === "countryDefault") {
      countryId = "118";
    } else {
      const selectedCountry: ILookupData =
        this.getCountryByName(selectedCountryName);
      countryId = selectedCountry.lookupId.toString();
    }

    this.countriesAndStatesService
      .getStateByCountryId(countryId)
      .subscribe((data) => {
        this.statesData = data;
      });
  }

  loadAgencies() {
    this.agenciesAndInspectorsDataService.getAllAgencies().subscribe((data) => {
      this.agenciesData = data;
    });
  }

  onCountryChange(selectedCountryName: string) {
    this.loadStates(selectedCountryName);
  }

  getCountryByName(name: string): ILookupData | null {
    var country = this.countriesData.find((country) => country.name === name);

    if (country === undefined) return null;
    else return country;
  }

  getStateByName(name: string): ILookupData | undefined {
    return this.statesData.find((state) => state.name === name);
  }

  getAgencyByName(name: string): IAgencyData | undefined {
    return this.agenciesData.find((agency) => agency.name === name);
  }

  getCurrencyByCode(code: string): ICurrencyData | undefined {
    return this.currenciesData.find((currency) => currency.code === code);
  }

  loadCurrencies() {
    this.currencyDataService.getCurrencies().subscribe((data) => {
      this.currenciesData = data;
    });
  }

  backToInspectorsView(): void {
    this.router.navigate([this.sourcePage]);
  }

  submitForm(): void {
    let messagesValidationsList: string[] = [];
    let formLastName: string = this.addForm.get("lastName").value;
    let formFirstName: string = this.addForm.get("firstName").value;
    let formAgencyName: string = this.addForm.get("agency").value;
    let formInspectionRate: number = this.addForm.get("inspectionRate").value;
    let formTravelRate: number = this.addForm.get("travelRate").value;
    let formReportRate: number = this.addForm.get("reportRate").value;
    let formMileageRate: number = this.addForm.get("mileageRate").value;
    let formOvertimeRate: number = this.addForm.get("overtimeRate").value;
    let formCurrency: string = this.addForm.get("currency").value;
    let formMiddleInitial: string = this.addForm.get("middleInitial").value;
    let formAddress: string = this.addForm.get("address").value;
    let formCountry: string = this.addForm.get("country").value;
    let formCity: string = this.addForm.get("city").value;
    let formState: string = this.addForm.get("state").value;
    let formZip: string = this.addForm.get("zip").value;
    let formPhone: string = this.addForm.get("phone").value;
    let formFax: string = this.addForm.get("fax").value;
    let formCellPhone: string = this.addForm.get("cellPhone").value;
    let formPager: string = this.addForm.get("pager").value;
    let formEmail: string = this.addForm.get("email").value;
    let formRecommended: boolean = this.addForm.get("recommended").value;
    let formActive: boolean = this.addForm.get("active").value;

    if (formLastName === "")
      messagesValidationsList.push("Last Name is Required.");

    if (formFirstName === "")
      messagesValidationsList.push("First Name is Required.");

    let agency: IAgencyData | undefined = this.getAgencyByName(formAgencyName);
    if (agency == null) messagesValidationsList.push("Agency is Required.");

    if (
      formInspectionRate !== null ||
      formTravelRate !== null ||
      formReportRate !== null ||
      formMileageRate !== null ||
      formOvertimeRate !== null
    ) {
      if (formCurrency === "currencyDefault") {
        messagesValidationsList.push("Please select Currency.");
      }
    }

    this.validationMessages = messagesValidationsList;

    if (this.addForm.valid && this.validationMessages.length === 0) {
      let country: ILookupData | undefined = this.getCountryByName(formCountry);
      let state: ILookupData | undefined = this.getStateByName(formState);

      this.inspectorData = {
        lastName: formLastName,
        firstName: formFirstName,
        middleInitial: formMiddleInitial,
        agencyId: agency?.agencyId,
        address: formAddress,
        countryId: country?.lookupId,
        city: formCity,
        stateId: state?.lookupId,
        postalCode: formZip,
        phone: formPhone,
        fax: formFax,
        cellphone: formCellPhone,
        pager: formPager,
        email: formEmail,
        inspectionRate: formInspectionRate,
        travelRate: formTravelRate,
        reportRate: formReportRate,
        mileageRate: formMileageRate,
        currency: formCurrency == "currencyDefault" ? "" : formCurrency,
        overtimeRate: formOvertimeRate,
        recommended: formRecommended,
        active: formActive,
        updatedOn: new Date(),
        insertedOn: new Date(),
        updatedBy: "",
        insertedBy: "",
      };

      this.loggedUserService
        .getLoggedUserData()
        .subscribe((loggedUser: ILoggedUserData | null) => {
          if (loggedUser) {
            this.inspectorData.updatedBy = loggedUser.userId.toString();
            this.inspectorData.insertedBy = loggedUser.userId.toString();
            this.agenciesAndInspectorsDataService
              .addInspector(this.inspectorData)
              .subscribe({
                next: () => {
                  this.router.navigate([
                    "/qa-management/agencies-and-inspectors",
                  ]);
                },
                error: (error) => {
                  console.error(error);
                },
              });
          } else
            console.error(
              "Cannot fetch logged user data in add-inspector form."
            );
        });
    }
  }
}
