import { Injectable } from "@angular/core";
import { Sort } from "@angular/material/sort";

@Injectable({
  providedIn: "root",
})
export class TableSortService {
  sortDataSource(originalDataSource: any[], event: Sort): any[] {
    const sortDirection = event.direction;
    const activeSortColumn = event.active;

    let resultDataSource = originalDataSource.slice();
    if (sortDirection === "asc") {
      return resultDataSource.sort((a, b) => {
        return this.compareValues(a, b, activeSortColumn);
      });
    } else if (sortDirection === "desc") {
      return resultDataSource.sort((a, b) => {
        return this.compareValues(b, a, activeSortColumn);
      });
    } else {
      return resultDataSource;
    }
  }

  compareValues(a: any, b: any, activeSortColumn: string): number {
    const fields = activeSortColumn.split("+");
    const aValue = this.getCombinedValue(a, fields);
    const bValue = this.getCombinedValue(b, fields);

    return aValue.localeCompare(bValue);
  }

  getCombinedValue(obj: any, fields: string[]): string {
    let combinedValue = "";

    for (const field of fields) {
      if (obj[field]) {
        combinedValue += obj[field];
      }
    }

    return combinedValue;
  }
}
