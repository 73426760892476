import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { environment } from "projects/aqua3/src/environments/environment";
import { BehaviorSubject, Observable } from "rxjs";
import { IEvaluationStatus as IEvaluationStatus } from "../../interfaces/qa-management/audit-observation/evaluationStatus";
import { IAuditObservationDetail } from "../../interfaces/qa-management/audit-observation/auditObservationDetailData";
import { PageViewMode } from "../../interfaces/common/pageViewMode";
import { RatingType } from "../../qa-management/manufacturer-audit-and-observation/mao.component";
import { IInspectorEvaluationDetails } from "../../interfaces/qa-management/inspector-evaluation/inspectorEvaluationDetails";
import { IAddInspectorEvaluation } from "../../interfaces/qa-management/inspector-evaluation/add-inspector-evaluation";
const apiUrl = environment.apiUrl + '/inspectors-evaluation';

@Injectable({
    providedIn: "root",
})
export class InspectorEvaluationService {
    private IEModeSubject = new BehaviorSubject<PageViewMode>(PageViewMode.Base);
    IEMode$ = this.IEModeSubject.asObservable();

    private IEAddEditModeSubject = new BehaviorSubject<PageViewMode>(PageViewMode.Base);
    IEAddEditMode$ = this.IEAddEditModeSubject.asObservable();

    private IEObjectSubject = new BehaviorSubject<IAddInspectorEvaluation>(null);
    IEObject$ = this.IEObjectSubject.asObservable();

    private commoditiesFormData: any;

    constructor(private http: HttpClient) { }

    public setIEMode(mode: PageViewMode): void {
        this.IEModeSubject.next(mode);
    }

    public setIEAddMode(mode: PageViewMode): void {
        this.IEAddEditModeSubject.next(mode);
    }

    public setIEObject(obj: IAddInspectorEvaluation): void {
        this.IEObjectSubject.next(obj);
    }

    public getAllEvaluationStatus(): Observable<IEvaluationStatus[]> {
        return this.http.get<IEvaluationStatus[]>(`${apiUrl}/evaluation-status/all`);
    }

    public getAllDetails(filters: any): Observable<IAuditObservationDetail[]> {
        let params = new HttpParams();
        Object.keys(filters).forEach(key => {
            if (filters[key] != null && filters[key] != undefined) {
                params = params.append(key, filters[key]);
            }
        });

        return this.http.get<IAuditObservationDetail[]>(`${apiUrl}/all`, { params });
    }

    public getDetailsById(id: number): Observable<IInspectorEvaluationDetails> {
        return this.http.get<IInspectorEvaluationDetails>(`${apiUrl}/details/${id}`);
    }

    public getRatingClass(rating: string): string {
        if (rating == RatingType.Qualified) {
            return 'rating-qualified';
        } else if (rating == RatingType.ConditionallyQualified) {
            return 'rating-conditionally-qualified';
        } else if (rating == RatingType.NotQualified) {
            return 'rating-not-qualified';
        } else if (rating == RatingType.NotAvailable || rating == null || rating == "") {
            return 'rating-not-available';
        }
    }

    public getRatingStringValue(rating: string): string {
        if (rating == RatingType.Qualified) {
            return RatingType.Qualified;
        } else if (rating == RatingType.ConditionallyQualified) {
            return RatingType.ConditionallyQualified;
        } else if (rating == RatingType.NotQualified) {
            return RatingType.NotQualified;
        } else if (rating == RatingType.NotAvailable || rating == null || rating == "") {
            return RatingType.NotAvailable;
        }
    }

    public addInspectorEvaluation(newInspectorEvaluation: IAddInspectorEvaluation
    ): Observable<number> {
        return this.http.post<number>(
            `${apiUrl}/add`,
            newInspectorEvaluation
        );
    }

    public updateInspectorEvaluation(editingInspectorEvaluation: IAddInspectorEvaluation): Observable<any> {
        return this.http.put<number>(`${apiUrl}/update`, editingInspectorEvaluation);
    }

    setCommoditiesFormData(data: any) {
        this.commoditiesFormData = data;
    }

    getCommoditiesFormData() {
        return this.commoditiesFormData;
    }
}
